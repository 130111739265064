import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./FedInfo.css";

function FedCommittee() {
  const [committees, setCommittees] = useState([]);
  const url = useParams();

  useEffect(() => {
    const fetchIDs = async () => {
      const response = await axios.get(`/api/data/fed-committee/${url.term}`);
      const sortedArray = response.data.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );

      console.log(sortedArray);
      setCommittees(sortedArray);
    };
    fetchIDs();
  }, [url.term]);

  return (
    <div>
      <div>
        <Row>
          {committees.map((user) => (
            <Col
              style={{ margin: "1em 0" }}
              lg={6}
              md={12}
              xs={12}
              key={user.id}
            >
              <Card className="idInfoCard">
                <Row>
                  <Col xs={4}>
                    <Card.Img
                      className="idInfoImage"
                      style={{ border: "0", borderRadius: "15px" }}
                      src={user.photo}
                      alt={user.name}
                    />
                  </Col>
                  <Col xs={8}>
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontSize: "22px",
                          fontWeight: "700",
                          color: "#3f0986",
                        }}
                      >
                        JSGian {user.name}
                      </Card.Title>
                      <Card.Subtitle
                        style={{ fontSize: "20px", color: "#4d0020" }}
                      >
                        JSGIF {user.post}
                      </Card.Subtitle>

                      <Row style={{ marginTop: "10px" }}>
                        <Col>
                          <p className="idInfoText1">
                            <i class="fa-solid fa-phone"></i> {user.mobile}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <p className="idInfoText1">
                          <i class="fa-solid fa-envelope"></i> {user.email}
                        </p>
                      </Row>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default FedCommittee;
