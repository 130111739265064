import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import axios from "axios";
import backdrop from "../../images/Backdrop.jpg";
import podium from "../../images/Podium.jpg";
import "./banner.css";

import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../Loader";

function ZoomBG() {
  const user = useSelector((state) => state.authentication.user);

  const [submitted, setSubmitted] = useState("");

  const [regName, setRegName] = useState("");
  const [grpName, setGrpName] = useState("");

  async function FormSubmit(event) {
    event.preventDefault();
    let formField = new FormData();

    formField.append("grpName", grpName);

    await axios({
      method: "post",
      url: "/api/banner/groups/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        setSubmitted(true);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  const responseId = useParams();

  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    setLoading(true);

    const backDropImage = document.getElementById("backDrop-background");
    html2canvas(backDropImage, { scale: 18 }).then((canvas) => {
      canvas.toBlob(
        (blob) => {
          saveAs(blob, `${grpName} Backdrop.jpg`);
        },
        "image/jpeg",
        1
      );
    });

    const podiumImage = document.getElementById("podium-background");
    html2canvas(podiumImage, { scale: 10.8 })
      .then((canvas) => {
        canvas.toBlob(
          (blob) => {
            saveAs(blob, `${grpName} Podium.jpg`);
          },
          "image/jpeg",
          1 // set the quality to 90%
        );
      })
      .finally(() => {
        setLoading(false); // Hide the loader when the download is complete
      });
  };

  const getBackFontSize = () => {
    const words = grpName.split(" ");

    for (let i = 0; i < words.length; i++) {
      if (words[i].length > 10) {
        const extraLength = words[i].length - 10;
        return 58 - extraLength * 3;
      }
    }

    return 64;
  };

  const getPodiumFontSize = () => {
    const words = grpName.split(" ");

    for (let i = 0; i < words.length; i++) {
      if (words[i].length > 10) {
        const extraLength = words[i].length - 10;
        return 39 - extraLength * 3;
      }
    }

    return 46;
  };

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get("/user/region");

    setRegions(response.data.sort((a, b) => a.region.localeCompare(b.region)));
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups`);

    setGroups(response.data);
  };

  useEffect(() => {
    fetchGroups();
  }, [regName]);

  return (
    <div>
      {loading && (
        <div className="loader">
          <Loader text="Downloading..." />
        </div>
      )}
      <Container>
        <Form>
          <Row>
            <Col md={8}>
              <h2 style={{ textTransform: "capitalize" }}>
                Group Banners Download
              </h2>
            </Col>
          </Row>
          {(() => {
            if (
              typeof user !== "undefined" &&
              typeof user.username !== "undefined"
            ) {
              return (
                <Row className="mb-3">
                  <Col md={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Group Name</Form.Label>
                      <Form.Select
                        name="grpName"
                        value={grpName}
                        onChange={(e) => setGrpName(e.target.value)}
                      >
                        <option>Select your Group</option>
                        <option style={{ textTransform: "capitalize" }}>
                          {user?.jsgGroupName.charAt(0).toUpperCase() +
                            user?.jsgGroupName.slice(1).toLowerCase()}
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Can not find your Group?</Form.Label>
                      <Form.Control
                        placeholder="Type your group name here"
                        name="grpName"
                        value={grpName}
                        onChange={(e) => setGrpName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              );
            } else
              return (
                <Row>
                  <Col md={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Region Name</Form.Label>
                      <Form.Select
                        type="text"
                        name="regName"
                        value={regName}
                        onChange={(e) => setRegName(e.target.value)}
                      >
                        <option>Select your Region</option>
                        {regions.map((region, index) => (
                          <option
                            key={index}
                            style={{ textTransform: "capitalize" }}
                            value={region.region.toLowerCase()}
                          >
                            {region.region.charAt(0).toUpperCase() +
                              region.region.slice(1).toLowerCase()}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Group Name</Form.Label>
                      <Form.Select
                        name="grpName"
                        value={grpName}
                        onChange={(e) => setGrpName(e.target.value)}
                      >
                        <option>Select your Group</option>
                        {groups.map((group, index) => (
                          <option
                            key={index}
                            style={{ textTransform: "capitalize" }}
                          >
                            {group.group.charAt(0).toUpperCase() +
                              group.group.slice(1).toLowerCase()}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={12} xs={12}>
                    <Form.Group>
                      <Form.Label>Can not find your Group?</Form.Label>
                      <Form.Control
                        placeholder="Type your group name here"
                        name="grpName"
                        value={grpName}
                        onChange={(e) => setGrpName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              );
          })()}

          <Button className="btn--outline--dark" onClick={FormSubmit}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </Button>
        </Form>

        <div className="bannerBox">
          <Col xs={10}>
            <div
              id="backDrop-background"
              className="backDropContainer"
              style={{ backgroundImage: `url(${backdrop})` }}
            >
              <Row style={{ height: "600px" }}>
                <Col xs={6}></Col>
                <Col xs={6} className="backDropTextContainer">
                  <h2
                    className="backDropText"
                    style={{ fontSize: `${getBackFontSize()}px` }}
                  >
                    JSG
                  </h2>
                  <h2
                    className="backDropText"
                    style={{ fontSize: `${getBackFontSize()}px` }}
                  >
                    {grpName !== "" ? grpName : " Your Group Name"}
                  </h2>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={2}>
            <div
              id="podium-background"
              className="podiumContainer"
              style={{ backgroundImage: `url(${podium})` }}
            >
              <Row
                style={{
                  height: "600px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Col xs={6}></Col>
                <Col xs={6} className="podiumTextContainer">
                  <h2
                    className="podiumText"
                    style={{ fontSize: `${getPodiumFontSize()}px` }}
                  >
                    JSG{" "}
                  </h2>
                  <h2
                    className="podiumText"
                    style={{ fontSize: `${getPodiumFontSize()}px` }}
                  >
                    {" "}
                    {grpName !== "" ? grpName : " Your Group Name"}
                  </h2>
                </Col>
              </Row>
            </div>
          </Col>
        </div>

        {(() => {
          if (submitted === true) {
            return (
              <Button className="btn--outline--dark" onClick={handleDownload}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Download
              </Button>
            );
          } else
            return (
              <>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      Submit first to download your background
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <Button className="btn--outline--dark" disabled>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      Download
                    </Button>
                  </span>
                </OverlayTrigger>
              </>
            );
        })()}
      </Container>
    </div>
  );
}

export default ZoomBG;
