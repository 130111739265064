import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, Table } from "react-bootstrap";
import "./RegionScreen.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector } from "react-redux";
import RegEvents from "../events/RegEvents";

const RegionScreen = () => {
  const user = useSelector((state) => state.authentication.user);

  const [officeBearers, setOfficeBearers] = useState([]);
  const [events, setEvents] = useState([]);
  const [groups, setGroups] = useState([]);

  const url = useParams();

  const fetchOfficeBearer = async () => {
    const obResponse = await axios.get(`/api/data/${url.regName}/region-ob/2023-25`);

    const sortedData = obResponse.data.sort((a, b) => a.order - b.order);

    console.log(sortedData);
    setOfficeBearers(sortedData);
  };

  // const fetchEvents = async () => {
  //   const eventsResponse = await axios.get(`/api/reg/events-list/${url.regName}`);
  //   console.log(eventsResponse.data);
  //   setEvents(eventsResponse.data);
  // };

  const fetchGroups = async () => {
    const groupsResponse = await axios.get(`/user/${url.regName}/groups`);

    console.log(groupsResponse.data);
    const groupData = groupsResponse.data.sort((a, b) => a.group.localeCompare(b.group));
    setGroups(groupData);
  };

  useEffect(() => {
    fetchOfficeBearer();
    // fetchEvents();
    fetchGroups();
  }, [url.regName]);

  return (
    <div>
      <Row>
        <h1 className="regName">{url.regName} Region</h1>
      </Row>
      <Row>
        <h2 className="regHead">Team:</h2>
      </Row>
      <Row>
        {officeBearers.map((user) => (
          <Col
            style={{
              margin: "1em 1em",
              display: "flex",
              justifyContent: "center",
            }}
            sm="auto"
            md="auto"
            lg="auto"
            key={user.id}
          >
            <div className="flip" style={{ height: "320px" }}>
              <Card className="front" style={{ height: "320px" }}>
                <Row
                  style={{
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                  }}
                >
                  <Card.Img className="cardImage1" src={user.photo}></Card.Img>
                </Row>
                <Row>
                  <h2 className="userName">
                    JSGian <span style={{ textTransform: "capitalize" }}>{user.name}</span>
                  </h2>
                </Row>
                <Row>
                  <h3 className="userPost" style={{ textTransform: "capitalize" }}>
                    {user.post}
                  </h3>
                </Row>
              </Card>
              <Card className="back" style={{ height: "320px" }}>
                <h2 className="userName1">{user.name}</h2>
                <p className="userInfo2">
                  <span className="infoHead">Region : </span>
                  {user.region} Region
                </p>
                <p className="userInfo1">
                  <span className="infoHead">Group : </span> {user.group}
                </p>

                <p className="userInfoEmail">
                  <span className="infoHead">Email : </span> {user.email}
                </p>
                <p className="userInfo1">
                  <span className="infoHead">Contact Number : </span>
                  {user.mobile}
                </p>
                <p className="userInfo1">
                  <span className="infoHead">Whatsapp Number : </span>
                  {user.mobile}
                </p>
              </Card>
            </div>
          </Col>
        ))}
      </Row>
      <Row>
        <h2 className="regHead">Groupwise List:</h2>
      </Row>
      <Row>
        <Table striped bordered hover responsive>
          <thead className="tableHead">
            <tr>
              <th style={{ width: "15%" }}>S. No.</th>
              <th style={{ width: "15%" }}>Group Number</th>
              <th style={{ width: "70%" }}>Group Name</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "center" }} className="tableBody">
            {groups.map((group, index) => (
              <LinkContainer to={`/group/${group.group.toLowerCase()}`}>
                <tr key={group.id}>
                  <td>{(index += 1)}</td>
                  <td>{group.id}</td>
                  <td style={{ textTransform: "uppercase" }}>JSG {group.group}</td>
                </tr>
              </LinkContainer>
            ))}
          </tbody>
        </Table>
      </Row>
      <Row>
        <h2 className="regHead">{url.regName} Region Events:</h2>
      </Row>
      <RegEvents />
      {/* <Row>
        {events.map((event, index) => (
          <Col style={{ margin: "1em 0" }} sm="auto" md="auto" lg="auto" xl="auto" key={event.id}>
            <Link to={`/reg/event/${event.id}`} className="customCard1_link">
              <div className="cardsX">
                <div className="customCard1 customCard1--1">
                  <div className="customCard1__info-hover"></div>
                  <Row className="customRow">
                    <img className="customCard1__img" alt={event.eTitle} src={event.ePhoto0}></img>
                    <img className="customCard1__img--hover" alt={event.eTitle} src={event.eThumbnail}></img>
                  </Row>
                  <Row className="customRow">
                    <div className="customCard1__info">
                      <span className="customCard1__category">{event.region}</span>
                      <h3 className="customCard1__title">{event.eTitle}</h3>
                      <h4 className="customCard1__date">
                        {event.eStartDate} - {event.eEndDate}
                      </h4>
                      <p className="customCard1__text">{event.eDescription}</p>
                    </div>
                  </Row>
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </Row> */}
    </div>
  );
};

export default RegionScreen;
