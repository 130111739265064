import React, { useState } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";

// creating functional component ans getting props from app.js and destucturing them
const BEditpage2 = ({ nextStep, handleFormData, prevStep, values }) => {
  //creating error state for validation
  const [error, setError] = useState(false);

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div>
      <Container>
        <Form>
          <Row>
            <Col xs={12}>
              <h2>Group Form B Edit</h2>
            </Col>
          </Row>
          <Row className="formSectionHeader">
            <h3>Founder President Details</h3>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Founder President Name</Form.Label>
                <Form.Control
                  name="founderPresName"
                  defaultValue={values.founderPresName}
                  placeholder="Founder President Name"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresName")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="founderPresPhoto"
                  defaultValue={values.founderPresPhoto}
                  type="file"
                  placeholder="Founder President Photo"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresPhoto")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="founderPresaddress"
                  defaultValue={values.founderPresaddress}
                  placeholder="Founder President Address"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresaddress")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  name="founderPresPinCode"
                  defaultValue={values.founderPresPinCode}
                  placeholder="Founder President Address Pincode"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresPinCode")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Office Landline</Form.Label>
                <Form.Control
                  name="founderPresOffLandLine"
                  defaultValue={values.founderPresOffLandLine}
                  placeholder="Founder President Office Landline"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresOffLandLine")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Residence Landline</Form.Label>
                <Form.Control
                  name="founderPresResLandLine"
                  defaultValue={values.founderPresResLandLine}
                  placeholder="Founder President Residence Landline"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresResLandLine")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name="founderPresMobileNumber"
                  defaultValue={values.founderPresMobileNumber}
                  placeholder="Founder President Mobile Number"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresMobileNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  name="founderPresWhatsappNumber"
                  defaultValue={values.founderPresWhatsappNumber}
                  placeholder="Founder President Whatsapp Number"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresWhatsappNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  name="founderPresEmail"
                  defaultValue={values.founderPresEmail}
                  placeholder="Founder President Email Address"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresEmail")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Occupation Details</Form.Label>
                <Form.Control
                  name="founderPresOccupation"
                  defaultValue={values.founderPresOccupation}
                  placeholder="Founder President Occupation"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresOccupation")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  name="founderPresBirthDay"
                  defaultValue={values.founderPresBirthDay}
                  type="date"
                  placeholder="Founder President Date of Birth"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresBirthDay")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Name</Form.Label>
                <Form.Control
                  name="founderPresSpouseName"
                  defaultValue={values.founderPresSpouseName}
                  placeholder="Founder President Spouse Name"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresSpouseName")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Date of Birth</Form.Label>
                <Form.Control
                  name="founderPresSpouseBirthDay"
                  defaultValue={values.founderPresSpouseBirthDay}
                  type="date"
                  placeholder="Founder President's Spouse Date of Birth"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresSpouseBirthDay")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Marraige Anniversary</Form.Label>
                <Form.Control
                  name="founderPresMarraigeDate"
                  defaultValue={values.founderPresMarraigeDate}
                  type="date"
                  placeholder="Founder President Marraige Anniversary"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("founderPresMarraigeDate")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="formSectionHeader">
            <h3>Nominated Former President</h3>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Nominated Former President Name</Form.Label>
                <Form.Control
                  name="nominatedFormerPres1Name"
                  defaultValue={values.nominatedFormerPres1Name}
                  placeholder="Nominated Former President Name"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1Name")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="nominatedFormerPres1Photo"
                  defaultValue={values.nominatedFormerPres1Photo}
                  type="file"
                  placeholder="Nominated Former President Photo"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1Photo")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="nominatedFormerPres1address"
                  defaultValue={values.nominatedFormerPres1address}
                  placeholder="Nominated Former President Address"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1address")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  name="nominatedFormerPres1PinCode"
                  defaultValue={values.nominatedFormerPres1PinCode}
                  placeholder="Nominated Former President Address Pincode"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1PinCode")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Office Landline</Form.Label>
                <Form.Control
                  name="nominatedFormerPres1OffLandLine"
                  defaultValue={values.nominatedFormerPres1OffLandLine}
                  placeholder="Nominated Former President Office Landline"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1OffLandLine")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Residence Landline</Form.Label>
                <Form.Control
                  name="nominatedFormerPres1ResLandLine"
                  defaultValue={values.nominatedFormerPres1ResLandLine}
                  placeholder="Nominated Former President Residence Landline"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1ResLandLine")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name="nominatedFormerPres1MobileNumber"
                  defaultValue={values.nominatedFormerPres1MobileNumber}
                  placeholder="Nominated Former President Mobile Number"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1MobileNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  name="nominatedFormerPres1WhatsappNumber"
                  defaultValue={values.nominatedFormerPres1WhatsappNumber}
                  placeholder="Nominated Former President Whatsapp Number"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1WhatsappNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  name="nominatedFormerPres1Email"
                  defaultValue={values.nominatedFormerPres1Email}
                  placeholder="Nominated Former President Email Address"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1Email")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Occupation Details</Form.Label>
                <Form.Control
                  name="nominatedFormerPres1Occupation"
                  defaultValue={values.nominatedFormerPres1Occupation}
                  placeholder="Nominated Former President Occupation"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1Occupation")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  name="nominatedFormerPres1BirthDay"
                  defaultValue={values.nominatedFormerPres1BirthDay}
                  type="date"
                  placeholder="Nominated Former President Date of Birth"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1BirthDay")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Name</Form.Label>
                <Form.Control
                  name="nominatedFormerPres1SpouseName"
                  defaultValue={values.nominatedFormerPres1SpouseName}
                  placeholder="Nominated Former President Spouse Name"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1SpouseName")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Date of Birth</Form.Label>
                <Form.Control
                  name="nominatedFormerPres1SpouseBirthDay"
                  defaultValue={values.nominatedFormerPres1SpouseBirthDay}
                  type="date"
                  placeholder="Nominated Former President's Spouse Date of Birth"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1SpouseBirthDay")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Marraige Anniversary</Form.Label>
                <Form.Control
                  name="nominatedFormerPres1MarraigeDate"
                  defaultValue={values.nominatedFormerPres1MarraigeDate}
                  type="date"
                  placeholder="Nominated Former President Marraige Anniversary"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres1MarraigeDate")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="formSectionHeader">
            <h3>Nominated Former President</h3>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Nominated Former President Name</Form.Label>
                <Form.Control
                  name="nominatedFormerPres2Name"
                  defaultValue={values.nominatedFormerPres2Name}
                  placeholder="Nominated Former President Name"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2Name")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="nominatedFormerPres2Photo"
                  defaultValue={values.nominatedFormerPres2Photo}
                  type="file"
                  placeholder="Nominated Former President Photo"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2Photo")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="nominatedFormerPres2address"
                  defaultValue={values.nominatedFormerPres2address}
                  placeholder="Nominated Former President Address"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2address")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  name="nominatedFormerPres2PinCode"
                  defaultValue={values.nominatedFormerPres2PinCode}
                  placeholder="Nominated Former President Address Pincode"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2PinCode")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Office Landline</Form.Label>
                <Form.Control
                  name="nominatedFormerPres2OffLandLine"
                  defaultValue={values.nominatedFormerPres2OffLandLine}
                  placeholder="Nominated Former President Office Landline"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2OffLandLine")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Residence Landline</Form.Label>
                <Form.Control
                  name="nominatedFormerPres2ResLandLine"
                  defaultValue={values.nominatedFormerPres2ResLandLine}
                  placeholder="Nominated Former President Residence Landline"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2ResLandLine")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name="nominatedFormerPres2MobileNumber"
                  defaultValue={values.nominatedFormerPres2MobileNumber}
                  placeholder="Nominated Former President Mobile Number"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2MobileNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  name="nominatedFormerPres2WhatsappNumber"
                  defaultValue={values.nominatedFormerPres2WhatsappNumber}
                  placeholder="Nominated Former President Whatsapp Number"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2WhatsappNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  name="nominatedFormerPres2Email"
                  defaultValue={values.nominatedFormerPres2Email}
                  placeholder="Nominated Former President Email Address"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2Email")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Occupation Details</Form.Label>
                <Form.Control
                  name="nominatedFormerPres2Occupation"
                  defaultValue={values.nominatedFormerPres2Occupation}
                  placeholder="Nominated Former President Occupation"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2Occupation")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  name="nominatedFormerPres2BirthDay"
                  defaultValue={values.nominatedFormerPres2BirthDay}
                  type="date"
                  placeholder="Nominated Former President Date of Birth"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2BirthDay")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Name</Form.Label>
                <Form.Control
                  name="nominatedFormerPres2SpouseName"
                  defaultValue={values.nominatedFormerPres2SpouseName}
                  placeholder="Nominated Former President Spouse Name"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2SpouseName")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Date of Birth</Form.Label>
                <Form.Control
                  name="nominatedFormerPres2SpouseBirthDay"
                  defaultValue={values.nominatedFormerPres2SpouseBirthDay}
                  type="date"
                  placeholder="Nominated Former President's Spouse Date of Birth"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2SpouseBirthDay")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Marraige Anniversary</Form.Label>
                <Form.Control
                  name="nominatedFormerPres2MarraigeDate"
                  defaultValue={values.nominatedFormerPres2MarraigeDate}
                  type="date"
                  placeholder="Nominated Former President Marraige Anniversary"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres2MarraigeDate")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="formSectionHeader">
            <h3>Nominated Former President</h3>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Nominated Former President Name</Form.Label>
                <Form.Control
                  name="nominatedFormerPres3Name"
                  defaultValue={values.nominatedFormerPres3Name}
                  placeholder="Nominated Former President Name"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3Name")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="nominatedFormerPres3Photo"
                  defaultValue={values.nominatedFormerPres3Photo}
                  type="file"
                  placeholder="Nominated Former President Photo"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3Photo")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="nominatedFormerPres3address"
                  defaultValue={values.nominatedFormerPres3address}
                  placeholder="Nominated Former President Address"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3address")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  name="nominatedFormerPres3PinCode"
                  defaultValue={values.nominatedFormerPres3PinCode}
                  placeholder="Nominated Former President Address Pincode"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3PinCode")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Office Landline</Form.Label>
                <Form.Control
                  name="nominatedFormerPres3OffLandLine"
                  defaultValue={values.nominatedFormerPres3OffLandLine}
                  placeholder="Nominated Former President Office Landline"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3OffLandLine")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Residence Landline</Form.Label>
                <Form.Control
                  name="nominatedFormerPres3ResLandLine"
                  defaultValue={values.nominatedFormerPres3ResLandLine}
                  placeholder="Nominated Former President Residence Landline"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3ResLandLine")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name="nominatedFormerPres3MobileNumber"
                  defaultValue={values.nominatedFormerPres3MobileNumber}
                  placeholder="Nominated Former President Mobile Number"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3MobileNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  name="nominatedFormerPres3WhatsappNumber"
                  defaultValue={values.nominatedFormerPres3WhatsappNumber}
                  placeholder="Nominated Former President Whatsapp Number"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3WhatsappNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  name="nominatedFormerPres3Email"
                  defaultValue={values.nominatedFormerPres3Email}
                  placeholder="Nominated Former President Email Address"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3Email")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Occupation Details</Form.Label>
                <Form.Control
                  name="nominatedFormerPres3Occupation"
                  defaultValue={values.nominatedFormerPres3Occupation}
                  placeholder="Nominated Former President Occupation"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3Occupation")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  name="nominatedFormerPres3BirthDay"
                  defaultValue={values.nominatedFormerPres3BirthDay}
                  type="date"
                  placeholder="Nominated Former President Date of Birth"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3BirthDay")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Name</Form.Label>
                <Form.Control
                  name="nominatedFormerPres3SpouseName"
                  defaultValue={values.nominatedFormerPres3SpouseName}
                  placeholder="Nominated Former President Spouse Name"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3SpouseName")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Date of Birth</Form.Label>
                <Form.Control
                  name="nominatedFormerPres3SpouseBirthDay"
                  defaultValue={values.nominatedFormerPres3SpouseBirthDay}
                  type="date"
                  placeholder="Nominated Former President's Spouse Date of Birth"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3SpouseBirthDay")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Marraige Anniversary</Form.Label>
                <Form.Control
                  name="nominatedFormerPres3MarraigeDate"
                  defaultValue={values.nominatedFormerPres3MarraigeDate}
                  type="date"
                  placeholder="Nominated Former President Marraige Anniversary"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("nominatedFormerPres3MarraigeDate")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button className="btn--outline" onClick={(e) => prevStep(e)}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Previous
              </Button>
            </Col>
            <Col>
              <Button className="btn--outline--dark" onClick={(e) => submitFormData(e)}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default BEditpage2;
