import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Container, Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./events.css";

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

const GrpEvents = () => {
  const user = useSelector((state) => state.authentication.user);
  const [events, setEvents] = useState([]);
  const url = useParams();

  const fetchEvents = async () => {
    const response = await axios.get(`/api/grp/events-list/${url.grpName}/`);

    // Sort events based on eStartDate in descending order
    const sortedEvents = response.data.sort((a, b) => {
      const dateA = new Date(a.eStartDate);
      const dateB = new Date(b.eStartDate);
      return dateB - dateA;
    });

    console.log(sortedEvents);
    setEvents(sortedEvents);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const navigate = useNavigate();

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [deleteModalshow, setDeleteModalShow] = useState(false);

  const handleDeleteButton = (event) => {
    setSelectedEvent(event);
    setDeleteModalShow(true);
  };

  const handleModalClose = () => setDeleteModalShow(false);

  async function handleDelete(id) {
    await axios({
      method: "delete",
      url: `/api/grp/event/${id}/`,
    })
      .then(function(response) {
        window.location.reload();
      })
      .catch(function(error) {
        console.log(error);
        alert("Error, Please try again after some time");
      });
  }

  return (
    <div>
      <Container className="body">
        <Modal centered show={deleteModalshow} onHide={() => setDeleteModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Event?</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
            <p>
              Are your sure you want to delete the event <strong>{selectedEvent?.eTitle}</strong>?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn--secondary" onClick={() => handleDelete(selectedEvent?.id)}>
              Delete
            </Button>
            <Button className="btn--four" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Row>
          {events.map((event, index) => (
            <Col
              style={{
                marginBottom: "2em",
                display: "flex",
                justifyContent: "center",
              }}
              sm="auto"
              md="auto"
              lg="auto"
              xl="auto"
              key={index}
            >
              <div className="cardsX">
                <div className="customCard1 customCard1--1">
                  <Link to={`/grp/event/${event.id}`} className="customCard1_link">
                    <div className="customCard1__info-hover"></div>
                    <Row className="customRow">
                      <img className="customCard1__img" src={event.ePhoto0}></img>
                      <img className="customCard1__img--hover" src={event.ePhoto0}></img>
                    </Row>
                  </Link>
                  <Row className="customRow">
                    <div className="customCard1__info">
                      <Link to={`/grp/event/${event.id}`} className="customCard1_link">
                        <span className="customCard1__category">{url.grpName}</span>
                      </Link>
                      {(() => {
                        if (
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "group" &&
                            user.jsgGroupName === event.group) ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "admin")
                        ) {
                          return (
                            <span className="customCard1__icons">
                              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit Event?</Tooltip>}>
                                <i
                                  className="fa-solid fa-pen-to-square eventEditIcon"
                                  onClick={() => navigate(`/edit/grp/event/${event.id}/`)}
                                />
                              </OverlayTrigger>

                              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Event?</Tooltip>}>
                                <i
                                  className="fa-solid fa-trash eventEditDelete"
                                  onClick={() => handleDeleteButton(event)}
                                />
                              </OverlayTrigger>
                            </span>
                          );
                        }
                      })()}
                      <Link to={`/grp/event/${event.id}`} className="customCard1_link">
                        <h3 className="customCard1__title">{event.eTitle}</h3>
                        <h4 className="customCard1__date">
                          {moment(event.eStartDate).format("DD/MM/YYYY")} -{" "}
                          {moment(event.eEndDate).format("DD/MM/YYYY")}
                        </h4>
                        <p className="customCard1__text">{event.eDescription}</p>
                      </Link>
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default GrpEvents;
