import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Container, Button } from "react-bootstrap";
import axios from "axios";
import "./EventScreen.css";
import moment from "moment";

function ObProjectScreen() {
  const [event, setEvent] = useState("");
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);
  const url = useParams();

  const fetchProjects = async () => {
    const response = await axios.get(`/api/fed/project/${url.id}`);

    console.log(response.data);
    setEvent(response.data);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleImgClick = (index) => {
    setSelectedImgIndex(index);
  };

  const allImages = [
    event.ePhoto0,
    event.ePhoto1,
    event.ePhoto2,
    event.ePhoto3,
    event.ePhoto4,
    event.ePhoto5,
  ];

  const images = allImages.filter((image) => image != null);

  const numberOfImages = images.length;

  const scrollRef = useRef(null);

  const prevImg = () => {
    setSelectedImgIndex(selectedImgIndex - 1);
  };

  const nextImg = () => {
    setSelectedImgIndex(selectedImgIndex + 1);
  };

  const rotateImages = () => {
    if (selectedImgIndex !== numberOfImages) {
      setSelectedImgIndex(selectedImgIndex + 1);
    }
    if (selectedImgIndex === numberOfImages - 1) {
      setSelectedImgIndex(0);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      rotateImages();
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedImgIndex]);

  function redirectToExternalLinkInNewTab(url) {
    window.open(url, "_blank");
  }

  return (
    <div>
      <Row>
        <h2 className="event-title">{event.eTitle}</h2>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          {(() => {
            if (event.eDate !== null) {
              return (
                <p className="event-details" style={{ textAlign: "left" }}>
                  {moment(event.eDate).format("Do MMMM YYYY")}
                </p>
              );
            }
          })()}
        </Col>
      </Row>
      <Row>
        <p className="event-head1">{event.eDescription}</p>
      </Row>
      <Row style={{ textAlign: "left", marginBottom: "10px" }}>
        <Col>
          {event?.ytLink ? (
            <Button
              className="btn--secondary"
              onClick={() => redirectToExternalLinkInNewTab(event.ytLink)}
            >
              Watch on Youtube
            </Button>
          ) : null}
        </Col>
      </Row>

      <Row className="eventImageRow">
        <div className="eventMainImage" style={{ position: "relative" }}>
          {(() => {
            if (selectedImgIndex !== 0) {
              return (
                <div className="scroll-arrow left" onClick={prevImg}>
                  <i className="fa-solid fa-angle-left"></i>
                </div>
              );
            }
          })()}
          <img
            className="eImage"
            src={images[selectedImgIndex]}
            style={{ width: "100%" }}
            fluid
          />
          {(() => {
            if (selectedImgIndex !== numberOfImages - 1) {
              return (
                <div className="scroll-arrow right" onClick={nextImg}>
                  <i className="fa-solid fa-angle-right"></i>
                </div>
              );
            }
          })()}
        </div>

        <div className="eventImages">
          <p className="eventPhotoHead">Photos</p>
          <Row>
            <div className="image-scroll-container" ref={scrollRef}>
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Image ${index + 1}`}
                  className="image-scroll-item"
                  onClick={() => handleImgClick(index)}
                />
              ))}
            </div>
          </Row>
        </div>
      </Row>
    </div>
  );
}

export default ObProjectScreen;
