import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";

function FedOBScreen() {
  const [officeBearers, setOfficeBearers] = useState([]);
  const url = useParams();

  useEffect(() => {
    const fetchofficeBearers = async () => {
      // const response = await axios.get(`/api/data/fed-ob/${url.term}`);
      const response = await axios.get(`user/data/ob/True`);

      const bearers = response.data.sort((a, b) =>
        a.username > b.username ? 1 : -1
      );

      console.log(response.data);
      setOfficeBearers(bearers);
    };
    fetchofficeBearers();
  }, []);

  return (
    <div>
      <div>
        <div>
          <Row>
            {officeBearers.map((user) => (
              <Col
                style={{ margin: "1em 0" }}
                lg={6}
                md={12}
                xs={12}
                key={user.id}
              >
                <Card className="obInfoCard">
                  <Row>
                    <Col xs={4}>
                      <Card.Img
                        className="obInfoImage"
                        style={{ border: "0", borderRadius: "15px" }}
                        src={user.img}
                        alt=""
                      />
                    </Col>
                    <Col xs={8}>
                      <Card.Body>
                        <Row className="obInfoBodyTop">
                          <Card.Title
                            style={{
                              fontSize: "22px",
                              fontWeight: "700",
                              color: "#3f0986",
                            }}
                          >
                            JSGian {user.first_name} {user.last_name}
                          </Card.Title>
                          <Card.Subtitle
                            style={{ fontSize: "20px", color: "#4d0020" }}
                          >
                            {user.post}
                          </Card.Subtitle>
                        </Row>
                        <Row style={{ marginTop: "10px" }}>
                          <Card.Subtitle
                            style={{
                              fontSize: "18px",
                              textTransform: "capitalize",
                              color: "#4d0020",
                            }}
                          >
                            JSG {user.jsgGroupName} | {user.jsgRegionName}{" "}
                            Region
                          </Card.Subtitle>
                          <Col>
                            <p className="obInfoText1">
                              <i class="fa-solid fa-phone"></i>{" "}
                              {user.contactNumber}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <p className="obInfoText1">
                            <i class="fa-solid fa-envelope"></i> {user.email}
                          </p>
                        </Row>
                        <Button className="obButton">
                          <LinkContainer to={`/fed/${user.id}/ob-projects`}>
                            <Row>
                              <Col xs={2}>
                                <i className="fa-solid fa-bars-progress" />
                              </Col>
                              <Col xs={10}>View Projects</Col>
                            </Row>
                          </LinkContainer>
                        </Button>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default FedOBScreen;
