import React, { useState, useRef, useEffect } from "react";
import "../mangalyatra/MangalyatraScreen.css";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Image, Accordion } from "react-bootstrap";

import Images from "../images";
import { LinkContainer } from "react-router-bootstrap";

function ConstitutionIndex() {
  const navigate = useNavigate();

  const zoomBgRedirect = () => {
    navigate(`/zoom-background/mangalyatra`);
  };

  return (
    <div>
      <div>
        <Row>
          <Col style={{ textAlign: "left" }}>
            <h1>MANGALYATRA</h1>
          </Col>
          <Col style={{ textAlign: "right" }}></Col>
        </Row>
        <div>
          <Row style={{ marginTop: "5px" }}>
            <Col style={{ textAlign: "center", margin: "0 15px" }}>
              <Row>
                <LinkContainer to="/constitution">
                  <Button className="btn--four">Constitution</Button>
                </LinkContainer>
              </Row>
            </Col>
            <Col style={{ textAlign: "center", margin: "0 15px" }}>
              <Row>
                <LinkContainer to="/constitution-16-20-2020">
                  <Button className="btn--four">Constitution 16-12-2020</Button>
                </LinkContainer>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ConstitutionIndex;
