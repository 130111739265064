import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../components/Button.css";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Container,
  Table,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import "../formsList.css";
import pdfFile from "../../images/GRP Form A.pdf";
import { saveAs } from "file-saver";

import axios from "axios";
import SecurePageSection from "../../SecurePageSection";
import { LinkContainer } from "react-router-bootstrap";

const GroupFormA = () => {
  const user = useSelector((state) => state.authentication.user);

  const [forms, setForms] = useState([]);
  const [groupForms, setGroupForms] = useState([]);
  const [groupForm, setGroupForm] = useState(false);

  const [regName, setRegName] = useState("");

  let filterUrl = "";

  if (regName === "") {
    filterUrl = `/api/forms/group/grp-form-a/`;
  }
  if (regName !== "") {
    filterUrl = `/api/forms/group/filter/grp-form-a/?regName=${regName}`;
  }

  const fetchForms = async () => {
    const response = await axios.get(filterUrl);
    console.log(response.data);

    const filteredForms = response.data.filter((form) => {
      const recdDate = new Date(form.recdDate);
      const cutoffDate = new Date("2023-12-17T00:00:00");

      return recdDate > cutoffDate;
    });

    setForms(filteredForms);
  };

  const fetchGroupForms = async () => {
    const response = await axios.get(
      `/api/forms/${user.jsgGroupName}/grp-form-a/`
    );

    console.log(response.data);
    setGroupForms(response.data);
  };

  const navigate = useNavigate();
  const newForm = () => {
    navigate("/form/grp-form-a");
  };

  useEffect(() => {
    fetchGroupForms();
    fetchForms();
  }, [regName]);

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get("/user/region");

    setRegions(response.data.sort((a, b) => a.region.localeCompare(b.region)));
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const tutorials = () => {
    navigate("/tutorials/grp/form-a");
  };

  const downloadPDF = () => {
    saveAs(pdfFile, "Group Form A.pdf");
  };

  return (
    <div>
      <SecurePageSection
        accessableUsers={"admin,group,former,intDirector,offBearer"}
      />
      <Container className="body">
        <Row style={{ marginBottom: "1em" }}>
          <Col md={3}>
            <h2>Group Form A </h2>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              style={{ marginRight: "10px" }}
              className="btn--secondary"
              onClick={() => downloadPDF()}
            >
              Download Empty Form
            </Button>
            <Button
              className="btn--primary"
              onClick={tutorials}
              style={{ marginRight: "10px" }}
            >
              Tutorial
            </Button>

            <Button className="btn--primary" onClick={newForm}>
              New Form
            </Button>
          </Col>
        </Row>
        {(() => {
          if (
            (typeof user !== "undefined" &&
              typeof user.username !== "undefined" &&
              user.accessGroup === "admin") ||
            (typeof user !== "undefined" &&
              typeof user.username !== "undefined" &&
              user.accessGroup === "intDirector") ||
            (typeof user !== "undefined" &&
              typeof user.username !== "undefined" &&
              user.accessGroup === "offBearer")
          ) {
            return (
              <div>
                <Row>
                  <Col>
                    <Form.Label>Region</Form.Label>
                    <Form.Select
                      defaultValue={regName}
                      onChange={(e) => setRegName(e.target.value)}
                    >
                      <option value="">All</option>
                      {regions.map((region) => (
                        <option
                          style={{ textTransform: "capitalize" }}
                          value={region.region.toLowerCase()}
                        >
                          {region.region.charAt(0).toUpperCase() +
                            region.region.slice(1).toLowerCase()}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Table striped bordered responsive="md">
                    <thead className="tableHead">
                      <tr>
                        <th>Serial No.</th>
                        <th>Group </th>
                        <th>Region</th>
                        <th>Old Outstanding</th>
                        <th>Old Excess</th>
                        <th>Amount Payable</th>
                        <th>Amount Paid</th>
                        <th>New Outstanding</th>
                        <th>New Excess</th>
                        <th>Form</th>
                      </tr>
                    </thead>
                    <tbody className="tableBody">
                      {forms
                        .slice(0)
                        .reverse()
                        .map((form, index) => (
                          <tr
                            key={index}
                            style={{
                              textAlign: "center",
                              textTransform: "capitalize",
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>{form.grpName}</td>
                            <td>{form.regName}</td>
                            <td>
                              {form.oldDuepayable === null ||
                              form.oldDuepayable === "" ||
                              form.oldDuepayable === undefined
                                ? "0"
                                : form.oldDuepayable}
                            </td>
                            <td>
                              {form.oldDueRedeemable === null ||
                              form.oldDueRedeemable === "" ||
                              form.oldDueRedeemable === undefined
                                ? "0"
                                : form.oldDueRedeemable}
                            </td>
                            <td>{form.totalAmtPayable}</td>
                            <td>{form.amtPaid}</td>
                            <td>
                              {form.newDuepayable === null ||
                              form.newDuepayable === "" ||
                              form.newDuepayable === undefined
                                ? "0"
                                : form.newDuepayable}
                            </td>
                            <td>
                              {form.newDueRedeemable === null ||
                              form.newDueRedeemable === "" ||
                              form.newDueRedeemable === undefined
                                ? "0"
                                : form.newDueRedeemable}
                            </td>
                            <td style={{ width: "20rem" }}>
                              <Row>
                                <Col>
                                  <div style={{ textAlign: "center" }}>
                                    <LinkContainer
                                      to={`/grp-form-a-response/${form.id}`}
                                    >
                                      <Button
                                        variant="light"
                                        className="btn--four"
                                      >
                                        View Form
                                      </Button>
                                    </LinkContainer>
                                  </div>
                                </Col>
                                {(() => {
                                  if (
                                    form.grpName === user.jsgGroupName &&
                                    index === 0
                                  ) {
                                    return (
                                      <Col>
                                        <div style={{ textAlign: "center" }}>
                                          <LinkContainer
                                            to={`/form/grp-form-a/${form.id}/edit`}
                                          >
                                            <Button
                                              variant="light"
                                              className="btn--four"
                                            >
                                              Edit Form
                                            </Button>
                                          </LinkContainer>
                                        </div>
                                      </Col>
                                    );
                                    // } else if (user.accessGroup === "admin") {
                                    //   return (
                                    //     <Col>
                                    //       <div style={{ textAlign: "center" }}>
                                    //         <LinkContainer to={`/form/grp-form-a/${form.id}/edit`}>
                                    //           <Button variant="light" className="btn--four">
                                    //             Edit Form
                                    //           </Button>
                                    //         </LinkContainer>
                                    //       </div>
                                    //     </Col>
                                    //   );
                                  }
                                })()}
                              </Row>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Row>
              </div>
            );
          }
        })()}
        {(() => {
          if (
            typeof user !== "undefined" &&
            typeof user.username !== "undefined" &&
            user.accessGroup === "group"
          ) {
            return (
              <div>
                <Row style={{ marginTop: "20px" }}>
                  <Table striped bordered responsive="md">
                    <thead className="tableHead">
                      <tr>
                        <th>Group No.</th>
                        <th>Group </th>
                        <th>Region</th>
                        <th>Old Outstanding</th>
                        <th>Old Excess</th>
                        <th>Amount Payable</th>
                        <th>Amount Paid</th>
                        <th>New Outstanding</th>
                        <th>New Excess</th>
                        <th>Form</th>
                      </tr>
                    </thead>
                    <tbody className="tableBody">
                      {groupForms
                        .slice(0)
                        .reverse()
                        .map((form, index) => (
                          <tr
                            key={index}
                            style={{
                              textAlign: "center",
                              textTransform: "capitalize",
                            }}
                          >
                            <td>{form.grpNumber}</td>
                            <td>{form.grpName}</td>
                            <td>{form.regName}</td>
                            <td>
                              {form.oldDuepayable === null ||
                              form.oldDuepayable === "" ||
                              form.oldDuepayable === undefined
                                ? "0"
                                : form.oldDuepayable}
                            </td>
                            <td>
                              {form.oldDueRedeemable === null ||
                              form.oldDueRedeemable === "" ||
                              form.oldDueRedeemable === undefined
                                ? "0"
                                : form.oldDueRedeemable}
                            </td>
                            <td>{form.totalAmtPayable}</td>
                            <td>{form.amtPaid}</td>
                            <td>
                              {form.newDuepayable === null ||
                              form.newDuepayable === "" ||
                              form.newDuepayable === undefined
                                ? "0"
                                : form.newDuepayable}
                            </td>
                            <td>
                              {form.newDueRedeemable === null ||
                              form.newDueRedeemable === "" ||
                              form.newDueRedeemable === undefined
                                ? "0"
                                : form.newDueRedeemable}
                            </td>
                            <td style={{ width: "20rem" }}>
                              <Row>
                                <Col>
                                  <div style={{ textAlign: "center" }}>
                                    <LinkContainer
                                      to={`/grp-form-a-response/${form.id}`}
                                    >
                                      <Button
                                        variant="light"
                                        className="btn--four"
                                      >
                                        View Form
                                      </Button>
                                    </LinkContainer>
                                  </div>
                                </Col>
                                {/* {(() => {
                                  if (
                                    form.grpName === user.jsgGroupName ||
                                    user.accessGroup === "admin"
                                  ) {
                                    return (
                                      <Col>
                                        <div style={{ textAlign: "center" }}>
                                          <LinkContainer to={`/form/grp-form-a/${form.id}/edit`}>
                                            <Button variant="light" className="btn--four">
                                              Edit Form
                                            </Button>
                                          </LinkContainer>
                                        </div>
                                      </Col>
                                    );
                                  }
                                })()} */}
                              </Row>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Row>
              </div>
            );
          }
        })()}
      </Container>
    </div>
  );
};

export default GroupFormA;
