import React, { useState, useRef, useEffect } from "react";
import "../MangalyatraScreen.css";
import HTMLFlipBook from "react-pageflip";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Button, Row, Col, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import pdfFile from "../../images/MY-9 Guj.pdf";
import play from "../../images/audioPlay.png";

function GujMy9() {
  const user = useSelector((state) => state.authentication.user);
  const location = useLocation();

  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState("");
  const [isPageFlipReady, setIsPageFlipReady] = useState(false);
  const pageFlipRef = useRef();

  const handlePageChange = () => {
    let page = pageFlipRef.current.pageFlip().getCurrentPageIndex();
    setCurrentPage(page);
    console.log(page);
  };

  useEffect(() => {
    if (isPageFlipReady) {
      handlePageChange();
    }
  }, [isPageFlipReady]);

  useEffect(() => {
    const totalPageCounter = () => {
      let counter = pageFlipRef.current.pageFlip().getPageCount();
      setTotalPages(counter);
    };

    if (isPageFlipReady) {
      totalPageCounter();
    }
  }, [isPageFlipReady]);

  const fetchPages = async () => {
    const pageResponse = await axios.get(`/api/book/41`);
    console.log(pageResponse.data);
    setPages(pageResponse.data);
  };

  useEffect(() => {
    fetchPages();
  }, []);

  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < 768; // or any other breakpoint you prefer
      setTurnEnable(!isMobile);
    }
    handleResize(); // Call handleResize initially to set the initial value of turnEnable
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const divRef = useRef(null);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [bookWidth, setBookWidth] = useState("");
  const [bookHeight, setBookHeight] = useState("");

  const handleFullscreen = () => {
    const element = divRef.current;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  const handleExitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  useEffect(() => {
    function handleContainer() {
      if (window.innerWidth > 1400) {
        setBookWidth(isFullscreen ? "1400px" : "1400px");
        setBookHeight(isFullscreen ? "1060px" : "100%");
      } else {
        setBookWidth("100%");
        setBookHeight("100%");
      }
    }
    handleContainer();
  }, [window.innerWidth, isFullscreen]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  const [turnEnable, setTurnEnable] = useState(true);
  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < 768; // or any other breakpoint you prefer
      setTurnEnable(!isMobile);
    }
    handleResize(); // Call handleResize initially to set the initial value of turnEnable
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const downloadPDF = () => {
    saveAs(pdfFile, "Mangalyatra Edition 8 Gujarati.pdf");
  };

  const prevPage = () => {
    if (isSpeakingLeft || isPausedLeft) {
      window.speechSynthesis.cancel();
      setIsPausedLeft(true);
      setIsSpeakingLeft(false);
    }
    if (isSpeakingRight || isPausedRight) {
      window.speechSynthesis.cancel();
      setIsPausedRight(true);
      setIsSpeakingRight(false);
    }
    pageFlipRef.current.pageFlip().flipPrev();
  };

  const nextPage = () => {
    if (isSpeakingLeft || isPausedLeft) {
      window.speechSynthesis.cancel();
      setIsPausedLeft(true);
      setIsSpeakingLeft(false);
    }
    if (isSpeakingRight || isPausedRight) {
      window.speechSynthesis.cancel();
      setIsPausedRight(true);
      setIsSpeakingRight(false);
    }
    pageFlipRef.current.pageFlip().flipNext();
  };

  const [isSpeakingLeft, setIsSpeakingLeft] = useState(false);
  const [isPausedLeft, setIsPausedLeft] = useState(false);

  const [isSpeakingRight, setIsSpeakingRight] = useState(false);
  const [isPausedRight, setIsPausedRight] = useState(false);

  const [currentSpeech, setCurrentSpeech] = useState(null);

  const handleReadAloudLeft = () => {
    handleStop();
    const hindiVoice = window.speechSynthesis
      .getVoices()
      .find((voice) => voice.name === "Google हिन्दी");
    const content = getCurrentPageContent(currentPage);

    setIsSpeakingLeft(true);
    setIsPausedLeft(false);

    const utterance = new SpeechSynthesisUtterance(content);
    utterance.lang = "hi-IN";
    utterance.rate = 1;
    utterance.volume = 1;
    utterance.voice = hindiVoice;

    const resumeSpeech = () => {
      resumeIntervalRef.current = setInterval(() => {
        if (!speechSynthesis.speaking) {
          clearInterval(resumeIntervalRef.current);
          setIsSpeakingLeft(false);
        } else {
          speechSynthesis.pause();
          speechSynthesis.resume();
        }
      }, 5000);
    };

    utterance.onend = () => {
      clearInterval(resumeIntervalRef.current);
      setCurrentSpeech(null);
      setIsSpeakingLeft(false);
    };

    window.speechSynthesis.speak(utterance);
    setCurrentSpeech(utterance);
    resumeSpeech();
  };

  const resumeIntervalRef = useRef(null);
  const handleReadAloudRight = () => {
    handleStop();
    const hindiVoice = window.speechSynthesis
      .getVoices()
      .find((voice) => voice.name === "Google हिन्दी");

    const content = getCurrentPageContent(currentPage + 1);

    setIsSpeakingRight(true);
    setIsPausedRight(false);

    const utterance = new SpeechSynthesisUtterance(content);
    utterance.lang = "hi-IN";
    utterance.rate = 1;
    utterance.volume = 1;
    utterance.voice = hindiVoice;

    const resumeSpeech = () => {
      resumeIntervalRef.current = setInterval(() => {
        if (!speechSynthesis.speaking) {
          clearInterval(resumeIntervalRef.current);
          setIsSpeakingRight(false);
        } else {
          speechSynthesis.pause();
          speechSynthesis.resume();
        }
      }, 5000);
    };

    utterance.onend = () => {
      clearInterval(resumeIntervalRef.current);
      setCurrentSpeech(null);
      setIsSpeakingRight(false);
    };

    window.speechSynthesis.speak(utterance);
    setCurrentSpeech(utterance);
    resumeSpeech();
  };

  const handleLeftPause = () => {
    clearInterval(resumeIntervalRef.current);
    setIsPausedRight(true);
    setIsPausedLeft(true);
    window.speechSynthesis.pause();
  };

  const handleLeftResume = () => {
    setIsPausedLeft(false);
    setIsPausedRight(true);
    window.speechSynthesis.resume();
  };

  const handleRightPause = () => {
    clearInterval(resumeIntervalRef.current);
    setIsPausedRight(true);
    setIsPausedLeft(true);
    window.speechSynthesis.pause();
  };

  const handleRightResume = () => {
    setIsPausedRight(false);
    setIsPausedLeft(true);
    window.speechSynthesis.resume();
  };

  const handleStop = () => {
    if (currentSpeech) {
      window.speechSynthesis.cancel();
      setCurrentSpeech(null);
    }
    setIsSpeakingLeft(false);
    setIsSpeakingRight(false);
    clearInterval(resumeIntervalRef.current);
  };

  const speakTextChunks = (text, wordLimit = 20) => {
    const words = text.split(/\s+/);
    const chunks = [];
    for (let i = 0; i < words.length; i += wordLimit) {
      chunks.push(words.slice(i, i + wordLimit).join(" "));
    }
    return chunks;
  };

  const handleReadAloudLeftMobile = () => {
    handleStop();
    const content = getCurrentPageContent(currentPage);
    const contentChunks = speakTextChunks(content);

    setIsSpeakingLeft(true);
    setIsPausedLeft(false);

    const hindiVoice = window.speechSynthesis
      .getVoices()
      .find((voice) => voice.name === "Google हिन्दी");

    const speakNextChunk = (index) => {
      if (index < contentChunks.length) {
        const utterance = new SpeechSynthesisUtterance(contentChunks[index]);
        utterance.lang = "hi-IN";
        utterance.rate = 1;
        utterance.volume = 1;
        utterance.voice = hindiVoice;

        if (!isPausedLeft) {
          utterance.onend = () => speakNextChunk(index + 1);
          window.speechSynthesis.speak(utterance);
          setCurrentSpeech(utterance);
        }
      } else {
        setIsSpeakingLeft(false);
      }
    };

    speakNextChunk(0);
  };

  const getCurrentPageContent = (currentPage) => {
    switch (currentPage) {
      case 0:
        return "";
      case 1:
        return "";
      case 2:
        return "";
      case 3:
        return "";
      case 4:
        return "";
      case 5:
        return "";
      case 6:
        return "";
      case 7:
        return "";
      case 8:
        return "";
      case 9:
        return "";
      case 10:
        return "";
      case 11:
        return "";
      case 12:
        return "";
      case 13:
        return "";
      case 14:
        return "";
      case 15:
        return "";
      case 16:
        return "";
      case 17:
        return "";
      case 18:
        return "";
      case 19:
        return "";
      case 20:
        return "";
      case 21:
        return "";
      case 22:
        return "";
      case 23:
        return "";
      case 24:
        return "";
      case 25:
        return "";
      case 26:
        return "";
      case 27:
        return "";
      case 28:
        return "";
      case 29:
        return "";
      case 30:
        return "";
      case 31:
        return "";
      case 32:
        return "";
      case 33:
        return "";
      case 34:
        return "";
      case 35:
        return "";
      case 36:
        return "";
      case 37:
        return "";
      case 38:
        return "";
      case 39:
        return "";
      case 40:
        return "";
      case 41:
        return "";
      case 42:
        return "";
      case 43:
        return "";
      case 44:
        return "";
      case 45:
        return "";
      case 46:
        return "";
      case 47:
        return "";
      case 48:
        return "";
      case 49:
        return "";
      case 50:
        return "";
      case 51:
        return "";
      case 52:
        return "";
      case 53:
        return "";
      case 54:
        return "";
      case 55:
        return "";
      case 56:
        return "";
      case 57:
        return "";
      case 58:
        return "";
      case 59:
        return "";
      case 60:
        return "";
      case 61:
        return "";
      case 62:
        return "";
      case 63:
        return "";
      case 64:
        return "";
      case 65:
        return "";
      case 66:
        return "";
      case 67:
        return "";
      case 68:
        return "";
      case 69:
        return "";
      case 70:
        return "";
      case 71:
        return "";
      case 72:
        return "";
      case 73:
        return "";
      case 74:
        return "";
      case 75:
        return "";
      case 76:
        return "";
      case 77:
        return "";
      case 78:
        return "";
      case 79:
        return "";
      case 80:
        return "";
      case 81:
        return "";
      case 82:
        return "";
      case 83:
        return "";
      case 84:
        return "";
      case 85:
        return "";
      case 86:
        return "";
      case 87:
        return "";
      case 88:
        return "";
      case 89:
        return "";
      case 90:
        return "";
      case 91:
        return "";
      case 92:
        return "";
      case 93:
        return "";
      case 94:
        return "";
      case 95:
        return "";
      case 96:
        return "";
      case 97:
        return "";
      case 98:
        return "";
      case 99:
        return "";
      case 100:
        return "";
      case 101:
        return "";
      case 102:
        return "";
      case 103:
        return "";
      case 104:
        return "";
      case 105:
        return "";
      case 106:
        return "";
      case 107:
        return "";
      case 108:
        return "";
      case 109:
        return "";
      case 110:
        return "";
      case 111:
        return "";
      case 112:
        return "";
      case 113:
        return "";
      case 114:
        return "";
      case 115:
        return "";
      case 116:
        return "";
      case 117:
        return "";
      case 118:
        return "";
      case 119:
        return "";
      case 120:
        return "";
      case 121:
        return "";
      case 122:
        return "";
      case 123:
        return "";
      case 124:
        return "";
      case 125:
        return "";
      case 126:
        return "";
      case 127:
        return "";
      case 128:
        return "";
      case 129:
        return "";
      case 130:
        return "";
      case 131:
        return "";
      case 132:
        return "";
      case 133:
        return "";
      case 134:
        return "";
      case 135:
        return "";
      case 136:
        return "";
      case 137:
        return "";
      case 138:
        return "";
      case 139:
        return "";
      case 140:
        return "";
      case 141:
        return "";
      case 142:
        return "";
      case 143:
        return "";
      case 144:
        return "";
      case 145:
        return "";
      case 146:
        return "";
      case 147:
        return "";
      case 148:
        return "";
      case 149:
        return "";
      case 150:
        return "";
      case 151:
        return "";
      case 152:
        return "";
      case 153:
        return "";
      case 154:
        return "";
      default:
        return "";
    }
  };

  return (
    <div className="book-container">
      <div className="bookControls">
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col
            xs={12}
            md={6}
            style={{
              marginTop: "5px",
              textAlign: "center",
            }}
          >
            <h2>Mangalyatra Edition 9 (Gujarati)</h2>
          </Col>
          <Col
            xs={12}
            md={6}
            style={{
              marginTop: "5px",
              textAlign: "right",
            }}
          >
            <Button className="btn--primary" onClick={() => downloadPDF()}>
              Download Mangalyatra Edition 9
            </Button>
          </Col>
        </Row>
      </div>
      <div
        ref={divRef}
        style={{
          backgroundColor: "#face1d",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className="gurukul-book-container"
          style={{ width: bookWidth, height: bookHeight }}
        >
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col
              xs={12}
              md={3}
              style={{
                marginTop: "5px",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {isFullscreen ? (
                <Button
                  className="btn--secondary"
                  style={{
                    width: "160px",
                    margin: "0 20px",
                    padding: "8px",
                  }}
                  onClick={handleExitFullscreen}
                >
                  Exit Fullscreen
                </Button>
              ) : (
                <Button
                  className="btn--secondary"
                  style={{
                    width: "160px",
                    margin: "0 20px",
                    padding: "8px",
                  }}
                  onClick={handleFullscreen}
                >
                  Fullscreen View
                </Button>
              )}
            </Col>
            <Col
              xs={12}
              md={3}
              style={{
                marginTop: "5px",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Button
                style={{ width: "160px", margin: "0 20px" }}
                className="btn--primary"
                onClick={prevPage}
              >
                Prev Page
              </Button>
            </Col>
            <Col
              xs={12}
              md={3}
              style={{
                marginTop: "5px",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <p style={{ fontWeight: "600", fontSize: "20px" }}>
                Page: {currentPage + 1}/ {totalPages - 1}
              </p>
            </Col>
            <Col
              xs={12}
              md={3}
              style={{
                marginTop: "5px",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Button
                style={{ width: "160px", margin: "0 20px" }}
                className="btn--primary"
                onClick={nextPage}
              >
                Next Page
              </Button>
            </Col>
          </Row>
          {(() => {
            if (window.innerWidth >= 768) {
              return (
                <>
                  {(() => {
                    if (currentPage !== 0 && currentPage !== 119) {
                      return (
                        <Row style={{ margin: "10px" }}>
                          <Col
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {(() => {
                              if (isPausedLeft === false) {
                                return (
                                  <Button
                                    className="btn--secondary"
                                    style={{ height: "50px", width: "80px" }}
                                    onClick={
                                      isSpeakingLeft
                                        ? handleLeftPause
                                        : handleReadAloudLeft
                                    }
                                    disabled={
                                      getCurrentPageContent(currentPage) === ""
                                    }
                                  >
                                    {isSpeakingLeft ? (
                                      <i className="fa-solid fa-pause" />
                                    ) : (
                                      <Image width="30px" src={play} />
                                    )}
                                  </Button>
                                );
                              } else {
                                return (
                                  <Button
                                    className="btn--secondary"
                                    style={{ height: "50px", width: "80px" }}
                                    onClick={handleLeftResume}
                                    disabled={
                                      getCurrentPageContent(currentPage) === ""
                                    }
                                  >
                                    <Image width="30px" src={play} />
                                  </Button>
                                );
                              }
                            })()}
                          </Col>

                          <Col
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {(() => {
                              if (isPausedRight === false) {
                                return (
                                  <Button
                                    className="btn--secondary"
                                    style={{ height: "50px", width: "80px" }}
                                    onClick={
                                      isSpeakingRight
                                        ? handleRightPause
                                        : handleReadAloudRight
                                    }
                                    disabled={
                                      getCurrentPageContent(currentPage + 1) ===
                                      ""
                                    }
                                  >
                                    {isSpeakingRight ? (
                                      <i className="fa-solid fa-pause" />
                                    ) : (
                                      <Image width="30px" src={play} />
                                    )}
                                  </Button>
                                );
                              } else {
                                return (
                                  <Button
                                    className="btn--secondary"
                                    style={{ height: "50px", width: "80px" }}
                                    onClick={handleRightResume}
                                    disabled={
                                      getCurrentPageContent(currentPage + 1) ===
                                      ""
                                    }
                                  >
                                    <Image width="30px" src={play} />
                                  </Button>
                                );
                              }
                            })()}
                          </Col>
                        </Row>
                      );
                    } else if (currentPage === 0) {
                      return (
                        <Row style={{ margin: "10px" }}>
                          <Col
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          ></Col>
                          <Col
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {(() => {
                              if (isPausedLeft === false) {
                                return (
                                  <Button
                                    className="btn--secondary"
                                    style={{ height: "50px", width: "80px" }}
                                    onClick={
                                      isSpeakingLeft
                                        ? handleLeftPause
                                        : handleReadAloudLeft
                                    }
                                    disabled={
                                      getCurrentPageContent(currentPage) === ""
                                    }
                                  >
                                    {isSpeakingLeft ? (
                                      <i className="fa-solid fa-pause" />
                                    ) : (
                                      <Image width="30px" src={play} />
                                    )}
                                  </Button>
                                );
                              } else {
                                return (
                                  <Button
                                    className="btn--secondary"
                                    style={{ height: "50px", width: "80px" }}
                                    onClick={handleLeftResume}
                                    disabled={
                                      getCurrentPageContent(currentPage) === ""
                                    }
                                  >
                                    <Image width="30px" src={play} />
                                  </Button>
                                );
                              }
                            })()}
                          </Col>
                        </Row>
                      );
                    } else if (currentPage === 125) {
                      return (
                        <Row style={{ margin: "10px" }}>
                          <Col
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {(() => {
                              if (isPausedRight === false) {
                                return (
                                  <Button
                                    className="btn--secondary"
                                    style={{ height: "50px", width: "80px" }}
                                    onClick={
                                      isSpeakingRight
                                        ? handleRightPause
                                        : handleReadAloudRight
                                    }
                                    disabled={
                                      getCurrentPageContent(currentPage) === ""
                                    }
                                  >
                                    {isSpeakingRight ? (
                                      <i className="fa-solid fa-pause" />
                                    ) : (
                                      <Image width="30px" src={play} />
                                    )}
                                  </Button>
                                );
                              } else {
                                return (
                                  <Button
                                    className="btn--secondary"
                                    style={{ height: "50px", width: "80px" }}
                                    onClick={handleRightResume}
                                    disabled={
                                      getCurrentPageContent(currentPage) === ""
                                    }
                                  >
                                    <Image width="30px" src={play} />
                                  </Button>
                                );
                              }
                            })()}
                          </Col>
                          <Col
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          ></Col>
                        </Row>
                      );
                    }
                  })()}
                </>
              );
            } else {
              return (
                <Row style={{ margin: "10px" }}>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {(() => {
                      if (isPausedLeft === false) {
                        return (
                          <Button
                            className="btn--secondary"
                            style={{ height: "50px", width: "80px" }}
                            onClick={
                              isSpeakingLeft
                                ? handleLeftPause
                                : handleReadAloudLeftMobile
                            }
                            disabled={getCurrentPageContent(currentPage) === ""}
                          >
                            {isSpeakingLeft ? (
                              <i className="fa-solid fa-pause" />
                            ) : (
                              <Image width="30px" src={play} />
                            )}
                          </Button>
                        );
                      } else {
                        return (
                          <Button
                            className="btn--secondary"
                            style={{ height: "50px", width: "80px" }}
                            onClick={handleLeftResume}
                            disabled={getCurrentPageContent(currentPage) === ""}
                          >
                            <Image width="30px" src={play} />
                          </Button>
                        );
                      }
                    })()}
                  </Col>
                </Row>
              );
            }
          })()}
          <div className="bookBook" style={{ marginTop: "20px" }}>
            {pages.length !== [] ? (
              <>
                <HTMLFlipBook
                  style={{
                    margin: "0 auto",
                    touchAction: "manipulation",
                  }}
                  size="stretch"
                  width={508}
                  height={720}
                  ref={pageFlipRef}
                  showCover={true}
                  minWidth={315}
                  maxWidth={800}
                  minHeight={400}
                  maxHeight={1210}
                  useMouseEvents={turnEnable}
                  mobileScrollSupport={false}
                  onFlip={handlePageChange}
                  onInit={() => setIsPageFlipReady(true)}
                >
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.coverPage})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page1})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page2})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page3})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page4})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page5})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page6})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page7})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page8})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page9})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page10})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page11})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page12})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page13})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page14})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page15})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page16})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page17})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page18})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page19})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page20})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page21})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page22})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page23})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page24})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page25})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page26})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page27})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page28})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page29})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page30})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page31})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page32})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page33})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page34})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page35})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page36})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page37})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page38})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page39})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page40})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page41})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page42})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page43})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page44})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page45})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page46})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page47})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page48})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page49})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page50})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page51})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page52})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page53})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page54})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page55})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page56})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page57})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page58})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page59})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page60})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page61})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page62})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page63})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page64})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page65})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page66})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page67})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page68})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page69})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page70})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page71})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page72})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page73})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page74})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page75})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page76})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page77})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page78})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page79})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page80})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page81})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page82})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page83})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page84})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page85})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page86})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page87})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page88})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page89})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page90})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page91})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page92})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page93})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page94})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page95})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page96})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page97})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page98})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page99})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page100})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page101})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page102})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page103})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page104})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page105})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page106})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page107})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page108})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page109})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page110})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page111})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page112})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page113})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page114})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page115})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page116})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page117})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page118})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page119})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page120})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page121})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page122})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page123})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page124})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page125})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page126})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page127})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page128})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page129})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page130})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page131})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page132})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page133})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page134})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page135})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page136})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page137})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page138})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page139})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page140})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page141})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page142})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page143})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page144})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page145})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page146})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page147})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page148})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page149})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page150})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page151})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page152})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page153})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page154})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page155})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.lastPage})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                </HTMLFlipBook>
              </>
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GujMy9;
