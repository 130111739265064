import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import "./EventScreen.css";

function RegEventScreen() {
  const [event, setEvent] = useState("");
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);
  const url = useParams();

  const fetchEvents = async () => {
    const response = await axios.get(`/api/reg/event/${url.id}`);
    console.log(response.data);
    const eventData = response.data;

    const startTime = eventData.eStartTime?.split(" - ")[0];
    const endTime = eventData.eEndTime?.split(" - ")[0];

    const formattedStartTime = moment(startTime, "HH:mm").format("h:mm A");
    const formattedEndTime = moment(endTime, "HH:mm").format("h:mm A");

    const formattedEventData = {
      ...eventData,
      formattedStartTime,
      formattedEndTime,
    };

    console.log(formattedEventData);
    setEvent(formattedEventData);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleImgClick = (index) => {
    setSelectedImgIndex(index);
  };

  const allImages = [
    event.ePhoto0,
    event.ePhoto1,
    event.ePhoto2,
    event.ePhoto3,
    event.ePhoto4,
    event.ePhoto5,
    event.ePhoto6,
    event.ePhoto7,
    event.ePhoto8,
    event.ePhoto9,
    event.ePhoto10,
    event.ePhoto11,
    event.ePhoto12,
    event.ePhoto13,
    event.ePhoto14,
    event.ePhoto15,
  ];

  const images = allImages.filter((image) => image != null);

  const numberOfImages = images.length;

  const scrollRef = useRef(null);

  const prevImg = () => {
    setSelectedImgIndex(selectedImgIndex - 1);
  };

  const nextImg = () => {
    setSelectedImgIndex(selectedImgIndex + 1);
  };

  const rotateImages = () => {
    if (selectedImgIndex !== numberOfImages) {
      setSelectedImgIndex(selectedImgIndex + 1);
    }
    if (selectedImgIndex === numberOfImages - 1) {
      setSelectedImgIndex(0);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      rotateImages();
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedImgIndex]);

  return (
    <div>
      <Row>
        <h2 className="event-title">{event.eTitle}</h2>
      </Row>
      <Row>
        <p className="event-head1">{event.eDescription}</p>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <p className="event-head2">{event.eCity}</p>
        </Col>
        <Col md={8} xs={12}>
          {(() => {
            if (event.eStartDate !== null || event.eEndDate !== null) {
              return (
                <>
                  {(() => {
                    if (event.eStartDate !== event.eEndDate) {
                      return (
                        <p className="event-details">
                          {moment(event.eStartDate).format("Do MMMM YYYY")} -{" "}
                          {moment(event.eEndDate).format("Do MMMM YYYY")}
                        </p>
                      );
                    } else if (event.eStartDate === event.eEndDate) {
                      return <p className="event-details">{moment(event.eStartDate).format("Do MMMM YYYY")}</p>;
                    }
                  })()}
                </>
              );
            }
          })()}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {(() => {
            if (event.eStartTime !== "" || event.eEndTime !== "") {
              return (
                <p className="event-details">
                  {event.formattedStartTime} - {event.formattedEndTime}
                </p>
              );
            }
          })()}
        </Col>
      </Row>
      <Row>
        <div className="eventMainImage" style={{ position: "relative" }}>
          {(() => {
            if (selectedImgIndex !== 0) {
              return (
                <div className="scroll-arrow left" onClick={prevImg}>
                  <i className="fa-solid fa-angle-left"></i>
                </div>
              );
            }
          })()}
          <img className="eImage" src={images[selectedImgIndex]} style={{ width: "100%" }} fluid />
          {(() => {
            if (selectedImgIndex !== numberOfImages - 1) {
              return (
                <div className="scroll-arrow right" onClick={nextImg}>
                  <i className="fa-solid fa-angle-right"></i>
                </div>
              );
            }
          })()}
        </div>

        <div className="eventImages">
          <p className="eventPhotoHead">Photos</p>
          <Row>
            <div className="image-scroll-container" ref={scrollRef}>
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Image ${index + 1}`}
                  className="image-scroll-item"
                  onClick={() => handleImgClick(index)}
                />
              ))}
            </div>
          </Row>
        </div>
      </Row>
    </div>
  );
}

export default RegEventScreen;
