import React, { useState, useRef, useEffect } from "react";
import "../MangalyatraScreen.css";
import HTMLFlipBook from "react-pageflip";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Button, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import pdfFile from "../../images/MY-4 Eng.pdf";

function EngMy4() {
  const user = useSelector((state) => state.authentication.user);
  const location = useLocation();

  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState("");
  const [isPageFlipReady, setIsPageFlipReady] = useState(false);
  const pageFlipRef = useRef();

  const handlePageChange = () => {
    let page = pageFlipRef.current.pageFlip().getCurrentPageIndex();
    setCurrentPage(page);
    console.log(page);
  };

  useEffect(() => {
    if (isPageFlipReady) {
      handlePageChange();
    }
  }, [isPageFlipReady]);

  const prevPage = () => {
    pageFlipRef.current.pageFlip().flipPrev();
  };

  const nextPage = () => {
    pageFlipRef.current.pageFlip().flipNext();
  };

  useEffect(() => {
    const totalPageCounter = () => {
      let counter = pageFlipRef.current.pageFlip().getPageCount();
      setTotalPages(counter);
    };

    if (isPageFlipReady) {
      totalPageCounter();
    }
  }, [isPageFlipReady]);

  const fetchPages = async () => {
    const pageResponse = await axios.get(`/api/book/23`);
    console.log(pageResponse.data);
    setPages(pageResponse.data);
  };

  useEffect(() => {
    fetchPages();
  }, []);

  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < 768; // or any other breakpoint you prefer
      setTurnEnable(!isMobile);
    }
    handleResize(); // Call handleResize initially to set the initial value of turnEnable
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const divRef = useRef(null);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [bookWidth, setBookWidth] = useState("");
  const [bookHeight, setBookHeight] = useState("");

  const handleFullscreen = () => {
    const element = divRef.current;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  const handleExitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  useEffect(() => {
    function handleContainer() {
      if (window.innerWidth > 1400) {
        setBookWidth(isFullscreen ? "1400px" : "1400px");
        setBookHeight(isFullscreen ? "1060px" : "100%");
      } else {
        setBookWidth("100%");
        setBookHeight("100%");
      }
    }
    handleContainer();
  }, [window.innerWidth, isFullscreen]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
      document.removeEventListener("mozfullscreenchange", handleFullscreenChange);
      document.removeEventListener("MSFullscreenChange", handleFullscreenChange);
    };
  }, []);

  const [turnEnable, setTurnEnable] = useState(true);
  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < 768; // or any other breakpoint you prefer
      setTurnEnable(!isMobile);
    }
    handleResize(); // Call handleResize initially to set the initial value of turnEnable
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const downloadPDF = () => {
    saveAs(pdfFile, "Apna Time Ayega Mangalyatra English.pdf");
  };

  return (
    <div className="book-container">
      <div className="bookControls">
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col
            xs={12}
            md={6}
            style={{
              marginTop: "5px",
              textAlign: "center",
            }}
          >
            <h2>Mangalyatra Edition 4 (English)</h2>
          </Col>
          <Col
            xs={12}
            md={6}
            style={{
              marginTop: "5px",
              textAlign: "right",
            }}
          >
            <Button className="btn--primary" onClick={() => downloadPDF()}>
              Download Mangalyatra Edition 4
            </Button>
          </Col>
        </Row>
      </div>
      <div
        ref={divRef}
        style={{
          backgroundColor: "#face1d",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="gurukul-book-container" style={{ width: bookWidth, height: bookHeight }}>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col
              xs={12}
              md={3}
              style={{
                marginTop: "5px",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {isFullscreen ? (
                <Button
                  className="btn--secondary"
                  style={{
                    width: "160px",
                    margin: "0 20px",
                    padding: "8px",
                  }}
                  onClick={handleExitFullscreen}
                >
                  Exit Fullscreen
                </Button>
              ) : (
                <Button
                  className="btn--secondary"
                  style={{
                    width: "160px",
                    margin: "0 20px",
                    padding: "8px",
                  }}
                  onClick={handleFullscreen}
                >
                  Fullscreen View
                </Button>
              )}
            </Col>
            <Col
              xs={12}
              md={3}
              style={{
                marginTop: "5px",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Button
                style={{ width: "160px", margin: "0 20px" }}
                className="btn--primary"
                onClick={prevPage}
              >
                Prev Page
              </Button>
            </Col>
            <Col
              xs={12}
              md={3}
              style={{
                marginTop: "5px",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <p style={{ fontWeight: "600", fontSize: "20px" }}>
                Page: {currentPage + 1}/ {totalPages}
              </p>
            </Col>
            <Col
              xs={12}
              md={3}
              style={{
                marginTop: "5px",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Button
                style={{ width: "160px", margin: "0 20px" }}
                className="btn--primary"
                onClick={nextPage}
              >
                Next Page
              </Button>
            </Col>
          </Row>
          <div className="bookBook" style={{ marginTop: "20px" }}>
            {pages.length !== [] ? (
              <>
                <HTMLFlipBook
                  style={{
                    margin: "0 auto",
                    touchAction: "manipulation",
                  }}
                  size="stretch"
                  width={508}
                  height={720}
                  ref={pageFlipRef}
                  showCover={true}
                  minWidth={315}
                  maxWidth={800}
                  minHeight={400}
                  maxHeight={1210}
                  useMouseEvents={turnEnable}
                  mobileScrollSupport={false}
                  onFlip={handlePageChange}
                  onInit={() => setIsPageFlipReady(true)}
                >
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.coverPage})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page1})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page2})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page3})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page4})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page5})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page6})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page7})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page8})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page9})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page10})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page11})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page12})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page13})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page14})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page15})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page16})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page17})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page18})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page19})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page20})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page21})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page22})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page23})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page24})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page25})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page26})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page27})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page28})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page29})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page30})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page31})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page32})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page33})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page34})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page35})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page36})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page37})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page38})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page39})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page40})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page41})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page42})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page43})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page44})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page45})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page46})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page47})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page48})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page49})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page50})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page51})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page52})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page53})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page54})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page55})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page56})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page57})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page58})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page59})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page60})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page61})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page62})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page63})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page64})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page65})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page66})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page67})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page68})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page69})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page70})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page71})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page72})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page73})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page74})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page75})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page76})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page77})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page78})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page79})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page80})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page81})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page82})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page83})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "120%",
                        backgroundImage: `url(${pages.page84})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page85})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page86})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page87})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page88})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page89})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page90})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page91})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page92})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page93})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page94})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page95})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page96})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page97})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page98})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page99})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page100})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page101})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page102})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page103})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page104})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page105})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page106})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.lastPage})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                </HTMLFlipBook>
              </>
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EngMy4;
