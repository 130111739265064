// chatReducer.js

// Define the initial state of the chat
const initialState = {
  history: [], // Add any initial chat history if needed
};

// Define the chat reducer
export function veer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_CHAT_HISTORY":
      return {
        ...state,
        history: action.payload,
      };
    default:
      return state;
  }
}
