import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../../../SecurePageSection";

const RegQ5Form = () => {
  const user = useSelector((state) => state.authentication.user);
  const [submitting, setSubmitting] = useState(false);

  const [regName, setRegName] = useState("");
  const [regChairName, setregChairName] = useState("");
  const [name, setname] = useState("");
  const [chairNum, setchairNum] = useState("");
  const [email, setemail] = useState("");
  const [timings, settimings] = useState("");
  const [protocol, setprotocol] = useState("");
  const [mangalyatra, setmangalyatra] = useState("");
  const [eminence, seteminence] = useState("");
  const [jsgActivity, setjsgActivity] = useState("");
  const [collaborationRegion, setcollaborationRegion] = useState("");
  const [collaborationOB, setcollaborationOB] = useState("");
  const [collaborationMeet, setcollaborationMeet] = useState("");
  const [techForm, settechForm] = useState("");
  const [techpayment, settechpayment] = useState("");
  const [techcommunication, settechcommunication] = useState("");
  const [techsocial, settechsocial] = useState("");

  const [regEmails, setRegEmails] = useState("");
  const [regConvenor, setRegConvenor] = useState("");

  const navigate = useNavigate();

  const fetchRegEmails = async () => {
    const chairmanResponse = await axios.get(
      `api/data/${regName}/region-ob/emails/`
    );
    const chairmanEmail = chairmanResponse.data
      .filter((item) => item.post === "chairman")
      .map((item) => item.email);
    setRegEmails(chairmanEmail);

    const convenorResponse = await axios.get(
      `api/data/eminence/region-convenor/emails/`
    );
    const convenorEmail = convenorResponse.data
      .filter(
        (item) =>
          item.post ===
            `${regName.charAt(0).toUpperCase() +
              regName.slice(1).toLowerCase()} Region Convenor` ||
          item.post ===
            `${regName.charAt(0).toUpperCase() +
              regName.slice(1).toLowerCase()} Region Coordinator`
      )
      .map((item) => item.email);

    const chairmanName = chairmanResponse.data
      .filter((item) => item.post === "chairman")
      .map((item) => item.name);
    setRegConvenor(convenorEmail);
    // setregChairName(chairmanName);
  };

  useEffect(() => {
    fetchRegEmails();
  }, [regName]);

  async function FormSubmit(event) {
    event.preventDefault();
    let formField = new FormData();

    if (!regName || !name || !email) {
      alert(
        "Please Submit your Region Name, Respondant's Name and Respondant's Event"
      );
      setSubmitting(false);
      return;
    }

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,office@jsgif.co.in,dj22jsgif@gmail.com,dsmehta8842@gmail.com," +
      email +
      "," +
      regEmails;
    // "," +
    // regConvenor;

    formField.append("user", user?.user_id);
    formField.append("regName", regName);
    formField.append("regChairName", regChairName);
    formField.append("name", name);
    formField.append("chairNum", chairNum);
    formField.append("email", email);
    formField.append("timings", timings);
    formField.append("protocol", protocol);
    formField.append("mangalyatra", mangalyatra);
    formField.append("eminence", eminence);
    formField.append("collaborationRegion", collaborationRegion);
    formField.append("collaborationOB", collaborationOB);
    formField.append("collaborationMeet", collaborationMeet);
    formField.append("jsgActivity", jsgActivity);
    formField.append("techForm", techForm);
    formField.append("techpayment", techpayment);
    formField.append("techcommunication", techcommunication);
    formField.append("techsocial", techsocial);

    events.forEach((event, index) => {
      formField.append(`subEvent${index + 1}`, event.subEvent);
      if (event.picEvent !== null) {
        formField.append(`picEvent${index + 1}`, event.picEvent);
      }
    });
    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/forms/ventures/eminence-q5-regions/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        navigate(`/eminence/q5/region/response/${response.data.id}`, {
          replace: true,
        });
      })
      .catch(function(error) {
        console.log(error);
        alert(
          "We encountered an error processing your request. Please try again later"
        );
      });
    setSubmitting(false);
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get(`/user/region/`);
    setRegions(response.data);
  };
  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups/`);
    setGroups(response.data);
  };
  useEffect(() => {
    fetchGroups();
  }, [regName]);

  const initialEvents = [
    {
      subEvent: "",
      picEvent: null,
    },
  ];

  const [events, setEvents] = useState(initialEvents);

  const addEvent = () => {
    if (events.length < 10) {
      setEvents([...events, { subEvent: "", picEvent: null }]);
    }
  };

  const removeEvent = (index) => {
    const updatedEvents = [...events];
    updatedEvents.splice(index, 1);
    setEvents(updatedEvents);
  };

  const handleSubEventChange = (index, value) => {
    const updatedEvents = [...events];
    updatedEvents[index].subEvent = value;
    setEvents(updatedEvents);
  };

  const handlePicEventChange = (index, file) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB

    if (file && file.size > maxSizeInBytes) {
      // The file size exceeds the limit, so you can display an error message or take some action.
      alert(
        "File size exceeds the maximum allowed size (2MB). Please choose a smaller file."
      );
      // Set the value of the file input to an empty string if no file is selected
      const fileInput = document.getElementsByName(`picEvent${index + 1}`)[0];
      fileInput.value = "";
    } else {
      const updatedEvents = [...events];
      updatedEvents[index].picEvent = file;
      setEvents(updatedEvents);
    }
  };

  return (
    <div>
      <SecurePageSection
        accessableUsers={
          "admin,region,former,intDirector,offBearer,eminence,eminenceChair"
        }
      />
      {/* <SecurePageSection accessableUsers={"admin,eminenceChair"} /> */}
      <Container>
        <Form>
          <Row>
            <Col lg={8}>
              <h2>Eminence 5th Quarter Application (Regions)</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  name="name"
                  value={name}
                  placeholder="Respondant's Full name"
                  onChange={(e) => setname(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Email ID</Form.Label>
                <Form.Control
                  name="email"
                  value={email}
                  placeholder="Respondant's Email Address"
                  onChange={(e) => setemail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Region Name</Form.Label>
                <Form.Select
                  name="regName"
                  value={regName}
                  onChange={(e) => setRegName(e.target.value)}
                >
                  <option>Select your Region</option>
                  {regions.map((region, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() +
                        region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Region Chairman Name</Form.Label>
                <Form.Control
                  name="regChairName"
                  value={regChairName}
                  placeholder="Region Chairman's Full name"
                  onChange={(e) => setregChairName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Region Chairman Number</Form.Label>
                <Form.Control
                  name="chairNum"
                  value={chairNum}
                  placeholder="Region Chairman's Contact Number"
                  onChange={(e) => setchairNum(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Timings</Form.Label>
                <Form.Control
                  as="textarea"
                  name="timings"
                  value={timings}
                  rows="3"
                  placeholder="This is the time given by the group Points executive team & no. of meetings held. 5"
                  maxlength="500"
                  onChange={(e) => settimings(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Subject + Theme + Protocol</Form.Label>
                <Form.Control
                  as="textarea"
                  name="protocol"
                  value={protocol}
                  rows="3"
                  placeholder="No of Groups attended and no of groups in the region"
                  maxlength="500"
                  onChange={(e) => setprotocol(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Your presence on MANGALYATRA, FB. JSGIF, Insta JSGIF, YouTube
                  JSGIF & Zoom meeting of Eminence.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="mangalyatra"
                  value={mangalyatra}
                  rows="3"
                  placeholder="Your presence in the issues of Mangalyatra"
                  maxlength="500"
                  onChange={(e) => setmangalyatra(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Eminence form upto 4th quarter, If yes how many quarters.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="eminence"
                  value={eminence}
                  rows="3"
                  placeholder="No of groups deposited the annual fees & form A online on the JSGIF Website. Region with maximum such groups will get more points"
                  maxlength="500"
                  onChange={(e) => seteminence(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h3 style={{ marginTop: "30px", marginBottom: "0" }}>
              Your collaboration with
            </h3>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>(A) Another Region & JSGIF.</Form.Label>
                <Form.Control
                  name="collaborationRegion"
                  value={collaborationRegion}
                  rows="3"
                  placeholder="Your collaboration with Another Group Region & JSGIF."
                  maxlength="500"
                  onChange={(e) => setcollaborationRegion(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  (B) Have you invited any O/B'S of another Region / JSGIF in
                  your Region meeting.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="collaborationOB"
                  value={collaborationOB}
                  rows="3"
                  placeholder="Your collaboration with  any O/B'S of another Region / JSGIF in your Region meeting."
                  maxlength="500"
                  onChange={(e) => setcollaborationOB(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>
                  (C) Have you attended any such meeting of another Region /
                  JSGIF.
                </Form.Label>
                <Form.Control
                  name="collaborationMeet"
                  value={collaborationMeet}
                  rows="3"
                  placeholder="Your collaboration with attending any such meeting of another Region / JSGIF."
                  maxlength="500"
                  onChange={(e) => setcollaborationMeet(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Your Attendance / participation in Dream projects of Amish
                  Bhai i.e JSGIF Aashray & Sub-Projets, Udaan, Pari, Anmol...
                  All committees.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="jsgActivity"
                  value={jsgActivity}
                  rows="3"
                  placeholder="No of groups that have filled & if your Region filled Eminence form of last 2 Quarters. 
                  Also mention the no of Groups who have filled the forms in each quarter. 1st Qtr/2nd Qtr"
                  maxlength="500"
                  onChange={(e) => setjsgActivity(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h3 style={{ marginTop: "30px", marginBottom: "0" }}>
              Getting friendly with technology
            </h3>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>
                  (A) How many groups of your Region have filled form A + B.
                </Form.Label>
                <Form.Control
                  name="techForm"
                  value={techForm}
                  rows="3"
                  placeholder="Getting friendly with technology - How many groups of your Region have filled form A + B."
                  maxlength="500"
                  onChange={(e) => settechForm(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>(B) Online payment of JSGIF dues.</Form.Label>
                <Form.Control
                  name="techpayment"
                  value={techpayment}
                  rows="3"
                  placeholder="Getting friendly with technology - Online payment of JSGIF."
                  maxlength="500"
                  onChange={(e) => settechpayment(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  (C) Communication (Email to Region / JSGIF WhatsApp group
                  within your group, electronic circular i.e is on email &
                  whatsapp
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="techcommunication"
                  value={techcommunication}
                  rows="3"
                  placeholder="Getting friendly with technology - Communication (Email to Region / JSGIF WhatsApp group within your group, electronic circular i.e is on email & whatsapp."
                  maxlength="500"
                  onChange={(e) => settechcommunication(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  (D) Your own FB page | Insta page YouTube Channel of your
                  JSGIF region.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="techsocial"
                  value={techsocial}
                  rows="3"
                  placeholder="Getting friendly with technology - Your own FB page | Insta page YouTube Channel of your JSGIF region."
                  maxlength="500"
                  onChange={(e) => settechsocial(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {events.map((event, index) => (
            <div key={index}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="formSectionHeader"
                    style={{
                      marginTop: "30px",
                      width: "97%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4 style={{ marginLeft: "30px" }}>
                      {`Event ${index + 1}`}{" "}
                    </h4>
                    {index > 0 && (
                      <Button
                        className="btn--secondary"
                        onClick={() => removeEvent(index)}
                      >
                        X
                      </Button>
                    )}
                  </div>
                </div>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>{`Event Subject ${index + 1}`}</Form.Label>
                    <Form.Control
                      name={`subEvent${index + 1}`}
                      value={event.subEvent}
                      placeholder={`Subject of Event ${index + 1}`}
                      maxlength="500"
                      onChange={(e) =>
                        handleSubEventChange(index, e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>
                      {`Event Photo ${index + 1}`}
                      <span style={{ fontSize: "14px" }}>
                        (Maximum file size: 1.5mb, Format: jpg only)
                      </span>
                    </Form.Label>
                    <Form.Control
                      name={`picEvent${index + 1}`}
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) =>
                        handlePicEventChange(index, e.target.files[0])
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          ))}

          <Row style={{ marginTop: "10px" }}>
            <Col>
              {events.length < 10 ? (
                <Button className="btn--secondary" onClick={addEvent}>
                  Add Event
                </Button>
              ) : (
                <>
                  <Button
                    className="btn--secondary"
                    disabled
                    onClick={addEvent}
                  >
                    Maximimun 10 Events
                  </Button>
                  <p>Fill another form for more events</p>
                </>
              )}
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>
              }
            >
              <Button className="btn--outline--dark" disabled>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              className="btn--outline--dark"
              onClick={(e) => FormSubmit(e)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default RegQ5Form;
