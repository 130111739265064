import React, { useState } from "react";
import { Row, Col, Tabs, Tab, Container, Button } from "react-bootstrap";
import "./Oath.css";
import { saveAs } from "file-saver";
import pdfFile from "../images/OATH.pdf";

function Oath() {
  const [key, setKey] = useState("eng");

  const downloadPDF = () => {
    saveAs(pdfFile, "JSGIF Kartavya.pdf");
  };

  return (
    <div>
      <Row>
        <Col md={6} xs={12}>
          <h2 style={{ textAlign: "left" }}> JSGIF Oath</h2>
        </Col>
        <Col md={6} xs={12} style={{ textAlign: "right" }}>
          <Button className="btn--primary" onClick={(e) => downloadPDF()}>
            Download Oath of Office
          </Button>
        </Col>
      </Row>
      <Row></Row>
      <div style={{ textAlign: "left", color: "rgb(17,20,78)" }}>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          justify
        >
          <Tab eventKey="eng" title="English">
            <Container>
              <h3 className="oathEngHead">
                Oath for JSGIF Committee Chairman:—
              </h3>
              <p className="oathEngText">
                “I, ________________ having been nominated as a Chairman of
                ____________ Committee in JSGIF do swear in the name of God that
                I will bear true faith and allegiance to the Constitution of
                JSGIF as by law established and that I will uphold the integrity
                of JSGIF.”
              </p>
              <h3>Oath of office for Region Chairman of JSGIF:—</h3>
              <p className="oathEngText">
                “I, ________________ do swear in the name of God that I will
                bear true faith and allegiance to the Constitution of JSGIF as
                by law established, that I will uphold the integrity of JSGIF,
                that I will faithfully and conscientiously discharge my duties
                as a Region Chairman of ________________. region and that I will
                do right to all manner of people in accordance with the
                Constitution and the law without fear or favour, affection or
                ill-will.”
              </p>
              <h3>Oath to be made by International Director of JSGIF:—</h3>
              <p className="oathEngText">
                “I, ________________ having been elected as an International
                Director of JSGIF do swear in the name of God that I will bear
                true faith and allegiance to the Constitution of JSGIF as by law
                established, that I will uphold the integrity of JSGIF and that
                I will faithfully discharge the duty upon which I am about to
                enter.”
              </p>
              <h3> Oath for nominated office bearers:—</h3>
              <p className="oathEngText">
                “I, ________________ having been nominated as ________________.
                of JSGIF, do swear in the name of God that I will bear true
                faith and allegiance to the Constitution of JSGIF as by law
                established, that I will uphold the integrity of JSGIF and that
                I will faithfully discharge the duty upon which I am about to
                enter.”
              </p>
              <h3> Oath of office for Office Bearer of JSGIF:—</h3>
              <p className="oathEngText">
                “I, ________________ do swear in the name of God that I will
                bear true faith and allegiance to the Constitution of JSGIF as
                by law established, that I will uphold the integrity of JSGIF ,
                that I will faithfully and conscientiously discharge my duties
                as ________________. of JSGIF and that I will do right to all
                manner of people in accordance with the Constitution and the
                law, without fear or favour, affection or ill-will.”
              </p>
            </Container>
          </Tab>
          <Tab eventKey="hin" title="Hindi">
            <Container>
              <h3 className="oathHinHead">
                जेएसजीआईएफ समिति के (कमिटी चेयरमैन) अध्यक्ष पद की शपथ:-
              </h3>
              <p className="oathHinText">
                “मैं, ________________, ________________ के अध्यक्ष के रूप में
                नामांकित किया गया हूँ ________________ JSGIFके इस पद ग्रहण
                समारोह में शपथ लेता हूं कि मैं JSGIF के संविधान के प्रति सच्ची
                आस्था और निष्ठा रखूंगा जैसा कि कानून द्वारा स्थापित किया गया है,
                और मैं JSGIF की अखंडता को बरकरार रखूंगा।
              </p>
              <h3 className="oathHinHead">
                जेएसजीआईएफ के क्षेत्रीय अध्यक्ष (रीजन चेअरमन) पद की शपथ:-
              </h3>
              <p className="oathHinText">
                “मैं, ________________.. इस पद ग्रहण समारोह में शपथ लेता हूं कि
                मैं कानून द्वारा स्थापित जेएसजीआईएफ के संविधान के प्रति सच्ची
                आस्था और निष्ठा रखूंगा, कि मैं जेएसजीआईएफ की अखंडता को हम बरकरार
                रखूंगा, कि मैं ________________ के प्रदेश अध्यक्ष के रूप में
                अपने कर्तव्यों का ईमानदारी और कर्तव्यनिष्ठा से पालन करूंगा। मैं
                बिना किसी डर या पक्षपात, स्नेह या दुर्भावना को दुर रखकर संविधान
                और कानून के अनुसार सभी प्रकार के लोगों के साथ न्याय करूंगा।
              </p>
              <h3 className="oathHinHead">
                जेएसजीआईएफ के अंतरराष्ट्रीय निदेशक (इंटरनेशनल डायरेक्टर) द्वारा
                ली जाने वाली शपथ:-
              </h3>
              <p className="oathHinText">
                "मैं, ________________. जेएसजीआईएफ के अंतर्राष्ट्रीय निदेशक के
                रूप में निर्वाचित होने के बाद , शपथ लेता हूं कि मैं कानून द्वारा
                स्थापित जेएसजीआईएफ के संविधान के प्रति सच्ची आस्था और निष्ठा
                रखूंगा, कि मैं JSGIF की सत्यनिष्ठा को कायम रखूंगा और यह कि मैं
                जिस कर्तव्य को ग्रहण करने जा रहा हूं उसका ईमानदारी से निर्वहन
                करूंगा।"
              </p>
              <h3 className="oathHinHead">
                मनोनीत पदाधिकारियों की (नोमिनेटेड ओफीस बेअरर) शपथ:-
              </h3>
              <p className="oathHinText">
                "मैं, ________________. के रूप में मनोनीत किया गया हूँ
                ________________ मै, शपथ लेंता हुं कि मैं JSGIF के संविधान के
                प्रति सच्ची आस्था और निष्ठा रखूंगा, जैसा कि कानून द्वारा स्थापित
                किया गया है, कि मैं JSGIF की अखंडता को बनाए रखूंगा और मैं
                ईमानदारी से उस कर्तव्य का निर्वहन करूंगा जिस पर मैं जा रहा हूं
                प्रवेश करना।"
              </p>
              <h3 className="oathHinHead">
                {" "}
                जेएसजीआईएफ के पदाधिकारी पद (ओफीस बेअरर) की शपथ:-
              </h3>
              <p className="oathHinText">
                “मैं, ________________ शपथ लेता हूं कि मैं कानून द्वारा स्थापित
                JSGIF के संविधान के प्रति सच्ची आस्था और निष्ठा रखूंगा, कि मैं
                JSGIF की अखंडता को बनाए रखूंगा, कि मैं JSGIF के
                ________________. के रूप में अपने कर्तव्यों का ईमानदारी और
                कर्तव्यनिष्ठा से निर्वहन करूंगा और यह कि मैं संविधान और कानून के
                अनुसार सभी प्रकार के लोगों के लिए भय या पक्षपात, स्नेह के बिना
                सही काम करूंगा या दुर्भावना मनमे न आने दूंगा
              </p>
            </Container>
          </Tab>
          <Tab eventKey="guj" title="Gujarati">
            <Container>
              <h3 className="oathGujHead">
                JSGIF સમિતિના અધ્યક્ષ માટે(કમિટી ચેરમેન) શપથ:-
              </h3>
              <p className="oathGujText">
                “હું, ________________. ના અધ્યક્ષ તરીકે નામાંકિત થયો છું. JSGIF
                મા હું શપથ લઉ છું કે હું કાયદા દ્વારા સ્થાપિત JSGIF ના બંધારણમાં
                સાચો વિશ્વાસ અને નિષ્ઠા રાખીશ અને હું JSGIF ની અખંડિતતાને જાળવી
                રાખીશ.
              </p>
              <h3 className="oathGujHead">
                JSGIF ના પ્રદેશ અધ્યક્ષ(રિજન ચેરમેન) માટે શપથ:-
              </h3>
              <p className="oathGujText">
                “હું, ________________ શપથ લેઉં છું કે હું જેએસજીઆઈએફના
                બંધારણમાં સાચો વિશ્વાસ અને વફાદારી રાખીશ, જેમ કે કાયદા દ્વારા
                સ્થાપિત, હું જેએસજીઆઈએફની અખંડિતતાને જાળવી રાખીશ, કે હું
                ________________ ના પ્રદેશ અધ્યક્ષ તરીકે મારી ફરજો નિષ્ઠાપૂર્વક
                અને ઈમાનદારી થી નિભાવીશ. પ્રદેશ અને તે કે હું બંધારણ અને કાયદા
                અનુસાર તમામ પ્રકારના લોકો સાથે ડર કે તરફેણ, સ્નેહ અથવા દુર્ભાવના
                વિના યોગ્ય કાર્ય કરીશ.
              </p>
              <h3 className="oathGujHead">
                JSGIF ના આંતરરાષ્ટ્રીય નિર્દેશક (ઇન્ટરનેશનલ ડાયરેક્ટર)દ્વારા શપથ
                લેવાશે:-
              </h3>
              <p className="oathGujText">
                “હું, ________________. JSGIF ના આંતરરાષ્ટ્રીય ડિરેક્ટર તરીકે
                ચૂંટાયા પછી, હું શપથ લઉં છું કે હું કાયદા દ્વારા સ્થાપિત JSGIF
                ના બંધારણમાં સાચો વિશ્વાસ અને નિષ્ઠા રાખીશ. હું JSGIF ની
                પ્રામાણિકતાને જાળવી રાખીશ અને જે ફરજમાં હું દાખલ થવાનો છું તે
                હું નિષ્ઠાપૂર્વક નિભાવીશ.”
              </p>
              <h3 className="oathGujHead">
                નામાંકિત પદાધિકારીઓ(નોમિનેટેડ ઓફિસ બેઅરર) માટે શપથ:-
              </h3>
              <p className="oathGujText">
                “હું, ________________. તરીકે નામાંકિત કરવામાં આવ્યો છું. JSGIF
                મ હું શપથ લઉ છું કે હું કાયદા દ્વારા સ્થાપિત JSGIF ના બંધારણ
                પ્રત્યે સાચી શ્રદ્ધા અને નિષ્ઠા રાખીશ, કે હું JSGIF ની
                અખંડિતતાને જાળવી રાખીશ અને હું જે ફરજ નિભાવવાનો છું તે હું
                નિષ્ઠાપૂર્વક નિભાવીશ. .
              </p>
              <h3 className="oathGujHead"> JSGIF ના પદાધિકારી માટે શપથ:-</h3>
              <p className="oathGujText">
                “હું, ________________. શપથ લેઉં છું કે હું જેએસજીઆઈએફના
                બંધારણમાં સાચો વિશ્વાસ અને વફાદારી રાખીશ, જેમ કે કાયદા દ્વારા
                સ્થાપિત, હું જેએસજીઆઈએફની અખંડિતતાને જાળવી રાખીશ, કે હું JSGIF
                ના ________________. તરીકે મારી ફરજો નિષ્ઠાપૂર્વક અને
                નિષ્ઠાપૂર્વક નિભાવીશ અને હું બંધારણ અને કાયદા અનુસાર, ભય કે
                પક્ષપાત, નિસ્વાર્થ ભાવે તમામ પ્રકારના લોકો સાથે યોગ્ય ન્યાય
                કરીશ. જે.એસ.જી ની ઉત્તરોત્તર પ્રગતિ થાય એવા પ્રયત્નો કરીશ"
              </p>
            </Container>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Oath;
