import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import "./MediaCoverages.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function MediaCoverages() {
  const user = useSelector((state) => state.authentication.user);
  const [coveragesCol1, setCoveragesCol1] = useState([]);
  const [coveragesCol2, setCoveragesCol2] = useState([]);
  const [coveragesCol3, setCoveragesCol3] = useState([]);
  const [coveragesCol4, setCoveragesCol4] = useState([]);

  useEffect(() => {
    const fetchMedia = async () => {
      const response = await axios.get("/api/media-coverages");
      const LatestImages = response.data.reverse();

      const orderImages = LatestImages.sort((a, b) => a.mediaFor - b.mediaFor);

      if (window.innerWidth <= 768) {
        // Mobile device logic
        const chunkSize = Math.ceil(LatestImages.length / 4);
        const chunks = [];
        for (let i = 0; i < LatestImages.length; i += chunkSize) {
          chunks.push(LatestImages.slice(i, i + chunkSize));
        }

        setCoveragesCol1(chunks[0] || []);
        setCoveragesCol2(chunks[1] || []);
        setCoveragesCol3(chunks[2] || []);
        setCoveragesCol4(chunks[3] || []);
      } else {
        // Desktop or larger device logic
        const col1 = orderImages.filter((_, index) => index % 4 === 0);
        const col2 = orderImages.filter((_, index) => index >= 1 && (index - 1) % 4 === 0);
        const col3 = orderImages.filter((_, index) => index >= 2 && (index - 2) % 4 === 0);
        const col4 = orderImages.filter((_, index) => index >= 3 && (index - 3) % 4 === 0);

        setCoveragesCol1(col1);
        setCoveragesCol2(col2);
        setCoveragesCol3(col3);
        setCoveragesCol4(col4);
      }

      console.log(response.data);
    };

    fetchMedia();
  }, [window.innerWidth]);

  const [title, setTitle] = useState("");
  const [uploadFile, setuploadFile] = useState(null);
  const [ytLink, setYtLink] = useState("");

  const [selectedImage, setSelectedImage] = useState(null);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowMediaModal(true);
  };

  const handleModalClose = () => {
    setSelectedImage("");
    setShowMediaModal(false);
    console.log(selectedImage);
  };

  const handleAddForm = (image) => {
    setShowFormModal(true);
  };

  const [regions, setRegions] = useState([]);
  const [regName, setRegName] = useState("");
  const fetchRegions = async () => {
    const response = await axios.get("/user/region");

    setRegions(response.data.sort((a, b) => a.region.localeCompare(b.region)));
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const [grpName, setGrpName] = useState("");
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups`);

    console.log(response.data);
    setGroups(response.data);
  };

  useEffect(() => {
    fetchGroups();
  }, [regName]);

  const navigate = useNavigate();

  async function MediaSubmit(event) {
    event.preventDefault();
    let formField = new FormData();

    formField.append("title", title);
    formField.append("photo", uploadFile);
    formField.append("ytLink", ytLink);
    formField.append("regName", regName);
    formField.append("grpName", grpName);

    await axios({
      method: "post",
      url: "/api/media-coverages/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        setShowFormModal(false);
        window.location.reload();
      })
      .catch(function(error) {
        console.log(error);
        // window.location.reload();
      });
  }

  async function handleDelete(id) {
    await axios({
      method: "delete",
      url: `/api/media-coverage/${id}/`,
    })
      .then(function(response) {
        setShowFormModal(false);
        setSelectedImage(null);
        window.location.reload();
      })
      .catch(function(error) {
        console.log(error);
        // window.location.reload();
      });
  }

  function redirectToExternalLinkInNewTab(url) {
    window.open(url, "_blank");
  }

  return (
    <div className="my-image-grid" style={{ textAlign: "center" }}>
      <h2>MediaCoverages</h2>
      {(() => {
        if (typeof user !== "undefined" && typeof user.username !== "undefined" && user.accessGroup === "admin") {
          return (
            <Row>
              <Col>
                <Button className="btn--four" onClick={() => handleAddForm()}>
                  Add Media Coverage
                </Button>
              </Col>
            </Row>
          );
        }
      })()}
      <Container>
        <Row>
          <Col style={{ padding: "0 4px" }} xs={12} sm={6} md={3}>
            {coveragesCol1.map((coverage1, index) => (
              <div key={index}>
                <Image
                  className="mediaImage"
                  src={coverage1.photo}
                  alt={coverage1.alt}
                  fluid
                  onClick={() => handleImageClick(coverage1)}
                />
              </div>
            ))}
          </Col>

          <Col style={{ padding: "0 4px" }} xs={12} sm={6} md={3}>
            {coveragesCol2.map((coverage2, index) => (
              <div key={index}>
                <Image
                  className="mediaImage"
                  src={coverage2.photo}
                  alt={coverage2.alt}
                  fluid
                  onClick={() => handleImageClick(coverage2)}
                />
              </div>
            ))}
          </Col>

          <Col style={{ padding: "0 4px" }} xs={12} sm={6} md={3}>
            {coveragesCol3.map((coverage3, index) => (
              <div key={index}>
                <Image
                  className="mediaImage"
                  src={coverage3.photo}
                  alt={coverage3.title}
                  fluid
                  onClick={() => handleImageClick(coverage3)}
                />
              </div>
            ))}
          </Col>

          <Col style={{ padding: "0 4px" }} xs={12} sm={6} md={3}>
            {coveragesCol4.map((coverage4, index) => (
              <div key={index}>
                <Image
                  className="mediaImage"
                  src={coverage4.photo}
                  alt={coverage4.alt}
                  fluid
                  onClick={() => handleImageClick(coverage4)}
                />
              </div>
            ))}
          </Col>
        </Row>

        <Modal centered show={showMediaModal} onHide={() => setShowMediaModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedImage?.title} | {selectedImage?.region ? selectedImage?.region + "Region" : null}
              {selectedImage?.group ? selectedImage?.group + "Group" : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
            <Row>
              <Image src={selectedImage?.photo} alt={selectedImage?.alt} />
            </Row>
          </Modal.Body>

          <Modal.Footer>
            {selectedImage?.ytLink ? (
              <Button className="btn--secondary" onClick={() => redirectToExternalLinkInNewTab(selectedImage.ytLink)}>
                Watch on Youtube
              </Button>
            ) : null}

            {(() => {
              if (typeof user !== "undefined" && typeof user.username !== "undefined" && user.accessGroup === "admin") {
                return (
                  <Button className="btn--secondary" onClick={() => handleDelete(selectedImage?.id)}>
                    Delete
                  </Button>
                );
              }
            })()}

            <Button className="btn--four" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal centered show={showFormModal} onHide={() => setShowFormModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Media Coverage</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group as={Col}>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  name="photo"
                  onChange={(e) => setuploadFile(e.target.files[0])}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Title</Form.Label>
                <Form.Control name="title" value={title} onChange={(e) => setTitle(e.target.value)} />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Link of the video</Form.Label>
                <Form.Control name="ytLink" value={ytLink} onChange={(e) => setYtLink(e.target.value)} />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Region Name</Form.Label>
                <Form.Select type="text" name="regName" value={regName} onChange={(e) => setRegName(e.target.value)}>
                  <option>Only select if the coverage is for the particular region</option>
                  {regions.map((region) => (
                    <option style={{ textTransform: "capitalize" }} value={region.region.toLowerCase()}>
                      {region.region.charAt(0).toUpperCase() + region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Group Name</Form.Label>
                <Form.Select name="grpName" value={grpName} onChange={(e) => setGrpName(e.target.value)}>
                  <option> Only select if the coverage is for the particular Group</option>
                  {groups.map((group) => (
                    <option style={{ textTransform: "capitalize" }} value={group.group.toLowerCase()}>
                      {group.group.charAt(0).toUpperCase() + group.group.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Button className="btn--outline--dark" onClick={(e) => MediaSubmit(e)}>
                Submit
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn--four" onClick={() => setShowFormModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default MediaCoverages;
