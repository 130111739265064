import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../../../SecurePageSection";

const GrpQ2Form = () => {
  const user = useSelector((state) => state.authentication.user);
  const [submitting, setSubmitting] = useState(false);

  const [regName, setRegName] = useState("");
  const [grpName, setGrpName] = useState("");
  const [fNum, setfNum] = useState("1");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [grpPresName, setgrpPresName] = useState(null);
  const [regChairName, setregChairName] = useState("");
  const [convNum, setconvNum] = useState("");
  const [presNum, setpresNum] = useState("");
  const [timings, settimings] = useState("");
  const [protocol, setprotocol] = useState("");
  const [strot, setstrot] = useState("");
  const [regContribution, setregContribution] = useState("");
  const [socialMedia, setsocialMedia] = useState("");
  const [youthMatrimony, setyouthMatrimony] = useState(null);
  const [sevaSaptah, setsevaSaptah] = useState("");
  const [selfAssement, setselfAssement] = useState("");
  const [regEmails, setRegEmails] = useState("");
  const [grpEmails, setGrpEmails] = useState("");

  // const [regConvenor, setRegConvenor] = useState("");

  const navigate = useNavigate();

  const fetchRegEmails = async () => {
    const chairmanResponse = await axios.get(`api/data/${regName}/region-ob/emails/`);
    const chairmanEmail = chairmanResponse.data
      .filter((item) => item.post === "chairman")
      .map((item) => item.email);
    setRegEmails(chairmanEmail);

    const convenorResponse = await axios.get(`api/data/eminence/region-convenor/emails/`);
    const convenorEmail = convenorResponse.data
      .filter(
        (item) =>
          item.post ===
            `${regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()} Region Convenor` ||
          item.post ===
            `${regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()} Region Coordinator`
      )
      .map((item) => item.email);
    const chairmanName = chairmanResponse.data
      .filter((item) => item.post === "chairman")
      .map((item) => item.name);

    // setRegConvenor(convenorEmail);
    setregChairName(chairmanName);
  };

  useEffect(() => {
    fetchRegEmails();
  }, [regName]);

  const fetchGrpEmails = async () => {
    const grpResponse = await axios.get(`api/data/${grpName}/group/emails/`);
    const res = grpResponse?.data[0];
    setGrpEmails(res.presEmail + "," + res.secEmail);
    setgrpPresName(res.presName);
  };

  useEffect(() => {
    fetchGrpEmails();
  }, [grpName]);

  async function FormSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    let formField = new FormData();

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,office@jsgif.co.in,dj22jsgif@gmail.com,dsmehta8842@gmail.com," +
      email +
      "," +
      grpEmails +
      "," +
      regEmails;
    // "," +
    // regConvenor;

    console.log(finalEmail);

    if (!regName || !grpName) {
      alert("Please Submit your Region Name, Group Name");
      setSubmitting(false);
      return;
    }

    formField.append("user", user?.user_id);
    formField.append("regName", regName);
    formField.append("email", email);
    formField.append("grpName", grpName);
    formField.append("fNum", fNum);
    formField.append("grpPresName", grpPresName);
    formField.append("regChairName", regChairName);
    formField.append("name", name);
    formField.append("timings", timings);
    formField.append("protocol", protocol);
    formField.append("convNum", convNum);
    formField.append("presNum", presNum);
    formField.append("strot", strot);
    formField.append("regContribution", regContribution);
    formField.append("socialMedia", socialMedia);
    formField.append("youthMatrimony", youthMatrimony);
    formField.append("sevaSaptah", sevaSaptah);
    formField.append("selfAssement", selfAssement);

    events.forEach((event, index) => {
      formField.append(`subEvent${index + 1}`, event.subEvent);
      if (event.picEvent !== null) {
        formField.append(`picEvent${index + 1}`, event.picEvent);
      }
    });

    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/forms/ventures/eminence-q2-groups/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        navigate(`/eminence/q2/group/response/${response.data.id}`, { replace: true });
      })
      .catch(function(error) {
        console.log(error);
        alert("We encountered an error processing your request. Please try again later");
      });

    setSubmitting(false);
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get(`/user/region/`);
    setRegions(response.data);
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups/`);
    setGroups(response.data);
  };
  useEffect(() => {
    fetchGroups();
  }, [regName]);

  const [sgnGroups, setSgnGroups] = useState([]);
  const fetchSgnGroups = async () => {
    const response = await axios.get(`/user/${regName}/sangini/`);
    setSgnGroups(response.data);
  };
  useEffect(() => {
    fetchSgnGroups();
  }, [regName]);

  const initialEvents = [
    {
      subEvent: "",
      picEvent: null,
    },
  ];

  const [events, setEvents] = useState(initialEvents);

  const addEvent = () => {
    if (events.length < 10) {
      setEvents([...events, { subEvent: "", picEvent: null }]);
    }
  };

  const removeEvent = (index) => {
    const updatedEvents = [...events];
    updatedEvents.splice(index, 1);
    setEvents(updatedEvents);
  };

  const handleSubEventChange = (index, value) => {
    const updatedEvents = [...events];
    updatedEvents[index].subEvent = value;
    setEvents(updatedEvents);
  };

  const handlePicEventChange = (index, file) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB

    if (file && file.size > maxSizeInBytes) {
      // The file size exceeds the limit, so you can display an error message or take some action.
      alert("File size exceeds the maximum allowed size (2MB). Please choose a smaller file.");
      // Set the value of the file input to an empty string if no file is selected
      const fileInput = document.getElementsByName(`picEvent${index + 1}`)[0];
      fileInput.value = "";
    } else {
      const updatedEvents = [...events];
      updatedEvents[index].picEvent = file;
      setEvents(updatedEvents);
    }
  };

  return (
    <div>
      <SecurePageSection
        accessableUsers={
          "admin,region,group,former,intDirector,offBearer,eminence,eminenceChair,sangini,sanginiGroup"
        }
      />
      {/* <SecurePageSection accessableUsers={"admin,eminenceChair"} /> */}
      <Container>
        <Form>
          <Row>
            <Col lg={8}>
              <h2>Eminence 2nd Quarter Application (Groups and Sangini)</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  name="name"
                  value={name}
                  placeholder="Respondant's Full name"
                  maxlength="500"
                  onChange={(e) => setname(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Email</Form.Label>
                <Form.Control
                  name="email"
                  value={email}
                  placeholder="Respondant's Email ID"
                  maxlength="500"
                  onChange={(e) => setemail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Name</Form.Label>
                <Form.Select
                  name="regName"
                  value={regName}
                  maxlength="500"
                  onChange={(e) => setRegName(e.target.value)}
                >
                  <option>Select your Region</option>
                  {regions.map((region, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() + region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            {(() => {
              if (
                user.accessGroup === "admin" ||
                user.accessGroup === "group" ||
                user.accessGroup === "intDirector" ||
                user.accessGroup === "offBearer" ||
                user.accessGroup === "former" ||
                user.accessGroup === "region"
              ) {
                return (
                  <Col lg={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Group Name</Form.Label>
                      <Form.Select
                        name="grpName"
                        value={grpName}
                        maxlength="500"
                        onChange={(e) => setGrpName(e.target.value)}
                      >
                        <option>Select your Group</option>
                        {groups.map((group, index) => (
                          <option
                            key={index}
                            style={{ textTransform: "capitalize" }}
                            value={group.group.toLowerCase()}
                          >
                            {group.group.charAt(0).toUpperCase() +
                              group.group.slice(1).toLowerCase()}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                );
              } else if (
                user.accessGroup === "admin" ||
                user.accessGroup === "sangini" ||
                user.accessGroup === "sanginiGroup"
              ) {
                return (
                  <Col lg={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Sangini Group Name</Form.Label>
                      <Form.Select
                        name="grpName"
                        value={grpName}
                        maxlength="500"
                        onChange={(e) => setGrpName(e.target.value)}
                      >
                        <option>Select your Group</option>
                        {sgnGroups.map((group, index) => (
                          <option
                            key={index}
                            style={{ textTransform: "capitalize" }}
                            value={group.group.toLowerCase()}
                          >
                            {group.group.charAt(0).toUpperCase() +
                              group.group.slice(1).toLowerCase()}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                );
              }
            })()}

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Number of eminence forms filled for the current quarter</Form.Label>
                <Form.Control
                  name="fNum"
                  value={fNum}
                  rows="3"
                  placeholder="To be filled if you have more than 20 events and filling a 2nd/3rd form. Only write the number Ex: 2 or 3"
                  maxlength="500"
                  onChange={(e) => setfNum(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Chairman Name</Form.Label>
                <Form.Control
                  style={{ textTransform: "capitalize" }}
                  name="regChairName"
                  value={regChairName}
                  placeholder="Region Chairman's Full name"
                  maxlength="500"
                  onChange={(e) => setregChairName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Group President Name</Form.Label>
                <Form.Control
                  style={{ textTransform: "capitalize" }}
                  name="grpPresName"
                  value={grpPresName}
                  placeholder="Group President's Full name"
                  maxlength="500"
                  onChange={(e) => setgrpPresName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Convenor Contact Number</Form.Label>
                <Form.Control
                  name="convNum"
                  value={convNum}
                  rows="3"
                  placeholder="Region Eminence Contact Number"
                  maxlength="500"
                  onChange={(e) => setconvNum(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>President Contact Number</Form.Label>
                <Form.Control
                  name="presNum"
                  value={presNum}
                  rows="3"
                  placeholder="Group President Contact Number"
                  maxlength="500"
                  onChange={(e) => setpresNum(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Timings</Form.Label>
                <Form.Control
                  as="textarea"
                  name="timings"
                  value={timings}
                  rows="3"
                  placeholder="Timings"
                  maxlength="500"
                  onChange={(e) => settimings(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Subject + Theme + Protocol</Form.Label>
                <Form.Control
                  as="textarea"
                  name="protocol"
                  value={protocol}
                  rows="3"
                  placeholder="Subject + Tmeme + Protocol"
                  maxlength="500"
                  onChange={(e) => setprotocol(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Registration from the group for STROT Exclusive Empresaas on 26th & 27th August @
                  Pune.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="strot"
                  value={strot}
                  rows="3"
                  placeholder="Registration from the group for STROT Exclusive Empresaas on 26th & 27th August @ Pune."
                  maxlength="500"
                  onChange={(e) => setstrot(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Contribution/Your presence/Your supporti any activity of Region/JSGIF or visit of
                  any diginitaries from Region/JSGIF in your group.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="regContribution"
                  value={regContribution}
                  rows="3"
                  placeholder="
                  Contribution/Your presence/Your supporti any activity of Region/JSGIF or visit of
                  any diginitaries from Region/JSGIF in your group.
                  
                  Region O/B
                  JSGIF O/B"
                  maxlength="500"
                  onChange={(e) => setregContribution(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Any activity publicised on JSGIF social media platform of
                  Website/Instagram/Facebook/ YouTube to enhance our social media presence.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="socialMedia"
                  value={socialMedia}
                  rows="3"
                  placeholder="Any activity publicised on JSGIF social media platform (Website, Instagram, Facebook, Youtube)"
                  maxlength="500"
                  onChange={(e) => setsocialMedia(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>
                  Youth activity/Activity related matrimony + joint group program
                </Form.Label>
                <Form.Control
                  name="youthMatrimony"
                  value={youthMatrimony}
                  rows="3"
                  placeholder="Youth activity/Activity related matrimony+ joint group program"
                  maxlength="500"
                  onChange={(e) => setyouthMatrimony(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>No. of activites done in Seva Saptah of JSGIF AASHRAY</Form.Label>
                <Form.Control
                  name="sevaSaptah"
                  value={sevaSaptah}
                  rows="3"
                  placeholder="No. of activites done in Seva Saptah of JSGIF AASHRAY"
                  maxlength="500"
                  onChange={(e) => setsevaSaptah(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Self Assement</Form.Label>
                <Form.Control
                  name="selfAssement"
                  value={selfAssement}
                  rows="3"
                  placeholder="Self Assement"
                  maxlength="500"
                  onChange={(e) => setselfAssement(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {events.map((event, index) => (
            <div key={index}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="formSectionHeader"
                    style={{
                      marginTop: "30px",
                      width: "97%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4 style={{ marginLeft: "30px" }}>{`Event ${index + 1}`} </h4>
                    {index > 0 && (
                      <Button className="btn--secondary" onClick={() => removeEvent(index)}>
                        X
                      </Button>
                    )}
                  </div>
                </div>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>{`Event Subject ${index + 1}`}</Form.Label>
                    <Form.Control
                      name={`subEvent${index + 1}`}
                      value={event.subEvent}
                      placeholder={`Subject of Event ${index + 1}`}
                      maxlength="500"
                      onChange={(e) => handleSubEventChange(index, e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>
                      {`Event Photo ${index + 1}`}
                      <span style={{ fontSize: "14px" }}>(Maximum file size: 1.5mb)</span>
                    </Form.Label>
                    <Form.Control
                      name={`picEvent${index + 1}`}
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => handlePicEventChange(index, e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          ))}

          <Row style={{ marginTop: "10px" }}>
            <Col>
              {events.length < 10 ? (
                <Button className="btn--secondary" onClick={addEvent}>
                  Add Event
                </Button>
              ) : (
                <>
                  <Button className="btn--secondary" disabled onClick={addEvent}>
                    Maximimun 10 Events
                  </Button>
                  <p>Fill another form for more events</p>
                </>
              )}
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>}
            >
              <Button style={{ marginTop: "30px" }} className="btn--outline--dark" disabled>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              style={{ marginTop: "30px" }}
              className="btn--outline--dark"
              onClick={(e) => FormSubmit(e)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default GrpQ2Form;
