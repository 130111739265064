import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../components/Button.css";
import { Row, Col, Button, Container, Table } from "react-bootstrap";
import moment from "moment";
import "../formsList.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

const SanginiCommitteeMeet = () => {
  const user = useSelector((state) => state.authentication.user);

  const [forms, setForms] = useState([]);

  const fetchForms = async () => {
    const response = await axios.get("/api/forms/sangini/sgncommitteemeet/");

    console.log(response.data);
    setForms(response.data);
  };

  const navigate = useNavigate();
  const newForm = () => {
    navigate("/form/sgn-committeemeet");
  };

  useEffect(() => {
    fetchForms();
  }, []);

  const tutorials = () => {
    navigate("/tutorials/sgn/committee-meet");
  };

  return (
    <div>
      <Container className="body">
        <Row style={{ marginBottom: "1em" }}>
          <Col xs={10}>
            <h2> Sangini Committee Meet </h2>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button className="btn--primary" onClick={tutorials} style={{ marginRight: "10px" }}>
              Tutorial
            </Button>

            <Button className="btn--primary" onClick={newForm}>
              New Form
            </Button>
          </Col>
        </Row>
        <Table striped bordered hover>
          <thead className="tableHead">
            <tr>
              <th>Id</th>
              <th>City</th>
              <th>Notice Date</th>
              <th>Meeting Date</th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {forms
              .slice(0)
              .reverse()
              .map((form, index) => (
                <tr>
                  <td>{form.id}</td>
                  <td>{form.mCity}</td>
                  <td>{moment(form.nDate).format("Do MMMM YYYY")}</td>
                  <td>{moment(form.mDate).format("Do MMMM YYYY")}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default SanginiCommitteeMeet;
