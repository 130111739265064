import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import axios from "axios";
import jsgifLogo from "../../images/ZoomLogo.png";
import zoomBg from "../../images/ZoomBG.jpg";
import "./zoomBg.css";

import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

function ZoomBGFed() {
  const user = useSelector((state) => state.authentication.user);

  const [submitted, setSubmitted] = useState("");

  const [name, setName] = useState("");
  const [post, setPost] = useState("");

  async function FormSubmit(event) {
    event.preventDefault();
    let formField = new FormData();

    formField.append("name", name);
    formField.append("post", post);
    formField.append("url", "Default");

    await axios({
      method: "post",
      url: "/api/zoom/downloads/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        setSubmitted(true);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  const responseId = useParams();

  const handleDownload = () => {
    const div = document.getElementById("zoom-background");
    html2canvas(div, { scale: 12 }).then((canvas) => {
      canvas.toBlob(
        (blob) => {
          saveAs(blob, `${name} Zoom (Default).jpg`);
        },
        "image/jpeg",
        1 // set the quality
      );
    });
  };

  return (
    <div>
      <Container>
        <Form>
          <Row>
            <Col md={8}>
              <h2 style={{ textTransform: "capitalize" }}>
                Zoom Background (Default)
              </h2>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  placeholder="Write your full name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Post</Form.Label>
                <Form.Control
                  name="post"
                  placeholder="Write your post"
                  value={post}
                  onChange={(e) => setPost(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button className="btn--outline--dark" onClick={FormSubmit}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </Button>
        </Form>
        <div className="zoomBox">
          <div
            id="zoom-background"
            className="zoomContainer"
            style={{ backgroundImage: `url(${zoomBg})` }}
          >
            <Row style={{ height: "120px" }}>
              <Col className="jsgLogoTop" xs={2}>
                <img className="logosImg" src={jsgifLogo} />
              </Col>
              <Col className="nameBoxContainer" xs={8}>
                <div className="nameBoxTop">
                  <p className="zoomName">
                    JSGian {name !== "" ? name : " Your Name"}
                  </p>
                  <p className="zoomPost">
                    {post !== "" ? post : " Your Post"}
                  </p>
                </div>
              </Col>

              <Col className="brandLogoTop" xs={2}></Col>
            </Row>
          </div>
        </div>

        {(() => {
          if (submitted === true) {
            return (
              <Button className="btn--outline--dark" onClick={handleDownload}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Download
              </Button>
            );
          } else
            return (
              <>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      Submit first to download your background
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <Button className="btn--outline--dark" disabled>
                      Download
                    </Button>
                  </span>
                </OverlayTrigger>
              </>
            );
        })()}
      </Container>
    </div>
  );
}

export default ZoomBGFed;
