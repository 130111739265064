import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import "./RegionScreen.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import GrpEvents from "../events/GrpEvents";

const GroupScreen = () => {
  const user = useSelector((state) => state.authentication.user);

  const [users, setUsers] = useState("");
  const [events, setEvents] = useState([]);
  const url = useParams();

  useEffect(() => {
    const fetchForms = async () => {
      const response = await axios.get(`/api/forms/${url.grpName}/grp-form-b/`);
      if (response.data.length > 0) {
        const latestForm = response.data[response.data.length - 1]; // Get the last form in the array
        setUsers(latestForm);
      }
    };
    fetchForms();
  }, [url.grpName]);

  useEffect(() => {
    const fetchEvents = async () => {
      const response2 = await axios.get(`/api/grp/events-list/${url.grpName}/`);

      console.log(response2.data);
      setEvents(response2.data);
    };

    fetchEvents();
  }, [url.grpName]);

  return (
    <div>
      <Row>
        <h1 className="regName" style={{ textAlign: "center" }}>
          JSG {url.grpName}
        </h1>
        <h4 style={{ textAlign: "center", textTransform: "capitalize" }}>{users.regName} Region</h4>
      </Row>
      <Row>
        <h2 className="regHead">Committee Members:</h2>
      </Row>
      <Row style={{ textTransform: "capitalize", justifyContent: "center" }}>
        <Col style={{ margin: "1em 0.5em", justifyContent: "center" }}>
          {(() => {
            if (typeof user !== "undefined" && typeof user.username !== "undefined") {
              return (
                <div className="flip">
                  <Card className="front">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img className="cardImage1" alt={users.presName} src={users.presPhoto}></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.presName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">President</h2>
                    </Row>
                  </Card>

                  <Card className="back">
                    <h2 className="userName1">JSGian {users.presName}</h2>
                    <p className="userInfo2">
                      <span className="infoHead">Region : </span>
                      {users.regName} Region
                    </p>
                    <p className="userInfo1">
                      <span className="infoHead">Group : </span> {users.grpName}
                    </p>

                    <p className="userInfoEmail">
                      <span className="infoHead">Email : </span> {users.presEmail}
                    </p>

                    <p className="userInfo1">
                      <span className="infoHead">Contact Number : </span>
                      {users.presMobileNumber}
                    </p>
                  </Card>
                </div>
              );
            } else
              return (
                <div className="flip">
                  <Card className="frontOnly">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img className="cardImage1" alt={users.presName} src={users.presPhoto}></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.presName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">President</h2>
                    </Row>
                  </Card>
                </div>
              );
          })()}
        </Col>

        <Col style={{ margin: "1em 0.5em", justifyContent: "center" }}>
          {(() => {
            if (typeof user !== "undefined" && typeof user.username !== "undefined") {
              return (
                <div className="flip">
                  <Card className="front">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img
                        className="cardImage1"
                        alt={users.immFormerPresName}
                        src={users.immFormerPresPhoto}
                      ></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.immFormerPresName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Immediate Former President</h2>
                    </Row>
                  </Card>
                  <Card className="back">
                    <h2 className="userName1">JSGian {users.immFormerPresName}</h2>
                    <p className="userInfo2">
                      <span className="infoHead">Region : </span>
                      {users.regName} Region
                    </p>
                    <p className="userInfo1">
                      <span className="infoHead">Group : </span> {users.grpName}
                    </p>

                    <p className="userInfoEmail">
                      <span className="infoHead">Email : </span> {users.immFormerPresEmail}
                    </p>

                    <p className="userInfo1">
                      <span className="infoHead">Contact Number : </span>
                      {users.immFormerPresMobileNumber}
                    </p>
                  </Card>
                </div>
              );
            } else
              return (
                <div className="flip">
                  <Card className="frontOnly">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img
                        className="cardImage1"
                        alt={users.immFormerPresName}
                        src={users.immFormerPresPhoto}
                      ></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.immFormerPresName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Immediate Former President</h2>
                    </Row>
                  </Card>
                </div>
              );
          })()}
        </Col>

        <Col style={{ margin: "1em 0.5em", justifyContent: "center" }}>
          {(() => {
            if (typeof user !== "undefined" && typeof user.username !== "undefined") {
              return (
                <div className="flip">
                  <Card className="front">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img
                        className="cardImage1"
                        alt={users.founderPresName}
                        src={users.founderPresPhoto}
                      ></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.founderPresName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Founder President</h2>
                    </Row>
                  </Card>
                  <Card className="back">
                    <h2 className="userName1">JSGian {users.founderPresName}</h2>
                    <p className="userInfo2">
                      <span className="infoHead">Region : </span>
                      {users.regName} Region
                    </p>
                    <p className="userInfo1">
                      <span className="infoHead">Group : </span> {users.grpName}
                    </p>

                    <p className="userInfoEmail">
                      <span className="infoHead">Email : </span> {users.founderPresEmail}
                    </p>

                    <p className="userInfo1">
                      <span className="infoHead">Contact Number : </span>
                      {users.founderPresMobileNumber}
                    </p>
                  </Card>
                </div>
              );
            } else
              return (
                <div className="flip">
                  <Card className="frontOnly">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img
                        className="cardImage1"
                        alt={users.founderPresName}
                        src={users.founderPresPhoto}
                      ></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.founderPresName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Founder President</h2>
                    </Row>
                  </Card>
                </div>
              );
          })()}
        </Col>

        <Col style={{ margin: "1em 0.5em", justifyContent: "center" }}>
          {(() => {
            if (typeof user !== "undefined" && typeof user.username !== "undefined") {
              return (
                <div className="flip">
                  <Card className="front">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img
                        className="cardImage1"
                        alt={users.nominatedFormerPres1Name}
                        src={users.nominatedFormerPres1Photo}
                      ></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.nominatedFormerPres1Name}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Nominated Former President </h2>
                    </Row>
                  </Card>
                  <Card className="back">
                    <h2 className="userName1">JSGian {users.nominatedFormerPres1Name}</h2>
                    <p className="userInfo2">
                      <span className="infoHead">Region : </span>
                      {users.regName} Region
                    </p>
                    <p className="userInfo1">
                      <span className="infoHead">Group : </span> {users.grpName}
                    </p>

                    <p className="userInfoEmail">
                      <span className="infoHead">Email : </span> {users.nominatedFormerPres1Email}
                    </p>

                    <p className="userInfo1">
                      <span className="infoHead">Contact Number : </span>
                      {users.nominatedFormerPres1MobileNumber}
                    </p>
                  </Card>
                </div>
              );
            } else
              return (
                <div className="flip">
                  <Card className="frontOnly">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img
                        className="cardImage1"
                        alt={users.nominatedFormerPres1Name}
                        src={users.nominatedFormerPres1Photo}
                      ></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.nominatedFormerPres1Name}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Nominated Former President </h2>
                    </Row>
                  </Card>
                </div>
              );
          })()}
        </Col>

        <Col style={{ margin: "1em 0.5em", justifyContent: "center" }}>
          {(() => {
            if (typeof user !== "undefined" && typeof user.username !== "undefined") {
              return (
                <div className="flip">
                  <Card className="front">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img
                        className="cardImage1"
                        alt={users.nominatedFormerPres2Name}
                        src={users.nominatedFormerPres2Photo}
                      ></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.nominatedFormerPres2Name}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Nominated Former President</h2>
                    </Row>
                  </Card>
                  <Card className="back">
                    <h2 className="userName1">JSGian {users.nominatedFormerPres2Name}</h2>
                    <p className="userInfo2">
                      <span className="infoHead">Region : </span>
                      {users.regName} Region
                    </p>
                    <p className="userInfo1">
                      <span className="infoHead">Group : </span> {users.grpName}
                    </p>

                    <p className="userInfoEmail">
                      <span className="infoHead">Email : </span> {users.nominatedFormerPres2Email}
                    </p>

                    <p className="userInfo1">
                      <span className="infoHead">Contact Number : </span>
                      {users.nominatedFormerPres2MobileNumber}
                    </p>
                  </Card>
                </div>
              );
            } else
              return (
                <div className="flip">
                  <Card className="frontOnly">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img
                        className="cardImage1"
                        alt={users.nominatedFormerPres2Name}
                        src={users.nominatedFormerPres2Photo}
                      ></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.nominatedFormerPres2Name}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Nominated Former President</h2>
                    </Row>
                  </Card>
                </div>
              );
          })()}
        </Col>

        <Col style={{ margin: "1em 0.5em", justifyContent: "center" }}>
          {(() => {
            if (typeof user !== "undefined" && typeof user.username !== "undefined") {
              return (
                <div className="flip">
                  <Card className="front">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img
                        className="cardImage1"
                        alt={users.nominatedFormerPres3Name}
                        src={users.nominatedFormerPres3Photo}
                      ></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.nominatedFormerPres3Name}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Nominated Former President </h2>
                    </Row>
                  </Card>
                  <Card className="back">
                    <h2 className="userName1">JSGian {users.nominatedFormerPres3Name}</h2>
                    <p className="userInfo2">
                      <span className="infoHead">Region : </span>
                      {users.regName} Region
                    </p>
                    <p className="userInfo1">
                      <span className="infoHead">Group : </span> {users.grpName}
                    </p>

                    <p className="userInfoEmail">
                      <span className="infoHead">Email : </span> {users.nominatedFormerPres3Email}
                    </p>

                    <p className="userInfo1">
                      <span className="infoHead">Contact Number : </span>
                      {users.nominatedFormerPres3MobileNumber}
                    </p>
                  </Card>
                </div>
              );
            } else
              return (
                <div className="flip">
                  <Card className="frontOnly">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img
                        className="cardImage1"
                        alt={users.nominatedFormerPres3Name}
                        src={users.nominatedFormerPres3Photo}
                      ></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.nominatedFormerPres3Name}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Nominated Former President </h2>
                    </Row>
                  </Card>
                </div>
              );
          })()}
        </Col>

        <Col style={{ margin: "1em 0.5em", justifyContent: "center" }}>
          {(() => {
            if (typeof user !== "undefined" && typeof user.username !== "undefined") {
              return (
                <div className="flip">
                  <Card className="front">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img className="cardImage1" alt={users.vPresName} src={users.vPresPhoto}></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.vPresName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Vice President</h2>
                    </Row>
                  </Card>
                  <Card className="back">
                    <h2 className="userName1">JSGian {users.vPresName}</h2>
                    <p className="userInfo2">
                      <span className="infoHead">Region : </span>
                      {users.regName} Region
                    </p>
                    <p className="userInfo1">
                      <span className="infoHead">Group : </span> {users.grpName}
                    </p>

                    <p className="userInfoEmail">
                      <span className="infoHead">Email : </span> {users.vPresEmail}
                    </p>

                    <p className="userInfo1">
                      <span className="infoHead">Contact Number : </span>
                      {users.vPresMobileNumber}
                    </p>
                  </Card>
                </div>
              );
            } else
              return (
                <div className="flip">
                  <Card className="frontOnly">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img className="cardImage1" alt={users.vPresName} src={users.vPresPhoto}></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.vPresName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Vice President</h2>
                    </Row>
                  </Card>
                </div>
              );
          })()}
        </Col>

        <Col style={{ margin: "1em 0.5em", justifyContent: "center" }}>
          {(() => {
            if (typeof user !== "undefined" && typeof user.username !== "undefined") {
              return (
                <div className="flip">
                  <Card className="front">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img className="cardImage1" alt={users.secName} src={users.secPhoto}></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.secName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Secretary</h2>
                    </Row>
                  </Card>
                  <Card className="back">
                    <h2 className="userName1">JSGian {users.secName}</h2>
                    <p className="userInfo2">
                      <span className="infoHead">Region : </span>
                      {users.regName} Region
                    </p>
                    <p className="userInfo1">
                      <span className="infoHead">Group : </span> {users.grpName}
                    </p>

                    <p className="userInfoEmail">
                      <span className="infoHead">Email : </span> {users.secEmail}
                    </p>

                    <p className="userInfo1">
                      <span className="infoHead">Contact Number : </span>
                      {users.secMobileNumber}
                    </p>
                  </Card>
                </div>
              );
            } else
              return (
                <div className="flip">
                  <Card className="frontOnly">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img className="cardImage1" alt={users.secName} src={users.secPhoto}></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.secName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Secretary</h2>
                    </Row>
                  </Card>
                </div>
              );
          })()}
        </Col>

        <Col style={{ margin: "1em 0.5em", justifyContent: "center" }}>
          {(() => {
            if (typeof user !== "undefined" && typeof user.username !== "undefined") {
              return (
                <div className="flip">
                  <Card className="front">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img className="cardImage1" alt={users.jtSecName} src={users.jtSecPhoto}></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.jtSecName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Joint Secretary</h2>
                    </Row>
                  </Card>
                  <Card className="back">
                    <h2 className="userName1">JSGian {users.jtSecName}</h2>
                    <p className="userInfo2">
                      <span className="infoHead">Region : </span>
                      {users.regName} Region
                    </p>
                    <p className="userInfo1">
                      <span className="infoHead">Group : </span> {users.grpName}
                    </p>

                    <p className="userInfoEmail">
                      <span className="infoHead">Email : </span> {users.jtSecEmail}
                    </p>

                    <p className="userInfo1">
                      <span className="infoHead">Contact Number : </span>
                      {users.jtSecMobileNumber}
                    </p>
                  </Card>
                </div>
              );
            } else
              return (
                <div className="flip">
                  <Card className="frontOnly">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img className="cardImage1" alt={users.jtSecName} src={users.jtSecPhoto}></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.jtSecName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Joint Secretary</h2>
                    </Row>
                  </Card>
                </div>
              );
          })()}
        </Col>

        <Col style={{ margin: "1em 0.5em", justifyContent: "center" }}>
          {(() => {
            if (typeof user !== "undefined" && typeof user.username !== "undefined") {
              return (
                <div className="flip">
                  <Card className="front">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img className="cardImage1" alt={users.treasurerName} src={users.treasurerPhoto}></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.treasurerName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Treasurer</h2>
                    </Row>
                  </Card>
                  <Card className="back">
                    <h2 className="userName1">JSGian {users.treasurerName}</h2>
                    <p className="userInfo2">
                      <span className="infoHead">Region : </span>
                      {users.regName} Region
                    </p>
                    <p className="userInfo1">
                      <span className="infoHead">Group : </span> {users.grpName}
                    </p>

                    <p className="userInfoEmail">
                      <span className="infoHead">Email : </span> {users.treasurerEmail}
                    </p>

                    <p className="userInfo1">
                      <span className="infoHead">Contact Number : </span>
                      {users.treasurerMobileNumber}
                    </p>
                  </Card>
                </div>
              );
            } else
              return (
                <div className="flip">
                  <Card className="frontOnly">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img className="cardImage1" alt={users.treasurerName} src={users.treasurerPhoto}></Card.Img>
                    </Row>
                    <Row>
                      <h3 className="userName">JSGian {users.treasurerName}</h3>
                    </Row>
                    <Row>
                      <h2 className="userPost">Treasurer</h2>
                    </Row>
                  </Card>
                </div>
              );
          })()}
        </Col>
      </Row>

      <Row>
        <h2 className="regHead">{url.grpName} Group Events:</h2>
      </Row>
      <GrpEvents />
      {/* <Row>
        {events.map((event, index) => (
          <Col style={{ margin: "1em 0" }} sm="auto" md="auto" lg="auto" xl="auto" key="{index}">
            <Link to={`/grp/event/${event.id}`} className="customCard1_link">
              <div className="cardsX">
                <div className="customCard1 customCard1--1">
                  <div className="customCard1__info-hover"></div>
                  <Row className="customRow">
                    <img className="customCard1__img" alt={event.eTitle} src={event.ePhoto0}></img>
                    <img className="customCard1__img--hover" alt={event.eTitle} src={event.ePhoto0}></img>
                  </Row>
                  <Row className="customRow">
                    <div className="customCard1__info">
                      <span className="customCard1__category">{event.group}</span>
                      <h3 className="customCard1__title">{event.eTitle}</h3>
                      <h4 className="customCard1__date">
                        {event.eStartDate} - {event.eEndDate}
                      </h4>
                      <p className="customCard1__text">{event.eDescription}</p>
                    </div>
                  </Row>
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </Row> */}
    </div>
  );
};

export default GroupScreen;
