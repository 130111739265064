import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container, Card, ListGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
// import SecurePageSection from "../../SecurePageSection";
import { useSelector } from "react-redux";

const EditProfile = () => {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponse] = useState([]);

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [isOb, setIsOb] = useState("");
  const [isId, setIsId] = useState("");
  const [isSangini, setIsSangini] = useState("");
  const [isSuperUser, setIsSuperUser] = useState("");
  const [isActive, setisActive] = useState("");
  const [img, setImg] = useState();

  const navigate = useNavigate();
  const responseId = useParams();

  const EditForm = () => {
    axios.get(`/user/user/${responseId.id}/`).then(function(res) {
      setResponse(res.data);
      console.log(res.data);
      setFirst_name(res.data.first_name);
      setLast_name(res.data.last_name);
      setEmail(res.data.email);
      setContactNumber(res.data.contactNumber);
      setWhatsappNumber(res.data.whatsappNumber);
      setIsOb(res.data.isOb);
      setIsId(res.data.isId);
      setIsSangini(res.data.isSangini);
      setIsSuperUser(res.data.isSuperUser);
      setisActive(res.data.isActive);
    });
  };

  useEffect(() => {
    EditForm();
  }, []);

  const redirectToResponse = (id) => {
    navigate(`/`, { replace: true });
  };

  async function FormSubmit(event) {
    console.log("img", img);
    event.preventDefault();
    let formField = new FormData();

    formField.append("id", response.id);
    formField.append("first_name", first_name);
    formField.append("last_name", last_name);
    formField.append("email", email);
    formField.append("contactNumber", contactNumber);
    formField.append("whatsappNumber", whatsappNumber);
    formField.append("isOb", isOb);
    formField.append("isId", isId);
    formField.append("isSangini", isSangini);
    formField.append("isSuperUser", isSuperUser);
    formField.append("isActive", isActive);
    if (img !== undefined) {
      formField.append("img", img);
    }

    await axios({
      method: "put",
      url: `/user/user/${response.id}/`,
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        redirectToResponse(response.data.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  const [previewImage, setPreviewImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImg(event.target.files[0]);

    // Check if a file is selected
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the preview image URL
        setPreviewImage(reader.result);
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <h1>Edit Profile</h1>
        </Col>
      </Row>
      <Row>
        <Col md={7} xs={12}>
          <Row style={{ textAlign: "center" }}>
            <Col>
              <h2>Edit</h2>
            </Col>
          </Row>
          <Form onSubmit={FormSubmit}>
            <Row>
              <Col md={6} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    name="first_name"
                    value={first_name}
                    onChange={(e) => setFirst_name(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col md={6} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name="last_name"
                    value={last_name}
                    onChange={(e) => setLast_name(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Profile Picture</Form.Label>
                  <Form.Control type="file" name="img" onChange={(e) => handleImageUpload(e)} />
                </Form.Group>
              </Col>

              <Col md={6} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Email Id</Form.Label>
                  <Form.Control
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    name="contactNumber"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col md={6} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Whatsapp Number</Form.Label>
                  <Form.Control
                    name="whatsappNumber"
                    value={whatsappNumber}
                    onChange={(e) => setWhatsappNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <p>Your password would be automatically changed to your phone number</p>
            </Row>
            <Button className="btn--outline--dark" type="submit">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          </Form>
        </Col>
        <Col md={5} xs={12}>
          <Row style={{ textAlign: "center" }}>
            <Col>
              <h2>Preview</h2>
            </Col>
          </Row>
          <Card style={{ width: "100%" }} className="customCard">
            <Card.Header className="customHeader">
              JSGian Profile - "{response.username}"
            </Card.Header>
            <div className="customCardImg">
              {previewImage ? (
                <img alt={"JSGian " + first_name + last_name} src={previewImage} />
              ) : (
                <img alt={"JSGian " + first_name + last_name} src={response.img} />
              )}
            </div>
            <Card.Body>
              <Card.Title className="customTitle">
                JSGian{" "}
                <span style={{ textTransform: "uppercase" }}>
                  {first_name} {last_name}
                </span>
              </Card.Title>
              <Row>
                <Card.Text className="customLink" href={`mailto:${email}`}>
                  <i className="fa-solid fa-envelope"></i> {email}
                </Card.Text>
              </Row>
              <Row>
                <Col>
                  <Card.Text className="customLink" href={`tel:+${contactNumber}`}>
                    <i className="fa-solid fa-mobile-button"></i> {contactNumber}
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text className="customLink" href={`https://wa.me/:${whatsappNumber}`}>
                    <i className="fa-brands fa-whatsapp"></i> {whatsappNumber}
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
            <ListGroup>
              <ListGroup.Item className="customItem">Post : {response.post}</ListGroup.Item>
              <ListGroup.Item className="customItem">
                Group Name : {response.jsgGroupName}
              </ListGroup.Item>
              <ListGroup.Item className="customItem">
                Region Name : {response.jsgRegionName}
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditProfile;
