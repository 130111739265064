import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Images from "../../images/index";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import "../Response.css";
import SecurePageSection from "../../SecurePageSection";

function FedIDMeetResponse() {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponse] = useState([]);
  const responseId = useParams();
  const ref = React.createRef();

  const getResponse = async () => {
    const res = await axios.get(`/api/forms/federation/fed-idmeet/${responseId.id}`);
    setResponse(res.data);
  };

  useEffect(() => {
    getResponse();
  }, []);

  const navigate = useNavigate();

  const editForm = () => {
    navigate(`/form/fed-idmeet/${response.id}/edit`);
  };

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("", "blank", "height=1123,width=800,scrollbars=yes,");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>Form B</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    html {
      overflow-y: auto;
    }

    fResponseBody {
      padding: 20px 20px;
      margin: 0px 20px;
      width: 800px;
      height: 1123px;
      background: #fff;
      overflow-y: auto;
    }

    .page{
      page-break-after: always;
    }

    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }

    .section2, .section3{
      margin-top: 30px;
    }

    .section4{
      margin-top: 20px;
    }

    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }

    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }

    .bordered-mid-row{
      padding: 3px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-col-b{
      padding: 3px 5px;
      border: 1px solid #000;
    }

    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }

    .bannerText {
      margin-top: 20px;
    }

    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }

    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }

    .toptext {
      margin: 20px 0;
    }

    .logoImage {
      height: 80px;
      margin: 10px;
    }

    .bannerYellow {
      background: #ffcc29;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }

    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }

    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }

    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }

    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }

    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }

    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }

    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }

    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }`);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
    }, 1000);
  };

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Federation ID Meet Response</h2>
      <Row style={{ marginBottom: "1em", marginTop: "1em" }}>
        <Col className="d-flex justify-content-end">
          <Button onClick={printDocument} variant="primary">
            Download Form
          </Button>
        </Col>
        <Col className="d-flex justify-content-start">
          <Button onClick={editForm} variant="primary">
            Edit Form
          </Button>
        </Col>
      </Row>
      <div className="centerFormat" id="mainForm">
        <div className="fResponseBody">
          <div className="bannerYellow">
            <Row className="T-Banner">
              <Col xs={1}>
                <img src={Images.LogoB} className="logoImage" alt="jsgBanner"></img>
              </Col>
              <Col xs={11} className="bannerText text-center">
                <h1>JAIN SOCIAL GROUP INT. FEDERATION</h1>
                <p
                  className="Tsingle-enter"
                  style={{
                    padding: "0 20px",
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  4-O/P, Vijay Chambers, Opp. Dreamland Cinema, Tribhuvan Road, Mumbai - 400 004. (India)
                </p>
                <p
                  className="Tsingle-enter"
                  style={{
                    padding: "0 20px",
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Tel.: 022-23870724 / 23891884 Mobile : 8169274400
                </p>
                <p
                  className="Tsingle-enter"
                  style={{
                    padding: "0 20px",
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  E-mail : office@jsgif.co.in Website : www.jsgif.co.i
                </p>
              </Col>
            </Row>
          </div>

          <Row>
            <p className="Tsingle-enter">
              <span className="response">{response.nDate}</span>
            </p>
            <p className="Tdouble-enter"> Dear JSGians, </p>
            <p className="Tsingle-enter"> Jai Jinendra </p>
            <p className="Tdouble-enter">
              Notice is hereby given that a meeting of the International Directors of Jain Social Groups Int. Federation
              will be held on <span className="response">{response.mDay}</span>, the{" "}
              <span className="response">{moment(response.mDate).format("Do MMMM YYYY")}</span> at{" "}
              <span className="response">{response.mTime}</span> at{" "}
              <span className="response">{response.mAddress}</span>, <span className="response">{response.mCity}</span>,{" "}
              <span className="response">{response.mState}</span>, <span className="response">{response.mZip}</span>, to
              transact the following business.
            </p>
          </Row>

          <Row>
            <h2 className="Hdouble-enter text-center">AGENDA</h2>
          </Row>
          <ol className="Lsingle-enter">
            <li>Recitation of Namaskar Mahamantra & Federation Sutra 1 minute.</li>
            <li>Welcome address by the JSGIF President 3 minutes.</li>
            <li>To read and confirm the minutes of the previous meeting 3 minutes.</li>
            <li>Matters arising out of the minutes 3 minutes.</li>
            <li>Report presentation by each region ID's Chairman and Sangini Forum each 2 minutes.</li>
            <li>Presentation of the budget for the year 2023-24 2 minutes.</li>
            <li>Secretarial report by Secretary General with Secretary & Jt. Secretary 2 minutes.</li>
            <li>Report presentation by the editors Mangalyatra 1 minute.</li>
            <li>Report presentation by P.R.O. Admin 1 minute.</li>
            <li>Report presentation by P.R.O. Greetings 1 minute.</li>
            <li>Presentation of forthcoming events 5 minutes</li>
            {response.agendaPoint !== "" ? (
              <li>
                <span className="response">{response.agendaPoint}</span>
              </li>
            ) : (
              ""
            )}
            <li>To transact any other matter with the permission of the chair 5 minutes.</li>
            <li> Vote of thanks.</li>
          </ol>
          <p className="Tdouble-enter">For Jain Social Groups Int. Federation</p>
          <Row>
            <Col className="text-center">
              <p className="Tdouble-enter">
                JSGian <span className="response">{response.secretaryGen}</span>{" "}
              </p>
              <p className="Tsingle-enter">Secretary General</p>
            </Col>
            <Col>
              <p className="Tdouble-enter">
                JSGian <span className="response">{response.secretary}</span>{" "}
              </p>
              <p className="Tsingle-enter">Secretary</p>
            </Col>
            <Col>
              <p className="Tdouble-enter">
                JSGian <span className="response">{response.jtSecretary} </span>{" "}
              </p>
              <p className="Tsingle-enter">Jt. Secretary</p>
            </Col>
          </Row>
          <p className="Tdouble-enter"> Note :- </p>
          <ol className="Lsingle-enter">
            <li>All members are requested to be present on time.</li>
            <li>
              Member(s) desirous of taking up a matter in any other matter are requested to inform the point(s) in
              detail to the President and Secretary General of the Federation by email 24 hours before the date of the
              meeting.
            </li>
            <li>Total planned duration of the meeting is 48 minutes.</li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default FedIDMeetResponse;
