import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../../../SecurePageSection";

const ObH1Form = () => {
  const user = useSelector((state) => state.authentication.user);
  const [submitting, setSubmitting] = useState(false);

  const [name, setname] = useState("");
  const [num, setnum] = useState("");
  const [email, setemail] = useState("");
  const [post, setpost] = useState("");
  const [meetingAttended, setmeetingAttended] = useState("");
  const [workDone, setworkDone] = useState("");
  const [specialActivity, setspecialActivity] = useState("");
  const [protocol, setprotocol] = useState("");
  const [presidentReview, setpresidentReview] = useState("");

  const navigate = useNavigate();

  async function FormSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    let formField = new FormData();

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,office@jsgif.co.in,dj22jsgif@gmail.com,dsmehta8842@gmail.com," +
      email;

    console.log(finalEmail);

    if (!post || !name) {
      alert("Please Submit your Name and Post");
      setSubmitting(false);
      return;
    }

    formField.append("user", user?.user_id);
    formField.append("name", name);
    formField.append("num", num);
    formField.append("email", email);
    formField.append("post", post);
    formField.append("meetingAttended", meetingAttended);
    formField.append("workDone", workDone);
    formField.append("specialActivity", specialActivity);
    formField.append("protocol", protocol);
    formField.append("presidentReview", presidentReview);
    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/forms/ventures/eminence-h1-obs/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        navigate(`/eminence/h1/ob/response/${response.data.id}`, { replace: true });
      })
      .catch(function(error) {
        console.log(error);
      });
    setSubmitting(false);
  }

  return (
    <div>
      <SecurePageSection accessableUsers={"admin,offBearer,eminence,eminenceChair"} />
      <Container>
        <Form>
          <Row>
            <Col lg={8}>
              <h2>Eminence 1st Half Application (Office Bearers)</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  name="name"
                  value={name}
                  placeholder="Respondant's Full name"
                  onChange={(e) => setname(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Email</Form.Label>
                <Form.Control
                  name="email"
                  value={email}
                  placeholder="Respondant's Email ID"
                  onChange={(e) => setemail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Phone Number</Form.Label>
                <Form.Control
                  name="num"
                  value={num}
                  placeholder="Respondant's num ID"
                  onChange={(e) => setnum(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Post</Form.Label>
                <Form.Control
                  name="post"
                  value={post}
                  placeholder="Respondant's Post"
                  onChange={(e) => setpost(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Timing: Meeting Attended: Visits in Region Communication with President &
                  Secretary General
                </Form.Label>
                <Form.Control
                  name="meetingAttended"
                  value={meetingAttended}
                  placeholder="Timing: Meeting Attended: Visits in Region Communication with President & Secretary General"
                  onChange={(e) => setmeetingAttended(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Work Done by you</Form.Label>
                <Form.Control
                  name="workDone"
                  value={workDone}
                  placeholder="Eg.: Opening a new group, Helping groups in their activities. Work assigned by JSGIF to you."
                  onChange={(e) => setworkDone(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Special Activity/Social Media</Form.Label>
                <Form.Control
                  name="specialActivity"
                  value={specialActivity}
                  placeholder="Special Activity/Social Media"
                  onChange={(e) => setspecialActivity(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Protocol Personally followed by you</Form.Label>
                <Form.Control
                  name="protocol"
                  value={protocol}
                  placeholder="Protocol Personally followed by you"
                  onChange={(e) => setprotocol(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>JSGIF Preident Review</Form.Label>
                <Form.Control
                  name="presidentReview"
                  value={presidentReview}
                  placeholder="JSGIF Preident Review"
                  onChange={(e) => setpresidentReview(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>}
            >
              <Button style={{ marginTop: "30px" }} className="btn--outline--dark" disabled>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              style={{ marginTop: "30px" }}
              className="btn--outline--dark"
              onClick={(e) => FormSubmit(e)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default ObH1Form;
