import React from "react";
import { Row, Col, Button, Modal, Image } from "react-bootstrap";
import qr from "../images/jsg_rbl.jpg";

function JsgPaymentModal(props) {
  return (
    <div>
      <Row>
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">JSG Bank Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row>
                <Col md={8} lg={8}>
                  <Row>
                    <p style={{ margin: "0", fontWeight: "600" }}>
                      <span>Name:</span> JSG Int. Federation
                    </p>
                  </Row>
                  <Row>
                    <p style={{ margin: "0", fontWeight: "600" }}>Bank Name: Indian Bank</p>
                  </Row>
                  <Row>
                    <p style={{ margin: "0", fontWeight: "600" }}>Branch Name: Prarthana Samaj</p>
                  </Row>
                  <Row>
                    <p style={{ margin: "0", fontWeight: "600" }}>Account Number: 417721000</p>
                  </Row>
                  <Row>
                    <p style={{ margin: "0", fontWeight: "600" }}>IFS Code: IDIB000P049</p>
                  </Row>
                  <p style={{ margin: "5px 0" }}> or</p>
                  <p style={{ margin: "0", fontWeight: "600" }}>
                    Name: Jain Social Groups International Federation
                  </p>
                  <Row>
                    <p style={{ margin: "0", fontWeight: "600" }}>Bank Name: RBL Bank</p>
                  </Row>
                  <Row>
                    <p style={{ margin: "0", fontWeight: "600" }}>Branch Name: Mandaveli</p>
                  </Row>
                  <Row>
                    <p style={{ margin: "0", fontWeight: "600" }}>Account Number: 400001042023</p>
                  </Row>
                  <Row>
                    <p style={{ margin: "0", fontWeight: "600" }}>IFS Code: RATN0000396</p>
                  </Row>
                </Col>
                <Col md={8} lg={4}>
                  <Image src={qr} className="qrImg" fluid />
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn--secondary" onClick={props.onHide}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </div>
  );
}

export default JsgPaymentModal;
