import React, { useState } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";

// creating functional component ans getting props from app.js and destucturing them
const EduPage2 = ({ nextStep, handleFormData, prevStep, values }) => {
  //creating error state for validation
  const [error, setError] = useState(false);

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div>
      <Container>
        <Row>
          <Col xs={12}>
            <h2>JSGFF Education Loan Form Step 2</h2>
          </Col>
        </Row>
        <Form>
          <Row className="formSectionHeader">
            <h3>Education/Career Achievement</h3>
          </Row>
          <Row>
            <h2>1st Education / Career Achievements</h2>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Examination/Course Passed/Cleared 1</Form.Label>
                <Form.Control
                  name="edu1Course"
                  value={values.edu1Course}
                  placeholder="X Std or Equivalent"
                  onChange={handleFormData("edu1Course")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Passing Details Month and year 1</Form.Label>
                <Form.Control
                  name="edu1PassMonth"
                  value={values.edu1PassMonth}
                  placeholder=""
                  onChange={handleFormData("edu1PassMonth")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 1</Form.Label>
                <Form.Control
                  name="edu1Marks"
                  value={values.edu1Marks}
                  placeholder="Secured"
                  onChange={handleFormData("edu1Marks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 1</Form.Label>
                <Form.Control
                  name="edu1TotalMarks"
                  value={values.edu1TotalMarks}
                  placeholder="Out Of"
                  onChange={handleFormData("edu1TotalMarks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 1</Form.Label>
                <Form.Control
                  name="edu1Percentage"
                  value={values.edu1Percentage}
                  placeholder="%"
                  onChange={handleFormData("edu1Percentage")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Institute/University Name From Where Passed 1</Form.Label>
                <Form.Control
                  name="edu1Institite"
                  value={values.edu1Institite}
                  placeholder="Institute/Uniersity Name"
                  onChange={handleFormData("edu1Institite")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>2nd Education / Career Achievements</h2>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Examination/Course Passed/Cleared 2</Form.Label>
                <Form.Control
                  name="edu2Course"
                  value={values.edu2Course}
                  placeholder="XI Std or Equivalent"
                  onChange={handleFormData("edu2Course")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Passing Details Month and year 2</Form.Label>
                <Form.Control
                  name="edu2PassMonth"
                  value={values.edu2PassMonth}
                  placeholder=""
                  onChange={handleFormData("edu2PassMonth")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 2</Form.Label>
                <Form.Control
                  name="edu2Marks"
                  value={values.edu2Marks}
                  placeholder="Secured"
                  onChange={handleFormData("edu2Marks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 2</Form.Label>
                <Form.Control
                  name="edu2TotalMarks"
                  value={values.edu2TotalMarks}
                  placeholder="Out Of"
                  onChange={handleFormData("edu2TotalMarks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 2</Form.Label>
                <Form.Control
                  name="edu2Percentage"
                  value={values.edu2Percentage}
                  placeholder="%"
                  onChange={handleFormData("edu2Percentage")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Institute/University Name From Where Passed 2</Form.Label>
                <Form.Control
                  name="edu2Institite"
                  value={values.edu2Institite}
                  placeholder="Institute/Uniersity Name"
                  onChange={handleFormData("edu2Institite")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>3rd Education / Career Achievements</h2>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Examination/Course Passed/Cleared 3</Form.Label>
                <Form.Control
                  name="edu3Course"
                  value={values.edu3Course}
                  placeholder="XII Std or Equivalent"
                  onChange={handleFormData("edu3Course")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Passing Details Month and year 3</Form.Label>
                <Form.Control
                  name="edu3PassMonth"
                  value={values.edu3PassMonth}
                  placeholder=""
                  onChange={handleFormData("edu3PassMonth")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 3</Form.Label>
                <Form.Control
                  name="edu3Marks"
                  value={values.edu3Marks}
                  placeholder="Secured"
                  onChange={handleFormData("edu3Marks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 3</Form.Label>
                <Form.Control
                  name="edu3TotalMarks"
                  value={values.edu3TotalMarks}
                  placeholder="Out Of"
                  onChange={handleFormData("edu3TotalMarks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 3</Form.Label>
                <Form.Control
                  name="edu3Percentage"
                  value={values.edu3Percentage}
                  placeholder="%"
                  onChange={handleFormData("edu3Percentage")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Institute/University Name From Where Passed 3</Form.Label>
                <Form.Control
                  name="edu3Institite"
                  value={values.edu3Institite}
                  placeholder="Institute/Uniersity Name"
                  onChange={handleFormData("edu3Institite")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>4th Education / Career Achievements</h2>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Examination/Course Passed/Cleared 4</Form.Label>
                <Form.Control
                  name="edu4Course"
                  value={values.edu4Course}
                  placeholder="X Std or Equivalent"
                  onChange={handleFormData("edu4Course")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Passing Details Month and year 4</Form.Label>
                <Form.Control
                  name="edu4PassMonth"
                  value={values.edu4PassMonth}
                  placeholder=""
                  onChange={handleFormData("edu4PassMonth")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 4</Form.Label>
                <Form.Control
                  name="edu4Marks"
                  value={values.edu4Marks}
                  placeholder="Secured"
                  onChange={handleFormData("edu4Marks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 4</Form.Label>
                <Form.Control
                  name="edu4TotalMarks"
                  value={values.edu4TotalMarks}
                  placeholder="Out Of"
                  onChange={handleFormData("edu4TotalMarks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 4</Form.Label>
                <Form.Control
                  name="edu4Percentage"
                  value={values.edu4Percentage}
                  placeholder="%"
                  onChange={handleFormData("edu4Percentage")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Institute/University Name From Where Passed 4</Form.Label>
                <Form.Control
                  name="edu4Institite"
                  value={values.edu4Institite}
                  placeholder="Institute/Uniersity Name"
                  onChange={handleFormData("edu4Institite")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>5th Education / Career Achievements</h2>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Examination/Course Passed/Cleared 5</Form.Label>
                <Form.Control
                  name="edu5Course"
                  value={values.edu5Course}
                  placeholder="X Std or Equivalent"
                  onChange={handleFormData("edu5Course")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Passing Details Month and year 5</Form.Label>
                <Form.Control
                  name="edu5PassMonth"
                  value={values.edu5PassMonth}
                  placeholder=""
                  onChange={handleFormData("edu5PassMonth")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 5</Form.Label>
                <Form.Control
                  name="edu5Marks"
                  value={values.edu5Marks}
                  placeholder="Secured"
                  onChange={handleFormData("edu5Marks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 5</Form.Label>
                <Form.Control
                  name="edu5TotalMarks"
                  value={values.edu5TotalMarks}
                  placeholder="Out Of"
                  onChange={handleFormData("edu5TotalMarks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 5</Form.Label>
                <Form.Control
                  name="edu5Percentage"
                  value={values.edu5Percentage}
                  placeholder="%"
                  onChange={handleFormData("edu5Percentage")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Institute/University Name From Where Passed 5</Form.Label>
                <Form.Control
                  name="edu5Institite"
                  value={values.edu5Institite}
                  placeholder="Institute/Uniersity Name"
                  onChange={handleFormData("edu5Institite")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>6th Education / Career Achievements</h2>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Examination/Course Passed/Cleared 6</Form.Label>
                <Form.Control
                  name="edu6Course"
                  value={values.edu6Course}
                  placeholder="X Std or Equivalent"
                  onChange={handleFormData("edu6Course")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Passing Details Month and year 6</Form.Label>
                <Form.Control
                  name="edu6PassMonth"
                  value={values.edu6PassMonth}
                  placeholder=""
                  onChange={handleFormData("edu6PassMonth")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 6</Form.Label>
                <Form.Control
                  name="edu6Marks"
                  value={values.edu6Marks}
                  placeholder="Secured"
                  onChange={handleFormData("edu6Marks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 6</Form.Label>
                <Form.Control
                  name="edu6TotalMarks"
                  value={values.edu6TotalMarks}
                  placeholder="Out Of"
                  onChange={handleFormData("edu6TotalMarks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 6</Form.Label>
                <Form.Control
                  name="edu6Percentage"
                  value={values.edu6Percentage}
                  placeholder="%"
                  onChange={handleFormData("edu6Percentage")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Institute/University Name From Where Passed 6</Form.Label>
                <Form.Control
                  name="edu6Institite"
                  value={values.edu6Institite}
                  placeholder="Institute/Uniersity Name"
                  onChange={handleFormData("edu6Institite")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>7th Education / Career Achievements</h2>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Examination/Course Passed/Cleared 7</Form.Label>
                <Form.Control
                  name="edu7course"
                  value={values.edu7course}
                  placeholder="X Std or Equivalent"
                  onChange={handleFormData("edu7course")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Passing Details Month and year 7</Form.Label>
                <Form.Control
                  name="edu7PassMonth"
                  value={values.edu7PassMonth}
                  placeholder=""
                  onChange={handleFormData("edu7PassMonth")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 7</Form.Label>
                <Form.Control
                  name="edu7Marks"
                  value={values.edu7Marks}
                  placeholder="Secured"
                  onChange={handleFormData("edu7Marks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 7</Form.Label>
                <Form.Control
                  name="edu7TotalMarks"
                  value={values.edu7TotalMarks}
                  placeholder="Out Of"
                  onChange={handleFormData("edu7TotalMarks")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Marks/Grade 7</Form.Label>
                <Form.Control
                  name="edu7Percentage"
                  value={values.edu7Percentage}
                  placeholder="%"
                  onChange={handleFormData("edu7Percentage")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Institute/University Name From Where Passed 7</Form.Label>
                <Form.Control
                  name="edu7Institite"
                  value={values.edu7Institite}
                  placeholder="Institute/Uniersity Name"
                  onChange={handleFormData("edu7Institite")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="formSectionHeader">
            <h3>Details Of Proposed/Commited Higher Education Study For Which Loan Is Applied</h3>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Course</Form.Label>
                <Form.Control
                  name="HESCourse"
                  value={values.HESCourse}
                  placeholder="Course Details"
                  onChange={handleFormData("HESCourse")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Duration</Form.Label>
                <Form.Control
                  name="HESDuration"
                  value={values.HESDuration}
                  placeholder="Years"
                  onChange={handleFormData("HESDuration")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Starts</Form.Label>
                <Form.Control
                  name="HESStart"
                  value={values.HESStart}
                  placeholder="Month and Year"
                  onChange={handleFormData("HESStart")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Duration</Form.Label>
                <Form.Control
                  name="HESEnds"
                  value={values.HESEnds}
                  placeholder="Month and Year"
                  onChange={handleFormData("HESEnds")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Institute Name</Form.Label>
                <Form.Control
                  name="HESInstituteName"
                  value={values.HESInstituteName}
                  placeholder="Institute Name"
                  onChange={handleFormData("HESInstituteName")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Insitute Address</Form.Label>
                <Form.Control
                  name="HESInstituteAddress"
                  value={values.HESInstituteAddress}
                  placeholder="Institute Address"
                  onChange={handleFormData("HESInstituteAddress")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Affiliated To Uniersity</Form.Label>
                <Form.Control
                  name="HESUniversityName"
                  value={values.HESUniversityName}
                  placeholder="Uniersity Name"
                  onChange={handleFormData("HESUniversityName")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="formSectionHeader">
            <h3>Proposed / Commited Education Expenses Details:</h3>
          </Row>

          <Row>
            <h2>1st Expense Details</h2>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Date 1</Form.Label>
                <Form.Control
                  name="EEDEDDate1"
                  value={values.EEDEDDate1}
                  type="date"
                  placeholder="Date"
                  onChange={handleFormData("EEDEDDate1")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Against/Head 1</Form.Label>
                <Form.Control
                  name="EEDEDAgainst1"
                  value={values.EEDEDAgainst1}
                  placeholder="Head"
                  onChange={handleFormData("EEDEDAgainst1")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Actual Approx Amount Of Expense 1</Form.Label>
                <Form.Control
                  name="EEDAmount1"
                  value={values.EEDAmount1}
                  placeholder="Amount"
                  onChange={handleFormData("EEDAmount1")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Remarks/Other Details 1</Form.Label>
                <Form.Control
                  name="EEDRemarks1"
                  value={values.EEDRemarks1}
                  placeholder="Remarks"
                  onChange={handleFormData("EEDRemarks1")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>2nd Expense Details</h2>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Date 2</Form.Label>
                <Form.Control
                  name="EEDEDDate2"
                  value={values.EEDEDDate2}
                  type="date"
                  onChange={handleFormData("EEDEDDate2")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Against/Head 2</Form.Label>
                <Form.Control
                  name="EEDEDAgainst2"
                  value={values.EEDEDAgainst2}
                  placeholder="Head"
                  onChange={handleFormData("EEDEDAgainst2")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Actual Approx Amount Of Expense 2</Form.Label>
                <Form.Control
                  name="EEDAmount2"
                  value={values.EEDAmount2}
                  placeholder="Amount"
                  onChange={handleFormData("EEDAmount2")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Remarks/Other Details 2</Form.Label>
                <Form.Control
                  name="EEDRemarks2"
                  value={values.EEDRemarks2}
                  placeholder="Remarks"
                  onChange={handleFormData("EEDRemarks2")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>3rd Expense Details</h2>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Date 3</Form.Label>
                <Form.Control
                  name="EEDEDDate3"
                  value={values.EEDEDDate3}
                  type="date"
                  onChange={handleFormData("EEDEDDate3")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Against/Head 3</Form.Label>
                <Form.Control
                  name="EEDEDAgainst3"
                  value={values.EEDEDAgainst3}
                  placeholder="Date"
                  onChange={handleFormData("EEDEDAgainst3")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Actual Approx Amount Of Expense 3</Form.Label>
                <Form.Control
                  name="EEDAmount3"
                  value={values.EEDAmount3}
                  placeholder="Amount"
                  onChange={handleFormData("EEDAmount3")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Remarks/Other Details 3</Form.Label>
                <Form.Control
                  name="EEDRemarks3"
                  value={values.EEDRemarks3}
                  placeholder="Remarks"
                  onChange={handleFormData("EEDRemarks3")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>4th Expense Details</h2>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Date 4</Form.Label>
                <Form.Control
                  name="EEDEDDate4"
                  value={values.EEDEDDate4}
                  type="date"
                  placeholder="Date"
                  onChange={handleFormData("EEDEDDate4")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Against/Head 4</Form.Label>
                <Form.Control
                  name="EEDEDAgainst4"
                  value={values.EEDEDAgainst4}
                  placeholder="Head"
                  onChange={handleFormData("EEDEDAgainst4")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Actual Approx Amount Of Expense 4</Form.Label>
                <Form.Control
                  name="EEDAmount4"
                  value={values.EEDAmount4}
                  placeholder="Amount"
                  onChange={handleFormData("EEDAmount4")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Remarks/Other Details 4</Form.Label>
                <Form.Control
                  name="EEDRemarks4"
                  value={values.EEDRemarks4}
                  placeholder="Remarks"
                  onChange={handleFormData("EEDRemarks4")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>5th Expense Details</h2>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Date 5</Form.Label>
                <Form.Control
                  name="EEDEDDate5"
                  value={values.EEDEDDate5}
                  type="date"
                  placeholder="Date"
                  onChange={handleFormData("EEDEDDate5")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Against/Head 5</Form.Label>
                <Form.Control
                  name="EEDEDAgainst5"
                  value={values.EEDEDAgainst5}
                  placeholder="Head"
                  onChange={handleFormData("EEDEDAgainst5")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Actual Approx Amount Of Expense 5</Form.Label>
                <Form.Control
                  name="EEDAmount5"
                  value={values.EEDAmount5}
                  placeholder="Amount"
                  onChange={handleFormData("EEDAmount5")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Remarks/Other Details 5</Form.Label>
                <Form.Control
                  name="EEDRemarks5"
                  value={values.EEDRemarks5}
                  placeholder="Remarks"
                  onChange={handleFormData("EEDRemarks5")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>6th Expense Details</h2>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Date 6</Form.Label>
                <Form.Control
                  name="EEDEDDate6"
                  value={values.EEDEDDate6}
                  type="date"
                  placeholder="Date"
                  onChange={handleFormData("EEDEDDate6")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Against/Head 6</Form.Label>
                <Form.Control
                  name="EEDEDAgainst6"
                  value={values.EEDEDAgainst6}
                  placeholder="Head"
                  onChange={handleFormData("EEDEDAgainst6")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Actual Approx Amount Of Expense 6</Form.Label>
                <Form.Control
                  name="EEDAmount6"
                  value={values.EEDAmount6}
                  placeholder="Amount"
                  onChange={handleFormData("EEDAmount6")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Remarks/Other Details 6</Form.Label>
                <Form.Control
                  name="EEDRemarks6"
                  value={values.EEDRemarks6}
                  placeholder="Remarks"
                  onChange={handleFormData("EEDRemarks6")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>Total Expenses</h2>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Total Expense </Form.Label>
                <Form.Control
                  name="EEDAmountTotal"
                  value={values.EEDAmountTotal}
                  placeholder="Total Amount"
                  onChange={handleFormData("EEDAmountTotal")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="formSectionHeader">
            <h3>Details Of Arranged Finance / Assistance :</h3>
          </Row>

          <Row>
            <h2>Arranged Finance (Self)</h2>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Head</Form.Label>
              </Form.Group>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Group>
                <Form.Label>Organization Name</Form.Label>
                <Form.Control
                  name="DAFOwnResources"
                  value={values.DAFOwnResources}
                  placeholder=" Organization Name"
                  onChange={handleFormData("DAFOwnResources")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Applied</Form.Label>
                <Form.Label></Form.Label>
                <Form.Control
                  name="DAFSelfApplied"
                  value={values.DAFSelfApplied}
                  placeholder="Applied"
                  onChange={handleFormData("DAFSelfApplied")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Sanctioned</Form.Label>
                <Form.Control
                  name="DAFSelfSanctioned"
                  value={values.DAFSelfSanctioned}
                  placeholder=" Sanctioned"
                  onChange={handleFormData("DAFSelfSanctioned")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Disbursed</Form.Label>
                <Form.Control
                  name="DAFBankDisbursed"
                  value={values.DAFBankDisbursed}
                  placeholder="Disbursed"
                  onChange={handleFormData("DAFBankDisbursed")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>Arranged Finance (Bank)</h2>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Head</Form.Label>
                <Form.Control name="DAFHeadBank" value="Bank" placeholder="Bank" />
              </Form.Group>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Group>
                <Form.Label>Organization Name</Form.Label>
                <Form.Control
                  name="DAFBankName"
                  value={values.DAFBankName}
                  placeholder=" Organization Name"
                  onChange={handleFormData("DAFBankName")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Applied</Form.Label>
                <Form.Label></Form.Label>
                <Form.Control
                  name="DAFBankApplied"
                  value={values.DAFBankApplied}
                  placeholder="Applied"
                  onChange={handleFormData("DAFBankApplied")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Sanctioned</Form.Label>
                <Form.Control
                  name="DAFBankSanctioned"
                  value={values.DAFBankSanctioned}
                  placeholder=" Sanctioned"
                  onChange={handleFormData("DAFBankSanctioned")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Disbursed</Form.Label>
                <Form.Control
                  name="DAFBankDisbursed"
                  value={values.DAFBankDisbursed}
                  placeholder="Disbursed"
                  onChange={handleFormData("DAFBankDisbursed")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>Arranged Finance (Institute 1)</h2>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Head</Form.Label>
                <Form.Control name="DAFHeadinstitute1" value={"Institute"} placeholder="Institute" />
              </Form.Group>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Group>
                <Form.Label>Organization Name</Form.Label>
                <Form.Control
                  name="DAFinstitute4OrganizationName"
                  value={values.DAFinstitute4OrganizationName}
                  placeholder=" Organization Name"
                  onChange={handleFormData("DAFinstitute4OrganizationName")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Applied</Form.Label>
                <Form.Label></Form.Label>
                <Form.Control
                  name="DAFinstitute4Applied"
                  value={values.DAFinstitute4Applied}
                  placeholder="Applied"
                  onChange={handleFormData("DAFinstitute4Applied")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Sanctioned</Form.Label>
                <Form.Control
                  name="DAFinstitute4Sanctioned"
                  value={values.DAFinstitute4Sanctioned}
                  placeholder=" Sanctioned"
                  onChange={handleFormData("DAFinstitute4Sanctioned")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Disbursed</Form.Label>
                <Form.Control
                  name="DAFinstitute4Disbursed"
                  value={values.DAFinstitute4Disbursed}
                  placeholder="Disbursed"
                  onChange={handleFormData("DAFinstitute4Disbursed")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>Arranged Finance (Institute 2)</h2>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Head</Form.Label>
                <Form.Control name="DAFHeadinstitute2" value={"Institute"} placeholder="Institute" />
              </Form.Group>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Group>
                <Form.Label>Organization Name</Form.Label>
                <Form.Control
                  name="DAFinstitute5OrganizationName"
                  value={values.DAFinstitute5OrganizationName}
                  placeholder=" Organization Name"
                  onChange={handleFormData("DAFinstitute5OrganizationName")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Applied</Form.Label>
                <Form.Label></Form.Label>
                <Form.Control
                  name="DAFinstitute5Applied"
                  value={values.DAFinstitute5Applied}
                  placeholder="Applied"
                  onChange={handleFormData("DAFinstitute5Applied")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Sanctioned</Form.Label>
                <Form.Control
                  name="DAFinstitute5Sanctioned"
                  value={values.DAFinstitute5Sanctioned}
                  placeholder=" Sanctioned"
                  onChange={handleFormData("DAFinstitute5Sanctioned")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Disbursed</Form.Label>
                <Form.Control
                  name="DAFinstitute5Disbursed"
                  value={values.DAFinstitute5Disbursed}
                  placeholder="Disbursed"
                  onChange={handleFormData("DAFinstitute5Disbursed")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>Arranged Finance (Addtional Source 1)</h2>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Head</Form.Label>
                <Form.Control
                  name="DAFHead6"
                  value={values.DAFHead6}
                  placeholder=""
                  onChange={handleFormData("DAFHead6")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Group>
                <Form.Label>Organization Name</Form.Label>
                <Form.Control
                  name="DAFOrganizationName6"
                  value={values.DAFOrganizationName6}
                  placeholder=" Organization Name"
                  onChange={handleFormData("DAFOrganizationName6")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Applied</Form.Label>
                <Form.Label></Form.Label>
                <Form.Control
                  name="DAFApplied6"
                  value={values.DAFApplied6}
                  placeholder="Applied"
                  onChange={handleFormData("DAFApplied6")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Sanctioned</Form.Label>
                <Form.Control
                  name="DAFSanctioned6"
                  value={values.DAFSanctioned6}
                  placeholder=" Sanctioned"
                  onChange={handleFormData("DAFSanctioned6")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Disbursed</Form.Label>
                <Form.Control
                  name="DAFDisbursed6"
                  value={values.DAFDisbursed6}
                  placeholder="Disbursed"
                  onChange={handleFormData("DAFDisbursed6")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>Arranged Finance (Addtional Source 2)</h2>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Head</Form.Label>
                <Form.Control
                  name="DAFHead7"
                  value={values.DAFHead7}
                  placeholder=""
                  onChange={handleFormData("DAFHead7")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Group>
                <Form.Label>Organization Name</Form.Label>
                <Form.Control
                  name="DAFOrganizationName7"
                  value={values.DAFOrganizationName7}
                  placeholder=" Organization Name"
                  onChange={handleFormData("DAFOrganizationName7")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Applied</Form.Label>
                <Form.Label></Form.Label>
                <Form.Control
                  name="DAFApplied7"
                  value={values.DAFApplied7}
                  placeholder="Applied"
                  onChange={handleFormData("DAFApplied7")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Sanctioned</Form.Label>
                <Form.Control
                  name="DAFSanctioned7"
                  value={values.DAFSanctioned7}
                  placeholder=" Sanctioned"
                  onChange={handleFormData("DAFSanctioned7")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Disbursed</Form.Label>
                <Form.Control
                  name="DAFDisbursed7"
                  value={values.DAFDisbursed7}
                  placeholder="Disbursed"
                  onChange={handleFormData("DAFDisbursed7")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h2>Total Arranged Finance </h2>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label> Total Applied Amount</Form.Label>
                <Form.Label></Form.Label>
                <Form.Control
                  name="DAFAppliedTotal"
                  value={values.DAFAppliedTotal}
                  placeholder="Applied"
                  onChange={handleFormData("DAFAppliedTotal")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Sanctioned</Form.Label>
                <Form.Control
                  name="DAFSanctionedTotal"
                  value={values.DAFSanctionedTotal}
                  placeholder=" Sanctioned"
                  onChange={handleFormData("DAFSanctionedTotal")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Amount Disbursed</Form.Label>
                <Form.Control
                  name="DAFDisbursedTotal"
                  value={values.DAFDisbursedTotal}
                  placeholder="Disbursed"
                  onChange={handleFormData("DAFDisbursedTotal")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="btn--outline" onClick={prevStep}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Previous
              </Button>
            </Col>
            <Col>
              <Button className="btn--outline--dark" onClick={submitFormData}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default EduPage2;
