import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, ListGroup, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";

function TutorialScreenHin() {
  const [tutorial, setTutorial] = useState([]);

  const url = useParams();

  const fetchTuotial = async () => {
    const response = await axios.get(`/api/data/lan/tutorial/?lan=hin&f_id=${url.id}`);
    const data = response.data[0];

    console.log(data);
    setTutorial(data);
  };

  useEffect(() => {
    fetchTuotial();
  }, [url.id]);

  return (
    <div>
      <Row>
        <Col>
          <h2>Hindi Tutorial for {tutorial.name}</h2>
        </Col>
      </Row>
      <Row>
        <Col style={{ display: "flex", justifyContent: "center" }}>
          <iframe
            width="840"
            height="472"
            src={tutorial.link}
            title={tutorial.name}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Col>
      </Row>
    </div>
  );
}

export default TutorialScreenHin;
