import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";

// creating functional component ans getting props from app.js and destucturing them
const SgnBEditpage1 = ({ nextStep, handleFormData, values }) => {
  //creating error state for validation
  const user = useSelector((state) => state.authentication.user);
  const [error, setError] = useState(false);

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    console.log("submit");
    e.preventDefault();
    nextStep();
  };

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get("/user/region");

    setRegions(response.data.sort((a, b) => a.region.localeCompare(b.region)));
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${values.regName}/sangini`);

    setGroups(response.data);
  };

  useEffect(() => {
    fetchGroups();
  }, [values.regName]);

  return (
    <div>
      <Container>
        <Form>
          <Row>
            <Col xs={12}>
              <h2>Sangini Form B Edit</h2>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Region Name</Form.Label>
                <Form.Select
                  name="regName"
                  defaultValue={values.regName}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("regName")}
                >
                  <option>Select your Region</option>
                  {regions.map((region) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() + region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Group Name</Form.Label>
                <Form.Select
                  name="grpName"
                  defaultValue={values.grpName}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("grpName")}
                >
                  <option>Select your Group</option>
                  {groups.map((group) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      value={group.group.toLowerCase()}
                    >
                      {group.group.charAt(0).toUpperCase() + group.group.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Group Number</Form.Label>
                <Form.Control
                  name="grpNumber"
                  defaultValue={values.grpNumber}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("grpNumber")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Group Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="grpAddress"
                  defaultValue={values.grpAddress}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("grpAddress")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Landline STD Code</Form.Label>
                <Form.Control
                  name="stdCode"
                  defaultValue={values.stdCode}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("stdCode")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Date Of Inaugration</Form.Label>
                <Form.Control
                  name="dateOfInaugration"
                  defaultValue={values.dateOfInaugration}
                  type="date"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("dateOfInaugration")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Date of Charter</Form.Label>
                <Form.Control
                  name="dateOfCharter"
                  defaultValue={values.dateOfCharter}
                  type="date"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("dateOfCharter")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Name of Sponsor Group</Form.Label>
                <Form.Control
                  name="sponsorGrpName"
                  defaultValue={values.sponsorGrpName}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("sponsorGrpName")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Group Landline Number</Form.Label>
                <Form.Control
                  name="grpPhone"
                  defaultValue={values.grpPhone}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("grpPhone")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Group Mobile</Form.Label>
                <Form.Control
                  name="grpMobile"
                  defaultValue={values.grpMobile}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("grpMobile")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Group Email ID</Form.Label>
                <Form.Control
                  name="grpEmail"
                  defaultValue={values.grpEmail}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("grpEmail")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>General Meeting Date for Election</Form.Label>
                <Form.Control
                  name="electionMeetingDate"
                  defaultValue={values.electionMeetingDate}
                  type="date"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("electionMeetingDate")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Officers Elected Date</Form.Label>
                <Form.Control
                  name="electedOnDate"
                  defaultValue={values.electedOnDate}
                  type="date"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("electedOnDate")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="formSectionHeader">
            <h3>President Details</h3>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>President Name</Form.Label>
                <Form.Control
                  name="presName"
                  defaultValue={values.presName}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presName")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="presPhoto"
                  type="file"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presPhoto")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="presaddress"
                  defaultValue={values.presaddress}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presaddress")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  name="presPinCode"
                  defaultValue={values.presPinCode}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presPinCode")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Office Landline</Form.Label>
                <Form.Control
                  name="presOffLandLine"
                  defaultValue={values.presOffLandLine}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presOffLandLine")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Residence Landline</Form.Label>
                <Form.Control
                  name="presResLandLine"
                  defaultValue={values.presResLandLine}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presResLandLine")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name="presMobileNumber"
                  defaultValue={values.presMobileNumber}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presMobileNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  name="presWhatsappNumber"
                  defaultValue={values.presWhatsappNumber}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presWhatsappNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  name="presEmail"
                  defaultValue={values.presEmail}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presEmail")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Occupation Details</Form.Label>
                <Form.Control
                  name="presOccupation"
                  defaultValue={values.presOccupation}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presOccupation")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  name="presBirthDay"
                  defaultValue={values.presBirthDay}
                  type="date"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presBirthDay")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Name</Form.Label>
                <Form.Control
                  name="presSpouseName"
                  defaultValue={values.presSpouseName}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presSpouseName")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Date of Birth</Form.Label>
                <Form.Control
                  name="presSpouseBirthDay"
                  defaultValue={values.presSpouseBirthDay}
                  type="date"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presSpouseBirthDay")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Marraige Anniversary</Form.Label>
                <Form.Control
                  name="presMarraigeDate"
                  defaultValue={values.presMarraigeDate}
                  type="date"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("presMarraigeDate")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="formSectionHeader">
            <h3>Immediate Former President</h3>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Imm Former President Name</Form.Label>
                <Form.Control
                  name="immFormerPresName"
                  defaultValue={values.immFormerPresName}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresName")}
                />
              </Form.Group>
            </Col>
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="immFormerPresPhoto"
                  type="file"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresPhoto")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Correspondance Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="immFormerPresaddress"
                  defaultValue={values.immFormerPresaddress}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresaddress")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  name="immFormerPresPinCode"
                  defaultValue={values.immFormerPresPinCode}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresPinCode")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Office Landline</Form.Label>
                <Form.Control
                  name="immFormerPresOffLandLine"
                  defaultValue={values.immFormerPresOffLandLine}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresOffLandLine")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Residence Landline</Form.Label>
                <Form.Control
                  name="immFormerPresResLandLine"
                  defaultValue={values.immFormerPresResLandLine}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresResLandLine")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name="immFormerPresMobileNumber"
                  defaultValue={values.immFormerPresMobileNumber}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresMobileNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  name="immFormerPresWhatsappNumber"
                  defaultValue={values.immFormerPresWhatsappNumber}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresWhatsappNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  name="immFormerPresEmail"
                  defaultValue={values.immFormerPresEmail}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresEmail")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Occupation</Form.Label>
                <Form.Control
                  name="immFormerPresOccupation"
                  defaultValue={values.immFormerPresOccupation}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresOccupation")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  name="immFormerPresBirthDay"
                  defaultValue={values.immFormerPresBirthDay}
                  type="date"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresBirthDay")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Name</Form.Label>
                <Form.Control
                  name="immFormerPresSpouseName"
                  defaultValue={values.immFormerPresSpouseName}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresSpouseName")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Date of Birth</Form.Label>
                <Form.Control
                  name="immFormerPresSpouseBirthDay"
                  defaultValue={values.immFormerPresSpouseBirthDay}
                  type="date"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresSpouseBirthDay")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Marraige Anniversary</Form.Label>
                <Form.Control
                  name="immFormerPresMarraigeDate"
                  defaultValue={values.immFormerPresMarraigeDate}
                  type="date"
                  style={{ textTransform: "capitalize" }}
                  onChange={handleFormData("immFormerPresMarraigeDate")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button className="btn--outline--dark" onClick={(e) => submitFormData(e)}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Next
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default SgnBEditpage1;
