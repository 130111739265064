import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../App.css";
import { Row, Col, Container, Card, ListGroup, Alert, Toast } from "react-bootstrap";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import "./HeroSection.css";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";

const HeroSection = () => {
  const [info, setInfos] = useState([]);
  const user = useSelector((state) => state.authentication.user);

  const fetchData = async () => {
    const response = await axios.get(`/user/user/${user.user_id}`);
    setInfos(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setShow(false);
  //   }, 10000);
  //   return () => clearTimeout(timeoutId);
  // }, []);

  return (
    <div>
      <Container>
        {/* <Toast show={show} className="alertToast" onClose={handleClose}>
          <Toast.Header className="me-auto alertHeader ">
            <h5>Alert</h5>
          </Toast.Header>
          <Toast.Body className="alertBody">
            <h6>
              We are constantly updating the website as there might be some
              errors in the data. Thank you for your patience{" "}
            </h6>
          </Toast.Body>
        </Toast> */}
        <Row>
          {(() => {
            if (typeof user !== "undefined" && typeof user.username !== "undefined") {
              return (
                <Col lg={6} md={12} xs={12}>
                  <Card className="customCard">
                    <Card.Header className="customHeader">JSGian Profile - "{info.username}"</Card.Header>
                    <div className="customCardImg">
                      <img alt={"JSGian " + info.first_name + info.last_name} src={info.img} />
                    </div>
                    <Card.Body>
                      <Card.Title className="customTitle">
                        JSGian{" "}
                        <span style={{ textTransform: "uppercase" }}>
                          {info.first_name} {info.last_name}
                        </span>
                      </Card.Title>
                      <Row>
                        <Card.Text className="customLink" href={`mailto:${info.email}`}>
                          <i className="fa-solid fa-envelope"></i> {info.email}
                        </Card.Text>
                      </Row>
                      <Row>
                        <Col>
                          <Card.Text className="customLink" href={`tel:+${info.contactNumber}`}>
                            <i className="fa-solid fa-mobile-button"></i> {info.contactNumber}
                          </Card.Text>
                        </Col>
                        <Col>
                          <Card.Text className="customLink" href={`https://wa.me/:${info.whatsappNumber}`}>
                            <i className="fa-brands fa-whatsapp"></i> {info.whatsappNumber}
                          </Card.Text>
                        </Col>
                      </Row>
                      <LinkContainer to={`/user/edit-profile/${info.id}`}>
                        <Row>
                          <Card.Text className="customLink2">Edit Profile</Card.Text>
                        </Row>
                      </LinkContainer>
                    </Card.Body>
                    <ListGroup>
                      <ListGroup.Item className="customItem">Post : {info.post}</ListGroup.Item>
                      <ListGroup.Item className="customItem">Group Name : {info.jsgGroupName}</ListGroup.Item>
                      <ListGroup.Item className="customItem">Region Name : {info.jsgRegionName}</ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
              );
            } else return <Col></Col>;
          })()}

          <Col lg={6} md={12} xs={12} className="col-md-auto">
            <div className="hero-text">
              <h1>JSG Int Federation</h1>
              <p>Welcome to one of the largest associations of JAINS in the world</p>
            </div>
            <div className="hero-btns">
              <Button className="btns" buttonStyle="btn--secondary" buttonSize="btn--large" pagelink={"aboutus"}>
                About us
              </Button>
              <Button className="btns" buttonStyle="btn--primary" buttonSize="btn--large" pagelink={"fed/events"}>
                Latest Events
              </Button>
              {(() => {
                if (
                  typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "group"
                ) {
                  return (
                    <Button
                      className="btns"
                      buttonStyle="btn--primary"
                      buttonSize="btn--large"
                      pagelink={"upload/grp/event/"}
                    >
                      Add Group's Events
                    </Button>
                  );
                } else if (
                  (typeof user !== "undefined" &&
                    typeof user.username !== "undefined" &&
                    user.accessGroup === "admin") ||
                  (typeof user !== "undefined" &&
                    typeof user.username !== "undefined" &&
                    user.accessGroup === "offBearer")
                ) {
                  return (
                    <Button
                      className="btns"
                      buttonStyle="btn--primary"
                      buttonSize="btn--large"
                      pagelink={"upload/fed/event/"}
                    >
                      Add Federation's Events
                    </Button>
                  );
                } else if (
                  (typeof user !== "undefined" &&
                    typeof user.username !== "undefined" &&
                    user.accessGroup === "admin") ||
                  (typeof user !== "undefined" && typeof user.username !== "undefined" && user.accessGroup === "region")
                ) {
                  return (
                    <Button
                      className="btns"
                      buttonStyle="btn--primary"
                      buttonSize="btn--large"
                      pagelink={"upload/reg/event/"}
                    >
                      Add Region's Events
                    </Button>
                  );
                } else if (
                  (typeof user !== "undefined" &&
                    typeof user.username !== "undefined" &&
                    user.accessGroup === "sangini") ||
                  (typeof user !== "undefined" &&
                    typeof user.username !== "undefined" &&
                    user.accessGroup === "sanginiGroup")
                ) {
                  return (
                    <Button
                      className="btns"
                      buttonStyle="btn--primary"
                      buttonSize="btn--large"
                      pagelink={"upload/sgn/event/"}
                    >
                      Add Sangini Events
                    </Button>
                  );
                }
              })()}
              {(() => {
                if (
                  (typeof user !== "undefined" &&
                    typeof user.username !== "undefined" &&
                    user.accessGroup === "admin") ||
                  (typeof user !== "undefined" &&
                    typeof user.username !== "undefined" &&
                    user.accessGroup === "offBearer")
                ) {
                  return (
                    <Button
                      className="btns"
                      buttonStyle="btn--secondary"
                      buttonSize="btn--large"
                      pagelink={"upload/sgn/event/"}
                    >
                      Add Your Project
                    </Button>
                  );
                }
              })()}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
