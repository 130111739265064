import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../components/Button.css";
import { Row, Col, Card, Form, Button, Container, Table } from "react-bootstrap";
import "../../formsList.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import SecurePageSection from "../../../SecurePageSection";
import { LinkContainer } from "react-router-bootstrap";

const GrpQ1List = () => {
  const user = useSelector((state) => state.authentication.user);

  const [forms, setForms] = useState([]);

  const fetchForms = async () => {
    const response = await axios.get(`/api/forms/ventures/eminence-q1-groups/`);
    console.log(response.data);
    setForms(response.data);
  };

  const navigate = useNavigate();
  const newForm = () => {
    navigate("/form/eminence/q1/group");
  };
  const tutorials = () => {
    navigate("/tutorials/eng/grp-agm");
  };

  useEffect(() => {
    fetchForms();
  }, []);

  return (
    <div>
      <SecurePageSection accessableUsers={"admin,eminenceChair"} />
      <Container className="body">
        <Row style={{ marginBottom: "1em" }}>
          <Col className="d-flex justify-content-end">
            <Button className="btn--primary" onClick={newForm}>
              New Form
            </Button>
          </Col>
        </Row>
        <Table responsive="md" striped bordered>
          <thead className="tableHead">
            <tr>
              <th>S. No.</th>
              <th>Form Id</th>
              <th>Group Name</th>
              <th>Region Name</th>
              <th>Submitted By</th>
              {(() => {
                if (
                  (typeof user !== "undefined" &&
                    typeof user.username !== "undefined" &&
                    user.accessGroup === "admin") ||
                  (typeof user !== "undefined" &&
                    typeof user.username !== "undefined" &&
                    user.accessGroup === "eminenceChair")
                ) {
                  return <th>Form</th>;
                }
              })()}
            </tr>
          </thead>

          <tbody className="tableBody">
            {forms
              .slice(0)
              .reverse()
              .map((form, index) => (
                <tr key={index} style={{ textTransform: "capitalize", textAlign: "center" }}>
                  <td>{index + 1}</td>
                  <td>{form.id}</td>
                  <td>{form.grpName}</td>
                  <td>{form.regName}</td>
                  <td>{form.name}</td>

                  <td style={{ width: "20rem" }}>
                    <Row>
                      <Col>
                        <div style={{ textTransform: "center" }}>
                          <LinkContainer to={`/eminence/q1/group/response/${form.id}`}>
                            <Button variant="light" className="btn--four">
                              View Form
                            </Button>
                          </LinkContainer>
                        </div>
                      </Col>
                    </Row>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default GrpQ1List;
