import React, { useState, useRef, useEffect } from "react";
import "./Book.css";
import HTMLFlipBook from "react-pageflip";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Button, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import pdfFile from "../images/JSGIF Constitution 16-12-2020.pdf";

function Constitution16() {
  const user = useSelector((state) => state.authentication.user);
  const location = useLocation();

  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState("");
  const [isPageFlipReady, setIsPageFlipReady] = useState(false);
  const pageFlipRef = useRef();

  const handlePageChange = () => {
    let page = pageFlipRef.current.pageFlip().getCurrentPageIndex();
    setCurrentPage(page);
    console.log(page);
  };

  useEffect(() => {
    if (isPageFlipReady) {
      handlePageChange();
    }
  }, [isPageFlipReady]);

  const prevPage = () => {
    pageFlipRef.current.pageFlip().flipPrev();
  };

  const nextPage = () => {
    pageFlipRef.current.pageFlip().flipNext();
  };

  useEffect(() => {
    const totalPageCounter = () => {
      let counter = pageFlipRef.current.pageFlip().getPageCount();
      setTotalPages(counter);
    };

    if (isPageFlipReady) {
      totalPageCounter();
    }
  }, [isPageFlipReady]);

  const fetchPages = async () => {
    const pageResponse = await axios.get(`/api/book/37`);
    console.log(pageResponse.data);
    setPages(pageResponse.data);
  };

  useEffect(() => {
    fetchPages();
  }, []);

  const downloadPDF = () => {
    saveAs(pdfFile, "JSGIF Constitution 16-20-2020.pdf");
  };

  const [turnEnable, setTurnEnable] = useState(true);

  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < 768; // or any other breakpoint you prefer
      setTurnEnable(!isMobile);
    }
    handleResize(); // Call handleResize initially to set the initial value of turnEnable
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  return (
    <div className="book-container">
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Col
          xs={12}
          md={3}
          style={{
            marginTop: "5px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <h2>JSGIF Constitution 16-20-2020</h2>
        </Col>
        <Col xs={12} md={3}>
          <Button className="btn--primary" onClick={(e) => downloadPDF()}>
            Download JSGIF Constitution 16-20-2020
          </Button>
        </Col>
        <Col
          xs={12}
          md={2}
          style={{
            marginTop: "5px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <p style={{ fontWeight: "600", fontSize: "20px" }}>
            Page: {currentPage + 1}/ {totalPages + 2}
          </p>
        </Col>
        <Col
          xs={12}
          md={2}
          style={{
            marginTop: "5px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Button
            style={{ width: "160px", margin: "0 20px" }}
            className="btn--secondary"
            onClick={prevPage}
          >
            Prev Page
          </Button>
        </Col>
        <Col
          xs={12}
          md={2}
          style={{
            marginTop: "5px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Button
            style={{ width: "160px", margin: "0 20px" }}
            className="btn--secondary"
            onClick={nextPage}
          >
            Next Page
          </Button>
        </Col>
      </Row>
      <div style={{ marginTop: "20px" }}>
        {pages.length !== [] ? (
          <>
            <HTMLFlipBook
              style={{
                margin: "0 auto",
                touchAction: "manipulation",
              }}
              size="stretch"
              width={538}
              height={715}
              ref={pageFlipRef}
              showCover={true}
              minWidth={335}
              maxWidth={1025}
              minHeight={400}
              maxHeight={1533}
              useMouseEvents={turnEnable}
              mobileScrollSupport={false}
              onFlip={handlePageChange}
              onInit={() => setIsPageFlipReady(true)}
            >
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.coverPage})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page1})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page2})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page3})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page4})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page6})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page7})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page8})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page9})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page10})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page11})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page12})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page13})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page14})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page16})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page17})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page18})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page19})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page20})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page21})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page22})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page23})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page24})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.page25})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>

              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${pages.lastPage})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
            </HTMLFlipBook>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
}

export default Constitution16;
