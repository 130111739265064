import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../components/Button.css";
import { Row, Col, Button, Container, Table, Form } from "react-bootstrap";
import moment from "moment";
import "../formsList.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import SecurePageSection from "../../SecurePageSection";

const RegionMangalyatra = () => {
  const user = useSelector((state) => state.authentication.user);

  const [regName, setRegName] = useState(""); // Selected region
  const [regions, setRegions] = useState([]); // Your regions data
  const [forms, setForms] = useState([]); // All forms

  const fetchRegions = async () => {
    const response = await axios.get("/user/region");
    setRegions(response.data.sort((a, b) => a.region.localeCompare(b.region)));
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  let filterUrl = "";

  if (regName === "") {
    filterUrl = `/api/forms/reg-mangalyatras/`;
  }
  if (regName !== "") {
    filterUrl = `/api/forms/group/filter/reg-mangalyatra/?regName=${regName}`;
  }

  const fetchForms = async () => {
    const response = await axios.get(filterUrl);
    console.log(response);
    const formData = response.data;

    // Define the start and end date for filtering
    const startDate = new Date("2024-07-15");
    const endDate = new Date("2024-09-20");

    // Filter the forms based on the eDate field
    const filteredForms = formData.filter((form) => {
      const eDate = new Date(form.eDate);
      return eDate >= startDate && eDate <= endDate;
    });

    // Sort the filtered forms in descending order based on eDate
    const sortedForms = filteredForms.sort((a, b) => {
      const dateA = new Date(a.eDate);
      const dateB = new Date(b.eDate);
      return dateB - dateA;
    });

    console.log(sortedForms);
    setForms(sortedForms);
  };

  const navigate = useNavigate();
  const newForm = () => {
    navigate("/form/reg-mangalyatra");
  };

  useEffect(() => {
    fetchForms();
  }, [regName]);

  const tutorials = () => {
    navigate("/tutorials/reg/mangalyatra");
  };

  const handleStatusChange = async (e, formId) => {
    const newStatus = e.target.checked; // true if checked, false if unchecked
    const updatedForms = forms.map((form) =>
      form.id === formId ? { ...form, status: newStatus } : form
    );
    setForms(updatedForms);

    try {
      // Make a PUT request to update the form's status
      const response = await axios.put(
        `/api/forms/reg-mangalyatra/${formId}/`,
        {
          status: newStatus,
        }
      );

      // Assuming your API response indicates a successful update
      if (response.status === 200) {
        // Update the status in the local state
        const updatedForms = forms.map((form) =>
          form.id === formId ? { ...form, status: newStatus } : form
        );
        setForms(updatedForms);
      }
    } catch (error) {
      console.error("Error updating status:", error);
      const updatedForms = forms.map((form) =>
        form.id === formId ? { ...form, status: !newStatus } : form
      );
      setForms(updatedForms);
      // Handle error accordingly
    }
  };

  return (
    <div>
      <SecurePageSection accessableUsers={"admin"} />
      <Container className="body">
        <Row style={{ marginBottom: "1em" }}>
          <Col xs={10}>
            <h2> Region Mangalyatra </h2>
          </Col>
          <Col className="d-flex justify-content-end">
            {/* <Button
              className="btn--primary"
              onClick={tutorials}
              style={{ marginRight: "10px" }}
            >
              Tutorial
            </Button> */}

            <Button className="btn--primary" onClick={newForm}>
              New Report
            </Button>
          </Col>
        </Row>
        {(() => {
          if (
            (typeof user !== "undefined" && user.username === "JSG0019") ||
            (typeof user !== "undefined" && user.username === "admin")
          ) {
            return (
              <Row style={{ marginBottom: "10px" }}>
                <Col>
                  <Form.Label>Region</Form.Label>
                  <Form.Select
                    defaultValue={regName}
                    onChange={(e) => setRegName(e.target.value)}
                  >
                    <option value="">All</option>
                    {regions.map((region) => (
                      <option
                        style={{ textTransform: "capitalize" }}
                        value={region.region.toLowerCase()}
                      >
                        {region.region.charAt(0).toUpperCase() +
                          region.region.slice(1).toLowerCase()}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            );
          }
        })()}
        <Table striped bordered responsive="md" hover>
          <thead className="tableHead">
            <tr>
              <th>S. No.</th>
              <th>Submission Date</th>
              <th>Group</th>
              <th>Event Name</th>
              <th>Event Date</th>
              <th>View Report</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {forms
              .slice(0)
              .reverse()
              .map((form, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{moment(form.recdDate).format("DD-MM-YYYY")}</td>
                  <td>{form.grpName}</td>
                  <td>{form.eName}</td>
                  <td>{moment(form.eDate).format("Do MMMM YYYY")}</td>
                  <td style={{ width: "20rem" }}>
                    <Row>
                      <Col>
                        <div style={{ textAlign: "center" }}>
                          <LinkContainer
                            to={`/reg-mangalyatra-response/${form.id}`}
                          >
                            <Button variant="light" className="btn--four">
                              View Report
                            </Button>
                          </LinkContainer>
                        </div>
                      </Col>
                    </Row>
                  </td>
                  {(() => {
                    if (
                      (typeof user !== "undefined" &&
                        user.username === "JSG0019") ||
                      (typeof user !== "undefined" && user.username === "admin")
                    ) {
                      return (
                        <td style={{ textAlign: "center" }}>
                          <Form.Check
                            type="checkbox"
                            checked={form.status}
                            onChange={(e) => handleStatusChange(e, form.id)}
                          />
                        </td>
                      );
                    }
                  })()}
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default RegionMangalyatra;
