import React, { useState, useRef, useEffect } from "react";
import "./MangalyatraScreen.css";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Image, Accordion } from "react-bootstrap";

import Images from "../images";
import { LinkContainer } from "react-router-bootstrap";

function MangalyatraScreen() {
  const navigate = useNavigate();

  const zoomBgRedirect = () => {
    navigate(`/zoom-background/mangalyatra`);
  };

  return (
    <div>
      <div>
        <Row>
          <Col style={{ textAlign: "left" }}>
            <h1>MANGALYATRA</h1>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button onClick={zoomBgRedirect} className="btn--four">
              Get Zoom Background
            </Button>
          </Col>
        </Row>
        <div>
          <Accordion defaultActiveKey="9">
            <Accordion.Item eventKey="9">
              <Accordion.Header>Apna Time Aa Gaya - Edition 9</Accordion.Header>
              <Accordion.Body>
                <Row style={{ marginTop: "5px" }}>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/eng-9">
                      <Image
                        src={Images.MY9CoverEng}
                        alt="Mangalyatra 9 Cover Eng"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/hin-9">
                      <Image
                        src={Images.MY9CoverHin}
                        alt="Mangalyatra 9 Cover Hin"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/guj-9">
                      <Image
                        src={Images.MY9CoverGuj}
                        alt="Mangalyatra 9 Cover Guj"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Apna Time Aa Gaya - Edition 8</Accordion.Header>
              <Accordion.Body>
                <Row style={{ marginTop: "5px" }}>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/eng-8">
                      <Image
                        src={Images.MY8CoverEng}
                        alt="Mangalyatra 8 Cover Eng"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/hin-8">
                      <Image
                        src={Images.MY8CoverHin}
                        alt="Mangalyatra 8 Cover Hin"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/guj-8">
                      <Image
                        src={Images.MY8CoverGuj}
                        alt="Mangalyatra 8 Cover Guj"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Apna Time Aa Gaya - Edition 7</Accordion.Header>
              <Accordion.Body>
                <Row style={{ marginTop: "5px" }}>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/eng-7">
                      <Image
                        src={Images.MY7CoverEng}
                        alt="Mangalyatra 7 Cover Eng"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/hin-7">
                      <Image
                        src={Images.MY7CoverHin}
                        alt="Mangalyatra 7 Cover Hin"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/guj-7">
                      <Image
                        src={Images.MY7CoverGuj}
                        alt="Mangalyatra 7 Cover Guj"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                {" "}
                Apna Time Aa Gaya - Edition 6
              </Accordion.Header>
              <Accordion.Body>
                <Row style={{ marginTop: "5px" }}>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/eng-6">
                      <Image
                        src={Images.MY6CoverEng}
                        alt="Mangalyatra 6 Cover Eng"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/hin-6">
                      <Image
                        src={Images.MY6CoverHin}
                        alt="Mangalyatra 6 Cover Hin"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/guj-6">
                      <Image
                        src={Images.MY6CoverGuj}
                        alt="Mangalyatra 6 Cover Guj"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                {" "}
                Apna Time Aa Gaya - Edition 5
              </Accordion.Header>
              <Accordion.Body>
                <Row style={{ marginTop: "5px" }}>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/eng-5">
                      <Image
                        src={Images.MY5CoverEng}
                        alt="Mangalyatra 5 Cover Eng"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/hin-5">
                      <Image
                        src={Images.MY5CoverHin}
                        alt="Mangalyatra 5 Cover Hin"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/guj-5">
                      <Image
                        src={Images.MY5CoverGuj}
                        alt="Mangalyatra 5 Cover Guj"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                {" "}
                Apna Time Aa Gaya - Edition 4
              </Accordion.Header>
              <Accordion.Body>
                <Row style={{ marginTop: "5px" }}>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/eng-4">
                      <Image
                        src={Images.MY4CoverEng}
                        alt="Mangalyatra 4 Cover Eng"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/hin-4">
                      <Image
                        src={Images.MY4CoverHin}
                        alt="Mangalyatra 4 Cover Hin"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/guj-4">
                      <Image
                        src={Images.MY4CoverGuj}
                        alt="Mangalyatra 4 Cover Guj"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {" "}
                Apna Time Aa Gaya - Edition 3
              </Accordion.Header>
              <Accordion.Body>
                <Row style={{ marginTop: "5px" }}>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/eng-3">
                      <Image
                        src={Images.MY3CoverEng}
                        alt="Mangalyatra 3 Cover Eng"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/hin-3">
                      <Image
                        src={Images.MY3CoverHin}
                        alt="Mangalyatra 3 Cover Hin"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/guj-3">
                      <Image
                        src={Images.MY3CoverGuj}
                        alt="Mangalyatra 3 Cover Guj"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {" "}
                Apna Time Aa Gaya - Edition 2
              </Accordion.Header>
              <Accordion.Body>
                <Row style={{ marginTop: "5px" }}>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/eng-2">
                      <Image
                        src={Images.MY2CoverEng}
                        alt="Mangalyatra 2 Cover Eng"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/hin-2">
                      <Image
                        src={Images.MY2CoverHin}
                        alt="Mangalyatra 2 Cover Hin"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/guj-2">
                      <Image
                        src={Images.MY2CoverGuj}
                        alt="Mangalyatra 2 Cover Guj"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                Apna Time Aa Gaya - Edition 1
              </Accordion.Header>
              <Accordion.Body>
                <Row style={{ marginTop: "5px" }}>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/eng-1">
                      <Image
                        src={Images.MY1CoverEng}
                        alt="Mangalyatra 1 Cover Eng"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/hin-1">
                      <Image
                        src={Images.MY1CoverHin}
                        alt="Mangalyatra 1 Cover Hin"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <LinkContainer to="/mangalyatra/guj-1">
                      <Image
                        src={Images.MY1CoverGuj}
                        alt="Mangalyatra 1 Cover Guj"
                        className="bookCover"
                        fluid
                      />
                    </LinkContainer>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default MangalyatraScreen;
