import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Table, Image } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Images from "../../images/index";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import "../Response.css";
import SecurePageSection from "../../SecurePageSection";

function EduLoanResponse() {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponses] = useState([]);
  const responseId = useParams();

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("Invoice", "Invoice", "height=1125, width=800");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>Invoices Windzard Technologies</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 1em;
        margin-right: 1em;
      }
    }
    .fResponseBody {
      padding: 20px 20px;
      width: 800px;
      min-height: 1123px;
      background: #fff;
    }
    
    .page{
      page-break-after: always;
    }
    
    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }
    
    
    .section2, .section3{
      margin-top: 30px;
    }
    
    .section4{
      margin-top: 20px;
    }
    
    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }
    
    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }
    
    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }
    
    
    .bordered-mid-row{
      padding: 2px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }
    
    .bordered-row-b{
      padding: 2px 0;
      border: 1px solid #000;
      height: 30px;
    }
    
    .bordered-col-b{
      padding: 2px 5px;
      border: 1px solid #000;
      height: 30px;
    }
    
    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }
    
    
    .bannerText {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    
    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }
    
    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }
    
    .toptext {
      margin: 20px 0;
    }
    
    .logoImage {
      height: 80px;
      margin: 10px;
    }
    
    .bannerYellow {
      background: #face1d;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }
    
    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }
    
    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }
    
    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    
    .H2double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 16px;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    
    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
    
    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
    
    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }
    
    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }
    
    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }
    
    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }
    
    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }`);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
    }, 1000);
  };

  const getResponse = async () => {
    const res = await axios.get(`/api/forms/jsgff-education-loan/${responseId.id}`);
    setResponses(res.data);
    console.log(res.data);
  };

  useEffect(() => {
    getResponse();
  }, []);

  const navigate = useNavigate();
  const editForm = () => {
    navigate(`form/grp-form-a/${response.id}/edit/`);
  };

  return (
    <div>
      <Row style={{ textAlign: "center", marginBottom: "1em", marginTop: "1em" }}>
        <Col>
          <h2 style={{ textAlign: "center" }}>JSGFF Education Loan Response</h2>
          <Button onClick={printDocument} variant="primary">
            Download Form
          </Button>
        </Col>
      </Row>
      <div className="centerFormat">
        <div id="mainForm" className="fResponseBody">
          <div className="page" style={{ textTransform: "capitalize" }}>
            <div>
              <Row className="T-Banner">
                <Col xs={4}>
                  <h3 className="Hdouble-enter">Federation Copy</h3>
                </Col>
                <Col xs={4} style={{ textAlign: "center" }}>
                  <img src={Images.LogoB} height="80px" alt="jsgBanner"></img>
                </Col>
                <Col xs={4}>
                  <h3 className="Hdouble-enter">Recieved Date:</h3>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="bannerText text-center">
                  <h1 style={{ color: " #000", marginBottom: "0" }}>JAIN SOCIAL GROUP INT. FEDERATION</h1>
                  <p className="Tsingle-enter" style={{ color: " #000" }}>
                    4-O/P, Vijay CHambers, Opp. Dreamland Cinema, Tribhuvan Road, Mumbai - 400 004{" "}
                  </p>
                  <p className="Tsingle-enter" style={{ color: " #000" }}>
                    Tel: 022-23870724 / 23891884 Mobile: 9820402349 / 8169274400 E-Mail: office@jsgif.co.in
                    www.jsgif.co.in{" "}
                  </p>
                </Col>
              </Row>
              <Row>
                <h4
                  style={{
                    textAlign: "center",
                    textDecoration: "underline",
                    fontFamily: "Arial-bold",
                    margin: "0",
                  }}
                >
                  Application Form For Higher Education Assistance Scheme
                </h4>
                <h5
                  style={{
                    textAlign: "center",
                    fontFamily: "Arial-bold",
                    margin: "0",
                    fontSize: "14px",
                  }}
                >
                  (To be filled in CAPITAL Letters only and All Details are Mandatory)
                </h5>
              </Row>
            </div>

            <Row
              style={{
                padding: "20px",
                margin: "0 5px",
              }}
            >
              <Col>
                <Row>
                  <Col>
                    <p className="H3double-enter">
                      Date:
                      <span className="response">{response.date}</span>
                    </p>
                    <p className="H3double-enter">
                      Place:
                      <span className="response">{response.place}</span>
                    </p>
                  </Col>
                  <Col>
                    <Row>
                      <Col xs={2} style={{ border: "1px Solid #000", padding: "0 5px" }}>
                        <p
                          className="H3single-enter"
                          style={{
                            fontWeight: "800",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          For Office Use Only
                        </p>
                      </Col>
                      <Col xs={10} style={{ border: "1px Solid #000", padding: "0 5px" }}>
                        <Row>
                          <Col xs={5}>
                            <p className="Tdouble-enter">Application No.:</p>
                          </Col>
                          <Col
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "0",
                            }}
                          ></Col>
                          <Col xs={1}></Col>
                        </Row>
                        <Row>
                          <Col xs={5}>
                            <p className="Tdouble-enter">Remarks:</p>
                          </Col>
                          <Col
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "0",
                            }}
                          ></Col>
                          <Col xs={1}></Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <p className="H3double-enter">To,</p>
                    <p className="Tsingle-enter">Chairman / Trustees</p>
                    <p className="H3single-enter">JAIN SOCIAL GROUP INT. FEDERATION</p>
                    <p className="Tsingle-enter" style={{ color: " #000" }}>
                      4-O/P, Vijay CHambers, Opp. Dreamland Cinema, Tribhuvan Road, Mumbai - 400 004{" "}
                    </p>
                    <p className="Tsingle-enter" style={{ color: " #000" }}>
                      Tel: 022-23870724 / 23891884 Mobile: 9820402349 / 8169274400 E-Mail: office@jsgif.co.in
                      www.jsgif.co.in{" "}
                    </p>
                  </Col>
                  <Col style={{ display: "flex", justifyContent: "end", marginTop: "5px" }}>
                    <Image src={response.photo} width="120px" />
                  </Col>
                </Row>
                <Row>
                  <p className="H3double-enter">Dear Sirs,</p>
                  <p className="Tsingle-enter">
                    I hearby request you to grant me interest free loan as per your norms for pursuing my higher
                    Education Studies in <span className="response">{response.studyIn}</span>
                  </p>
                </Row>
                <Row>
                  <p className="H2double-enter">PERSONAL DETAILS:</p>
                  <p className="H3single-enter">Student Information:</p>
                  <p className="Tsingle-enter">
                    1. Name: Mr. Mrs. {response.persFName} {response.persMName} {response.persLName}
                  </p>
                  <p className="Tsingle-enter">2. Birth Date: {response.persBDay}</p>

                  <Row>
                    <Col xs={2}>
                      <p className="Tsingle-enter">3. Phone No.(M)</p>
                    </Col>
                    <Col xs={4}>
                      <p className="Tsingle-enter">{response.persMobile}</p>
                    </Col>
                    <Col xs={2}>
                      <p className="Tsingle-enter">E-mail:</p>
                    </Col>
                    <Col xs={4}>
                      {" "}
                      <p className="Tsingle-enter">{response.persEmail}</p>
                    </Col>
                  </Row>

                  <p className="Tsingle-enter">4. Present Address: {response.persAddress}</p>
                </Row>

                <Row>
                  <p className="H3single-enter">Parent / Guardian Information:</p>
                  <p className="Tsingle-enter">
                    1. Name: Mr. Mrs. {response.parFName} {response.parMName} {response.parLName}
                  </p>
                  <p className="Tsingle-enter">2. Occupation: {response.parOccuation}</p>

                  <Row>
                    <Col xs={6}>
                      <p className="Tsingle-enter">3.Annual Income: Rs. {response.parAnnualIncome}</p>
                    </Col>
                    <Col xs={6}>
                      <p className="Tsingle-enter">Pan Number: {response.parPANno}</p>
                    </Col>
                  </Row>

                  <p className="Tsingle-enter">
                    4. Family Assistance: Rs. {response.parFamAssistanc} per annum for my Education Expenses
                  </p>
                  <p className="Tsingle-enter">5. Present Address: {response.parAddress}</p>
                  <Row>
                    <Col xs={2}>
                      <p className="Tsingle-enter">6.Phone No.</p>
                    </Col>
                    <Col xs={5}>
                      <p className="Tsingle-enter">(R) {response.parPhonenoR}</p>
                    </Col>
                    <Col xs={5}>
                      <p className="Tsingle-enter">(O) {response.parPhonenoO}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2}></Col>
                    <Col xs={5}>
                      <p className="Tsingle-enter">(M) {response.parPhonenoM}</p>
                    </Col>
                    <Col xs={5}>
                      <p className="Tsingle-enter">E-mail: {response.parEmail}</p>
                    </Col>
                  </Row>
                </Row>

                <Row>
                  <Col xs={6}>
                    <p className="Tsingle-enter">7.Native Place: {response.parNativePlace}</p>
                  </Col>
                  <Col xs={6}>
                    <p className="Tsingle-enter">District / State: {response.parState}</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={5}>
                    <p className="Tsingle-enter">8. Member of JSG: {response.parMemberOfJsg}</p>
                  </Col>
                  <Col xs={7}>
                    <p className="Tsingle-enter">(If Yes Group Name: {response.parGrpName})</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="page">
            <Row>
              <Col>
                <Row>
                  <p className="H3single-enter">Education / Career Achievements:</p>
                  <Table
                    bordered
                    style={{
                      textAlign: "center",
                      border: "2px",
                      padding: "2px",
                    }}
                    size="sm"
                  >
                    <thead>
                      <tr style={{ borderBottom: "0" }}>
                        <th>
                          <p className="H3single-enter">Examination / Course</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Passing Details</p>
                        </th>
                        <th colSpan={3} style={{ borderBottom: "1px solid #000" }}>
                          <p className="H3single-enter">Marks / Grade</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Institute / University Name</p>
                        </th>
                      </tr>
                      <tr style={{ borderTop: "0", height: "30px" }}>
                        <th>
                          <p className="H3single-enter">Passed / Cleared</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Month & Year</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Secured</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Out Of</p>
                        </th>
                        <th>
                          <p className="H3single-enter">%</p>
                        </th>
                        <th>
                          <p className="H3single-enter">passed from</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ borderTop: "0", height: "30px" }}>
                        <td>
                          <p className="Tsingle-enter">X Std. or Equivalent</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu1PassMonth}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu1Marks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu1TotalMarks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu1Percentage}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu1Institite}</p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "0", height: "30px" }}>
                        <td>
                          <p className="Tsingle-enter">XI Std. or Equivalent</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu2PassMonth}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu2Marks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu2TotalMarks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu2Percentage}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu2Institite}</p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "0", height: "30px" }}>
                        <td>
                          <p className="Tsingle-enter">XII Std. or Equivalent</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu3PassMonth}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu3Marks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu3TotalMarks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu3Percentage}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu3Institite}</p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "0", height: "30px" }}>
                        <td>
                          <p className="Tsingle-enter">{response.edu4Course}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu4PassMonth}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu4Marks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu4TotalMarks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu4Percentage}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu4Institite}</p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "0", height: "30px" }}>
                        <td>
                          <p className="Tsingle-enter">{response.edu5Course}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu5PassMonth}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu5Marks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu5TotalMarks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu5Percentage}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu5Institite}</p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "0", height: "30px" }}>
                        <td>
                          <p className="Tsingle-enter">{response.edu6Course}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu6PassMonth}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu6Marks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu6TotalMarks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu6Percentage}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu6Institite}</p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "0", height: "30px" }}>
                        <td>
                          <p className="Tsingle-enter">{response.edu7Course}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu7PassMonth}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu7Marks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu7TotalMarks}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu7Percentage}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.edu7Institite}</p>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <p className="Tsingle-enter">
                    Submut copies of Marksheets for the Examination/ Course/ Special Ecams like CET, etd. cleared till
                    date.)
                  </p>
                </Row>
                <Row>
                  <p className="H2double-enter">
                    DETAILS OF PROPOSED / COMMITTED HIGHER EDUCATION STURY FOR WHICH LOAN IS APPLIED:
                  </p>
                  <Row>
                    <Col xs={3}>
                      <p className="Tsingle-enter">1. Course</p>
                    </Col>
                    <Col xs={9}>
                      <p className="Tsingle-enter">{response.HESCourse}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>
                      <p className="Tsingle-enter">2. Duration:</p>
                    </Col>
                    <Col xs={3}>
                      <p className="Tsingle-enter">{response.HESDuration} Years</p>
                    </Col>
                    <Col xs={3}>
                      <p className="Tsingle-enter">Starts: {response.HESStart}</p>
                    </Col>
                    <Col xs={3}>
                      <p className="Tsingle-enter">Ends: {response.HESEnds}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>
                      <p className="Tsingle-enter">3. Institute Name:</p>
                    </Col>
                    <Col xs={9}>
                      <p className="Tsingle-enter">{response.HESInstituteName}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>
                      <p className="Tsingle-enter">4. Institute Address: </p>
                    </Col>
                    <Col xs={9}>
                      <p className="Tsingle-enter">{response.HESInstituteAddress}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>
                      <p className="Tsingle-enter">5. Affiliated to University</p>
                    </Col>
                    <Col xs={9}>
                      <p className="Tsingle-enter">{response.HESUniversityName}</p>
                    </Col>
                  </Row>
                </Row>
                <Row>
                  <p className="H2double-enter">PROPOSED / COMMITTED EDUCATION EXPENSES DETAILS</p>
                  <Table
                    bordered
                    style={{
                      textAlign: "center",
                      border: "2px",
                      padding: "2px",
                    }}
                    size="sm"
                  >
                    <thead>
                      <tr style={{ borderBottom: "0" }}>
                        <th>
                          <p className="H3single-enter">Sr.</p>
                        </th>
                        <th colSpan={2} style={{ borderBottom: "1px solid #000" }}>
                          <p className="H3single-enter">Expenses Details</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Actual / Approx.</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Remarks /</p>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr style={{ borderTop: "0" }}>
                        <th>
                          <p className="H3single-enter">No.</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Date</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Against / Head</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Amount of Expenses</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Other Details</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p className="Tsingle-enter">1</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDEDDate1}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDEDAgainst1}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDAmount1}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDRemarks1}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="Tsingle-enter">2</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDEDDate2}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDEDAgainst2}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDAmount2}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDRemarks2}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="Tsingle-enter">3</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDEDDate3}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDEDAgainst3}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDAmount3}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDRemarks3}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="Tsingle-enter">4</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDEDDate4}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDEDAgainst4}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDAmount4}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDRemarks4}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="Tsingle-enter">5</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDEDDate5}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDEDAgainst5}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDAmount5}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDRemarks5}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="Tsingle-enter">6</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDEDDate6}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDEDAgainst6}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDAmount6}</p>
                        </td>
                        <td>
                          <p className="Tsingle-enter">{response.EEDRemarks6}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="Tsingle-enter"></p>
                        </td>
                        <td>
                          <p className="H3single-enter">Total</p>
                        </td>
                        <td>
                          <p className="H3single-enter"></p>
                        </td>
                        <td>
                          <p className="H3single-enter">{response.EEDAmountTotal}</p>
                        </td>
                        <td>
                          <p className="H3single-enter"></p>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <p className="Tsingle-enter">
                    (Submit relevant institute's Course Brochure / Literature with Expenses & Payment Schedule)
                  </p>
                </Row>
                <Row>
                  <p className="H2double-enter">PROPOSED / COMMITTED EDUCATION EXPENSES DETAILS</p>
                  <Table
                    bordered
                    style={{
                      textAlign: "center",
                      border: "2px",
                      padding: "2px",
                    }}
                    size="sm"
                  >
                    <thead>
                      <tr style={{ borderBottom: "0" }}>
                        <th>
                          <p className="H3single-enter">Sr.</p>
                        </th>
                        <th colSpan={2} style={{ borderBottom: "1px solid #000" }}>
                          <p className="H3single-enter">Revenue Details</p>
                        </th>
                        <th colSpan={3} style={{ borderBottom: "1px solid #000" }}>
                          <p className="H3single-enter">Amount</p>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr style={{ borderTop: "0" }}>
                        <th>
                          <p className="H3single-enter">No.</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="H3single-enter">Head</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="H3single-enter">Organization Name</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Applied</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Sanctioned</p>
                        </th>
                        <th>
                          <p className="H3single-enter">Disbursed</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ height: "30px" }}>
                        <th>
                          <p className="Tsingle-enter">1</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="Tsingle-enter">Self</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="Tsingle-enter">Own Resource / Family / Relatives etc.</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFSelfApplied}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFSelfSanctioned}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFSelfDisbursed}</p>
                        </th>
                      </tr>
                      <tr style={{ height: "30px" }}>
                        <th>
                          <p className="Tsingle-enter">2</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="Tsingle-enter">Bank</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="Tsingle-enter">{response.DAFBankName}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFBankApplied}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFBankSanctioned}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFBankDisbursed}</p>
                        </th>
                      </tr>
                      <tr style={{ height: "30px" }}>
                        <th>
                          <p className="Tsingle-enter">3</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="Tsingle-enter">Institute</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="Tsingle-enter">{response.DAFinstitute4OrganizationName}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFinstitute4Applied}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFinstitute4Sanctioned}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFinstitute4Disbursed}</p>
                        </th>
                      </tr>
                      <tr style={{ height: "30px" }}>
                        <th>
                          <p className="Tsingle-enter">4</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="Tsingle-enter">Institute</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="Tsingle-enter">{response.DAFinstitute5OrganizationName}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFinstitute5Applied}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFinstitute5Sanctioned}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFinstitute5Disbursed}</p>
                        </th>
                      </tr>
                      <tr style={{ height: "30px" }}>
                        <th>
                          <p className="Tsingle-enter">5</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="Tsingle-enter">{response.DAFHead6}</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="Tsingle-enter">{response.DAFOrganizationName6}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFApplied6}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFSanctioned6}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFDisbursed6}</p>
                        </th>
                      </tr>
                      <tr style={{ height: "30px" }}>
                        <th>
                          <p className="Tsingle-enter">6</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="Tsingle-enter">{response.DAFHead7}</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="Tsingle-enter">{response.DAFOrganizationName7}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFApplied7}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFSanctioned7}</p>
                        </th>
                        <th>
                          <p className="Tsingle-enter">{response.DAFDisbursed7}</p>
                        </th>
                      </tr>
                      <tr style={{ height: "30px" }}>
                        <th>
                          <p className="Tsingle-enter"></p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="H3single-enter">TOTAL</p>
                        </th>
                        <th style={{ borderBottom: "1px solid #000" }}>
                          <p className="H3single-enter">{response.DAFOrganizationName6}</p>
                        </th>
                        <th>
                          <p className="H3single-enter">{response.DAFAppliedTotal}</p>
                        </th>
                        <th>
                          <p className="H3single-enter">{response.DAFSanctionedTotal}</p>
                        </th>
                        <th>
                          <p className="H3single-enter">{response.DAFDisbursedTotal}</p>
                        </th>
                      </tr>
                    </tbody>
                  </Table>
                  <p className="Tsingle-enter">(Submit copy of relevant sanction letter & Disbursement schedule)</p>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="page">
            <Row>
              <Col>
                <Row>
                  <p className="H2double-enter">TWO REFERENCE (From Jain Community, not related to the Student):</p>
                  <p className="H3single-enter">Details of 1st Referral:</p>
                  <Row>
                    <p className="Tsingle-enter">1. Name: Mr./ Ms.{response.REFERANCE1Name}</p>
                  </Row>
                  <Row>
                    <Col>
                      <p className="Tsingle-enter">2. Member of JSG: {response.REFERANCE1Member}</p>
                    </Col>
                    <Col>
                      <p className="Tsingle-enter">(If yes Group Name: {response.REFERANCE1GRPName})</p>
                    </Col>
                  </Row>
                  <Row>
                    <p className="Tsingle-enter">3. Address: {response.REFERANCE1Address}</p>
                  </Row>
                  <Row>
                    <Col xs={2}>
                      <p className="Tsingle-enter">4.Phone No.</p>
                    </Col>
                    <Col xs={5}>
                      <p className="Tsingle-enter">(R) {response.REFERANCE1PhoneNoR}</p>
                    </Col>
                    <Col xs={5}>
                      <p className="Tsingle-enter">(O) {response.REFERANCE1PhoneNoO}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2}></Col>
                    <Col xs={5}>
                      <p className="Tsingle-enter">(M) {response.REFERANCE1PhoneNoM}</p>
                    </Col>
                    <Col xs={5}>
                      <p className="Tsingle-enter">E-mail: {response.REFERANCE1Email}</p>
                    </Col>
                  </Row>
                </Row>
                <Row>
                  <p className="H3single-enter">Details of 2nd Referral:</p>
                  <Row>
                    <p className="Tsingle-enter">1. Name: Mr./ Ms.{response.REFERANCE2Name}</p>
                  </Row>
                  <Row>
                    <Col>
                      <p className="Tsingle-enter">2. Member of JSG: {response.REFERANCE2Member}</p>
                    </Col>
                    <Col>
                      <p className="Tsingle-enter">(If yes Group Name: {response.REFERANCE2GRPName})</p>
                    </Col>
                  </Row>
                  <Row>
                    <p className="Tsingle-enter">3. Address: {response.REFERANCE2Address}</p>
                  </Row>
                  <Row>
                    <Col xs={2}>
                      <p className="Tsingle-enter">4.Phone No.</p>
                    </Col>
                    <Col xs={5}>
                      <p className="Tsingle-enter">(R) {response.REFERANCE2PhoneNoR}</p>
                    </Col>
                    <Col xs={5}>
                      <p className="Tsingle-enter">(O) {response.REFERANCE2PhoneNoO}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2}></Col>
                    <Col xs={5}>
                      <p className="Tsingle-enter">(M) {response.REFERANCE2PhoneNoM}</p>
                    </Col>
                    <Col xs={5}>
                      <p className="Tsingle-enter">E-mail: {response.REFERANCE2Email}</p>
                    </Col>
                  </Row>
                </Row>
                <Row>
                  <p className="H2double-enter">Self Attested Documents Submitted hewewith</p>
                </Row>
                <Row>
                  <Col>
                    <p className="H3single-enter">For Applicamt Student:</p>

                    <p className="Tsingle-enter">Ration Card [ ]</p>
                    <p className="Tsingle-enter">Pan Card [ ]</p>
                    <p className="Tsingle-enter">Latest Paid FEE Receipt [ ]</p>
                  </Col>
                  <Col>
                    <p className="H3single-enter">For Parent/ Guardian:</p>

                    <p className="Tsingle-enter">Latest Rent/ Maintenance Receipt [ ]</p>
                    <p className="Tsingle-enter">Pan Card [ ]</p>
                    <p className="Tsingle-enter">Latest Residence Electricity/ Telephone Bill [ ]</p>
                  </Col>
                </Row>
                <Row className="Tbox">
                  <p className="H3single-enter">Remark / Mention any Special Details:</p>
                  <p className="Tsingle-enter">{response.SADRemark}</p>
                </Row>
                <Row>
                  <p className="Tsingle-enter">
                    We hereby, the Applicant Student & Parent / Guardian, confirm that we have read the rules,
                    regulations, terms & conditions of JAIN SOCIAL GROUPS FEDERATION FOUNDATION and agree to abide by
                    the same and further promise to furnish any details/information as & when called for and bring all
                    documents at the time of interview
                  </p>
                </Row>
                <Row style={{ marginTop: "50px" }}>
                  <Col style={{ textAlign: "center" }}>
                    <p className="Tingle-enter">__________________________</p>
                    <p className="H3single-enter">Signature of Applicant</p>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <p className="Tingle-enter">__________________________</p>
                    <p className="H3single-enter">Signature of Parent/ Guardian</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="Tsingle-enter">
                      (Full name: {response.persFName} {response.persMName} {response.persLName} )
                    </p>
                  </Col>
                  <Col>
                    <p className="Tsingle-enter">
                      (Full name: {response.parFName} {response.parMName} {response.parLName} )
                    </p>
                  </Col>
                </Row>
                <Row>
                  <p className="Tsingle-enter">
                    In case of Guradian, Specify Relationship with the Applicant Student {response.parRelationship}
                  </p>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="H3single-enter">Full Name: Mr./Mrs. :</p>
              </Col>
              <Col>
                <p className="Tsingle-enter">{response.OBOFullName}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="H3single-enter">Designation</p>
              </Col>
              <Col>
                <p className="Tsingle-enter">{response.OBODesignation}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="H3single-enter">Group Name and Number</p>
              </Col>
              <Col>
                <p className="Tsingle-enter">{response.OBOGroupNameAndNo}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="H3single-enter">Remarks / Details :</p>
              </Col>
              <Col>
                <p className="Tsingle-enter">{response.OBORemarks}</p>
              </Col>
            </Row>
            <Row>
              <p className="Tsingle-enter">
                I hereby recommend the loan application of Mr./ Mrs. {response.persFName} {response.persMName}{" "}
                {response.persLName} for his/ her higher studies.
              </p>
            </Row>
            <Row>
              <Col xs={9}></Col>
              <Col xs={3} style={{ textAlign: "center" }}>
                <p className="H3single-enter">_____________</p>
                <p className="H3single-enter">Signature of Recommending Office Bearer of JSG Organistaion</p>
              </Col>
            </Row>
          </div>
          <div className="page">
            <Row>
              <p className="H2double-enter" style={{ textAlign: "center" }}>
                TERMS AND CONDITIONS FOR HIGHER EDUCATION ASSISTANCE SCHEME
              </p>
            </Row>
            <Row>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">1</p>
              </Col>
              <Col xs={11}>
                <p className="Tsingle-enter">
                  The applicant should fulfil a minimum standard of Graduation or equivalent of any recognized Indian
                  University.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">2</p>
              </Col>
              <Col xs={11}>
                <p className="Tsingle-enter">
                  The applicant must have secured 60% or more marks in all the Board/College/University Examinations
                  commencing from standard 10th till last examination
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">3</p>
              </Col>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">A.</p>
              </Col>
              <Col xs={10}>
                <p className="Tsingle-enter">
                  Application must be made by the candidate in prescribed form of JAIN SOCIAL GROUPS FEDERATION
                  FOUNDATION.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ textAlign: "center" }}></Col>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">B.</p>
              </Col>
              <Col xs={10}>
                <p className="Tsingle-enter">
                  Trustees will sanction such amount as they may deem fit on merits of individual case.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ textAlign: "center" }}></Col>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">C.</p>
              </Col>
              <Col xs={10}>
                <p className="Tsingle-enter">
                  Applicant should arrange & give an assurance that he/she has arranged/obtained at least 50% of total
                  study expenses from other sources.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ textAlign: "center" }}></Col>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">D.</p>
              </Col>
              <Col xs={10}>
                <p className="Tsingle-enter">
                  Applicant must have secured admission in a recognized college/institution for further studies.
                  Certified Xerox Copy of such admission must be provided to FOUNDATION.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ textAlign: "center" }}></Col>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">E.</p>
              </Col>
              <Col xs={10}>
                <p className="Tsingle-enter">
                  The applicant shall have to produce a certificate of good health and fitness from recognized medical
                  practitioners/doctors.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ textAlign: "center" }}></Col>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">F.</p>
              </Col>
              <Col xs={10}>
                <p className="Tsingle-enter">
                  The applicant who has been given financial assistance will have to submit a certified copy of the
                  progress report/marks every six months till completion of course/study.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ textAlign: "center" }}></Col>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">G.</p>
              </Col>
              <Col xs={10}>
                <p className="Tsingle-enter">
                  The applicant must inform the change in his/her address and after completion of study his/her contact
                  nos, E-mail (if changed), etc. when it occurs.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ textAlign: "center" }}></Col>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">H.</p>
              </Col>
              <Col xs={10}>
                <p className="Tsingle-enter">
                  After completion of his/her study he/she must furnish the relevant details of job/employment. It's
                  address, contact nos., etc.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ textAlign: "center" }}></Col>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">I.</p>
              </Col>
              <Col xs={10}>
                <p className="Tsingle-enter">
                  Trustees reserve rights to decide (a) Quantum of financial assistance or reject without giving reasons
                  (b) Changes in rules & regulations in this regard, if necessary & as & when required.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ textAlign: "center" }}></Col>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">J.</p>
              </Col>
              <Col xs={10}>
                <p className="Tsingle-enter">
                  Trustees reserve right to cancel the application if not fully filled with all enclosures, etc.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ textAlign: "center" }}></Col>
              <Col xs={1} style={{ textAlign: "center" }}>
                <p className="Tsingle-enter">K.</p>
              </Col>
              <Col xs={10}>
                <p className="Tsingle-enter">
                  Education Assistance Amount will have to be returned after completion of study/course in 24/36 equal
                  monthly installments.
                </p>
              </Col>
            </Row>
          </div>
          {response.SADStudentRationCard ? (
            <div className="page">
              <h2 style={{ textAlign: "center" }}>Student Ration Card</h2>
              <Image src={response.SADStudentRationCard} fluid />
            </div>
          ) : null}
          {response.SADStudentPANCard ? (
            <div className="page">
              <h2 style={{ textAlign: "center" }}>Student Pan Card</h2>
              <Image src={response.SADStudentPANCard} fluid />
            </div>
          ) : null}
          {response.SADStudentLatestPaidFeeReciept ? (
            <div className="page">
              <h2 style={{ textAlign: "center" }}>Student Latest Fee Paid Reciept</h2>
              <Image src={response.SADStudentLatestPaidFeeReciept} fluid />
            </div>
          ) : null}
          {response.SADParentLatestRent ? (
            <div className="page">
              <h2 style={{ textAlign: "center" }}>Parent Latest Rent Reciept</h2>
              <Image src={response.SADParentLatestRent} fluid />
            </div>
          ) : null}
          {response.SADParentPANCar ? (
            <div className="page">
              <h2 style={{ textAlign: "center" }}>Parent Latest Pan Card</h2>
              <Image src={response.SADParentPANCar} fluid />
            </div>
          ) : null}
          {response.SADParentTelephoneBil ? (
            <div className="page">
              <h2 style={{ textAlign: "center" }}>Parent Latest Telephone Bill</h2>
              <Image src={response.SADParentTelephoneBil} fluid />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default EduLoanResponse;
