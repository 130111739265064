import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";

import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import SecurePageSection from "../../SecurePageSection";

const SgnFormAEditForm = () => {
  const user = useSelector((state) => state.authentication.user);
  const location = useLocation();

  const [response, setResponse] = useState([]);

  const [regName, setRegName] = useState("");
  const [grpName, setGrpName] = useState("");
  const [grpNumber, setGrpNumber] = useState("");
  const [grpAddress, setGrpAddress] = useState("");
  const [grpPresidentName, setGrpPresidentName] = useState("");
  const [grpSecretaryName, setGrpSecretaryName] = useState("");
  const [grpTreasurerName, setGrpTreasurerName] = useState("");
  const [grpPresidentNum, setGrpPresidentNum] = useState("");
  const [grpSecretaryNum, setGrpSecretaryNum] = useState("");
  const [grpTreasurerNum, setGrpTreasurerNum] = useState("");
  const [grpPresidentEmail, setGrpPresidentEmail] = useState("");
  const [grpSecretaryEmail, setGrpSecretaryEmail] = useState("");
  const [grpTreasurerEmail, setGrpTreasurerEmail] = useState("");
  const [CoupleMembers, setCoupleMembers] = useState("");
  const [SingleMembers, setSingleMembers] = useState("");
  const [amtToPay, setAmtToPay] = useState(1180);
  const [oldDuepayable, setOldDuepayable] = useState("");
  const [oldDueRedeemable, setOldDueRedeemable] = useState("");
  const [totalAmtPayable, setTotalAmtPayable] = useState(1180);
  const [amtPaid, setAmtPaid] = useState("");
  const [newDuepayable, setNewDuepayable] = useState("");
  const [newDueRedeemable, setNewDueRedeemable] = useState("");
  const [draft_chequeNum, setDraft_chequeNum] = useState("");
  const [chequedate, setChequedate] = useState("");
  const [drawnOn, setDrawnOn] = useState("");
  const [branchName, setBranchName] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [regEmails, setRegEmails] = useState("");
  const [defaultEmails, setDefaultEmails] = useState(
    "office@jsgif.co.in,president@jsgif.co.in,secretarygeneral@jsgif.co.in",
    "dj22jsgif@gmail.com",
    "manish.shah95@gmail.com"
  );
  const [emailList, setEmailList] = useState([]);

  const handleInputChange = (e) => {
    setEmailInput(e.target.value);
    const newValue = e.target.value.replace(/\s/g, ""); // replace all spaces with empty string
    setEmailList(newValue);
  };

  const redirectToResponse = (id) => {
    navigate(`/sgn-form-a-response/${id}`, { replace: true });
  };

  // const fetchRegEmails = async () => {
  //   const response = await axios.get(`api/data/${regName}/region-ob/emails/`);

  //   const filteredEmails = response.data
  //     .filter((item) => item.post === "chairman" || item.post === "secretary")
  //     .map((item) => item.email);

  //   console.log("email", filteredEmails);
  //   setRegEmails(filteredEmails);
  // };

  // useEffect(() => {
  //   fetchRegEmails();
  // }, [regName]);

  const responseId = useParams();

  const fetchForms = async () => {
    const res = await axios.get(`/api/forms/group/sgn-form-a/${responseId.id}`);

    setResponse(res.data);
    setRegName(res.data.regName);
    setGrpName(res.data.grpName);
    setGrpNumber(res.data.grpNumber);
    setGrpAddress(res.data.grpAddress);
    setGrpPresidentName(res.data.grpPresidentName);
    setGrpSecretaryName(res.data.grpSecretaryName);
    setGrpTreasurerName(res.data.grpTreasurerName);
    setGrpPresidentNum(res.data.grpPresidentNum);
    setGrpSecretaryNum(res.data.grpSecretaryNum);
    setGrpTreasurerNum(res.data.grpTreasurerNum);
    setGrpPresidentEmail(res.data.grpPresidentEmail);
    setGrpSecretaryEmail(res.data.grpSecretaryEmail);
    setGrpTreasurerEmail(res.data.grpTreasurerEmail);
    setCoupleMembers(res.data.CoupleMembers);
    setSingleMembers(res.data.SingleMembers);
    setOldDuepayable(res.data.oldDuepayable);
    setOldDueRedeemable(res.data.oldDueRedeemable);
    setNewDuepayable(res.data.newDuepayable);
    setNewDueRedeemable(res.data.newDueRedeemable);
    setDraft_chequeNum(res.data.draft_chequeNum);
    setChequedate(res.data.chequedate);
    setDrawnOn(res.data.drawnOn);
    setBranchName(res.data.branchName);
  };

  useEffect(() => {
    fetchForms();
  }, [regName, grpName]);

  const navigate = useNavigate();

  const amtPayable = () => {
    let amtToPayVal = amtToPay;
    let oldDuepayableVal = oldDuepayable;
    let oldDueRedeemableVal = oldDueRedeemable;
    if (!isNaN(amtToPay)) {
      amtToPayVal = 0;
    }

    if (!isNaN(oldDuepayable)) {
      oldDuepayableVal = 0;
    }

    if (!isNaN(oldDueRedeemable)) {
      oldDueRedeemableVal = 0;
    }
    let amt = Number(amtToPayVal) + Number(oldDuepayableVal) - Number(oldDueRedeemableVal);
    setTotalAmtPayable(amt);
  };

  useEffect(() => {
    // Calculate total amount payable after adding old due and subtracting old excess
    const calculatedTotalAmtPayable = amtToPay + oldDuepayable - oldDueRedeemable;
    setTotalAmtPayable(calculatedTotalAmtPayable);

    if (totalAmtPayable > amtPaid) {
      const calculatedNewDuepayable = Math.abs(amtPaid - totalAmtPayable);
      setNewDuepayable(calculatedNewDuepayable);
      setNewDueRedeemable(0);
    } else if (totalAmtPayable < amtPaid) {
      const calculatedNewDueRedeemable = Math.abs(totalAmtPayable - amtPaid);
      setNewDueRedeemable(calculatedNewDueRedeemable);
      setNewDuepayable(0);
    } else {
      setNewDuepayable(0);
      setNewDueRedeemable(0);
    }
  }, [amtToPay, oldDuepayable, oldDueRedeemable, totalAmtPayable, amtPaid]);

  async function FormSubmit(event) {
    event.preventDefault();

    const finalEmail =
      defaultEmails +
      "," +
      regEmails +
      "," +
      grpPresidentEmail +
      "," +
      grpSecretaryEmail +
      "," +
      emailList;

    let formField = new FormData();

    formField.append("user", user.user_id);
    formField.append("grpName", grpName);
    formField.append("regName", regName);
    formField.append("grpNumber", grpNumber);
    formField.append("grpAddress", grpAddress);
    formField.append("grpPresidentName", grpPresidentName);
    formField.append("grpSecretaryName", grpSecretaryName);
    formField.append("grpTreasurerName", grpTreasurerName);
    formField.append("grpPresidentNum", grpPresidentNum);
    formField.append("grpPresidentNum", grpPresidentNum);
    formField.append("grpSecretaryNum", grpSecretaryNum);
    formField.append("grpTreasurerNum", grpTreasurerNum);
    formField.append("grpPresidentEmail", grpPresidentEmail);
    formField.append("grpSecretaryEmail", grpSecretaryEmail);
    formField.append("grpTreasurerEmail", grpTreasurerEmail);
    formField.append("CoupleMembers", CoupleMembers);
    formField.append("SingleMembers", SingleMembers);
    {
      oldDuepayable === null
        ? formField.append("oldDuepayable", 0)
        : formField.append("oldDuepayable", oldDuepayable);
    }
    {
      oldDueRedeemable === null
        ? formField.append("oldDueRedeemable", 0)
        : formField.append("oldDueRedeemable", oldDueRedeemable);
    }
    {
      totalAmtPayable === null
        ? formField.append("totalAmtPayable", 0)
        : formField.append("totalAmtPayable", totalAmtPayable);
    }
    {
      amtPaid === null ? formField.append("amtPaid", 0) : formField.append("amtPaid", amtPaid);
    }
    {
      newDuepayable === null
        ? formField.append("newDuepayable", 0)
        : formField.append("newDuepayable", newDuepayable);
    }
    {
      newDueRedeemable === null
        ? formField.append("newDueRedeemable", 0)
        : formField.append("newDueRedeemable", newDueRedeemable);
    }
    formField.append("draft_chequeNum", draft_chequeNum);
    formField.append("chequedate", chequedate);
    formField.append("drawnOn", drawnOn);
    formField.append("branchName", branchName);
    formField.append("emailList", finalEmail);

    await axios({
      method: "put",
      url: `/api/forms/sangini/sgn-form-a/${response.id}`,
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        redirectToResponse(response.data.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get("/user/region");

    setRegions(response.data.sort((a, b) => a.region.localeCompare(b.region)));
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups`);

    setGroups(response.data);
  };

  useEffect(() => {
    fetchGroups();
  }, [regName]);

  function setGroupData(e) {
    const selectedGroup = groups.find((group) => group.group.toLowerCase() === e.target.value);
    setGrpName(e.target.value);
    setGrpNumber(selectedGroup.id);
  }

  return (
    <div>
      <Container>
        <Form>
          <Row>
            <Col md={8}>
              <h2>Sangini Form A Edit</h2>
            </Col>
          </Row>
          <Row className="mb-3">
            {(() => {
              if (typeof user !== "undefined" && typeof user.username !== "undefined") {
                return (
                  <>
                    <Form.Group as={Col}>
                      <Form.Label>Region Name</Form.Label>
                      <Form.Select
                        type="text"
                        name="regName"
                        value={regName}
                        onChange={(e) => setRegName(e.target.value)}
                      >
                        <option>Select your Region</option>
                        <option
                          value={user.jsgRegionName.toLowerCase()}
                          style={{ textTransform: "capitalize" }}
                        >
                          {user.jsgRegionName.charAt(0).toUpperCase() +
                            user.jsgRegionName.slice(1).toLowerCase()}
                        </option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Group Name</Form.Label>
                      <Form.Select name="grpName" value={grpName} onChange={(e) => setGroupData(e)}>
                        <option>Select your Group</option>
                        <option
                          value={user.jsgGroupName.toLowerCase()}
                          style={{ textTransform: "capitalize" }}
                        >
                          {user.jsgGroupName.charAt(0).toUpperCase() +
                            user.jsgGroupName.slice(1).toLowerCase()}
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </>
                );
              } else
                return (
                  <>
                    <Form.Group as={Col}>
                      <Form.Label>Region Name</Form.Label>
                      <Form.Select
                        type="text"
                        name="regName"
                        value={regName}
                        onChange={(e) => setRegName(e.target.value)}
                      >
                        <option>Select your Region</option>
                        {regions.map((region, index) => (
                          <option
                            key={index}
                            style={{ textTransform: "capitalize" }}
                            value={region.region.toLowerCase()}
                          >
                            {region.region.charAt(0).toUpperCase() +
                              region.region.slice(1).toLowerCase()}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Group Name</Form.Label>
                      <Form.Select name="grpName" value={grpName} onChange={(e) => setGroupData(e)}>
                        <option>Select your Group</option>
                        {groups.map((group, index) => (
                          <option
                            key={index}
                            style={{ textTransform: "capitalize" }}
                            value={group.group.toLowerCase()}
                          >
                            {group.group.charAt(0).toUpperCase() +
                              group.group.slice(1).toLowerCase()}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </>
                );
            })()}
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Group Address</Form.Label>
              <Form.Control
                style={{ textTransform: "capitalize" }}
                name="grpAddress"
                value={grpAddress}
                onChange={(e) => setGrpAddress(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>President Name</Form.Label>
              <Form.Control
                style={{ textTransform: "capitalize" }}
                name="grpPresidentName"
                value={grpPresidentName}
                onChange={(e) => setGrpPresidentName(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Secretary Name</Form.Label>
              <Form.Control
                style={{ textTransform: "capitalize" }}
                name="grpSecretaryName"
                value={grpSecretaryName}
                onChange={(e) => setGrpSecretaryName(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Treasurer Name</Form.Label>
              <Form.Control
                style={{ textTransform: "capitalize" }}
                name="grpTreasurerName"
                value={grpTreasurerName}
                onChange={(e) => setGrpTreasurerName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>President Number</Form.Label>
              <Form.Control
                style={{ textTransform: "capitalize" }}
                name="grpPresidentNum"
                value={grpPresidentNum}
                onChange={(e) => setGrpPresidentNum(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Secretary Number</Form.Label>
              <Form.Control
                style={{ textTransform: "capitalize" }}
                name="grpSecretaryNum"
                value={grpSecretaryNum}
                onChange={(e) => setGrpSecretaryNum(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Treasurer Number</Form.Label>
              <Form.Control
                style={{ textTransform: "capitalize" }}
                name="grpTreasurerNum"
                value={grpTreasurerNum}
                onChange={(e) => setGrpTreasurerNum(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>President Email</Form.Label>
              <Form.Control
                name="grpPresidentEmail"
                value={grpPresidentEmail}
                onChange={(e) => setGrpPresidentEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Secretary Email</Form.Label>
              <Form.Control
                name="grpSecretaryEmail"
                value={grpSecretaryEmail}
                onChange={(e) => setGrpSecretaryEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Treasurer Email</Form.Label>
              <Form.Control
                name="grpTreasurerEmail"
                value={grpTreasurerEmail}
                onChange={(e) => setGrpTreasurerEmail(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group as={Col}>
                <Form.Label>Couple Members</Form.Label>
                <Form.Control
                  name="CoupleMembers"
                  value={CoupleMembers}
                  onChange={(e) => setCoupleMembers(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col}>
                <Form.Label>Single Members</Form.Label>
                <Form.Control
                  name="SingleMembers"
                  value={SingleMembers}
                  onChange={(e) => setSingleMembers(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Amount to be Paid</Form.Label>
              <Form.Control readOnly name="amtToPay" value={amtToPay} />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Old Outstanding</Form.Label>
              <Form.Control
                name="oldDuepayable"
                value={oldDuepayable}
                // onChange={(e) => {
                //   let amtToPayVal = amtToPay;
                //   let oldDuepayableVal = e.target.value;
                //   let oldDueRedeemableVal = oldDueRedeemable;
                //   if (isNaN(amtToPayVal)) {
                //     amtToPayVal = 0;
                //   }

                //   if (isNaN(oldDuepayable)) {
                //     oldDuepayableVal = 0;
                //   }

                //   if (isNaN(oldDueRedeemable)) {
                //     oldDueRedeemableVal = 0;
                //   }
                //   let amt =
                //     Number(amtToPayVal) +
                //     Number(oldDuepayableVal) -
                //     Number(oldDueRedeemableVal);
                //   setTotalAmtPayable(amt);
                //   console.log("amt", amt);
                //   setOldDuepayable(e.target.value);
                // }}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Old Excess</Form.Label>
              <Form.Control
                name="oldDueRedeemable"
                value={oldDueRedeemable}
                // onChange={(e) => {
                //   let amtToPayVal = amtToPay;
                //   let oldDuepayableVal = oldDuepayable;
                //   let oldDueRedeemableVal = e.target.value;
                //   if (isNaN(amtToPayVal)) {
                //     amtToPayVal = 0;
                //   }

                //   if (isNaN(oldDuepayable)) {
                //     oldDuepayableVal = 0;
                //   }

                //   if (isNaN(oldDueRedeemable)) {
                //     oldDueRedeemableVal = 0;
                //   }
                //   let amt =
                //     Number(amtToPayVal) +
                //     Number(oldDuepayableVal) -
                //     Number(oldDueRedeemableVal);
                //   setTotalAmtPayable(amt);
                //   console.log("amt", amt);
                //   setOldDueRedeemable(e.target.value);
                // }}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Total Amount Payable</Form.Label>
              <Form.Control
                name="totalAmtPayable"
                value={totalAmtPayable}
                // onChange={amtPayable}
                readOnly
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Amount being Paid</Form.Label>
              <Form.Control
                name="amtPaid"
                value={amtPaid}
                onChange={(e) => {
                  // let dueamount =
                  //   Number(totalAmtPayable) - Number(e.target.value);
                  // if (dueamount > 0) {
                  //   setNewDuepayable(dueamount);
                  //   setNewDueRedeemable("");
                  // } else {
                  //   setNewDueRedeemable(Math.abs(dueamount));
                  //   setNewDuepayable("");
                  // }
                  setAmtPaid(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>New Outstanding</Form.Label>
              <Form.Control readOnly name="newDuepayable" value={newDuepayable} />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>New Excess</Form.Label>
              <Form.Control readOnly name="newDueRedeemable" value={newDueRedeemable} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Draft/Cheque Number</Form.Label>
              <Form.Control
                name="draft_chequeNum"
                value={draft_chequeNum}
                onChange={(e) => setDraft_chequeNum(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="Date"
                name="chequedate"
                value={chequedate}
                onChange={(e) => setChequedate(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                name="drawnOn"
                value={drawnOn}
                onChange={(e) => setDrawnOn(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Branch Name</Form.Label>
              <Form.Control
                name="branchName"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Mail the Form to</Form.Label>
              <Form.Control
                placeholder="Add each email address after a comma. Example = xyz@gmail.com, abc@gmail.com"
                name="emailInput"
                value={emailInput}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>
          </Row>

          <Button className="btn--outline--dark" onClick={FormSubmit} type="submit">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default SgnFormAEditForm;
