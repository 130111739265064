import React from "react";
import { Row, Col, Accordion, ListGroup, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";

function Tutorials() {
  const navigate = useNavigate();

  return (
    <div>
      <Row>
        <h2>Tutorials</h2>
        <h4>Watch all the tutorials below:</h4>
      </Row>
      <Row style={{ marginTop: "30px" }}>
        <Col>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>How to fill Federation Notice Forms?</Accordion.Header>
              <Accordion.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item style={{ transition: "400ms" }}>
                    <Row>
                      <Col>Federation AGM Notice</Col>
                      <Col style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          className="btn--primary"
                          style={{ padding: "5px 20px", marginRight: "10px" }}
                          onClick={(e) => navigate("/tutorial/eng/fed-agm")}
                        >
                          English
                        </Button>
                        <Button
                          className="btn--four"
                          style={{ padding: "5px 20px" }}
                          onClick={(e) => navigate("/tutorial/hin/fed-agm")}
                        >
                          Hindi
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item style={{ transition: "400ms" }}>
                    <Row>
                      <Col>Federation CCM Notice</Col>
                      <Col style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          className="btn--primary"
                          style={{ padding: "5px 20px", marginRight: "10px" }}
                          onClick={(e) => navigate("/tutorial/eng/fed-ccm")}
                        >
                          English
                        </Button>
                        <Button
                          className="btn--four"
                          style={{ padding: "5px 20px" }}
                          onClick={(e) => navigate("/tutorial/hin/fed-ccm")}
                        >
                          Hindi
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item style={{ transition: "400ms" }}>
                    <Row>
                      <Col>Federation ID Meet Notice</Col>
                      <Col style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          className="btn--primary"
                          style={{ padding: "5px 20px", marginRight: "10px" }}
                          onClick={(e) => navigate("/tutorial/eng/fed-idmeet")}
                        >
                          English
                        </Button>
                        <Button
                          className="btn--four"
                          style={{ padding: "5px 20px" }}
                          onClick={(e) => navigate("/tutorial/hin/fed-idmeet")}
                        >
                          Hindi
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item style={{ transition: "400ms" }}>
                    <Row>
                      <Col>Federation OB Meet Notice</Col>
                      <Col style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          className="btn--primary"
                          style={{ padding: "5px 20px", marginRight: "10px" }}
                          onClick={(e) => navigate("/tutorial/eng/fed-obmeet")}
                        >
                          English
                        </Button>
                        <Button
                          className="btn--four"
                          style={{ padding: "5px 20px" }}
                          onClick={(e) => navigate("/tutorial/hin/fed-obmeet")}
                        >
                          Hindi
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item style={{ transition: "400ms" }}>
                    <Row>
                      <Col>Federation After Event Report</Col>
                      <Col style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          className="btn--primary"
                          style={{ padding: "5px 20px", marginRight: "10px" }}
                          onClick={(e) => navigate("/tutorial/eng/fed-aer")}
                        >
                          English
                        </Button>
                        <Button
                          className="btn--four"
                          style={{ padding: "5px 20px" }}
                          onClick={(e) => navigate("/tutorial/hin/fed-aer")}
                        >
                          Hindi
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>How to fill Region Notice Forms?</Accordion.Header>
              <Accordion.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item style={{ transition: "400ms" }}>
                    <Row>
                      <Col>Region AGM Notice</Col>
                      <Col style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          className="btn--primary"
                          style={{ padding: "5px 20px", marginRight: "10px" }}
                          onClick={(e) => navigate("/tutorial/eng/reg-agm")}
                        >
                          English
                        </Button>
                        <Button
                          className="btn--four"
                          style={{ padding: "5px 20px" }}
                          onClick={(e) => navigate("/tutorial/hin/reg-agm")}
                        >
                          Hindi
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item style={{ transition: "400ms" }}>
                    <Row>
                      <Col>Region Committee Meet Notice</Col>
                      <Col style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          className="btn--primary"
                          style={{ padding: "5px 20px", marginRight: "10px" }}
                          onClick={(e) => navigate("/tutorial/eng/reg-committeemeet")}
                        >
                          English
                        </Button>
                        <Button
                          className="btn--four"
                          style={{ padding: "5px 20px" }}
                          onClick={(e) => navigate("/tutorial/hin/reg-committeemeet")}
                        >
                          Hindi
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item style={{ transition: "400ms" }}>
                    <Row>
                      <Col>Region OB Meet Notice</Col>
                      <Col style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          className="btn--primary"
                          style={{ padding: "5px 20px", marginRight: "10px" }}
                          onClick={(e) => navigate("/tutorial/eng/reg-obmeet")}
                        >
                          English
                        </Button>
                        <Button
                          className="btn--four"
                          style={{ padding: "5px 20px" }}
                          onClick={(e) => navigate("/tutorial/hin/reg-obmeet")}
                        >
                          Hindi
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>How to fill Group Forms?</Accordion.Header>
              <Accordion.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item style={{ transition: "400ms" }}>
                    <Row>
                      <Col>Group AGM Notice</Col>
                      <Col style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          className="btn--primary"
                          style={{ padding: "5px 20px", marginRight: "10px" }}
                          onClick={(e) => navigate("/tutorial/eng/grp-agm")}
                        >
                          English
                        </Button>
                        <Button
                          className="btn--four"
                          style={{ padding: "5px 20px" }}
                          onClick={(e) => navigate("/tutorial/hin/grp-agm")}
                        >
                          Hindi
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item style={{ transition: "400ms" }}>
                    <Row>
                      <Col>Group EGM Notice</Col>
                      <Col style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          className="btn--primary"
                          style={{ padding: "5px 20px", marginRight: "10px" }}
                          onClick={(e) => navigate("/tutorial/eng/grp-egm")}
                        >
                          English
                        </Button>
                        <Button
                          className="btn--four"
                          style={{ padding: "5px 20px" }}
                          onClick={(e) => navigate("/tutorial/hin/grp-egm")}
                        >
                          Hindi
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item style={{ transition: "400ms" }}>
                    <Row>
                      <Col>Group Committee Meet Notice</Col>
                      <Col style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          className="btn--primary"
                          style={{ padding: "5px 20px", marginRight: "10px" }}
                          onClick={(e) => navigate("/tutorial/eng/grp-committeemeet")}
                        >
                          English
                        </Button>
                        <Button
                          className="btn--four"
                          style={{ padding: "5px 20px" }}
                          onClick={(e) => navigate("/tutorial/hin/grp-committeemeet")}
                        >
                          Hindi
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  {/* <ListGroup.Item style={{ transition: "400ms" }}>
                    <Row>
                      <Col>Group Form A</Col>
                      <Col style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          className="btn--primary"
                          style={{ padding: "5px 20px", marginRight: "10px" }}
                          onClick={(e) => navigate("/tutorial/eng/grp-forma")}
                        >
                          English
                        </Button>
                        <Button
                          className="btn--four"
                          style={{ padding: "5px 20px" }}
                          onClick={(e) => navigate("/tutorial/hin/grp-forma")}
                        >
                          Hindi
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item style={{ transition: "400ms" }}>
                    <Row>
                      <Col>Group Form B</Col>
                      <Col style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          className="btn--primary"
                          style={{ padding: "5px 20px", marginRight: "10px" }}
                          onClick={(e) => navigate("/tutorial/eng/grp-formb")}
                        >
                          English
                        </Button>
                        <Button
                          className="btn--four"
                          style={{ padding: "5px 20px" }}
                          onClick={(e) => navigate("/tutorial/hin/grp-formb")}
                        >
                          Hindi
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item> */}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </div>
  );
}

export default Tutorials;
