import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import SecurePageSection from "../../SecurePageSection";

// creating functional component ans getting props from app.js and destucturing them
const EduPage1 = ({ nextStep, handleFormData, values }) => {
  //creating error state for validation
  const user = useSelector((state) => state.authentication.user);
  const [error, setError] = useState(false);

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    nextStep();
  };

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get("/user/region");

    setRegions(response.data.sort((a, b) => a.region.localeCompare(b.region)));
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${values.regName}/groups`);

    setGroups(response.data);
  };

  useEffect(() => {
    fetchGroups();
  }, [values.regName]);

  return (
    <div>
      <SecurePageSection accessableUsers={"adminss"} />
      <Container>
        <Row>
          <Col xs={12}>
            <h2>JSGFF Education Loan Form Step 1</h2>
          </Col>
        </Row>
        <Form>
          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control
                  name="date"
                  defaultValue={values.date}
                  type="date"
                  placeholder="Group Number"
                  onChange={handleFormData("date")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Place</Form.Label>
                <Form.Control
                  name="place"
                  defaultValue={values.place}
                  placeholder="place"
                  onChange={handleFormData("place")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="photo"
                  type="file"
                  accept="image/*"
                  placeholder="photo"
                  onChange={handleFormData("photo")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  name="persFName"
                  defaultValue={values.persFName}
                  placeholder="First name"
                  onChange={handleFormData("persFName")}
                />
              </Form.Group>
            </Col>

            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  name="persMName"
                  defaultValue={values.persMName}
                  placeholder="Middle name"
                  onChange={handleFormData("persMName")}
                />
              </Form.Group>
            </Col>

            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Surname</Form.Label>
                <Form.Control
                  name="persLName"
                  defaultValue={values.persLName}
                  placeholder="Surname"
                  onChange={handleFormData("persLName")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Birthday Date</Form.Label>
                <Form.Control
                  name="persBDay"
                  defaultValue={values.persBDay}
                  type="date"
                  placeholder="Group Number"
                  onChange={handleFormData("persBDay")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  name="persMobile"
                  defaultValue={values.persMobile}
                  placeholder="Mobile Number"
                  onChange={handleFormData("persMobile")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>E-Mail</Form.Label>
                <Form.Control
                  name="persEmail"
                  defaultValue={values.persEmail}
                  placeholder="Email"
                  onChange={handleFormData("persEmail")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Present Address</Form.Label>
                <Form.Control
                  name="persAddress"
                  defaultValue={values.persAddress}
                  placeholder="Address"
                  onChange={handleFormData("persAddress")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Parent/Guardian Name</Form.Label>
                <Form.Control
                  name="parFName"
                  defaultValue={values.parFname}
                  placeholder="First Name"
                  onChange={handleFormData("parFName")}
                />
              </Form.Group>
            </Col>

            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Parent/Guardian Name</Form.Label>
                <Form.Control
                  name="parMName"
                  defaultValue={values.parMName}
                  placeholder="Middle Name"
                  onChange={handleFormData("parMName")}
                />
              </Form.Group>
            </Col>

            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Parent/Guardian Name</Form.Label>
                <Form.Control
                  name="parLName"
                  defaultValue={values.parLName}
                  placeholder="Last Name"
                  onChange={handleFormData("parLName")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Occupation</Form.Label>
                <Form.Control
                  name="parOccupation"
                  defaultValue={values.parOccupation}
                  placeholder="Occupation"
                  onChange={handleFormData("parOccupation")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Annual Income</Form.Label>
                <Form.Control
                  name="parAnnualIncome"
                  defaultValue={values.parAnnualIncome}
                  placeholder="Annual Income"
                  onChange={handleFormData("parAnnualIncome")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>PAN No</Form.Label>
                <Form.Control
                  name="parPANno"
                  defaultValue={values.parPANno}
                  placeholder="PAN Number"
                  onChange={handleFormData("parPANno")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Family Assistance</Form.Label>
                <Form.Control
                  name="parFamAssistance"
                  defaultValue={values.parFamAssistance}
                  placeholder="Rs"
                  onChange={handleFormData("parFamAssistance")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Present Address</Form.Label>
                <Form.Control
                  name="parAddress"
                  defaultValue={values.parAddress}
                  placeholder="Address"
                  onChange={handleFormData("parAddress")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Phone Number (R)</Form.Label>
                <Form.Control
                  name="parPhonenoR"
                  defaultValue={values.parPhonenoR}
                  placeholder="Phone Number (R)"
                  onChange={handleFormData("parPhonenoR")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Phone Number (O)</Form.Label>
                <Form.Control
                  name="parPhonenoO"
                  defaultValue={values.parPhonenoO}
                  placeholder="Phone Number (O)"
                  onChange={handleFormData("parPhonenoO")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Phone Number (M)</Form.Label>
                <Form.Control
                  name="parPhonenoM"
                  defaultValue={values.parPhonenoM}
                  placeholder="Phone Number (M)"
                  onChange={handleFormData("parPhonenoM")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="parEmail"
                  defaultValue={values.parEmail}
                  placeholder="Email"
                  onChange={handleFormData("parEmail")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Native Place</Form.Label>
                <Form.Control
                  name="parNativePlace"
                  defaultValue={values.parNativePlace}
                  placeholder="Place"
                  onChange={handleFormData("parNativePlace")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>District/State</Form.Label>
                <Form.Control
                  name="parState"
                  defaultValue={values.parState}
                  placeholder="District/State"
                  onChange={handleFormData("parState")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Member Of JSG:</Form.Label>
                <Form.Select
                  name="parMemberOfJsg"
                  defaultValue={values.parMemberOfJsg}
                  placeholder="Place"
                  onChange={handleFormData("parMemberOfJsg")}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>If Yes Group Name</Form.Label>
                <Form.Control
                  name="parGrpName"
                  defaultValue={values.parGrpName}
                  placeholder="Group Name"
                  onChange={handleFormData("parGrpName")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button className="btn--outline--dark" onClick={submitFormData}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Next
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default EduPage1;
