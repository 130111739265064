import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./FedInfo.css";

function RegChairman() {
  const [chairmen, setChairmen] = useState([]);
  const url = useParams();

  useEffect(() => {
    const fetchIDs = async () => {
      const response = await axios.get(`/api/data/reg-chairmen/${url.term}`);
      const sortedArray = response.data.sort((a, b) =>
        a.regName > b.regName ? 1 : -1
      );

      console.log(sortedArray);
      setChairmen(sortedArray);
    };
    fetchIDs();
  }, [url.term]);

  return (
    <div>
      <div>
        <Row>
          {chairmen.map((user) => (
            <Col
              style={{ margin: "1em 0" }}
              lg={6}
              md={12}
              xs={12}
              key={user.id}
            >
              <Card className="idInfoCard">
                <Row>
                  <Col xs={4}>
                    <Card.Img
                      className="idInfoImage"
                      style={{ border: "0", borderRadius: "15px" }}
                      src={user.photo}
                      alt={user.name}
                    />
                  </Col>
                  <Col xs={8}>
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontSize: "22px",
                          fontWeight: "700",
                          color: "#3f0986",
                        }}
                      >
                        {user.name}
                      </Card.Title>
                      <Card.Subtitle
                        style={{
                          fontSize: "20px",
                          color: "#4d0020",
                          textTransform: "uppercase",
                        }}
                      >
                        {user.regName} Region Chairman
                      </Card.Subtitle>

                      <Row style={{ marginTop: "10px" }}>
                        <Card.Subtitle
                          style={{
                            fontSize: "18px",
                            textTransform: "capitalize",
                            color: "#4d0020",
                            marginBottom: "15px",
                          }}
                        >
                          JSG {user.grpName}
                        </Card.Subtitle>
                        <Col>
                          <p className="idInfoText1">
                            <i class="fa-solid fa-phone"></i> {user.mobile}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <p className="idInfoText1">
                          <i class="fa-solid fa-envelope"></i> {user.email}
                        </p>
                      </Row>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default RegChairman;
