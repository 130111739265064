import React, { useEffect, useState } from "react";
import "./About.css";
import SecurePageSection from "./../SecurePageSection";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Row, Col } from "react-bootstrap";

function About() {
  const user = useSelector((state) => state.authentication.user);
  const location = useLocation();

  return (
    <div className="about">
      <h1>About Us</h1>
      <div className="about-container">
        <Row>
          <p className="aboutText">
            Jain Social Groups' International Federation is the largest
            organisation in the world that encompasses the four sects of Jains
            and spreads the motto of BANDHUTVA SE PREM. The tenet WALK TOGETHER,
            TALK TOGETHER, ACT WITH ONE MIND has been the driving force for more
            than 60 years now and is being promoted worldwide by more than 425+
            groups having a presence of more than 85,000 members across not only
            the Indian boundaries, but also overseas in the European, American
            and African continents as also in the Middle East. These groups are
            divided into various regions namely, Bombay, Gujarat, Indore, Madhya
            Pradesh Maharashtra, Mewar, North, Overseas, Saurashtra, and South.
            The main objective of the organisation is to promote fellowship and
            fraternity among members, to strive for their progress and
            prosperity and to foster unity and propagate the fundamental
            principles of Jainism. MANGALYATRA, an inhouse publication
            highlights the activities and programs undertaken at various levels
            of the organisation. The organisation has a very well extended
            family of SANGINI, an all ladies offshoot as well as the only
            teenager membership titled YUVA FORUM as well as a GENNEXT group
            that lists young couples below the age of 40as it's members. Apart
            from this a myriad of other activities in the social, entertainment,
            educational, training sports are undertaken at all levels of the
            organisational structure. There is a biennial conference that awards
            the outstanding achievers not only from within the organisation but
            also from the society.
          </p>
        </Row>
        <Row>
          <Col>
            <h2 className="about-h2">Organisation Structure</h2>
            <p className="aboutText">
              The Jain Social Group (JSG) movement started in 1965 and was
              organised through its Jain Social Groups International Federation
              (JSGIF) in 1980 by Shri C. N. Sanghvi, Founder President. It
              covers more than 400 Groups at molecular level, spread across the
              world. The principal motto of JSG is as following and not limited
              to :
            </p>
            <h3 className="about-h3">
              "WALK TOGETHER, TALK TOGETHER, ACT WITH ONE MIND"
            </h3>
            <p className="aboutText">
              This is the motivation for us to attain social and cultural
              objectives by spreading the feeling of friendship and brother-hood
              amongst all members, imparting the teaching of Lord Mahaveera, and
              to create a healthy Socialist, Secular and Democratic society and
              help in Nation building. The organisation structure constitutes of
              different bodies, each holding its own unique position in the
              structure, but with one binding slogan "Bandhutva Se Prem" that
              provides a forum for social interaction to 85000+ Members.
            </p>
          </Col>
          <Col>
            <h2 className="about-h2">Activities of Federation</h2>
            <p className="aboutText">
              Jain Social Groups International Federation (JSGIF) contributes
              tremendously by bringing about a sense of unity amongst different
              sects (Firkas) of Jainism like Swetamber, Digambar, Sthanakwasi,
              Terapanthi, etc. and to active the object of "Bandhutvase Prem".
              To this objective, the Federation conducts various activities and
              programs at Group level, Region level and Federation level, like
            </p>
            <ul>
              <li className="aboutText" style={{ margin: "0" }}>
                Workshops and Camps
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Samuhika Vivah & Lagna Mela
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Celebration of Utsavs
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Publication of Monthly magazine "Mangal Yatra"
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Conference & Seminar
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Cultural Activities
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Film Show
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Drama
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Picnic
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Cricket etc.
              </li>
            </ul>
          </Col>
          <Col>
            <h2 className="about-h2">JSG Movement on Internet</h2>
            <p className="aboutText">
              Federation has developed database website and maintained by
              Authorised Site Administrator for providing globally three types
              of information, 24 hours a day and 365 days a year as under :
            </p>
            <h3 className="about-h3">General Information of Federation :</h3>
            <p className="aboutText">
              Database information of Federation Post, General Council Members,
              Groups of Federation, Members of Groups.
            </p>
            <h3 className="about-h3">Current News of Federation :</h3>
            <ul>
              <li>
                <Link to="/mann-ki-baat">
                  <p
                    style={{ margin: 0, marginTop: "10px" }}
                    className="aboutText"
                  >
                    Mann ki Baat
                  </p>
                </Link>
              </li>
              <li>
                <Link to="/testimonials">
                  <p style={{ margin: 0 }} className="aboutText">
                    Testimonials
                  </p>
                </Link>
              </li>
              <li>
                <Link to="/media-coverages">
                  <p
                    style={{ margin: 0, marginBottom: "10px" }}
                    className="aboutText"
                  >
                    Media Coverages
                  </p>
                </Link>
              </li>
            </ul>
            <h3 className="about-h3">
              Jain Social Groups International Federation Headed by
            </h3>
            <ul>
              <li className="aboutText" style={{ margin: "0" }}>
                Federation President and Team
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Regional Committee
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Zone Coordinator
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Activity Committee
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Regionwise General Council Committee and also YUVA Forums
              </li>
              <li className="aboutText" style={{ margin: "0" }}>
                Supported by Couple Members
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default About;
