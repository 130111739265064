import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Images from "../../images/index";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

import "../Response.css";
import SecurePageSection from "../../SecurePageSection";

function UdaanResponse() {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponses] = useState([]);
  const responseId = useParams();
  const ref = React.createRef();

  const getResponse = async () => {
    const res = await axios.get(`/api/forms/ventures/udaan-group-application/${responseId.id}`);
    setResponses(res.data);
  };

  useEffect(() => {
    getResponse();
  }, []);

  const navigate = useNavigate();

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("", "blank", "height=1123,width=800,scrollbars=yes,");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>Form B</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    html {
      overflow-y: auto;
    }

    .fResponseBody {
      padding: 20px 20px;
      width: 800px;
      min-height: 1123px;
      background: #fff;
    }
    
    .page{
      page-break-after: always;
    }
    
    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }
    
    
    .section2, .section3{
      margin-top: 30px;
    }
    
    .section4{
      margin-top: 20px;
    }
    
    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }
    
    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }
    
    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }
    
    
    .bordered-mid-row{
      padding: 2px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }
    
    .bordered-row-b{
      padding: 2px 0;
      border: 1px solid #000;
      height: 30px;
    }
    
    .bordered-col-b{
      padding: 2px 5px;
      border: 1px solid #000;
      height: 30px;
    }
    
    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }
    
    
    .bannerText {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    
    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }
    
    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }
    
    .toptext {
      margin: 20px 0;
    }
    
    .logoImage {
      height: 80px;
      margin: 10px;
    }
    
    .bannerYellow {
      background: #face1d;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }
    
    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }
    
    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }
    
    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    
    .H2double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 16px;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    
    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
    
    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
    
    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }
    
    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }
    
    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }
    
    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }
    
    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }`);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function () {
      a.print();
    }, 1000);
  };

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Udaan Group Response</h2>
      <Row style={{ marginBottom: "1em", marginTop: "1em" }}>
        <Col style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Button onClick={printDocument} style={{ margin: "0 5px" }}>
            Download Form
          </Button>
        </Col>
      </Row>

      <div id="mainForm" className="centerFormat">
        <div className="fResponseBody">
          <div className="page">
            <div className="bannerYellow">
              <Row className="T-Banner">
                <Col xs={1}>
                  <img src={Images.LogoB} className="logoImage" alt="jsgBanner"></img>
                </Col>
                <Col xs={11} className="bannerText text-center">
                  <h1
                    style={{
                      margin: "0",
                    }}
                  >
                    JAIN SOCIAL GROUP INT. FEDERATION
                  </h1>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    4-O/P, Vijay Chambers, Opp. Dreamland Cinema, Tribhuvan Road, Mumbai - 400 004.
                    (India)
                  </p>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Tel.: 022-23870724 / 23891884 Mobile : 8169274400
                  </p>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    E-mail : office@jsgif.co.in Website : www.jsgif.co.i
                  </p>
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <p className="Tdouble2">
                  Region Name - <span className="response2">{response.regName}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="Tdouble2">
                  Region Chairman - <span className="response2">{response.regChair}</span>
                </p>
              </Col>
              <Col>
                <p className="Tdouble2">
                  Region Secretary - <span className="response2">{response.regSec}</span>
                </p>
              </Col>
              <Col>
                <p className="Tdouble2">
                  Region Convenor - <span className="response2">{response.regConvenor}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="Tdouble2">
                  Group Name - <span className="response2">{response.grpName}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="Tdouble2">
                  Group President - <span className="response2">{response.grpPres}</span>
                </p>
              </Col>
              <Col>
                <p className="Tdouble2">
                  Group Secretary - <span className="response2">{response.grpSec}</span>
                </p>
              </Col>
              <Col>
                <p className="Tdouble2">
                  Group Location - <span className="response2">{response.grpLocation}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="Tdouble2">
                  Candidate Name - <span className="response2">{response.cName}</span>
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                <p className="Tdouble2">
                  Email Address - <span className="response2">{response.cEmail}</span>
                </p>
              </Col>
              <Col>
                <p className="Tdouble2">
                  Date of Birth - <span className="response2">{response.cDob}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="Tdouble2">
                  Gender - <span className="response2">{response.cSex}</span>
                </p>
              </Col>
              <Col>
                <p className="Tdouble2">
                  Marital Status - <span className="response2">{response.cMarital}</span>
                </p>
              </Col>
              <Col>
                <p className="Tdouble2">
                  Spouse Name - <span className="response2">{response.cSpouse}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="Tdouble2">
                  Address - <span className="response2">{response.cAddress}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="Tdouble2">
                  Profession - <span className="response2">{response.cProfession}</span>
                </p>
              </Col>
              <Col>
                <p className="Tdouble2">
                  Field - <span className="response2">{response.cField}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="Tdouble2">
                  Award Date - <span className="response2">{response.awardDate}</span>
                </p>
              </Col>
              <Col>
                <p className="Tdouble2">
                  Award Issuing authority -{" "}
                  <span className="response2">{response.awardAuthority}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="Tdouble2">
                  Address - <span className="response2">{response.awardDescription}</span>
                </p>
              </Col>
            </Row>
            <Row>
              {(() => {
                if (response.photo1 !== null) {
                  return (
                    <Col xs={4}>
                      <Image src={response.photo1} fluid />
                    </Col>
                  );
                }
              })()}

              {(() => {
                if (response.photo2 !== null) {
                  return (
                    <Col xs={4}>
                      <Image src={response.photo2} fluid />
                    </Col>
                  );
                }
              })()}

              {(() => {
                if (response.photo3 !== null) {
                  return (
                    <Col xs={4}>
                      <Image src={response.photo3} fluid />
                    </Col>
                  );
                }
              })()}

              {(() => {
                if (response.photo4 !== null) {
                  return (
                    <Col xs={4}>
                      <Image src={response.photo4} fluid />
                    </Col>
                  );
                }
              })()}

              {(() => {
                if (response.photo5 !== null) {
                  return (
                    <Col xs={4}>
                      <Image src={response.photo5} fluid />
                    </Col>
                  );
                }
              })()}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UdaanResponse;
