import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../../SecurePageSection";

const PariForm = () => {
  const user = useSelector((state) => state.authentication.user);

  const [regName, setRegName] = useState("");
  const [grpName, setGrpName] = useState("");
  const [DName, setDName] = useState("");
  const [DPhoto, setDPhoto] = useState(null);
  const [DDob, setDDob] = useState("");
  const [DPlace, setDPlace] = useState("");
  const [DTime, setDTime] = useState("");
  const [DHospitalName, setDHospitalName] = useState("");
  const [DBirthCertificate, setDBirthCertificate] = useState(null);
  const [FName, setFName] = useState("");
  const [FAddress, setFAddress] = useState("");
  const [FDob, setFDob] = useState("");
  const [FEmail, setFEmail] = useState("");
  const [FMobile, setFMobile] = useState("");
  const [FWhatsapp, setFWhatsapp] = useState("");
  const [FProfession, setFProfession] = useState("");
  const [FPhoto, setFPhoto] = useState(null);
  const [FAadharFront, setFAadharFront] = useState(null);
  const [FAadharBack, setFAadharBack] = useState(null);
  const [FPanFront, setFPanFront] = useState(null);
  const [FPanBack, setFPanBack] = useState(null);
  const [MName, setMName] = useState("");
  const [MAddress, setMAddress] = useState("");
  const [MDob, setMDob] = useState("");
  const [MEmail, setMEmail] = useState("");
  const [MMobile, setMMobile] = useState("");
  const [MWhatsapp, setMWhatsapp] = useState("");
  const [MProfession, setMProfession] = useState("");
  const [MPhoto, setMPhoto] = useState(null);
  const [MAadharFront, setMAadharFront] = useState(null);
  const [MAadharBack, setMAadharBack] = useState(null);
  const [MPanFront, setMPanFront] = useState(null);
  const [MPanBack, setMPanBack] = useState(null);
  const [regEmails, setRegEmails] = useState("");
  const [regConvenor, setRegConvenor] = useState("");

  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const fetchRegEmails = async () => {
    const chairmanResponse = await axios.get(`api/data/${regName}/region-ob/emails/`);
    const chairmanEmail = chairmanResponse.data
      .filter((item) => item.post === "chairman")
      .map((item) => item.email);
    setRegEmails(chairmanEmail);

    const convenorResponse = await axios.get(`api/data/pari/region-convenor/emails/`);
    const convenorEmail = convenorResponse.data
      .filter(
        (item) =>
          item.post ===
            `${regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()} Region Convenor` ||
          item.post ===
            `${regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()} Region Coordinator`
      )
      .map((item) => item.email);
    setRegConvenor(convenorEmail);
  };

  useEffect(() => {
    fetchRegEmails();
  }, [regName]);

  async function FormSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    let formField = new FormData();

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,office@jsgif.co.in,dj22jsgif@gmail.com,sonalsavani@gmail.com," +
      "manishdoshi9999@gmail.com,bakuleshvirani@gmail.com,adshah1952@gmail.com," +
      FEmail +
      "," +
      MEmail +
      "," +
      regEmails +
      "," +
      regConvenor;

    formField.append("user", user?.user_id);
    formField.append("regName", regName);
    formField.append("grpName", grpName);
    formField.append("DName", DName);
    if (DPhoto !== null) {
      formField.append("DPhoto", DPhoto);
    }
    formField.append("DDob", DDob);
    formField.append("DPlace", DPlace);
    formField.append("DTime", DTime);
    formField.append("DHospitalName", DHospitalName);
    if (DBirthCertificate !== null) {
      formField.append("DBirthCertificate", DBirthCertificate);
    }
    formField.append("FName", FName);
    formField.append("FAddress", FAddress);
    formField.append("FDob", FDob);
    formField.append("FEmail", FEmail);
    formField.append("FMobile", FMobile);
    formField.append("FWhatsapp", FWhatsapp);

    formField.append("FProfession", FProfession);
    if (FPhoto !== null) {
      formField.append("FPhoto", FPhoto);
    }
    if (FAadharFront !== null) {
      formField.append("FAadharFront", FAadharFront);
    }
    if (FAadharBack !== null) {
      formField.append("FAadharBack", FAadharBack);
    }
    if (FPanFront !== null) {
      formField.append("FPanFront", FPanFront);
    }
    if (FPanBack !== null) {
      formField.append("FPanBack", FPanBack);
    }
    formField.append("MName", MName);
    formField.append("MAddress", MAddress);
    formField.append("MDob", MDob);
    formField.append("MEmail", MEmail);
    formField.append("MMobile", MMobile);
    formField.append("MWhatsapp", MWhatsapp);
    formField.append("MProfession", MProfession);
    if (MPhoto !== null) {
      formField.append("MPhoto", MPhoto);
    }
    if (MAadharFront !== null) {
      formField.append("MAadharFront", MAadharFront);
    }
    if (MAadharBack !== null) {
      formField.append("MAadharBack", MAadharBack);
    }
    if (MPanFront !== null) {
      formField.append("MPanFront", MPanFront);
    }
    if (MPanBack !== null) {
      formField.append("MPanBack", MPanBack);
    }
    formField.append("emailList", finalEmail);
    formField.append("Status", "Pending");

    await axios({
      method: "post",
      url: "/api/forms/ventures/pari-applications/",

      data: formField,
    })
      .then(function(response) {
        console.log(response);
        setSubmitting(false);
        navigate(`/pari-application-form/response/${response.data.id}`, { replace: true });
      })
      .catch(function(error) {
        console.log(error);
        setSubmitting(false);
      });
    setSubmitting(false);
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get(`/user/region/`);
    setRegions(response.data);
  };
  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups/`);
    setGroups(response.data);
  };
  useEffect(() => {
    fetchGroups();
  }, [regName]);

  const handleFileChange = (setState, event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Check if file size is less than 2MB
      if (selectedFile.size <= 1 * 1024 * 1024) {
        setState(selectedFile);
      } else {
        // File size exceeds 2MB, reset file input
        event.target.value = null;
        setState(null);
        // You can also provide feedback to the user, e.g., show an error message
        alert("File size must be less than 1MB.");
      }
    }
  };

  return (
    <div>
      <Container>
        <Form>
          <Row>
            <Col md={8}>
              <h2>Pari Application Form</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Name</Form.Label>
                <Form.Select
                  name="regName"
                  value={regName}
                  onChange={(e) => setRegName(e.target.value)}
                >
                  <option>Select your Region</option>
                  {regions.map((region, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() + region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Group Name</Form.Label>
                <Form.Select
                  name="grpName"
                  value={grpName}
                  onChange={(e) => setGrpName(e.target.value)}
                >
                  <option>Select your Group</option>
                  {groups.map((group, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={group.group.toLowerCase()}
                    >
                      {group.group.charAt(0).toUpperCase() + group.group.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="formSectionHeader">
            <h3>Daughter's Details</h3>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  name="DName"
                  value={DName}
                  placeholder="Daughter's Full name"
                  onChange={(e) => setDName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="DPhoto"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(setDPhoto, e)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  name="DDob"
                  type="date"
                  value={DDob}
                  onChange={(e) => setDDob(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Place of Birth</Form.Label>
                <Form.Control
                  name="DPlace"
                  placeholder="Daughter's City of Birth as in birth certificate"
                  value={DPlace}
                  onChange={(e) => setDPlace(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Time of Birth</Form.Label>
                <Form.Control
                  name="DTime"
                  type="time"
                  placeholder="Daughter's Time of Birth as in birth certificate"
                  value={DTime}
                  onChange={(e) => setDTime(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Name of the Hospital</Form.Label>
                <Form.Control
                  name="DHospitalName"
                  value={DHospitalName}
                  placeholder="Daughter's Birth Hospital"
                  onChange={(e) => setDHospitalName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Birth Certificate</Form.Label>
                <Form.Control
                  name="DBirthCertificate"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(setDBirthCertificate, e)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="formSectionHeader">
            <h3>Father's Details</h3>
          </Row>

          <Row className="mb-3">
            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  name="FName"
                  value={FName}
                  placeholder="Father's Full Name"
                  onChange={(e) => setFName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="FPhoto"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(setFPhoto, e)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  name="FDob"
                  type="date"
                  value={FDob}
                  onChange={(e) => setFDob(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Residential Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="FAddress"
                  value={FAddress}
                  placeholder="Father's Current Residential Address"
                  onChange={(e) => setFAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name="FMobile"
                  value={FMobile}
                  placeholder="Father's Mobile Number"
                  onChange={(e) => setFMobile(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  name="FWhatsapp"
                  value={FWhatsapp}
                  placeholder="Father's Whatsapp Number"
                  onChange={(e) => setFWhatsapp(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  name="FEmail"
                  value={FEmail}
                  placeholder="Father's Email Address"
                  onChange={(e) => setFEmail(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Profession/ Business</Form.Label>
                <Form.Control
                  name="FProfession"
                  value={FProfession}
                  placeholder="Father's Whatsapp Number"
                  onChange={(e) => setFProfession(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <h5 style={{ textAlign: "center", margin: "0" }}>
              Please Crop the photos to the exact size of the document before uploading
            </h5>
          </Row>
          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Aadhar Front</Form.Label>
                <Form.Control
                  name="FAadharFront"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(setFAadharFront, e)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Aadhar Back</Form.Label>
                <Form.Control
                  name="FAadharFront"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(setFAadharBack, e)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>PAN Front</Form.Label>
                <Form.Control
                  name="FPanFront"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(setFPanFront, e)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>PAN Back</Form.Label>
                <Form.Control
                  name="FPanFront"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(setFPanBack, e)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="formSectionHeader">
            <h3>Mother's Details</h3>
          </Row>

          <Row className="mb-3">
            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  name="MName"
                  value={MName}
                  placeholder="Mother's Full Name"
                  onChange={(e) => setMName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="MPhoto"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(setMPhoto, e)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  name="MDob"
                  type="date"
                  value={MDob}
                  onChange={(e) => setMDob(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Residential Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="MAddress"
                  value={MAddress}
                  placeholder="Mother's Current Residential Address"
                  onChange={(e) => setMAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name="MMobile"
                  value={MMobile}
                  placeholder="Mother's Mobile Number"
                  onChange={(e) => setMMobile(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  name="MWhatsapp"
                  value={MWhatsapp}
                  placeholder="Mother's Whatsapp Number"
                  onChange={(e) => setMWhatsapp(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  name="MEmail"
                  value={MEmail}
                  placeholder="Mother's Email Address"
                  onChange={(e) => setMEmail(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Profession/ Business</Form.Label>
                <Form.Control
                  name="MProfession"
                  value={MProfession}
                  placeholder="Mother's Whatsapp Number"
                  onChange={(e) => setMProfession(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h5 style={{ textAlign: "center", margin: "0" }}>
              Please Crop the photos to the exact size of the document before uploading
            </h5>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Aadhar Front</Form.Label>
                <Form.Control
                  name="MAadharFront"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(setMAadharFront, e)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Aadhar Back</Form.Label>
                <Form.Control
                  name="MAadharFront"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(setMAadharBack, e)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>PAN Front</Form.Label>
                <Form.Control
                  name="MPanFront"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(setMPanFront, e)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>PAN Back</Form.Label>
                <Form.Control
                  name="MPanFront"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(setMPanBack, e)}
                />
              </Form.Group>
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>}
            >
              <Button style={{ marginTop: "30px" }} className="btn--outline--dark" disabled>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              style={{ marginTop: "30px" }}
              className="btn--outline--dark"
              onClick={(e) => FormSubmit(e)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default PariForm;
