import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import "./events.css";
import moment from "moment";
import { useSelector } from "react-redux";

import axios from "axios";

const ObWiseProjects = () => {
  const url = useParams();

  const [events, setEvents] = useState([]);

  const fetchProjects = async () => {
    const response = await axios.get(`/api/fed/${url.user_id}/projects/`);

    console.log(response.data);
    setEvents(response.data);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <div>
      <Container className="body">
        <Row>
          {events.map((event, index) => (
            <Col
              style={{
                marginBottom: "2em",
                display: "flex",
                justifyContent: "center",
              }}
              sm="auto"
              md="auto"
              lg="auto"
              xl="auto"
            >
              <Link
                to={`/fed/ob-project/${event.id}`}
                className="customCard1_link"
              >
                <div className="cardsX">
                  <div className="customCard1 customCard1--1">
                    <div className="customCard1__info-hover"></div>
                    <Row className="customRow">
                      <img
                        className="customCard1__img"
                        src={event.ePhoto0}
                      ></img>
                      <img
                        className="customCard1__img--hover"
                        src={event.ePhoto0}
                      ></img>
                    </Row>
                    <Row className="customRow">
                      <div className="customCard1__info">
                        <span className="customCard1__category">
                          {event.name}
                        </span>
                        <h3 className="customCard1__title">{event.eTitle}</h3>
                        <h4 className="customCard1__date">
                          {moment(event.eDate).format("Do MMMM YYYY")}
                        </h4>
                        <p className="customCard1__text">
                          {event.eDescription}
                        </p>
                      </div>
                    </Row>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ObWiseProjects;
