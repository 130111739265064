import React from "react";
import { Col, Row, Table } from "react-bootstrap";

function JsgBank() {
  return (
    <div>
      <Row>
        <h1>JSGIF Bank Account Details</h1>
      </Row>
      <Table responsive="md" striped bordered>
        <tbody className="tableBody">
          <tr>
            <td style={{ fontWeight: "800" }}>Name</td>
            <td style={{ fontWeight: "600" }}>JSG Int. Federation</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "800" }}>Bank Name</td>
            <td style={{ fontWeight: "600" }}>Indian Bank</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "800" }}>Branch Name</td>
            <td style={{ fontWeight: "600" }}>Prarthana Samaj</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "800" }}>Account Number</td>
            <td style={{ fontWeight: "600" }}>417721000</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "800" }}>IFS Code</td>
            <td style={{ fontWeight: "600" }}>IDIB000P049</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default JsgBank;
