import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../../../SecurePageSection";

const ConvenorH1Form = () => {
  const user = useSelector((state) => state.authentication.user);
  const [submitting, setSubmitting] = useState(false);

  const [regName, setRegName] = useState("");
  const [name, setname] = useState("");
  const [num, setnum] = useState("");
  const [email, setemail] = useState("");
  const [numForms, setnumForms] = useState("");
  const [meetings, setmeetings] = useState("");
  const [followup, setfollowup] = useState("");
  const [visit, setvisit] = useState("");
  const [chairmanObs, setchairmanObs] = useState("");

  const navigate = useNavigate();

  async function FormSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    let formField = new FormData();

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,office@jsgif.co.in,dj22jsgif@gmail.com,dsmehta8842@gmail.com," +
      email;

    console.log(finalEmail);

    if (!regName || !name) {
      alert("Please Submit your Name and Region Name");
      setSubmitting(false);
      return;
    }

    formField.append("user", user?.user_id);
    formField.append("regName", regName);
    formField.append("name", name);
    formField.append("num", num);
    formField.append("email", email);
    formField.append("numForms", numForms);
    formField.append("meetings", meetings);
    formField.append("followup", followup);
    formField.append("visit", visit);
    formField.append("chairmanObs", chairmanObs);
    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/forms/ventures/eminence-h1-convenors/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        navigate(`/eminence/h1/convenor/response/${response.data.id}`);
        // navigate(`/eminence/h1/ob/response/${response.data.id}`, { replace: true });
      })
      .catch(function(error) {
        console.log(error);
      });
    setSubmitting(false);
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get(`/user/region/`);
    setRegions(response.data);
  };
  useEffect(() => {
    fetchRegions();
  }, []);

  return (
    <div>
      <SecurePageSection accessableUsers={"admin,eminence,eminenceChair"} />
      <Container>
        <Form>
          <Row>
            <Col lg={8}>
              <h2>Eminence 1st Half Application (Eminence Convenors)</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Name</Form.Label>
                <Form.Select
                  name="regName"
                  value={regName}
                  onChange={(e) => setRegName(e.target.value)}
                >
                  <option>Select your Region</option>
                  {regions.map((region, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() + region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  name="name"
                  value={name}
                  placeholder="Respondant's Full name"
                  onChange={(e) => setname(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Phone Number</Form.Label>
                <Form.Control
                  name="num"
                  value={num}
                  placeholder="Respondant's num ID"
                  onChange={(e) => setnum(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Email</Form.Label>
                <Form.Control
                  name="email"
                  value={email}
                  placeholder="Respondant's Email ID"
                  onChange={(e) => setemail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  No of forms out of total groups submitted by your region in both quarters with
                  Sangini Forum.
                </Form.Label>
                <Form.Control
                  name="numForms"
                  value={numForms}
                  placeholder="No of forms out of total groups submitted by your region in both quarters with Sangini Forum."
                  onChange={(e) => setnumForms(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Meeting attended on Zoom Communication with Eminence Chairman & Group Leaders
                  (Timing)
                </Form.Label>
                <Form.Control
                  name="meetings"
                  value={meetings}
                  placeholder="Meeting attended on Zoom Communication with Eminence Chairman & Group Leaders (Timing)"
                  onChange={(e) => setmeetings(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Follow up with groups for social media awareness</Form.Label>
                <Form.Control
                  name="followup"
                  value={followup}
                  placeholder="Follow up with groups for social media awareness"
                  onChange={(e) => setfollowup(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Visiting groups regarding Eminence Award followed by protocol formalities
                </Form.Label>
                <Form.Control
                  name="visit"
                  value={visit}
                  placeholder="Visiting groups regarding Eminence Award followed by protocol formalities"
                  onChange={(e) => setvisit(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Eminence Chairman observation</Form.Label>
                <Form.Control
                  name="chairmanObs"
                  value={chairmanObs}
                  placeholder="Eminence Chairman observation"
                  onChange={(e) => setchairmanObs(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>}
            >
              <Button style={{ marginTop: "30px" }} className="btn--outline--dark" disabled>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              style={{ marginTop: "30px" }}
              className="btn--outline--dark"
              onClick={(e) => FormSubmit(e)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default ConvenorH1Form;
