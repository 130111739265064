import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./Loader.css"; // Import the CSS file

const Loader = ({ text }) => {
  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <Spinner animation="grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <h3 style={{ textAlign: "center" }}>{text}</h3>
      </div>
    </div>
  );
};

export default Loader;
