import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Images from "../../images/index";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import "../Response.css";
import SecurePageSection from "../../SecurePageSection";

function GroupFormBResponse() {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponses] = useState([]);
  const responseId = useParams();

  const getResponse = async () => {
    const res = await axios.get(
      `/api/forms/group/grp-form-b-response/${responseId.id}`
    );
    setResponses(res.data);
    console.log(res.data);
  };

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("", "blank", "height=1123,width=800,scrollbars=yes,");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>Form B</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    html {
      overflow-y: auto;
    }

    .fResponseBody {
      padding: 20px 20px;
      width: 800px;
      min-height: 1123px;
      background: #fff;
    }
    
    .page{
      page-break-after: always;
    }
    
    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }
    
    
    .section2, .section3{
      margin-top: 30px;
    }
    
    .section4{
      margin-top: 20px;
    }
    
    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }
    
    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }
    
    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }
    
    
    .bordered-mid-row{
      padding: 2px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }
    
    .bordered-row-b{
      padding: 2px 0;
      border: 1px solid #000;
      height: 30px;
    }
    
    .bordered-col-b{
      padding: 2px 5px;
      border: 1px solid #000;
      height: 30px;
    }
    
    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }
    
    
    .bannerText {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    
    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }
    
    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }
    
    .toptext {
      margin: 20px 0;
    }
    
    .logoImage {
      height: 80px;
      margin: 10px;
    }
    
    .bannerYellow {
      background: #face1d;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }
    
    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }
    
    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }
    
    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    
    .H2double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 16px;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    
    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
    
    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
    
    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }
    
    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }
    
    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }
    
    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }
    
    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }
    `);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
    }, 1000);
  };

  useEffect(() => {
    getResponse();
  }, []);

  const navigate = useNavigate();
  const editForm = () => {
    navigate(`form/grp-form-b/${response.id}/edit/`);
  };

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Group Form B Response</h2>
      <Row style={{ marginBottom: "1em", marginTop: "1em" }}>
        <Col className="d-flex justify-content-end">
          <Button onClick={printDocument} variant="primary">
            Download Form
          </Button>
        </Col>
        <Col className="d-flex justify-content-start">
          <Button onClick={editForm} variant="primary">
            Edit Form
          </Button>
        </Col>
      </Row>
      <div id="mainForm" className="centerFormat">
        <div className="fResponseBody">
          <div
            className="page"
            style={{
              textAlign: "center",
              padding: "0 15px",
              pageBreakAfter: "always",
              textTransform: "capitalize",
            }}
          >
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "2px 2px 2px 2px",
                padding: "10px",
              }}
            >
              <Col>
                <Row className="T-Banner">
                  <Col xs={4}>
                    {/* <h3 className="Hdouble-enter">Federation Copy</h3> */}
                  </Col>
                  <Col xs={4} style={{ textAlign: "center" }}>
                    <img src={Images.LogoB} height="60" alt="jsgBanner" />
                  </Col>
                  <Col xs={4}>
                    <h3 className="Hdouble-enter">Received Date:</h3>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    className="bannerText text-center"
                    style={{ margin: "0", marginBottom: "8px" }}
                  >
                    <h1 style={{ color: " #000" }}>
                      JAIN SOCIAL GROUP INT. FEDERATION
                    </h1>
                    <p className="Tsingle-enter" style={{ color: " #000" }}>
                      4-O/P, Vijay CHambers, Opp. Dreamland Cinema, Tribhuvan
                      Road, Mumbai - 400 004{" "}
                    </p>
                    <p className="Tsingle-enter" style={{ color: " #000" }}>
                      Tel: 022-23870724 / 23891884 Mobile: 9820402349 /
                      8169274400 E-Mail: office@jsgif.co.in www.jsgif.co.in{" "}
                    </p>
                  </Col>
                </Row>
                <Row
                  style={{
                    color: " #fff",
                    backgroundColor: "rgb(15, 15, 15)",
                    textAlign: "center",
                  }}
                >
                  <h4 style={{ margin: "0" }}>FORM "B" 2024-25</h4>
                </Row>
                <Row
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Col xs={2} style={{ padding: "0 2px", margin: "0" }}>
                    <Row>
                      <p className="Tsingle-enter">STD Code</p>
                    </Row>
                    <Row
                      className="bordered-row-b"
                      style={{ padding: "5px 0", margin: "0" }}
                    >
                      <p className="Tsingle-enter">{response.stdCode}</p>
                    </Row>
                  </Col>
                  <Col xs={2} style={{ padding: "0 2px", margin: "0" }}>
                    <Row>
                      <p className="Tsingle-enter">Region</p>
                    </Row>
                    <Row
                      className="bordered-row-b"
                      style={{ padding: "5px 0", margin: "0" }}
                    >
                      <p className="Tsingle-enter">{response.regName}</p>
                    </Row>
                  </Col>
                  <Col xs={2} style={{ padding: "0 2px", margin: "0" }}>
                    <Row>
                      <p className="Tsingle-enter">Date of Inaugration</p>
                    </Row>
                    <Row
                      className="bordered-row-b"
                      style={{ padding: "5px 0", margin: "0" }}
                    >
                      <p className="Tsingle-enter">
                        {response.dateOfInaugration}
                      </p>
                    </Row>
                  </Col>
                  <Col xs={2} style={{ padding: "0 2px", margin: "0" }}>
                    <Row>
                      <p className="Tsingle-enter">Date of Charter</p>
                    </Row>
                    <Row
                      className="bordered-row-b"
                      style={{ padding: "5px 0", margin: "0" }}
                    >
                      <p className="Tsingle-enter">{response.dateOfCharter}</p>
                    </Row>
                  </Col>
                  <Col xs={4} style={{ padding: "0 2px", margin: "0" }}>
                    <Row>
                      <p className="Tsingle-enter">Name of Sponsoring Group</p>
                    </Row>
                    <Row
                      className="bordered-row-b"
                      style={{ padding: "5px 0", margin: "0" }}
                    >
                      <p className="Tsingle-enter">{response.sponsorGrpName}</p>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} style={{ padding: "0 2px", margin: "0" }}>
                    <Row style={{ textAlign: "center" }}>
                      <p className="Tsingle-enter">Name of the Group</p>
                    </Row>
                    <Row
                      className="bordered-row-b"
                      style={{ padding: "5px 0", margin: "0" }}
                    >
                      <h3 className="H3single-enter">{response.grpName}</h3>
                    </Row>
                  </Col>
                  <Col xs={4} style={{ padding: "0 2px", margin: "0" }}>
                    <Row style={{ textAlign: "center" }}>
                      <p className="Tsingle-enter">Group Number</p>
                    </Row>
                    <Row
                      className="bordered-row-b"
                      style={{ padding: "5px 0", margin: "0" }}
                    >
                      <p className="Tsingle-enter">{response.grpNumber}</p>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <p className="Tsingle-enter">Correspondance Address</p>
                    </Row>
                    <Row
                      className="bordered-row-b"
                      style={{
                        borderColor: "#000",
                        borderStyle: "solid",
                        borderWidth: "1px 1px 1px 1px",
                        height: "30px",
                      }}
                    >
                      <p
                        className="Tsingle-enter"
                        style={{ margin: "0", padding: "0" }}
                      >
                        {response.grpAddress}
                      </p>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} style={{ padding: "0 2px", margin: "0" }}>
                    <Row style={{ textAlign: "center" }}>
                      <p className="Tsingle-enter">Phone with STD</p>
                    </Row>
                    <Row
                      className="bordered-row-b"
                      style={{ padding: "5px 0", margin: "0" }}
                    >
                      <h3 className="H3single-enter">{response.grpPhone}</h3>
                    </Row>
                  </Col>
                  <Col xs={4} style={{ padding: "0 2px", margin: "0" }}>
                    <Row style={{ textAlign: "center" }}>
                      <p className="Tsingle-enter">Mobile</p>
                    </Row>
                    <Row
                      className="bordered-row-b"
                      style={{ padding: "5px 0", margin: "0" }}
                    >
                      <h3 className="H3single-enter">{response.grpMobile}</h3>
                    </Row>
                  </Col>
                  <Col xs={4} style={{ padding: "0 2px", margin: "0" }}>
                    <Row style={{ textAlign: "center" }}>
                      <p className="Tsingle-enter">Email</p>
                    </Row>
                    <Row
                      className="bordered-row-b"
                      style={{ padding: "5px 0", margin: "0" }}
                    >
                      <p className="Tsingle-enter">{response.grpEmail}</p>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "10px",
              }}
            >
              <Col>
                <Row>
                  <h3 className="H3single-enter">
                    Details of General Council Members and Managing Committee
                    Members for 2023 - 2025
                  </h3>
                </Row>
                <Row
                  style={{
                    marginTop: "2px",
                    marginBottom: "2px",
                  }}
                >
                  <Col xs={9}>
                    <p className="Tsingle-enter">
                      General Meeting for Election of out Group was held on Date
                    </p>
                  </Col>
                  <Col xs={3} className="bordered-col-b">
                    <p className="Tsingle-enter">
                      {response.electionMeetingDate}
                    </p>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: "2px",
                    marginBottom: "2px",
                  }}
                >
                  <Col xs={9}>
                    <p className="Tsingle-enter">
                      Following Office Bearers of our Group were elected on Date
                    </p>
                  </Col>
                  <Col xs={3} className="bordered-col-b">
                    <p className="Tsingle-enter">{response.electedOnDate}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={9}>
                    <Row>
                      <h4 className="H4single-enter">(1) President</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">{response.presName}</p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        height: "40px",
                      }}
                    >
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.presaddress} {response.presPinCode}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col xs={1} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.stdCode}</p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.presOffLandLine}
                        </p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.presResLandLine}
                        </p>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.presMobileNumber}
                        </p>
                      </Col>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Whatsapp No.</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.presWhatsappNumber}
                        </p>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">{response.presEmail}</p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">{response.presBirthDay}</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Occupation Details
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.presOccupation}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Spouse Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.presSpouseName}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Spouse Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.presSpouseBirthDay}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        style={{
                          borderColor: "#000",
                          borderStyle: "solid",
                          borderWidth: "1px 1px 1px 1px",
                          marginLeft: "6px",
                          maxWidth: "120px",
                          height: "140px",
                        }}
                      >
                        <img src={response.presPhoto} alt={response.presName} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "7px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Marraige Date</p>
                      </Col>
                      <Col xs={8} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.presMarraigeDate}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Signature</p>
                      </Col>
                      <Col
                        xs={8}
                        className="bordered-col-b"
                        style={{ height: "52px" }}
                      ></Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={9}>
                    <Row>
                      <h4 className="H4single-enter">
                        (2) Immediate Former President
                      </h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.immFormerPresName}
                        </p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        height: "40px",
                      }}
                    >
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.immFormerPresaddress}{" "}
                          {response.immFormerPresPinCode}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col xs={1} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.stdCode}</p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.immFormerPresOffLandLine}
                        </p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.immFormerPresResLandLine}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.immFormerPresMobileNumber}
                        </p>
                      </Col>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Whatsapp No.</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.immFormerPresWhatsappNumber}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.immFormerPresEmail}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.immFormerPresBirthDay}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Occupation Details
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.immFormerPresOccupation}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Spouse Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.immFormerPresSpouseName}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Spouse Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.immFormerPresSpouseBirthDay}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        style={{
                          borderColor: "#000",
                          borderStyle: "solid",
                          borderWidth: "1px 1px 1px 1px",
                          marginLeft: "6px",
                          maxWidth: "120px",
                          height: "140px",
                        }}
                      >
                        <img
                          src={response.immFormerPresPhoto}
                          alt={response.immFormerPresName}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "7px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Marraige Date</p>
                      </Col>
                      <Col xs={8} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.immFormerPresMarraigeDate}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Signature</p>
                      </Col>
                      <Col
                        xs={8}
                        className="bordered-col-b"
                        style={{ height: "52px" }}
                      ></Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="page"
            style={{
              textAlign: "center",
              padding: "0 15px",
              marginTop: "22px",
              pageBreakAfter: "always",
              textTransform: "capitalize",
            }}
          >
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "2px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={9}>
                    <Row>
                      <h4 className="H4single-enter">(3) Founder President</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.founderPresName}
                        </p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        height: "40px",
                      }}
                    >
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.founderPresaddress}{" "}
                          {response.founderPresPinCode}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col xs={1} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.stdCode}</p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.founderPresOffLandLine}
                        </p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.founderPresResLandLine}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.founderPresMobileNumber}
                        </p>
                      </Col>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Whatsapp No.</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.founderPresWhatsappNumber}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.founderPresEmail}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.founderPresBirthDay}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Occupation Details
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.founderPresOccupation}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Spouse Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.founderPresSpouseName}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Spouse Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.founderPresSpouseBirthDay}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        style={{
                          borderColor: "#000",
                          borderStyle: "solid",
                          borderWidth: "1px 1px 1px 1px",
                          marginLeft: "6px",
                          maxWidth: "120px",
                          height: "140px",
                        }}
                      >
                        <img
                          src={response.founderPresPhoto}
                          alt={response.founderPresName}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "7px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Marraige Date</p>
                      </Col>
                      <Col xs={8} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.founderPresMarraigeDate}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Signature</p>
                      </Col>
                      <Col
                        xs={8}
                        className="bordered-col-b"
                        style={{ height: "52px" }}
                      ></Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={9}>
                    <Row>
                      <h4 className="H4single-enter">
                        (4) Nominated Former President
                      </h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres1Name}
                        </p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        height: "40px",
                      }}
                    >
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres1address}{" "}
                          {response.nominatedFormerPres1PinCode}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col xs={1} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.stdCode}</p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          ({response.nominatedFormerPres1OffLandLine}
                        </p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          ({response.nominatedFormerPres1ResLandLine}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres1MobileNumber}
                        </p>
                      </Col>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Whatsapp No.</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres1WhatsappNumber}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres1Email}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres1BirthDay}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Occupation Details
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres1Occupation}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Spouse Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres1SpouseName}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Spouse Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres1SpouseBirthDay}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        style={{
                          borderColor: "#000",
                          borderStyle: "solid",
                          borderWidth: "1px 1px 1px 1px",
                          marginLeft: "6px",
                          maxWidth: "120px",
                          height: "140px",
                        }}
                      >
                        <img
                          src={response.nominatedFormerPres1Photo}
                          alt={response.nominatedFormerPres1Name}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "7px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Marraige Date</p>
                      </Col>
                      <Col xs={8} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres1MarraigeDate}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Signature</p>
                      </Col>
                      <Col
                        xs={8}
                        className="bordered-col-b"
                        style={{ height: "52px" }}
                      ></Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={9}>
                    <Row>
                      <h4 className="H4single-enter">
                        (5) Nominated Former President
                      </h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres2Name}
                        </p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        height: "40px",
                      }}
                    >
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres2address}{" "}
                          {response.nominatedFormerPres2PinCode}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col xs={1} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.stdCode}</p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          ({response.nominatedFormerPres2OffLandLine}
                        </p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          ({response.nominatedFormerPres2ResLandLine}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres2MobileNumber}
                        </p>
                      </Col>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Whatsapp No.</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres2WhatsappNumber}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres2Email}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres2BirthDay}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Occupation Details
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres2Occupation}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Spouse Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres2SpouseName}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Spouse Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres2SpouseBirthDay}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        style={{
                          borderColor: "#000",
                          borderStyle: "solid",
                          borderWidth: "1px 1px 1px 1px",
                          marginLeft: "6px",
                          maxWidth: "120px",
                          height: "140px",
                        }}
                      >
                        <img
                          src={response.nominatedFormerPres2Photo}
                          alt={response.nominatedFormerPres2Name}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "7px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Marraige Date</p>
                      </Col>
                      <Col xs={8} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres2MarraigeDate}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Signature</p>
                      </Col>
                      <Col
                        xs={8}
                        className="bordered-col-b"
                        style={{ height: "52px" }}
                      ></Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={9}>
                    <Row>
                      <h4 className="H4single-enter">
                        (6) Nominated Former President
                      </h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres3Name}
                        </p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        height: "40px",
                      }}
                    >
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres3address}{" "}
                          {response.nominatedFormerPres3PinCode}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col xs={1} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.stdCode}</p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          ({response.nominatedFormerPres3OffLandLine}
                        </p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          ({response.nominatedFormerPres3ResLandLine}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres3MobileNumber}
                        </p>
                      </Col>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Whatsapp No.</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres3WhatsappNumber}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres3Email}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres3BirthDay}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Occupation Details
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres3Occupation}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Spouse Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres3SpouseName}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Spouse Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres3SpouseBirthDay}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        style={{
                          borderColor: "#000",
                          borderStyle: "solid",
                          borderWidth: "1px 1px 1px 1px",
                          marginLeft: "6px",
                          maxWidth: "120px",
                          height: "140px",
                        }}
                      >
                        <img
                          src={response.nominatedFormerPres3Photo}
                          alt={response.nominatedFormerPres3Name}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "7px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Marraige Date</p>
                      </Col>
                      <Col xs={8} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.nominatedFormerPres3MarraigeDate}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Signature</p>
                      </Col>
                      <Col
                        xs={8}
                        className="bordered-col-b"
                        style={{ height: "52px" }}
                      ></Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="page"
            style={{
              textAlign: "center",
              padding: "0 15px",
              marginTop: "22px",
              pageBreakAfter: "always",
              textTransform: "capitalize",
            }}
          >
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "2px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={9}>
                    <Row>
                      <h4 className="H4single-enter">(7) Vice President</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">{response.vPresName}</p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        height: "40px",
                      }}
                    >
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.vPresaddress} {response.vPresPinCode}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col xs={1} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.stdCode}</p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.vPresOffLandLine}
                        </p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.vPresResLandLine}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.vPresMobileNumber}
                        </p>
                      </Col>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Whatsapp No.</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.vPresWhatsappNumber}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">{response.vPresEmail}</p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.vPresBirthDay}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Occupation Details
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.vPresOccupation}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Spouse Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.vPresSpouseName}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Spouse Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.vPresSpouseBirthDay}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        style={{
                          borderColor: "#000",
                          borderStyle: "solid",
                          borderWidth: "1px 1px 1px 1px",
                          marginLeft: "6px",
                          maxWidth: "120px",
                          height: "140px",
                        }}
                      >
                        <img
                          src={response.vPresPhoto}
                          alt={response.vPresName}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "7px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Marraige Date</p>
                      </Col>
                      <Col xs={8} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.vPresMarraigeDate}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Signature</p>
                      </Col>
                      <Col
                        xs={8}
                        className="bordered-col-b"
                        style={{ height: "52px" }}
                      ></Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={9}>
                    <Row>
                      <h4 className="H4single-enter">(8) Secretary</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">{response.secName}</p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        height: "40px",
                      }}
                    >
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.secaddress} {response.secPinCode}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col xs={1} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.stdCode}</p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.secOffLandLine}
                        </p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.secResLandLine}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.secMobileNumber}
                        </p>
                      </Col>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Whatsapp No.</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.secWhatsappNumber}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">{response.secEmail}</p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">{response.secBirthDay}</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Occupation Details
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.secOccupation}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Spouse Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.secSpouseName}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Spouse Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.secSpouseBirthDay}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        style={{
                          borderColor: "#000",
                          borderStyle: "solid",
                          borderWidth: "1px 1px 1px 1px",
                          marginLeft: "6px",
                          maxWidth: "120px",
                          height: "140px",
                        }}
                      >
                        <img src={response.secPhoto} alt={response.secName} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "7px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Marraige Date</p>
                      </Col>
                      <Col xs={8} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.secMarraigeDate}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Signature</p>
                      </Col>
                      <Col
                        xs={8}
                        className="bordered-col-b"
                        style={{ height: "52px" }}
                      ></Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={9}>
                    <Row>
                      <h4 className="H4single-enter">(9) Joint Secretary</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">{response.jtSecName}</p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        height: "40px",
                      }}
                    >
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.jtSecaddress} {response.jtSecPinCode}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col xs={1} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.stdCode}</p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.jtSecOffLandLine}
                        </p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.jtSecResLandLine}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.jtSecMobileNumber}
                        </p>
                      </Col>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Whatsapp No.</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.jtSecWhatsappNumber}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">{response.jtSecEmail}</p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.jtSecBirthDay}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Occupation Details
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.jtSecOccupation}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Spouse Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.jtSecSpouseName}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Spouse Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.jtSecSpouseBirthDay}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        style={{
                          borderColor: "#000",
                          borderStyle: "solid",
                          borderWidth: "1px 1px 1px 1px",
                          marginLeft: "6px",
                          maxWidth: "120px",
                          height: "140px",
                        }}
                      >
                        <img
                          src={response.jtSecPhoto}
                          alt={response.jtSecName}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "7px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Marraige Date</p>
                      </Col>
                      <Col xs={8} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.jtSecMarraigeDate}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Signature</p>
                      </Col>
                      <Col
                        xs={8}
                        className="bordered-col-b"
                        style={{ height: "52px" }}
                      ></Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={9}>
                    <Row>
                      <h4 className="H4single-enter">(10) Treasurer</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.treasurerName}
                        </p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        height: "40px",
                      }}
                    >
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.treasureraddress}{" "}
                          {response.treasurerPinCode}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col xs={1} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.stdCode}</p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.treasurerOffLandLine}
                        </p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.treasurerResLandLine}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.treasurerMobileNumber}
                        </p>
                      </Col>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Whatsapp No.</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.treasurerWhatsappNumber}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.treasurerEmail}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.treasurerBirthDay}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">
                          Occupation Details
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">
                          {response.treasurerOccupation}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Spouse Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">
                          {response.treasurerSpouseName}
                        </p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Spouse Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">
                          {response.treasurerSpouseBirthDay}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        style={{
                          borderColor: "#000",
                          borderStyle: "solid",
                          borderWidth: "1px 1px 1px 1px",
                          marginLeft: "6px",
                          maxWidth: "120px",
                          height: "140px",
                        }}
                      >
                        <img
                          src={response.treasurerPhoto}
                          alt={response.treasurerName}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "7px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Marraige Date</p>
                      </Col>
                      <Col xs={8} className="bordered-col-b">
                        <p className="Tsingle-enter">
                          {response.treasurerMarraigeDate}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={4} style={{ margin: "0", padding: "2px" }}>
                        <p className="Tsingle-enter-label">Signature</p>
                      </Col>
                      <Col
                        xs={8}
                        className="bordered-col-b"
                        style={{ height: "52px" }}
                      ></Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="page"
            style={{
              textAlign: "center",
              padding: "0 15px",
              marginTop: "22px",
              pageBreakAfter: "always",
              textTransform: "capitalize",
            }}
          >
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "2px 2px 2px 2px",
                textAlign: "left",
              }}
            >
              <h5>Managing Committee Members</h5>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={12}>
                    <Row
                      style={{
                        padding: "0",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      <h4 className="H4single-enter">(1)</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={2}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b">
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                      }}
                    >
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={10}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col
                        xs={1}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1} style={{ padding: "10px 4px 0px 4px" }}>
                        <p className="Tsingle-enter">Signature</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b" />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={12}>
                    <Row
                      style={{
                        padding: "0",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      <h4 className="H4single-enter">(2)</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={2}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b">
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                      }}
                    >
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={10}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col
                        xs={1}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1} style={{ padding: "10px 4px 0px 4px" }}>
                        <p className="Tsingle-enter">Signature</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b" />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={12}>
                    <Row
                      style={{
                        padding: "0",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      <h4 className="H4single-enter">(3)</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={2}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b">
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                      }}
                    >
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={10}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col
                        xs={1}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1} style={{ padding: "10px 4px 0px 4px" }}>
                        <p className="Tsingle-enter">Signature</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b" />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={12}>
                    <Row
                      style={{
                        padding: "0",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      <h4 className="H4single-enter">(4)</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={2}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b">
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                      }}
                    >
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={10}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col
                        xs={1}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1} style={{ padding: "10px 4px 0px 4px" }}>
                        <p className="Tsingle-enter">Signature</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b" />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={12}>
                    <Row
                      style={{
                        padding: "0",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      <h4 className="H4single-enter">(5)</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={2}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b">
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                      }}
                    >
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={10}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col
                        xs={1}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1} style={{ padding: "10px 4px 0px 4px" }}>
                        <p className="Tsingle-enter">Signature</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b" />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={12}>
                    <Row
                      style={{
                        padding: "0",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      <h4 className="H4single-enter">(6)</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={2}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b">
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                      }}
                    >
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={10}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col
                        xs={1}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1} style={{ padding: "10px 4px 0px 4px" }}>
                        <p className="Tsingle-enter">Signature</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b" />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={12}>
                    <Row
                      style={{
                        padding: "0",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      <h4 className="H4single-enter">(7)</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={2}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b">
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                      }}
                    >
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={10}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col
                        xs={1}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1} style={{ padding: "10px 4px 0px 4px" }}>
                        <p className="Tsingle-enter">Signature</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b" />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <Col xs={12}>
                    <Row
                      style={{
                        padding: "0",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      <h4 className="H4single-enter">(8)</h4>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={2}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1}>
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b">
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                      }}
                    >
                      <Col xs={2}>
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={10}>
                        <p className="Tsingle-enter"></p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">Phone</p>
                      </Col>
                      <Col
                        xs={1}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(O)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col
                        xs={1}
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter-label">(R)</p>
                      </Col>
                      <Col
                        xs={2}
                        className="bordered-col-b"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                      >
                        <p className="Tsingle-enter"></p>
                      </Col>
                      <Col xs={1} style={{ padding: "10px 4px 0px 4px" }}>
                        <p className="Tsingle-enter">Signature</p>
                      </Col>
                      <Col xs={2} className="bordered-col-b" />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col xs={12}>
                <Row
                  style={{
                    marginTop: "10px",
                    marginBottom: "0",
                    textAlign: "right",
                  }}
                >
                  <Col xs={6}></Col>
                  <Col xs={6}>
                    <p className="Tsingle-enter">
                      For Jain Social Group {response.grpName}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: "22px", marginBottom: "0" }}>
                  <Col>
                    <p className="Tsingle-enter">Date</p>
                  </Col>
                  <Col>
                    <p className="Tsingle-enter">President</p>
                  </Col>
                  <Col>
                    <p className="Tsingle-enter">Secretary</p>
                  </Col>
                  <Col>
                    <p className="Tsingle-enter">Joint Secretary</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                borderColor: "#000",
                borderStyle: "solid",
                borderWidth: "0px 2px 2px 2px",
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <p className="Tsingle-enter">Note: </p>
              <ol className="Lsingle-enter">
                <li>
                  Please Full up complete details of Office Bearers & Managing
                  Committee Members
                </li>
                <li>
                  To be signed by President OR Secretary & Joint Secretary of
                  the Group
                </li>
                <li>
                  Please Ensure the receipt of this Form to Federation Office on
                  or before 20th January 2023
                </li>
              </ol>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupFormBResponse;
