import React, { useEffect, useState } from "react";
import { Row, Col, Button, Image, Container } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Images from "../../images/index";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import "../Response.css";
import SecurePageSection from "../../SecurePageSection";

function GroupMangalyatraResponse() {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponses] = useState([]);
  const responseId = useParams();

  const getResponse = async () => {
    const res = await axios.get(`api/forms/grp-mangalyatra/${responseId.id}/`);
    setResponses(res.data);
  };

  useEffect(() => {
    getResponse();
  }, []);

  const navigate = useNavigate();

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("", "blank", "height=1123,width=800,scrollbars=yes,");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>${response.regName} ${response.grpName} ${response.eDate}</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    html {
      overflow-y: auto;
    }

    fResponseBody {
      padding: 20px 20px;
      margin: 0px 20px;
      width: 800px;
      height: 1123px;
      background: #fff;
      overflow-y: auto;
    }

    .page{
      page-break-after: always;
    }

    .fResponseBody {
      padding: 20px 20px;
      width: 800px;
      min-height: 1123px;
      background: #fff;
    }
    
    .page{
      page-break-after: always;
    }
    
    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }
    
    
    .section2, .section3{
      margin-top: 30px;
    }
    
    .section4{
      margin-top: 20px;
    }
    
    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }
    
    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }
    
    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }
    
    
    .bordered-mid-row{
      padding: 2px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }
    
    .bordered-row-b{
      padding: 2px 0;
      border: 1px solid #000;
      height: 30px;
    }
    
    .bordered-col-b{
      padding: 2px 5px;
      border: 1px solid #000;
      height: 30px;
    }
    
    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }
    
    
    .bannerText {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    
    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }
    
    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }
    
    .toptext {
      margin: 20px 0;
    }
    
    .logoImage {
      height: 80px;
      margin: 10px;
    }
    
    .bannerYellow {
      background: #face1d;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }
    
    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }
    
    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }
    
    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    
    .H2double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 16px;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    
    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
    
    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
    
    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }
    
    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }
    
    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }
    
    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }
    
    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }
    
    .Tsingle2 {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 0;
    }
    
    .Tdouble2 {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }
    
    .btn-approve-hover{
      margin: 0 5px;
      background-color: #83bb00 ;
      border: 2px solid #000;
    }
    
    
    .btn-reject-hover{
      margin: 0 5px;
      background-color: #ff0000 ;
      border: 2px solid #000;
    }
    
    .btn-approve-hover:hover{
      background-color: #83bb00 ;
      transform: scale(1.1);
      transition: 500ms;
      border: 2px solid #000;
      cursor: var(--pointer);
    }
    
    .btn-reject-hover:hover{
      background-color: #ff0000 ;
      transform: scale(1.1);
      transition: 500ms;
      border: 2px solid #000;
      cursor: var(--pointer);
    }
    `);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
    }, 1000);
  };

  return (
    <div>
      <Container>
        <h2 style={{ textAlign: "center" }}>Group Mangalyatra Response</h2>
        <Row style={{ marginBottom: "1em", marginTop: "1em" }}>
          <Col className="d-flex justify-content-end">
            <Button onClick={printDocument} variant="primary">
              Download Form
            </Button>
          </Col>
        </Row>
        <div id="mainForm" className="centerFormat">
          <div className="fResponseBody">
            <div className="bannerYellow">
              <Row className="T-Banner">
                <Col xs={1}>
                  <img src={Images.LogoB} className="logoImage" alt="jsgBanner"></img>
                </Col>
                <Col xs={11} className="bannerText text-center">
                  <h1>JAIN SOCIAL GROUP INT. FEDERATION</h1>
                  <h2>{response.gName}</h2>
                </Col>
              </Row>
            </div>

            <Row>
              <Col>
                <p className="Tdouble2">President/ Secretary Email : {response.chairEmail}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <p className="Tsingle2">Name </p>
              </Col>
              <Col xs={1}>
                <p className="Tsingle2">
                  <span className="response2">:</span>
                </p>
              </Col>
              <Col>
                <p className="Tsingle2">
                  <span className="response2">{response.name}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <p className="Tsingle2">Cell</p>
              </Col>
              <Col xs={1}>
                <p className="Tsingle2">
                  <span className="response2">:</span>
                </p>
              </Col>
              <Col>
                <p className="Tsingle2">
                  <span className="response2">{response.mobile}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <p className="Tsingle2">Name of Group:</p>
              </Col>
              <Col xs={1}>
                <p className="Tsingle2">
                  <span className="response2">:</span>
                </p>
              </Col>
              <Col>
                <p className="Tsingle2">
                  <span className="response2">{response.grpName}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <p className="Tsingle2">Region Name:</p>
              </Col>
              <Col xs={1}>
                <p className="Tsingle2">
                  <span className="response2">:</span>
                </p>
              </Col>

              <Col>
                <p className="Tsingle2">
                  <span className="response2">{response.regName}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <p className="Tsingle2">Event Name:</p>
              </Col>
              <Col xs={1}>
                <p className="Tsingle2">
                  <span className="response2">:</span>
                </p>
              </Col>

              <Col>
                <p className="Tsingle2">
                  <span className="response2">{response.eName}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <p className="Tsingle2">Event Date:</p>
              </Col>
              <Col xs={1}>
                <p className="Tsingle2">
                  <span className="response2">:</span>
                </p>
              </Col>

              <Col>
                <p className="Tsingle2">
                  <span className="response2">{moment(response.eDate).format("DD-MM-YYYY")}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <p className="Tsingle2">Event Time:</p>
              </Col>
              <Col xs={1}>
                <p className="Tsingle2">
                  <span className="response2">:</span>
                </p>
              </Col>

              <Col>
                <p className="Tsingle2">
                  <span className="response2">{response.eTime}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <p className="Tsingle2">Event Venue:</p>
              </Col>
              <Col xs={1}>
                <p className="Tsingle2">
                  <span className="response2">:</span>
                </p>
              </Col>

              <Col>
                <p className="Tsingle2">
                  <span className="response2">{response.eVenue}</span>
                </p>
              </Col>
            </Row>

            <Row className="text-center">
              <h2 className="Hdouble-enter text-center">JSGIF Dignitaries:</h2>
            </Row>

            <Row>
              {response.fedDigName1 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    1. <span className="response2">JSGian {response.fedDigName1}</span> <br />
                    JSGIF {response.fedDigPost1}
                  </p>
                </Col>
              ) : null}

              {response.fedDigName6 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    6. <span className="response2">JSGian {response.fedDigName6}</span> <br />
                    JSGIF {response.fedDigPost6}
                  </p>
                </Col>
              ) : null}
              {response.fedDigName2 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    2. <span className="response2">JSGian {response.fedDigName2}</span> <br />
                    JSGIF {response.fedDigPost2}
                  </p>
                </Col>
              ) : null}
              {response.fedDigName7 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    7. <span className="response2">JSGian {response.fedDigName7}</span> <br />
                    JSGIF {response.fedDigPost7}
                  </p>
                </Col>
              ) : null}
              {response.fedDigName3 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    3. <span className="response2">JSGian {response.fedDigName3}</span> <br />
                    JSGIF {response.fedDigPost3}
                  </p>
                </Col>
              ) : null}
              {response.fedDigName8 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    8. <span className="response2">JSGian {response.fedDigName8}</span> <br />
                    JSGIF {response.fedDigPost8}
                  </p>
                </Col>
              ) : null}
              {response.fedDigName4 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    4. <span className="response2">JSGian {response.fedDigName4}</span> <br />
                    JSGIF {response.fedDigPost4}
                  </p>
                </Col>
              ) : null}
              {response.fedDigName9 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    9. <span className="response2">JSGian {response.fedDigName9}</span> <br />
                    JSGIF {response.fedDigPost9}
                  </p>
                </Col>
              ) : null}
              {response.fedDigName5 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    5. <span className="response2">JSGian {response.fedDigName5}</span> <br />
                    JSGIF {response.fedDigPost5}
                  </p>
                </Col>
              ) : null}
              {response.fedDigName10 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    10. <span className="response2">JSGian {response.fedDigName10}</span> - JSGIF{" "}
                    {response.fedDigPost10}
                  </p>
                </Col>
              ) : null}
            </Row>

            <Row className="text-center">
              <h2 className="Hdouble-enter text-center">JSGIF Region Dignitaries:</h2>
            </Row>

            <Row>
              {response.regDigName1 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    1. <span className="response2">JSGian {response.regDigName1}</span> <br />
                    JSGIF {response.regDigPost1}
                  </p>
                </Col>
              ) : null}
              {response.regDigName6 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    6. <span className="response2">JSGian {response.regDigName6}</span> <br />
                    JSGIF {response.regDigPost6}
                  </p>
                </Col>
              ) : null}
              {response.regDigName2 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    2. <span className="response2">JSGian {response.regDigName2}</span> <br />
                    JSGIF {response.regDigPost2}
                  </p>
                </Col>
              ) : null}
              {response.regDigName7 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    7. <span className="response2">JSGian {response.regDigName7}</span> <br />
                    JSGIF {response.regDigPost7}
                  </p>
                </Col>
              ) : null}
              {response.regDigName3 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    3. <span className="response2">JSGian {response.regDigName3}</span> <br />
                    JSGIF {response.regDigPost3}
                  </p>
                </Col>
              ) : null}
              {response.regDigName8 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    8. <span className="response2">JSGian {response.regDigName8}</span> <br />
                    JSGIF {response.regDigPost8}
                  </p>
                </Col>
              ) : null}

              {response.regDigName4 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    4. <span className="response2">JSGian {response.regDigName4}</span> <br />
                    JSGIF {response.regDigPost4}
                  </p>
                </Col>
              ) : null}
              {response.regDigName9 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    9. <span className="response2">JSGian {response.regDigName9}</span> <br />
                    JSGIF {response.regDigPost9}
                  </p>
                </Col>
              ) : null}

              {response.regDigName5 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    5. <span className="response2">JSGian {response.regDigName5}</span> <br />
                    JSGIF {response.regDigPost5}
                  </p>
                </Col>
              ) : null}
              {response.regDigName10 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    10. <span className="response2">JSGian {response.regDigName10}</span> - JSGIF{" "}
                    {response.regDigPost10}
                  </p>
                </Col>
              ) : null}
            </Row>

            <Row className="text-center">
              <h2 className="Hdouble-enter text-center">Other VIP / Dignitaries:</h2>
            </Row>

            <Row>
              {response.vipDigName1 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    1. <span className="response2">JSGian {response.vipDigName1}</span> <br />
                    JSGIF {response.vipDigPost1}
                  </p>
                </Col>
              ) : null}
              {response.vipDigName4 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    4. <span className="response2">JSGian {response.vipDigName4}</span> <br />
                    JSGIF {response.vipDigPost4}
                  </p>
                </Col>
              ) : null}
              {response.vipDigName2 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    2. <span className="response2">JSGian {response.vipDigName2}</span> <br />
                    JSGIF {response.vipDigPost2}
                  </p>
                </Col>
              ) : null}
              {response.vipDigName5 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    5. <span className="response2">JSGian {response.vipDigName5}</span> <br />
                    JSGIF {response.vipDigPost5}
                  </p>
                </Col>
              ) : null}
              {response.vipDigName3 ? (
                <Col xs={6}>
                  <p className="Tsingle2">
                    3. <span className="response2">JSGian {response.vipDigName3}</span> <br />
                    JSGIF {response.vipDigPost3}
                  </p>
                </Col>
              ) : null}
            </Row>

            <Row className="text-center">
              <h2 className="Hdouble-enter text-center">Event Details:</h2>
            </Row>

            <div
              style={{
                height: "auto",
                width: "100%",
                padding: "0px 10px",
                wordWrap: "break-word",
              }}
            >
              <p className="Tsingle2" style={{ marginBottom: 0 }}>
                {response.eDetails}
              </p>
            </div>

            <Row>
              <Col>
                <Image fluid src={response.ePhoto1} className="mangalyatraPhoto" />
              </Col>

              <Col>
                <Image fluid src={response.ePhoto2} className="mangalyatraPhoto" />
              </Col>

              <Col>
                <Image fluid src={response.ePhoto3} className="mangalyatraPhoto" />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default GroupMangalyatraResponse;
