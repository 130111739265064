import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Images from "../../../images/index";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import "../../Response.css";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import { saveAs } from "file-saver";

function RegQ5Response() {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponses] = useState([]);
  const responseId = useParams();

  const [formA, setFormA] = useState(null);
  const [formB, setFormB] = useState(null);
  const [dues, setDues] = useState(null);

  const ref = React.createRef();

  const getResponse = async () => {
    const res = await axios.get(
      `/api/forms/ventures/eminence-q5-region/${responseId.id}`
    );
    console.log(res.data);
    setResponses(res.data);

    const { formA, formB, duePending } = res.data;

    setFormA(formA);
    setFormB(formB);
    setDues(duePending);
  };

  useEffect(() => {
    getResponse();
  }, []);

  const navigate = useNavigate();

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("", "blank", "height=1123,width=800,scrollbars=yes,");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>Eminence Region Q1</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    html {
      overflow-y: auto;
    }

    .fResponseBody {
      padding: 20px 20px;
      width: 800px;
      min-height: 1123px;
      background: #fff;
    }

    .page{
      page-break-after: always;
    }

    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }

    .section2, .section3{
      margin-top: 30px;
    }

    .section4{
      margin-top: 20px;
    }

    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }

    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }

    .bordered-mid-row{
      padding: 2px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-row-b{
      padding: 2px 0;
      border: 1px solid #000;
      height: 30px;
    }

    .bordered-col-b{
      padding: 2px 5px;
      border: 1px solid #000;
      height: 30px;
    }

    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }

    .bannerText {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }

    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }

    .toptext {
      margin: 20px 0;
    }

    .logoImage {
      height: 80px;
      margin: 10px;
    }

    .bannerYellow {
      background: #face1d;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }

    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }

    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }

    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }

    .H2double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 16px;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }

    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }

    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }

    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }

    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }

    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }`);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
    }, 1000);
  };

  const mainFormRef = useRef(null);

  return (
    <div>
      <Row style={{ marginBottom: "1em", marginTop: "1em" }}>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => printDocument()}
            style={{ margin: "0 5px", marginBottom: "20px" }}
          >
            Download Form
          </Button>
          <h2>Eminence 5th Quarter Application (Region)</h2>
        </Col>
      </Row>

      <div id="mainForm" ref={mainFormRef} className="centerFormat">
        <div className="fResponseBody">
          <div className="page">
            <div className="bannerYellow">
              <Row className="T-Banner">
                <Col xs={1}>
                  <img
                    src={Images.LogoB}
                    className="logoImage"
                    alt="jsgBanner"
                  ></img>
                </Col>
                <Col xs={11} className="bannerText text-center">
                  <h1
                    style={{
                      margin: "0",
                    }}
                  >
                    JAIN SOCIAL GROUP INT. FEDERATION
                  </h1>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    4-O/P, Vijay Chambers, Opp. Dreamland Cinema, Tribhuvan
                    Road, Mumbai - 400 004. (India)
                  </p>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Tel.: 022-23870724 | E-mail : office@jsgif.co.in
                  </p>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "18px",
                      margin: "0",
                      fontWeight: "600",
                    }}
                  >
                    Eminence 5th Quarter Application (Region)
                  </p>
                </Col>
              </Row>
            </div>
            <Row>
              <h6
                style={{
                  fontWeight: "700",
                  textTransform: "capitalize",
                  marginBottom: "5px",
                }}
              >
                Region - {response.regName}
              </h6>
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <h3 className="H3single-enter">Respondant's name</h3>
                <p className="Tsingle-enter">{response.name}</p>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <h3 className="H3single-enter">Respondant's Email</h3>
                <p className="Tsingle-enter">{response.email}</p>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <h3 className="H3single-enter">Region Chariman name</h3>
                <p className="Tsingle-enter">{response.regChairName}</p>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <h3 className="H3single-enter">Region Chairman Number</h3>
                <p className="Tsingle-enter">{response.chairNum}</p>
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col style={{ textAlign: "left" }}>
                <h3 className="H3single-enter">Timings</h3>
                <p className="Tsingle-enter">{response.timings}</p>
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col style={{ textAlign: "left" }}>
                <h3 className="H3single-enter">Subject + Theme + Protocol</h3>
                <p className="Tsingle-enter">{response.protocol}</p>
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col style={{ textAlign: "left" }}>
                <h3 className="H3single-enter">
                  Your presence on MANGALYATRA, FB. JSGIF, Insta JSGIF, YouTube
                  JSGIF & Zoom meeting of Eminence for your region.
                </h3>
                <p className="Tsingle-enter">{response.mangalyatra}</p>
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col style={{ textAlign: "left" }}>
                <h3 className="H3single-enter">
                  Eminence form upto 4th QTR, If yes how many QTRS.
                </h3>
                <p className="Tsingle-enter">{response.eminence}</p>
              </Col>
            </Row>

            <Row>
              <h3>4. Your collaboration with</h3>
            </Row>
            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  (A) Another Region & JSGIF.
                </h3>
                <p className="Tsingle-enter">{response.collaborationRegion}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  (B) Have you invited any O/B'S of another Region / JSGIF in
                  your Region meeting.
                </h3>
                <p className="Tsingle-enter">{response.collaborationOB}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  (C) Have you attended any such meeting of another Region /
                  JSGIF.
                </h3>
                <p className="Tsingle-enter">{response.collaborationMeet}</p>
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col style={{ textAlign: "left" }}>
                <h3 className="H3single-enter">
                  Eminence form upto 4th QTR, If yes how many QTRS.Your
                  Attendance / participation in Dream projects of Amish Bhai i.e
                  JSGIF Aashray & Sub-Projets, Udaan, Pari, Anmol... All
                  committees.
                </h3>
                <p className="Tsingle-enter">{response.jsgActivity}</p>
              </Col>
            </Row>

            <Row>
              <h3>6. Getting friendly with technology</h3>
            </Row>
            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  (A) How many groups of your Region have filled form A + B.
                </h3>
                <p className="Tsingle-enter">{response.techForm}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  (B) Online payment of JSGIF.
                </h3>
                <p className="Tsingle-enter">{response.techpayment}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  (C) Communication (Email to Region / JSGIF WhatsApp group
                  within your group, electronic circular i.e is on email &
                  whatsapp.
                </h3>
                <p className="Tsingle-enter">{response.techcommunication}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  (D) Your own FB page | Insta page YouTube Channel of your
                  JSGIF region .
                </h3>
                <p className="Tsingle-enter">{response.techsocial}</p>
              </Col>
            </Row>

            <Row style={{ marginTop: "20px" }}>
              {(() => {
                if (
                  response.picEvent1 !== null ||
                  response.subEvent1 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 1</h3>
                      <h4 className="H4single-enter">{response.subEvent1}</h4>
                      <Image
                        src={response.picEvent1}
                        alt={response.subEvent1}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent2 !== null ||
                  response.subEvent2 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 2</h3>
                      <h4 className="H4single-enter">{response.subEvent2}</h4>
                      <Image
                        src={response.picEvent2}
                        alt={response.subEvent2}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent3 !== null ||
                  response.subEvent3 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 3</h3>
                      <h4 className="H4single-enter">{response.subEvent3}</h4>
                      <Image
                        src={response.picEvent3}
                        alt={response.subEvent3}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent4 !== null ||
                  response.subEvent4 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 4</h3>
                      <h4 className="H4single-enter">{response.subEvent4}</h4>
                      <Image
                        src={response.picEvent4}
                        alt={response.subEvent4}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent5 !== null ||
                  response.subEvent5 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 5</h3>
                      <h4 className="H4single-enter">{response.subEvent5}</h4>
                      <Image
                        src={response.picEvent5}
                        alt={response.subEvent5}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent6 !== null ||
                  response.subEvent6 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 6</h3>
                      <h4 className="H4single-enter">{response.subEvent6}</h4>
                      <Image
                        src={response.picEvent6}
                        alt={response.subEvent6}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent7 !== null ||
                  response.subEvent7 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 7</h3>
                      <h4 className="H4single-enter">{response.subEvent7}</h4>
                      <Image
                        src={response.picEvent7}
                        alt={response.subEvent7}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent8 !== null ||
                  response.subEvent8 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 8</h3>
                      <h4 className="H4single-enter">{response.subEvent8}</h4>
                      <Image
                        src={response.picEvent8}
                        alt={response.subEvent4}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent9 !== null ||
                  response.subEvent9 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 9</h3>
                      <h4 className="H4single-enter">{response.subEvent9}</h4>
                      <Image
                        src={response.picEvent9}
                        alt={response.subEvent9}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent10 !== null ||
                  response.subEvent10 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 10</h3>
                      <h4 className="H4single-enter">{response.subEvent10}</h4>
                      <Image
                        src={response.picEvent10}
                        alt={response.subEvent10}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegQ5Response;
