import React, { useState, useEffect, useRef } from "react";
import "./Veer.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Veer() {
  const [isChatbotVisible, setChatbotVisible] = useState(false);
  const [userInputValue, setUserInputValue] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [regCounter, setRegCounter] = useState(0);

  const [name, setName] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");

  const [regions, setRegions] = useState([]);
  const [groups, setGroups] = useState([]);

  const [regionOptions, setRegionOptions] = useState([]);

  const chatHistoryRef = useRef();

  const updateChatHistoryRef = (history) => {
    chatHistoryRef.current = history;
  };

  const navigate = useNavigate();

  const navigator = (link) => {
    navigate(link);
  };

  function getSelectedRegion(value) {
    setSelectedRegion(value);
  }

  async function fetchRegions() {
    await axios({
      method: "get",
      url: "/user/region/",
    })
      .then(function(response) {
        setRegions(response.data);
        const options = response.data.map((region) => ({
          text: `${region.region}`,
          nextStepId: `c3-regRedirect ${region.region}`,
        }));

        setRegionOptions(options);
      })
      .catch(function(error) {});
  }

  const fetchGroups = async (selectedRegion) => {
    try {
      const response = await axios.get(`/user/${selectedRegion}/groups`);
      setGroups(response.data);

      const options = response.data.map((group) => ({
        value: group.group,
        label: group.group,
        trigger: "handleGroupSelection",
      }));

      setGroups(options);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  useEffect(() => {
    if (selectedRegion) {
      fetchGroups(selectedRegion);
    }
  }, [selectedRegion]);

  function getName(userInputValue) {
    setName(userInputValue);
  }

  useEffect(() => {
    if (isChatbotVisible) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory, isChatbotVisible]);

  useEffect(() => {
    fetchRegions();
  }, [regCounter]);

  useEffect(() => {
    if (isChatbotVisible && questionAnswerPairs.length > 0 && currentQuestionIndex === 0) {
      showQuestion();
    }
  }, [isChatbotVisible, currentQuestionIndex]);

  const showQuestion = () => {
    const currentQuestion = questionAnswerPairs[currentQuestionIndex];
    setChatHistory((prevChatHistory) => [
      ...prevChatHistory,
      { user: false, message: currentQuestion.message, isOption: false },
    ]);

    if (currentQuestion.options) {
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory,
        {
          user: false,

          options: renderOptions(currentQuestion.options),
          isOption: true,
        },
      ]);
    }
  };

  const renderOptions = (options) => {
    return (
      <div className="options-container">
        {options.map((option) => (
          <button
            key={option.text}
            value={option.text}
            className="option-button"
            onClick={() => handleOptionClick(option)}
          >
            {option.text}
          </button>
        ))}
      </div>
    );
  };

  const TypingEffect = ({ text, speed }) => {
    const [typedText, setTypedText] = useState("");

    useEffect(() => {
      let i = 0;
      const typingInterval = setInterval(() => {
        setTypedText((prevText) => {
          if (i >= text.length) {
            clearInterval(typingInterval);
            return prevText;
          }
          const char = text.charAt(i);
          i++;
          return prevText + char;
        });
      }, speed);

      return () => {
        clearInterval(typingInterval);
      };
    }, []);

    return <span className="typed">{typedText}</span>;
  };

  const renderMessage = (message, index) => {
    const { user, message: content } = message;

    return (
      <div className="message">
        {user ? (
          <>
            <div className="user-message message-text">{content}</div>
            <div className="user-icon">
              <i className="fa-solid fa-user"></i>
            </div>
          </>
        ) : (
          <>
            <div className="bot-icon">
              <i className="fa-solid fa-robot"></i>
            </div>

            <div className="bot-message message-text">{content}</div>
          </>
        )}
      </div>
    );
  };

  const handleOptionClick = (option) => {
    setChatHistory((prevChatHistory) => {
      const updatedChatHistory = prevChatHistory.map((message) => {
        if (message.isOption) {
          // Remove the options from the latest step message
          return { ...message, options: undefined };
        }
        return message;
      });

      // Add the user's option selection to the chat history
      updatedChatHistory.push({ user: true, message: option.text });

      return updatedChatHistory;
    });

    // Proceed to the next step if the selected option has a nextStepId
    if (option.nextStepId) {
      setUserInputValue(""); // Clear the user input value first
      proceedToNextStep(option.nextStepId);
    }
  };

  // useEffect(() => {
  // }, [chatHistory]);

  const proceedToNextStep = (nextStepId) => {
    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setUserInputValue("");
      handleUserResponse(nextStepId);
    }, 500);
  };

  const handleUserResponse = (nextStepId) => {
    const nextQuestion = questionAnswerPairs.find((pair) => pair.id === nextStepId);

    if (nextQuestion) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setUserInputValue("");

      if (nextQuestion.onStepFunction) {
        nextQuestion.onStepFunction(userInputValue); // Pass the userInputValue as an argument
      }

      setChatHistory((prevChatHistory) => {
        const updatedChatHistory = [...prevChatHistory];
        const userMessageIndex = updatedChatHistory.findIndex((message) => message.user);

        if (userMessageIndex !== -1) {
          // Update the existing user message with the input value
          updatedChatHistory[userMessageIndex].message = userInputValue;
        } else {
          // Add the user's input as a new user message
          updatedChatHistory.push({
            user: true,
            message: userInputValue,
            isOption: false,
          });
        }

        return updatedChatHistory;
      });

      if (!nextQuestion.options) {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          { user: false, message: nextQuestion.message },
        ]);
      }

      if (nextQuestion.options) {
        setTimeout(() => {
          setChatHistory((prevChatHistory) => [
            ...prevChatHistory,
            {
              user: false,
              message: nextQuestion.message,
              options: renderOptions(nextQuestion.options),
              isOption: true,
            },
          ]);
        }, 500);
      }

      // Call the next step after a slight delay
      proceedToNextStep(nextQuestion.nextStepId);
    }
  };

  const handleSendMessage = () => {
    if (userInputValue.trim() !== "") {
      const currentQuestion = questionAnswerPairs[currentQuestionIndex];

      if (currentQuestion.userInput) {
        setUserInputValue(""); // Clear the user input value first
        handleUserResponse(currentQuestion.nextStepId);
      } else {
        setUserInputValue(""); // Clear the user input value first
        handleUserResponse(userInputValue.trim());
      }
    }
  };

  const toggleChatbotVisibility = () => {
    setChatbotVisible((prevState) => !prevState);
  };

  // Function to capture the contents of the latest user message
  // Function to capture the latest user message
  const chatHistoryReff = useRef([]);

  useEffect(() => {
    chatHistoryReff.current = chatHistory;
  }, [chatHistory]);

  const captureSelectedRegion = async () => {
    const userMessages = chatHistoryReff.current.filter((message) => message.user);

    const latestMessage = userMessages[userMessages.length - 1];

    const region = latestMessage?.message;

    setSelectedRegion(region);
  };

  const questionAnswerPairs = [
    {
      id: "start",
      message: "Welcome to JSGIF! My name is Veer. May I know your name?",
      userInput: true,
      nextStepId: "storeName",
    },
    {
      id: "storeName",
      onStepFunction: () => getName(),
      nextStepId: "mainMenu",
    },
    {
      id: "mainMenu",
      message: `Hi JSGian ${userInputValue}! How may I assist you?`,
      options: [
        { text: "Details regarding JSGIF", nextStepId: "c1" },
        { text: "Downloads", nextStepId: "c2" },
        { text: "Details about your Region", nextStepId: "c3" },
        { text: "Ventures", nextStepId: "c8" },
        // { text: "Details about your Group", nextStepId: "c4" },
        { text: "Forms", nextStepId: "c5" },
        { text: "Sangini", nextStepId: "c6" },
        { text: "Contact Office", nextStepId: "c7" },
      ],
    },
    {
      id: "mainMenu2",
      message: `How may I further assist you?`,
      options: [
        { text: "Details regarding JSGIF", nextStepId: "c1" },
        { text: "Downloads", nextStepId: "c2" },
        { text: "Details about your Region", nextStepId: "c3" },
        { text: "Ventures", nextStepId: "c8" },
        // { text: "Details about your Group", nextStepId: "c4" },
        { text: "Forms", nextStepId: "c5" },
        { text: "Sangini", nextStepId: "c6" },
        { text: "Contact Office", nextStepId: "c7" },
      ],
    },

    // C1 JSGIF Details
    {
      id: "c1",
      message: "Choose any one of the following",
      options: [
        { text: "About JSGIF", nextStepId: "c1-jsgAbout" },
        { text: "JSGIF Bank Details", nextStepId: "c1-jsgBank" },
        { text: "JSGIF Office Bearers", nextStepId: "c1-jsgOB" },
        { text: "JSGIF International Directors", nextStepId: "c1-jsgID" },
        { text: "Former Presidents of JSGIF", nextStepId: "c1-jsgFP" },
        { text: "Go back to Main menu", nextStepId: "mainMenu2" },
      ],
    },
    {
      id: "c1-jsgAbout",
      message: "You have been redirected to the About Us page",
      onStepFunction: () => navigator("aboutus"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c1-jsgBank",
      message: "You have been redirected to the Bank Details page",
      onStepFunction: () => navigator("fed/bank-details"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c1-jsgOB",
      message: "You have been redirected to the JSGIF Office Bearers page",
      onStepFunction: () => navigator("fed/ob/2023-25"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c1-jsgID",
      message: "You have been redirected to the JSGIF International Directors Page",
      onStepFunction: () => navigator("fed/id/2023-25"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c1-jsgFP",
      message: "You have been redirected to the JSGIF Former Presidents page",
      onStepFunction: () => navigator("former-presidents"),
      nextStepId: "mainMenu2",
    },

    // C2 JSGIF Details
    {
      id: "c2",
      message: "Choose any one of the following",
      options: [
        { text: "Oath of Office", nextStepId: "c2-oathOfOffice" },
        { text: "Kartavya", nextStepId: "c2-kartavya" },
        { text: "Samvid", nextStepId: "c2-Samvid" },
        { text: "Mangalyatra", nextStepId: "c2-mangalyatra" },
        { text: "Zoom Background", nextStepId: "c2-zoomBackground" },
        { text: "Banners", nextStepId: "c2-zoomBackground" },
        { text: "Go back to Main menu", nextStepId: "mainMenu2" },
      ],
    },
    {
      id: "c2-oathOfOffice",
      message: "You can download the Oath of Office from the redirected page",
      onStepFunction: () => navigator("oath-of-office"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2-kartavya",
      message: "You can download the Kartavya Book from the redirected page",
      onStepFunction: () => navigator("kartavya"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2-Samvid",
      message: "You can download the Samvid Book from the redirected page",
      onStepFunction: () => navigator("samvid"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2-mangalyatra",
      message: "You can download the Mangalyatra from the redirected page",
      onStepFunction: () => navigator("mangalyatra"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2-zoomBackground",
      message: "Which Zoom Background do you want to download?",
      options: [
        { text: "Default Background", nextStepId: "c2.1zoomBg-default" },
        { text: "Sangini", nextStepId: "c2.1zoomBg-sangini" },
        { text: "Aashray", nextStepId: "c2.1zoomBg-aashray" },
        { text: "Ask JSG", nextStepId: "c2.1zoomBg-askJSG" },
        {
          text: "Constitution & Bylaws",
          nextStepId: "c2.1zoomBg-constitution",
        },
        { text: "Eminence", nextStepId: "c2.1zoomBg-eminence" },
        { text: "Gurukul", nextStepId: "c2.1zoomBg-gurukul" },
        { text: "Pari", nextStepId: "c2.1zoomBg-pari" },
        { text: "Runbhoomi", nextStepId: "c2.1zoomBg-runbhoomi" },
        { text: "Shrot", nextStepId: "c2.1zoomBg-shrot" },
        { text: "Udaan", nextStepId: "c2.1zoomBg-udaan" },
        { text: "Go back to Main menu", nextStepId: "mainMenu2" },
      ],
    },
    {
      id: "c2.1zoomBg-default",
      message: "You can get your own Default Zoom Background from the redirected page",
      onStepFunction: () => navigator("fed/zoom-background"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2.1zoomBg-sangini",
      message: "You can get your own Zoom Background for Sangini from the redirected page",
      onStepFunction: () => navigator("zoom-background/sangini"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2.1zoomBg-aashray",
      message: "You can get your own Zoom Background for Aashray from the redirected page",
      onStepFunction: () => navigator("zoom-background/aashray"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2.1zoomBg-askJSG",
      message: "You can get your own Zoom Background for Ask JSG from the redirected page",
      onStepFunction: () => navigator("zoom-background/ask-jsg"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2.1zoomBg-constitution",
      message:
        "You can get your own Zoom Background for Constitution & Bylaws from the redirected page",
      onStepFunction: () => navigator("fed/zoom-background"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2.1zoomBg-eminence",
      message: "You can get your own Zoom Background for Eminence from the redirected page",
      onStepFunction: () => navigator("zoom-background/eminence"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2.1zoomBg-gurukul",
      message: "You can get your own Zoom Background for Gurukul from the redirected page",
      onStepFunction: () => navigator("zoom-background/gurukul"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2.1zoomBg-pari",
      message: "You can get your own Zoom Background for Pari from the redirected page",
      onStepFunction: () => navigator("zoom-background/pari"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2.1zoomBg-runbhoomi",
      message: "You can get your own Zoom Background for Runbhoomi from the redirected page",
      onStepFunction: () => navigator("zoom-background/runbhoomi"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2.1zoomBg-shrot",
      message: "You can get your own Zoom Background for Shrot from the redirected page",
      onStepFunction: () => navigator("zoom-background/shrot"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c2.1zoomBg-udaan",
      message: "You can get your own Zoom Background for Udaan from the redirected page",
      onStepFunction: () => navigator("zoom-background/udaan"),
      nextStepId: "mainMenu2",
    },
    // C3 JSGIF Details
    {
      id: "c3",
      message: "Please select your Region",
      onStepFunction: () => fetchRegions(),
      nextStepId: "c3-regDisplay",
    },
    {
      id: "c3-regDisplay",
      options: regionOptions,
      nextStepId: "c3-regCapture",
    },
    // {
    //   id: "c3-regCapture",
    //   onStepFunction: () => captureSelectedRegion(chatHistory),
    //   nextStepId: "c3-regRedirect",
    // },
    {
      id: "c3-regRedirect bombay",
      message: `You have been redirected to the Bombay Region's page`,
      onStepFunction: () => navigator("region/bombay"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c3-regRedirect gujarat",
      message: `You have been redirected to the Gujarat Region's page`,
      onStepFunction: () => navigator("region/gujarat"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c3-regRedirect indore",
      message: `You have been redirected to the Indore Region's page`,
      onStepFunction: () => navigator("region/indore"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c3-regRedirect m.p.",
      message: `You have been redirected to the M.P. Region's page`,
      onStepFunction: () => navigator("region/m.p."),
      nextStepId: "mainMenu2",
    },
    {
      id: "c3-regRedirect maharashtra",
      message: `You have been redirected to the Maharashtra Region's page`,
      onStepFunction: () => navigator("region/maharashtra"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c3-regRedirect mewar",
      message: `You have been redirected to the Mewar Region's page`,
      onStepFunction: () => navigator("region/mewar"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c3-regRedirect northern",
      message: `You have been redirected to the Northern Region's page`,
      onStepFunction: () => navigator("region/northern"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c3-regRedirect overseas",
      message: `You have been redirected to the Overseas Region's page`,
      onStepFunction: () => navigator("region/overseas"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c3-regRedirect saurashtra",
      message: `You have been redirected to the Saurashtra Region's page`,
      onStepFunction: () => navigator("region/saurashtra"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c3-regRedirect south",
      message: `You have been redirected to the South Region's page`,
      onStepFunction: () => navigator("region/south"),
      nextStepId: "mainMenu2",
    },

    // C5 JSGIF Details
    {
      id: "c5",
      message: "Which category of Form do you wish to fill?",
      options: [
        { text: "Federation", nextStepId: "c5-federation" },
        { text: "Region", nextStepId: "c5-region" },
        { text: "Sangini", nextStepId: "c5-sangini" },
        { text: "Group", nextStepId: "c5-group" },
        { text: "JSGFF", nextStepId: "c5-jsgff" },
        { text: "Go back to Main menu", nextStepId: "mainMenu2" },
      ],
    },
    {
      id: "c5-federation",
      message: "Choose the form that you wish to fill?",
      options: [
        { text: "Federation AGM", nextStepId: "c5-federation-AGM" },
        { text: "Federation CCM", nextStepId: "c5-federation-CCM" },
        { text: "Federation ID Meet", nextStepId: "c5-federation-IDM" },
        { text: "Federation OB Meet", nextStepId: "c5-federation-OBM" },
        {
          text: "Federation After Event Report",
          nextStepId: "c5-federation-AER",
        },
        {
          text: "Federation ID Nomination Form",
          nextStepId: "c5-federation-ID Nomination",
        },
        { text: "Go back to Main menu", nextStepId: "mainMenu2" },
      ],
    },
    {
      id: "c5-federation-AGM",
      message: `You have been redirected to the Federation AGM Form`,
      onStepFunction: () => navigator("fed-agm"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-federation-CCM",
      message: `You have been redirected to the Federation CCM Form`,
      onStepFunction: () => navigator("fed-ccm"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-federation-IDM",
      message: `You have been redirected to the Federation ID Meet Form`,
      onStepFunction: () => navigator("fed-idmeet"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-federation-OBM",
      message: `You have been redirected to the Federation OB Meet Form`,
      onStepFunction: () => navigator("fed-obmeet"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-federation-AER",
      message: `You have been redirected to the Federation After Event Report Form`,
      onStepFunction: () => navigator("fed-aer"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-federation-ID Nomination",
      message: `You have been redirected to the Federation ID Nomination Form`,
      onStepFunction: () => navigator("form/fed-nomination-id"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-region",
      message: "Choose the form that you wish to fill?",
      options: [
        { text: "Region AGM", nextStepId: "c5-region-AGM" },
        { text: "Region OB Meet", nextStepId: "c5-region-OBM" },
        { text: "Region Comittee Meet", nextStepId: "c5-region-Comittee Meet" },
        {
          text: "Region OB Nomination Form",
          nextStepId: "c5-region-OB Nomination",
        },
        {
          text: "Region Mangalyatra Reporting",
          nextStepId: "c5-region-mangalyatra",
        },
        { text: "Go back to Main menu", nextStepId: "mainMenu2" },
      ],
    },
    {
      id: "c5-region-AGM",
      message: `You have been redirected to the Region AGM Form`,
      onStepFunction: () => navigator("reg-agm"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-region-OBM",
      message: `You have been redirected to the Region OB Meet Form`,
      onStepFunction: () => navigator("reg-obmeet"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-region-Comittee Meet",
      message: `You have been redirected to the Region Comittee Meet Form`,
      onStepFunction: () => navigator("reg-comittee-meet"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-region-OB Nomination",
      message: `You have been redirected to the Region OB Nomination Form`,
      onStepFunction: () => navigator("form/reg-nomination-ob"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-region-mangalyatra",
      message: `You have been redirected to the Region Mangalyatra Reporting Form`,
      onStepFunction: () => navigator("reg-mangalyatra"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-sangini",
      message: "Choose the form that you wish to fill?",
      options: [
        {
          text: "Sangini Comittee Meet",
          nextStepId: "c5-sangini-Comittee Meet",
        },
        { text: "Sangini Forum Registration", nextStepId: "c5-sangini-forum" },
        {
          text: "Sangini Membership Form",
          nextStepId: "c5-sangini-membership",
        },
        {
          text: "Sangini Mangalyatra Reporting",
          nextStepId: "c5-sangini-mangalyatra",
        },
        { text: "Go back to Main menu", nextStepId: "mainMenu2" },
      ],
    },
    {
      id: "c5-sangini-Comittee Meet",
      message: `You have been redirected to the Sangini Comittee Meet Form`,
      onStepFunction: () => navigator("sgn-comittee-meet"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-sangini-forum",
      message: `You have been redirected to the Sangini Forum Registration Form`,
      onStepFunction: () => navigator("sgn-forum-registraion"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-sangini-membership",
      message: `You have been redirected to the Sangini Membership Form`,
      onStepFunction: () => navigator("sgn-membership-form"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-sangini-mangalyatra",
      message: `You have been redirected to the Sangini Mangalyatra Reporting Form`,
      onStepFunction: () => navigator("sgn-mangalyatra"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-group",
      message: "Choose the form that you wish to fill?",
      options: [
        {
          text: "Group AGM",
          nextStepId: "c5-group-AGM",
        },
        {
          text: "Group EGM",
          nextStepId: "c5-group-EGM",
        },
        {
          text: "Group Comittee Meet",
          nextStepId: "c5-group-Comittee Meet",
        },
        { text: "Group Group Registration", nextStepId: "c5-group-Group" },
        {
          text: "Group Membership Form",
          nextStepId: "c5-group-membership",
        },
        {
          text: "Group Form A",
          nextStepId: "c5-group-formA",
        },
        {
          text: "Group Form B ",
          nextStepId: "c5-group-formB",
        },
        {
          text: "Group Mangalyatra Reporting",
          nextStepId: "c5-group-mangalyatra",
        },
        { text: "Go back to Main menu", nextStepId: "mainMenu2" },
      ],
    },
    {
      id: "c5-group-AGM",
      message: `You have been redirected to the Group AGM Form`,
      onStepFunction: () => navigator("grpagm"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-group-EGM",
      message: `You have been redirected to the Group EGM Form`,
      onStepFunction: () => navigator("grpegm"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-group-group",
      message: `You have been redirected to the Group Registration Form`,
      onStepFunction: () => navigator("grpcommitteemeet"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-group-membership",
      message: `You have been redirected to the Group Membership Form`,
      onStepFunction: () => navigator("grp-forum-registraion"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-group-formA",
      message: `You have been redirected to the Group Form A Form`,
      onStepFunction: () => navigator("grp-form-a"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-group-formB",
      message: `You have been redirected to the Group Form B Form`,
      onStepFunction: () => navigator("grp-form-b"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-group-mangalyatra",
      message: `You have been redirected to the Group Mangalyatra Reporting Form`,
      onStepFunction: () => navigator("grp-mangalyatra"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c5-jsgff",
      message: "Choose the form that you wish to fill?",
      options: [
        {
          text: "JSGFF Education Loan Form",
          nextStepId: "c5-jsgff-edu",
        },
        { text: "Go back to Main menu", nextStepId: "mainMenu2" },
      ],
    },
    {
      id: "c5-jsgff-edu",
      message: `You have been redirected to the JSGFF Education Loan Form`,
      onStepFunction: () => navigator("form/jsgff-education-loan"),
      nextStepId: "mainMenu2",
    },

    // C6 Sangini Details
    {
      id: "c6",
      message: "Choose any one of the following?",
      options: [
        { text: "Sangini Comittee Members", nextStepId: "c6-comittee-members" },
        { text: "Sangini Events", nextStepId: "c6-events" },
        { text: "Go back to Main menu", nextStepId: "mainMenu2" },
      ],
    },
    {
      id: "c6-comittee-members",
      message: `You have been redirected to the Sangini Comittee Members Page`,
      onStepFunction: () => navigator("sgn/committee/2023-25"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c6-events",
      message: `You have been redirected to the Sangini Events Page`,
      onStepFunction: () => navigator("sgn/events"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c7",
      message:
        "You can contact the JSGIF Offices between 11:00 am - 5:00 pm from Monday to Saturday using the following means",
      nextStepId: "c7-address",
    },
    {
      id: "c7-address",
      message:
        "Address - #4/P, Vijay Chambers, Tribhuvan Road, 4th Floor, Opp. Dreamland Cinema, Mumbai - 400 004",
      nextStepId: "c7-landline",
    },
    {
      id: "c7-landline",
      message: "Landline Number - 022 02235302861 / 8163274400 ",
      nextStepId: "c7-email",
    },
    {
      id: "c7-email",
      message: "Email ID - office@jsgif.co.in ",
      nextStepId: "mainMenu2",
    },

    // C8 Ventures
    {
      id: "c8",
      message: "Choose any one of the following",
      options: [
        { text: "Aashray", nextStepId: "c8-aashray" },
        { text: "Ask JSG", nextStepId: "c8-ask" },
        { text: "Constitution & Bylaws", nextStepId: "c8-constitution" },
        { text: "Eminence", nextStepId: "c8-eminence" },
        { text: "Gurukul", nextStepId: "c8-gurukul" },
        { text: "Pari", nextStepId: "c8-pari" },
        { text: "Runbhoomi", nextStepId: "c8-runbhoomi" },
        { text: "Shrot", nextStepId: "c8-shrot" },
        { text: "Udaan", nextStepId: "c8-udaan" },
        { text: "Go back to Main menu", nextStepId: "mainMenu2" },
      ],
    },
    {
      id: "c8-aashray",
      message: "You have been redirected to the Aashray's Info page",
      onStepFunction: () => navigator("ventures/aashray"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c8-ask",
      message: "You have been redirected to the Ask JSG's Info page",
      onStepFunction: () => navigator("ventures/ask-jsg"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c8-constitution",
      message: "You have been redirected to the Constitution's Info page",
      onStepFunction: () => navigator("ventures/constitution-&-bylaws"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c8-eminence",
      message: "You have been redirected to the Eminence's Info page",
      onStepFunction: () => navigator("ventures/eminence"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c8-gurukul",
      message: "You have been redirected to the Gurukul's Info page",
      onStepFunction: () => navigator("ventures/gurukul"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c8-pari",
      message: "You have been redirected to the Pari's Info page",
      onStepFunction: () => navigator("ventures/pari"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c8-runbhoomi",
      message: "You have been redirected to the Runbhoomi's Info page",
      onStepFunction: () => navigator("ventures/runbhoomi"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c8-shrot",
      message: "You have been redirected to the Shrot's Info page",
      onStepFunction: () => navigator("ventures/shrot"),
      nextStepId: "mainMenu2",
    },
    {
      id: "c8-udaan",
      message: "You have been redirected to the Udaan's Info page",
      onStepFunction: () => navigator("ventures/udaan"),
      nextStepId: "mainMenu2",
    },

    // Add more question-answer pairs here
  ];

  return (
    <div className="chatbot-container">
      {isChatbotVisible ? (
        <div className="chatbot-expanded">
          {/* Header */}
          <div className="chatbot-header">
            <h2>Veer</h2>
            <button className="close-button" onClick={toggleChatbotVisibility}>
              <i className="fa-solid fa-times"></i>
            </button>
          </div>

          {/* Chat history */}
          <div className="chat-history" ref={chatHistoryRef}>
            {chatHistory.map((message, index) => (
              <>
                {message.message ? (
                  <div key={index} className="chat-row">
                    {renderMessage(message, currentQuestionIndex)}
                  </div>
                ) : null}
                <div className="chat-row">
                  {message.isOption && <div className="options-container">{message.options}</div>}
                </div>
              </>
            ))}
          </div>

          {/* User input */}
          <div className="input-container">
            <input
              type="text"
              placeholder="Type your message"
              value={userInputValue}
              onChange={(event) => setUserInputValue(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSendMessage();
                }
              }}
            />
            <button className="send-button" onClick={handleSendMessage}>
              <i className="fa-solid fa-paper-plane"></i>
            </button>
          </div>
        </div>
      ) : (
        <button className="chatbot-toggle" onClick={() => setChatbotVisible(true)}>
          <div className="chatbot-collapsed">
            <h3 className="veerHead">Veer</h3>
            <i className="fa-solid fa-comment"></i>
          </div>
        </button>
      )}
    </div>
  );
}

export default Veer;
