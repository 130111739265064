import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { users } from "./users.reducer";
import { alert } from "./alert.reducer";
import { veer } from "./veer.reducer";

const rootReducer = combineReducers({
  authentication,
  users,
  alert,
  veer,
});

export default rootReducer;
