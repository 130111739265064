import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import song from "../images/Navkar Mahamantra by Pari Gadiya.mp3";
import { saveAs } from "file-saver";

const NavkarMahamantra = () => {
  function downloader() {
    saveAs(song, "Navkar Mahamantra by Pari Gadiya.mp3");
  }

  return (
    <div>
      <h1>
        Navkar Mahamantra by JSGian Pari Kapil Gadiya of JSG Sangini Pride Jhabua, Indore Region.
      </h1>
      <div
        className="ventureContainer"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <audio style={{ width: "100%" }} src={song} controls={true} />
        <Button
          className="btn--secondary"
          style={{ marginTop: "10px" }}
          onClick={() => downloader()}
        >
          Download
        </Button>
      </div>
    </div>
  );
};

export default NavkarMahamantra;
