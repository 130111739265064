import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Images from "../../images/index";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import "../Response.css";

function FedAGMResponse() {
  const user = useSelector((state) => state.authentication.user);
  const location = useLocation();

  const [response, setResponses] = useState([]);
  const responseId = useParams();
  const ref = React.createRef();

  const getResponse = async () => {
    const res = await axios.get(`/api/forms/federation/fed-agm/${responseId.id}`);
    setResponses(res.data);
    console.log(res.data);
  };

  useEffect(() => {
    getResponse();
  }, []);

  const navigate = useNavigate();
  const editForm = () => {
    navigate(`/form/fed-agm/${response.id}/edit`);
  };

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("", "blank", "height=1123,width=800,scrollbars=yes,");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>Form B</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    html {
      overflow-y: auto;
    }

    fResponseBody {
      padding: 20px 20px;
      margin: 0px 20px;
      width: 800px;
      height: 1123px;
      background: #fff;
      overflow-y: auto;
    }

    .page{
      page-break-after: always;
    }

    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }

    .section2, .section3{
      margin-top: 30px;
    }

    .section4{
      margin-top: 20px;
    }

    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }

    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }

    .bordered-mid-row{
      padding: 3px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-col-b{
      padding: 3px 5px;
      border: 1px solid #000;
    }

    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }

    .bannerText {
      margin-top: 20px;
    }

    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }

    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }

    .toptext {
      margin: 20px 0;
    }

    .logoImage {
      height: 80px;
      margin: 10px;
    }

    .bannerYellow {
      background: #ffcc29;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }

    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }

    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }

    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }

    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }

    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }

    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }

    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }

    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }`);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
    }, 1000);
  };

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Federation AGM Response</h2>
      <Row style={{ marginBottom: "1em", marginTop: "1em" }}>
        <Col className="d-flex justify-content-end">
          <Button onClick={printDocument} variant="primary">
            Download Form
          </Button>
        </Col>
        <Col className="d-flex justify-content-start">
          <Button onClick={editForm} variant="primary">
            Edit Form
          </Button>
        </Col>
      </Row>
      <div id="mainForm" className="centerFormat">
        <div className="fResponseBody">
          <div className="bannerYellow">
            <Row className="T-Banner">
              <Col xs={1}>
                <img src={Images.LogoB} className="logoImage" alt="jsgBanner"></img>
              </Col>
              <Col xs={11} className="bannerText text-center">
                <h1>JAIN SOCIAL GROUP INT. FEDERATION</h1>
                <p
                  className="Tsingle-enter"
                  style={{
                    padding: "0 20px",
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  4-O/P, Vijay Chambers, Opp. Dreamland Cinema, Tribhuvan Road, Mumbai - 400 004. (India)
                </p>
                <p
                  className="Tsingle-enter"
                  style={{
                    padding: "0 20px",
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Tel.: 022-23870724 / 23891884 Mobile : 8169274400
                </p>
                <p
                  className="Tsingle-enter"
                  style={{
                    padding: "0 20px",
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  E-mail : office@jsgif.co.in Website : www.jsgif.co.i
                </p>
              </Col>
            </Row>
          </div>
          <Row>
            <p className="Tsingle-enter">
              <span className="response">{response.nDate}</span>
            </p>
            <p className="Tdouble-enter"> To, </p>
            <p className="Tsingle-enter"> All General Council Members,</p>

            <p className="Tsingle-enter"> Jain Social Groups Int. Federation </p>

            <p className="Tdouble-enter"> Dear JSGians, </p>
            <p className="Tsingle-enter"> Jai Jinendra </p>
            <p className="Tdouble-enter">
              Notice is hereby given that the Annual General Meeting of of the General Council Members of JSG Int.
              Federation would beheld as per details hereunder:-
            </p>
          </Row>

          <div className="Tbox">
            <p className="Tsingle-enter">
              Day : <span className="response">{response.mDay}</span>{" "}
            </p>
            <p className="Tsingle-enter">
              Date: <span className="response">{moment(response.mDate).format("Do MMMM YYYY")}</span>
            </p>
            <p className="Tsingle-enter">
              Time: <span className="response"> {response.mTime} </span>
            </p>
            <p className="Tsingle-enter">
              Place:{" "}
              <span className="response">
                {" "}
                {response.mAddress}, {response.mCity} -{response.mState} - {response.mZip}{" "}
              </span>
            </p>
          </div>
          <Row>
            <h2 className="Hdouble-enter text-center">AGENDA</h2>
          </Row>
          <ol className="Lsingle-enter">
            <li> Recitation of Namaskar Mahamantra and Federation Sutra.</li>
            <li> Welcome and opening remarks by the JSGIF President.</li>
            <li> Leave of absence if any.</li>
            <li> Read and approve minutes of the previous AGM.</li>
            <li> Matters arising out of the minutes.</li>
            <li>
              To approve & pass the audited accounts for the year
              <span className="response"> {response.mYear} </span>.
            </li>
            <li>
              To appoint auditor for the year <span className="response">{response.auditorFYear}</span> and fix their
              remuneration.
            </li>
            <li> To submit report by the Secretary General.</li>
            {response.agendaPoint !== "" ? (
              <li>
                <span className="response">{response.agendaPoint}</span>
              </li>
            ) : (
              ""
            )}
            <li> Any other matter with the permission of the Chair.</li>
            <li> Vote of thanks.</li>
          </ol>

          <p className="Tdouble-enter">Yours in JSG Movement</p>
          <p className="Tsingle-enter">
            JSGian <span className="response">{response.fedSecretary}</span>
          </p>
          <p className="Tsingle-enter">
            <span className="response">JSGIF - Secretary Generals</span>
          </p>
          <p className="Tdouble-enter">Note :- </p>
          <p className="Tsingle-enter">1. Only one leave of absence is allowed in a year.</p>
          <p className="Tsingle-enter">
            2 . Leave of absence to be notified to the President and Secretary General by email 24hrs before the day of
            meeting.
          </p>
          <p className="Tsingle-enter">
            3. Member(s) desirous of taking up a point in any other matter must inform the Chairman and Secretary by
            email with details of the point 48hrs before the date of meeting.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FedAGMResponse;
