import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const FedAEREditForm = () => {
  const user = useSelector((state) => state.authentication.user);
  const location = useLocation();

  const [response, setResponse] = useState([]);

  const [grpName, setGrpName] = useState("");
  const [regName, setRegName] = useState("");
  const [visitDate, setVisitDate] = useState("");
  const [visitDay, setVisitDay] = useState("");
  const [proContacted, setproContacted] = useState("");
  const [wallBanner, setwallBanner] = useState("");
  const [podiumBanner, setpodiumBanner] = useState("");
  const [tableFlag, settableFlag] = useState("");
  const [nameBoard, setnameBoard] = useState("");
  const [tableAgenda, settableAgenda] = useState("");
  const [callingFollowed, setcallingFollowed] = useState("");
  const [seatinfFollwed, setseatinfFollwed] = useState("");
  const [agendaTime, setagendaTime] = useState("");
  const [emailListOld, setEmailListOld] = useState("");
  const [emailList, setEmailList] = useState("");
  const [emailListFinal, setEmailListFinal] = useState("");

  const [emailInput, setEmailInput] = useState("");
  const handleInputChange = (e) => {
    setEmailInput(e.target.value);
    const newValue = e.target.value.replace(/\s/g, ""); // replace all spaces with empty string
    setEmailList(newValue);
    console.log(newValue);
  };

  const responseId = useParams();

  const EditForm = () => {
    axios.get(`/api/forms/federation/fed-aer/${responseId.id}`).then(function(res) {
      setResponse(res.data);
      console.log(res.data);
      setGrpName(res.data.grpName);
      setRegName(res.data.regName);
      setVisitDate(res.data.visitDate);
      setVisitDay(res.data.visitDay);
      setproContacted(res.data.proContacted);
      setwallBanner(res.data.wallBanner);
      setpodiumBanner(res.data.podiumBanner);
      settableFlag(res.data.tableFlag);
      setnameBoard(res.data.nameBoard);
      settableAgenda(res.data.tableAgenda);
      setcallingFollowed(res.data.callingFollowed);
      setseatinfFollwed(res.data.seatinfFollwed);
      setagendaTime(res.data.agendaTime);
      setEmailListOld(res.data.emailList);
      setEmailInput(res.data.emailList);
    });
  };

  useEffect(() => {
    EditForm();
  }, []);

  const redirectToResponse = () => {
    navigate(`/fed-aer`, { replace: true });
  };

  const navigate = useNavigate();

  const dateDay = (e) => {
    setVisitDate(e.target.value);
    let dayofdate = moment(e.target.value).format("dddd");
    setVisitDay(dayofdate);
  };

  async function FormSubmit(event) {
    event.preventDefault();
    setEmailListFinal(emailListOld + "," + emailList);

    let formField = new FormData();

    formField.append("user", user.user_id);
    formField.append("grpName", grpName);
    formField.append("regName", regName);
    formField.append("visitDate", visitDate);
    formField.append("visitDay", visitDay);
    formField.append("proContacted", proContacted);
    formField.append("wallBanner", wallBanner);
    formField.append("podiumBanner", podiumBanner);
    formField.append("tableFlag", tableFlag);
    formField.append("nameBoard", nameBoard);
    formField.append("tableAgenda", tableAgenda);
    formField.append("callingFollowed", callingFollowed);
    formField.append("seatinfFollwed", seatinfFollwed);
    formField.append("agendaTime", agendaTime);
    formField.append("emailList", emailList);

    await axios({
      method: "put",
      url: `/api/forms/federation/fed-aer/${response.id}/`,
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        redirectToResponse();
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get("/user/region");

    setRegions(response.data.sort((a, b) => a.region.localeCompare(b.region)));
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups`);

    setGroups(response.data);
  };

  useEffect(() => {
    fetchGroups();
  }, [regName]);

  return (
    <div>
      <Container>
        <Form onSubmit={FormSubmit}>
          <Row>
            <Col md={8}>
              <h2>Federation After Event Report Edit (AER)</h2>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={3} xs={12}>
              <Form.Group>
                <Form.Label>Region Visited</Form.Label>
                <Form.Select
                  type="text"
                  name="regName"
                  defaultValue={regName}
                  onChange={(e) => setRegName(e.target.value)}
                >
                  <option>Select your Region</option>
                  {regions.map((region) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() + region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={3} xs={12}>
              <Form.Group>
                <Form.Label>Group Visited</Form.Label>
                <Form.Select
                  type="text"
                  name="grpName"
                  defaultValue={grpName}
                  onChange={(e) => setGrpName(e.target.value)}
                >
                  <option>Select your Group</option>
                  {groups.map((group) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      value={group.group.toLowerCase()}
                    >
                      {group.group.charAt(0).toUpperCase() + group.group.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={3} xs={12}>
              <Form.Group controlId="meetingDate">
                <Form.Label>Visit Date</Form.Label>
                <Form.Control type="date" name="visitDate" value={visitDate} onChange={dateDay} />
              </Form.Group>
            </Col>

            <Col lg={3} xs={12}>
              <Form.Group controlId="meetingDay">
                <Form.Label>Visit Day</Form.Label>
                <Form.Select defaultValue="Day" name="visitDay" value={visitDay}>
                  <option>Sunday</option>
                  <option>Monday</option>
                  <option>Tuesday</option>
                  <option>Wednesday</option>
                  <option>Thursday</option>
                  <option>Friday</option>
                  <option>Saturday</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <div
            style={{
              padding: "2%",
              backgroundColor: "rgba(185, 235, 235, 0.5)",
              borderRadius: "20px",
            }}
          >
            <Row>
              <h3
                style={{
                  color: "rgb(63,9,134)",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Report
              </h3>
            </Row>
            <Row className="mb-3">
              <Col xs={8} md={10}>
                <Form.Label>
                  Was JSGIF P.R.O. Admin contacted and written approval for protocol and agenda
                  taken?
                </Form.Label>
              </Col>
              <Col xs={4} md={2}>
                <Form.Select
                  type="text"
                  name="proContacted"
                  value={proContacted}
                  onChange={(e) => setproContacted(e.target.value)}
                >
                  <option>Select</option>
                  <option>Yes</option>
                  <option>No</option>
                </Form.Select>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={8} md={10}>
                <Form.Label>Was the JSGIF wall banner present and clearly visible?</Form.Label>
              </Col>
              <Col xs={4} md={2}>
                <Form.Select
                  type="text"
                  name="wallBanner"
                  value={wallBanner}
                  onChange={(e) => setwallBanner(e.target.value)}
                >
                  <option>Select</option>
                  <option>Yes</option>
                  <option>No</option>
                </Form.Select>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={8} md={10}>
                <Form.Label>Was the JSGIF podium banner present and clearly visible?</Form.Label>
              </Col>
              <Col xs={4} md={2}>
                <Form.Select
                  type="text"
                  name="podiumBanner"
                  value={podiumBanner}
                  onChange={(e) => setpodiumBanner(e.target.value)}
                >
                  <option>Select</option>
                  <option>Yes</option>
                  <option>No</option>
                </Form.Select>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={8} md={10}>
                <Form.Label>Was the JSGIF flag prominently displayed on the table?</Form.Label>
              </Col>
              <Col xs={4} md={2}>
                <Form.Select
                  type="text"
                  name="tableFlag"
                  value={tableFlag}
                  onChange={(e) => settableFlag(e.target.value)}
                >
                  <option>Select</option>
                  <option>Yes</option>
                  <option>No</option>
                </Form.Select>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={8} md={10}>
                <Form.Label>
                  Were the name boards and designation displayed on the table?
                </Form.Label>
              </Col>
              <Col xs={4} md={2}>
                <Form.Select
                  type="text"
                  name="nameBoard"
                  value={nameBoard}
                  onChange={(e) => setnameBoard(e.target.value)}
                >
                  <option>Select</option>
                  <option>Yes</option>
                  <option>No</option>
                </Form.Select>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={8} md={10}>
                <Form.Label>Was the agenda with time placed on the table?</Form.Label>
              </Col>
              <Col xs={4} md={2}>
                <Form.Select
                  type="text"
                  name="tableAgenda"
                  value={tableAgenda}
                  onChange={(e) => settableAgenda(e.target.value)}
                >
                  <option>Select</option>
                  <option>Yes</option>
                  <option>No</option>
                </Form.Select>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={8} md={10}>
                <Form.Label>Was the calling on dais protocol followed?</Form.Label>
              </Col>
              <Col xs={4} md={2}>
                <Form.Select
                  type="text"
                  name="callingFollowed"
                  value={callingFollowed}
                  onChange={(e) => setcallingFollowed(e.target.value)}
                >
                  <option>Select</option>
                  <option>Yes</option>
                  <option>No</option>
                </Form.Select>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={8} md={10}>
                <Form.Label>Was seating protocol followed?</Form.Label>
              </Col>
              <Col xs={4} md={2}>
                <Form.Select
                  type="text"
                  name="seatinfFollwed"
                  value={seatinfFollwed}
                  onChange={(e) => setseatinfFollwed(e.target.value)}
                >
                  <option>Select</option>
                  <option>Yes</option>
                  <option>No</option>
                </Form.Select>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={8} md={10}>
                <Form.Label>Was agenda timing maintained?</Form.Label>
              </Col>
              <Col xs={4} md={2}>
                <Form.Select
                  type="text"
                  name="agendaTime"
                  value={agendaTime}
                  onChange={(e) => setagendaTime(e.target.value)}
                >
                  <option>Select</option>
                  <option>Yes</option>
                  <option>No</option>
                </Form.Select>
              </Col>
            </Row>
          </div>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="emailInput">
              <Form.Label>Mail the After Event Report to</Form.Label>
              <Form.Control
                placeholder="Add each email address after a comma. Example = xyz@gmail.com, abc@gmail.com"
                name="emailInput"
                value={emailInput}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>
          </Row>

          <Button className="btn--outline--dark" type="submit">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default FedAEREditForm;
