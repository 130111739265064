import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../components/Button.css";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Container,
  Table,
} from "react-bootstrap";
import "../formsList.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import SecurePageSection from "../../SecurePageSection";
import { LinkContainer } from "react-router-bootstrap";

const PariList = () => {
  const user = useSelector((state) => state.authentication.user);

  const [forms, setForms] = useState([]);

  const fetchForms = async () => {
    const response = await axios.get(`/api/forms/ventures/pari-applications/`);
    console.log(response.data);
    setForms(response.data);
  };

  const navigate = useNavigate();
  const newForm = () => {
    navigate("/form/pari-application-form");
  };
  const tutorials = () => {
    navigate("/tutorials/eng/grp-agm");
  };

  useEffect(() => {
    fetchForms();
  }, []);

  return (
    <div>
      <Container className="body">
        <Row style={{ marginBottom: "1em" }}>
          <Col xs={10}>
            <h2>Pari Forms</h2>
            <p style={{ fontSize: "20px" }}>
              Contact your Region's Convenor to fill the form
            </p>
          </Col>
          {(() => {
            if (
              typeof user !== "undefined" &&
              typeof user.username !== "undefined"
            ) {
              return (
                <Col className="d-flex justify-content-end">
                  <Button className="btn--primary" onClick={newForm}>
                    New Form
                  </Button>
                </Col>
              );
            }
          })()}
        </Row>
        <Table responsive="md" striped bordered>
          <thead className="tableHead">
            <tr>
              <th>S. No.</th>
              <th>Form Id</th>
              <th>Daughter Name</th>
              <th>Region Name</th>
              <th>Status</th>
              {(() => {
                if (
                  (typeof user !== "undefined" &&
                    typeof user.username !== "undefined" &&
                    user.accessGroup === "admin") ||
                  (typeof user !== "undefined" &&
                    typeof user.username !== "undefined" &&
                    user.accessGroup === "pariChair")
                ) {
                  return <th>Form</th>;
                }
              })()}
            </tr>
          </thead>
          <tbody className="tableBody">
            {forms
              .slice(0)
              .reverse()
              .map((form, index) => (
                <tr
                  key={index}
                  style={{ textTransform: "capitalize", textAlign: "center" }}
                >
                  <td>{index + 1}</td>
                  <td>{form.id}</td>
                  <td>{form.DName}</td>
                  <td>{form.regName}</td>
                  {(() => {
                    if (
                      (typeof user !== "undefined" &&
                        typeof user.username !== "undefined" &&
                        user.accessGroup === "admin") ||
                      (typeof user !== "undefined" &&
                        typeof user.username !== "undefined" &&
                        user.accessGroup === "pariChair")
                    ) {
                      return (
                        <>
                          {" "}
                          {(() => {
                            if (form.Status === "Pending") {
                              return (
                                <td>
                                  <Button className="btn-pending">
                                    {form.Status}
                                  </Button>
                                </td>
                              );
                            } else if (form.Status === "Approved") {
                              return (
                                <td>
                                  <Button className="btn-approved">
                                    {form.Status}
                                  </Button>
                                </td>
                              );
                            } else if (form.Status === "Rejected") {
                              return (
                                <td>
                                  <Button className="btn-rejected">
                                    {form.Status}
                                  </Button>
                                </td>
                              );
                            }
                          })()}
                          <td style={{ width: "20rem" }}>
                            <Row>
                              <Col>
                                <div style={{ textTransform: "center" }}>
                                  <LinkContainer
                                    to={`/pari-application-form/response/${form.id}`}
                                  >
                                    <Button
                                      variant="light"
                                      className="btn--four"
                                    >
                                      View Form
                                    </Button>
                                  </LinkContainer>
                                </div>
                              </Col>
                            </Row>
                          </td>
                        </>
                      );
                    }
                  })()}
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default PariList;
