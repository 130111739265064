import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Images from "../../images/index";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import "../Response.css";
import SecurePageSection from "../../SecurePageSection";

function GroupForumRegistrationResponse() {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponses] = useState([]);
  const responseId = useParams();

  const getResponse = async () => {
    const res = await axios.get(`/api/forms/group/grp-forum-registraion/${responseId.id}`);
    setResponses(res.data);
  };

  useEffect(() => {
    getResponse();
  }, []);

  const navigate = useNavigate();
  const editForm = () => {
    navigate(`form/grp-forum-registraion/${response.id}/edit/`);
  };

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("", "blank", "height=1123,width=800,scrollbars=yes,");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>Form B</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    html {
      overflow-y: auto;
    }

    fResponseBody {
      padding: 20px 20px;
      margin: 0px 20px;
      width: 800px;
      height: 1123px;
      background: #fff;
      overflow-y: auto;
    }

    .page{
      page-break-after: always;
    }

    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }

    .section2, .section3{
      margin-top: 30px;
    }

    .section4{
      margin-top: 20px;
    }

    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }

    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }

    .bordered-mid-row{
      padding: 3px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-col-b{
      padding: 3px 5px;
      border: 1px solid #000;
    }

    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }

    .bannerText {
      margin-top: 20px;
    }

    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }

    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }

    .toptext {
      margin: 20px 0;
    }

    .logoImage {
      height: 80px;
      margin: 10px;
    }

    .bannerYellow {
      background: #ffcc29;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }

    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }

    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }

    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }

    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }

    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }

    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }

    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }

    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }`);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
    }, 1000);
  };

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Group Forum Registration Response</h2>
      <Row style={{ marginBottom: "1em", marginTop: "1em" }}>
        <Col className="d-flex justify-content-end">
          <Button onClick={printDocument} variant="primary">
            Download Form
          </Button>
        </Col>
        <Col className="d-flex justify-content-start">
          <Button onClick={editForm} variant="primary">
            Edit Form
          </Button>
        </Col>
      </Row>
      <div id="mainForm" className="centerFormat">
        <div className="fResponseBody">
          <div className="bannerYellow">
            <Row className="T-Banner">
              <Col xs={1}>
                <img src={Images.LogoB} className="logoImage" alt="jsgBanner"></img>
              </Col>
              <Col xs={11} className="bannerText text-center">
                <h1>JAIN SOCIAL GROUP INT. FEDERATION</h1>
                <h2>{response.grpName}</h2>
              </Col>
            </Row>
          </div>
          <Row>
            <p className="Tsingle-enter">
              <span className="response">{moment(response.formDate).format("Do MMMM YYYY")}</span>
            </p>
          </Row>
          <Row className="text-center">
            <h2 className="Hdouble-enter text-center">GROUP FORUM REGISTRATION FORM</h2>
          </Row>

          <ol className="Ldouble-enter">
            <li>
              Name of Group - <span className="response">{response.grpName}</span>{" "}
            </li>
            <li>
              Sponsor Group - <span className="response">{response.parentgName}</span>{" "}
            </li>
            <li>Name of co-ordinator/s appointed by sponsor group with copy of resolution passed by sponsor group.</li>
            <Row>
              <Col>
                <p className="Tsingle-enter">
                  Name - <span className="response">{response.cordName1}</span>
                </p>
              </Col>
              <Col>
                <p className="Tsingle-enter">
                  Post - <span className="response">{response.cordpost1}</span>
                </p>
              </Col>
            </Row>
            <li>
              Date of formation -{" "}
              <span className="response">{moment(response.formationDate).format("Do MMMM YYYY")}</span>{" "}
            </li>
            <li>Name of office bearers - </li>
            <Row>
              <Col>
                <p className="Tsingle-enter">
                  President - <span className="response">{response.presName}</span>{" "}
                </p>
              </Col>
              <Col>
                <p className="Tsingle-enter">
                  V.P. - <span className="response">{response.vPresName}</span>{" "}
                </p>
              </Col>
              <Col>
                <p className="Tsingle-enter">
                  Secretary - <span className="response">{response.secName}</span>{" "}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="Tsingle-enter">
                  Jt. Secretary - <span className="response">{response.jtSecName}</span>{" "}
                </p>
              </Col>
              <Col>
                <p className="Tsingle-enter">
                  Treasurer - <span className="response">{response.tresName}</span>{" "}
                </p>
              </Col>
              <Col>
                <p className="Tsingle-enter"> </p>
              </Col>
            </Row>
            <li>Committee Member - </li>
            <Row>
              <Col>
                <p className="Tsingle-enter">
                  1. <span className="response">{response.commName1}</span>
                </p>
              </Col>
              <Col>
                <p className="Tsingle-enter">
                  2. <span className="response">{response.commName2}</span>
                </p>
              </Col>
              <Col>
                <p className="Tsingle-enter">
                  3. <span className="response">{response.commName3}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="Tsingle-enter">
                  4. <span className="response">{response.commName4}</span>
                </p>
              </Col>
              <Col>
                <p className="Tsingle-enter">
                  5. <span className="response">{response.commName5}</span>
                </p>
              </Col>
              <Col>
                <p className="Tsingle-enter"> </p>
              </Col>
            </Row>
            <li>List of members along with address, mobile & email (Please attach list separately)</li>

            <li>
              Attach DD/UTR of Rs.1180/- (Rs.1000/- yearly registration and Rs.180 GST on it) to be paid every year.
              DD/UTR Number - <span className="response">{response.ddNumber}</span> Bank -{" "}
              <span className="response">{response.bankName}</span> Dated -{" "}
              <span className="response">{response.ddDate}</span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default GroupForumRegistrationResponse;
