import React, { useState } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../SecurePageSection";

const ObProjectUpload = () => {
  const user = useSelector((state) => state.authentication.user);

  const [eTitle, setETitle] = useState("");
  const [ytLink, setYtLink] = useState("");
  const [eDescription, setEDescription] = useState("");
  const [ePhoto0, setEPhoto0] = useState("");
  const [eDate, setEDate] = useState("");
  const [ePhotos, setEPhotos] = useState(null);

  const navigate = useNavigate();
  const redirectToResponse = (id) => {
    navigate(`/fed/ob-project/${id}`);
  };

  async function FormSubmit(event) {
    event.preventDefault();
    let formField = new FormData();

    const name = user.first_name + " " + user.last_name;

    formField.append("user", user.user_id);
    formField.append("name", name);
    formField.append("eTitle", eTitle);
    formField.append("ytLink", ytLink);
    formField.append("eDescription", eDescription);
    formField.append("eDate", eDate);

    if (ePhoto0 !== null) {
      formField.append("ePhoto0", ePhoto0);
    }

    if (ePhotos !== null) {
      for (let i = 0; i < ePhotos.length; i++) {
        const fieldName = `ePhoto${i}`;
        formField.append(fieldName, ePhotos[i]);
      }
    }

    await axios({
      method: "post",
      url: "/api/fed/projects/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        redirectToResponse(response.data.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  return (
    <div>
      <SecurePageSection accessableUsers={"admin,offBearer"} />
      <Container>
        <Form>
          <Row>
            <Col md={8}>
              <h2>Office Bearer Project Upload</h2>
              <p>Showcase your contribution towards the JSG Int Federation to all the JSGians</p>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Project Title</Form.Label>
                <Form.Control name="eTitle" value={eTitle} onChange={(e) => setETitle(e.target.value)} />
              </Form.Group>
            </Col>

            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Project Date</Form.Label>
                <Form.Control type="date" name="eDate" value={eDate} onChange={(e) => setEDate(e.target.value)} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Project Description</Form.Label>
                <Form.Control
                  placeholder="Give a detailed report of the project"
                  as="textarea"
                  name="eDescription"
                  value={eDescription}
                  onChange={(e) => setEDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>Youtube Link</Form.Label>
                <Form.Control
                  placeholder="If you have a video then share the link to the video"
                  name="ytLink"
                  value={ytLink}
                  onChange={(e) => setYtLink(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>Thumbnail</Form.Label>
                <Form.Control
                  type="file"
                  name="ePhoto0"
                  accept="image/jpg, image/jpeg"
                  onChange={(e) => setEPhoto0(e.target.files[0])}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>
                  Event Photos <span style={{ fontSize: "18px" }}>(Upload 5 Photos only)</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  name="ePhotos"
                  multiple
                  accept="image/jpg, image/jpeg"
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files.length <= 5) {
                      setEPhotos(files);
                    } else {
                      // Display an error message or handle the limit exceeded scenario
                      window.alert("Maximum of 5 Images allowed.");
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button className="btn--outline--dark" onClick={FormSubmit} type="submit">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default ObProjectUpload;
