import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../../SecurePageSection";

const RegionMangalyatraForm = () => {
  const user = useSelector((state) => state.authentication.user);
  const [submitting, setSubmitting] = useState(false);

  const [chairEmail, setchairEmail] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [grpName, setGrpName] = useState("");
  const [regName, setRegName] = useState("");
  const [eName, setEName] = useState("");
  const [eDate, setEDate] = useState("");
  const [eTime, setETime] = useState("");
  const [eVenue, setEVenue] = useState("");
  const [hostGrpName, setHostGrpName] = useState("");
  const [fedDigName1, setFedDigName1] = useState("");
  const [fedDigPost1, setFedDigPost1] = useState("");
  const [fedDigName2, setFedDigName2] = useState("");
  const [fedDigPost2, setFedDigPost2] = useState("");
  const [fedDigName3, setFedDigName3] = useState("");
  const [fedDigPost3, setFedDigPost3] = useState("");
  const [fedDigName4, setFedDigName4] = useState("");
  const [fedDigPost4, setFedDigPost4] = useState("");
  const [fedDigName5, setFedDigName5] = useState("");
  const [fedDigPost5, setFedDigPost5] = useState("");
  const [fedDigName6, setFedDigName6] = useState("");
  const [fedDigPost6, setFedDigPost6] = useState("");
  const [fedDigName7, setFedDigName7] = useState("");
  const [fedDigPost7, setFedDigPost7] = useState("");
  const [fedDigName8, setFedDigName8] = useState("");
  const [fedDigPost8, setFedDigPost8] = useState("");
  const [fedDigName9, setFedDigName9] = useState("");
  const [fedDigPost9, setFedDigPost9] = useState("");
  const [fedDigName10, setFedDigName10] = useState("");
  const [fedDigPost10, setFedDigPost10] = useState("");
  const [regDigName1, setRegDigName1] = useState("");
  const [regDigPost1, setRegDigPost1] = useState("");
  const [regDigName2, setRegDigName2] = useState("");
  const [regDigPost2, setRegDigPost2] = useState("");
  const [regDigName3, setRegDigName3] = useState("");
  const [regDigPost3, setRegDigPost3] = useState("");
  const [regDigName4, setRegDigName4] = useState("");
  const [regDigPost4, setRegDigPost4] = useState("");
  const [regDigName5, setRegDigName5] = useState("");
  const [regDigPost5, setRegDigPost5] = useState("");
  const [regDigName6, setRegDigName6] = useState("");
  const [regDigPost6, setRegDigPost6] = useState("");
  const [regDigName7, setRegDigName7] = useState("");
  const [regDigPost7, setRegDigPost7] = useState("");
  const [regDigName8, setRegDigName8] = useState("");
  const [regDigPost8, setRegDigPost8] = useState("");
  const [regDigName9, setRegDigName9] = useState("");
  const [regDigPost9, setRegDigPost9] = useState("");
  const [regDigName10, setRegDigName10] = useState("");
  const [regDigPost10, setRegDigPost10] = useState("");
  const [vipDigName1, setVipDigName1] = useState("");
  const [vipDigPost1, setVipDigPost1] = useState("");
  const [vipDigName2, setVipDigName2] = useState("");
  const [vipDigPost2, setVipDigPost2] = useState("");
  const [vipDigName3, setVipDigName3] = useState("");
  const [vipDigPost3, setVipDigPost3] = useState("");
  const [vipDigName4, setVipDigName4] = useState("");
  const [vipDigPost4, setVipDigPost4] = useState("");
  const [vipDigName5, setVipDigName5] = useState("");
  const [vipDigPost5, setVipDigPost5] = useState("");
  const [eDetails, setEDetails] = useState("");
  const [ePhoto1, setEPhoto1] = useState();
  const [ePhoto2, setEPhoto2] = useState();
  const [ePhoto3, setEPhoto3] = useState();
  const [ePhoto4, setEPhoto4] = useState();
  const [ePhoto5, setEPhoto5] = useState();

  // const handleInputChange = (e) => {
  //   setEmailInput(e.target.value);
  //   const newValue = e.target.value.replace(/\s/g, ""); // replace all spaces with empty string
  //   setEmailList(newValue);
  //   console.log(newValue);
  // };

  const redirectToResponse = (id) => {
    navigate(`/reg-mangalyatra-response/${id}`, { replace: true });
  };

  const navigate = useNavigate();

  async function FormSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    let formField = new FormData();

    if (!regName || !eDetails || !eName || !eDate) {
      alert("Please Submit your Region Name, Event Name, Event Details and the Event Date");
      setSubmitting(false);
      return;
    }

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,mehtadoshi@gmail.com,ranshaent@gmail.com,bhartibshah9@rediffmail.com,manish.shah95@gmail.com,dj22jsgif@gmail.com,office@jsgif.co.in," +
      chairEmail;

    formField.append("user", user.user_id);
    formField.append("chairEmail", chairEmail);
    formField.append("name", name);
    formField.append("mobile", mobile);
    formField.append("grpName", grpName);
    formField.append("regName", regName);
    formField.append("eName", eName);
    formField.append("eDate", eDate);
    formField.append("eTime", eTime);
    formField.append("eVenue", eVenue);
    formField.append("hostGrpName", hostGrpName);
    formField.append("fedDigName1", fedDigName1);
    formField.append("fedDigPost1", fedDigPost1);
    formField.append("fedDigName2", fedDigName2);
    formField.append("fedDigPost2", fedDigPost2);
    formField.append("fedDigName3", fedDigName3);
    formField.append("fedDigPost3", fedDigPost3);
    formField.append("fedDigName4", fedDigName4);
    formField.append("fedDigPost4", fedDigPost4);
    formField.append("fedDigName5", fedDigName5);
    formField.append("fedDigPost5", fedDigPost5);
    formField.append("fedDigName6", fedDigName6);
    formField.append("fedDigPost6", fedDigPost6);
    formField.append("fedDigName7", fedDigName7);
    formField.append("fedDigPost7", fedDigPost7);
    formField.append("fedDigName8", fedDigName8);
    formField.append("fedDigPost8", fedDigPost8);
    formField.append("fedDigName9", fedDigName9);
    formField.append("fedDigPost9", fedDigPost9);
    formField.append("fedDigName10", fedDigName10);
    formField.append("fedDigPost10", fedDigPost10);
    formField.append("regDigName1", regDigName1);
    formField.append("regDigPost1", regDigPost1);
    formField.append("regDigName2", regDigName2);
    formField.append("regDigPost2", regDigPost2);
    formField.append("regDigName3", regDigName3);
    formField.append("regDigPost3", regDigPost3);
    formField.append("regDigName4", regDigName4);
    formField.append("regDigPost4", regDigPost4);
    formField.append("regDigName5", regDigName5);
    formField.append("regDigPost5", regDigPost5);
    formField.append("regDigName6", regDigName6);
    formField.append("regDigPost6", regDigPost6);
    formField.append("regDigName7", regDigName7);
    formField.append("regDigPost7", regDigPost7);
    formField.append("regDigName8", regDigName8);
    formField.append("regDigPost8", regDigPost8);
    formField.append("regDigName9", regDigName9);
    formField.append("regDigPost9", regDigPost9);
    formField.append("regDigName10", regDigName10);
    formField.append("regDigPost10", regDigPost10);
    formField.append("vipDigName1", vipDigName1);
    formField.append("vipDigPost1", vipDigPost1);
    formField.append("vipDigName2", vipDigName2);
    formField.append("vipDigPost2", vipDigPost2);
    formField.append("vipDigName3", vipDigName3);
    formField.append("vipDigPost3", vipDigPost3);
    formField.append("vipDigName4", vipDigName4);
    formField.append("vipDigPost4", vipDigPost4);
    formField.append("vipDigName5", vipDigName5);
    formField.append("vipDigPost5", vipDigPost5);
    formField.append("eDetails", eDetails);
    if (ePhoto1 !== null) {
      formField.append("ePhoto1", ePhoto1);
    }
    if (ePhoto2 !== null) {
      formField.append("ePhoto2", ePhoto2);
    }
    if (ePhoto3 !== null) {
      formField.append("ePhoto3", ePhoto3);
    }
    if (ePhoto4 !== null) {
      formField.append("ePhoto4", ePhoto4);
    }
    if (ePhoto5 !== null) {
      formField.append("ePhoto5", ePhoto5);
    }
    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: `/api/forms/reg-mangalyatras/`,
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        redirectToResponse(response.data.id);
      })
      .catch(function(error) {
        console.log(error);
        alert("We encountered an error processing your request. Please try again later");
      });
    setSubmitting(false);
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get("/user/region");

    setRegions(response.data.sort((a, b) => a.region.localeCompare(b.region)));
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups`);

    console.log(response.data);
    setGroups(response.data);
  };

  useEffect(() => {
    fetchGroups();
  }, [regName]);

  const [grpNumber, setGrpNumber] = useState([]);
  function setGroupData(e) {
    const selectedGroup = groups.find((group) => group.group.toLowerCase() === e.target.value);
    setGrpName(e.target.value);
    setGrpNumber(selectedGroup.id);
  }

  return (
    <div>
      <SecurePageSection accessableUsers={"admin,region"} />
      <Container>
        <Form onSubmit={FormSubmit}>
          <Row>
            <Col md={8}>
              <h2>Region Mangalyatra Reporting Form</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Chairman Email</Form.Label>
                <Form.Control
                  placeholder="E-mail"
                  name="chairEmail"
                  value={chairEmail}
                  onChange={(e) => setchairEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Cell</Form.Label>
                <Form.Control
                  placeholder="Cell Number"
                  name="mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="meetingDay">
              <Form.Label>Region Name</Form.Label>
              <Form.Select
                type="text"
                name="regName"
                value={regName}
                onChange={(e) => setRegName(e.target.value)}
              >
                <option>Select your Region</option>
                {regions.map((region) => (
                  <option
                    style={{ textTransform: "capitalize" }}
                    value={region.region.toLowerCase()}
                  >
                    {region.region.charAt(0).toUpperCase() + region.region.slice(1).toLowerCase()}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="grpName">
              <Form.Label>Group Name</Form.Label>
              <Form.Select name="grpName" value={grpName} onChange={(e) => setGroupData(e)}>
                <option>Select your Group</option>
                {groups.map((group) => (
                  <option style={{ textTransform: "capitalize" }} value={group.group.toLowerCase()}>
                    {group.group.charAt(0).toUpperCase() + group.group.slice(1).toLowerCase()}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="grpNumber">
              <Form.Label>Group Number</Form.Label>
              <Form.Control
                name="grpNumber"
                value={grpNumber}
                // onChange={(e) => setGrpNumber(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Event Name</Form.Label>
                <Form.Control
                  placeholder="Event Name"
                  name="eName"
                  value={eName}
                  onChange={(e) => setEName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Event Date</Form.Label>
                <Form.Control
                  placeholder="Event Date"
                  type="date"
                  name="eDate"
                  value={eDate}
                  onChange={(e) => setEDate(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Event Time</Form.Label>
                <Form.Control
                  type="time"
                  placeholder="Time"
                  name="eTime"
                  value={eTime}
                  onChange={(e) => setETime(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Event Venue</Form.Label>
                <Form.Control
                  placeholder="Venue"
                  name="eVenue"
                  value={eVenue}
                  onChange={(e) => setEVenue(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Host Group Name</Form.Label>
                <Form.Control
                  placeholder="Host Group Name"
                  name="hostGrpName"
                  value={hostGrpName}
                  onChange={(e) => setHostGrpName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Form.Label>JSGIF Dignitaries:</Form.Label>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="fedDigName1"
                  value={fedDigName1}
                  onChange={(e) => setFedDigName1(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="fedDigPost1"
                  value={fedDigPost1}
                  onChange={(e) => setFedDigPost1(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="fedDigName2"
                  value={fedDigName2}
                  onChange={(e) => setFedDigName2(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="fedDigPost2"
                  value={fedDigPost2}
                  onChange={(e) => setFedDigPost2(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="fedDigName3"
                  value={fedDigName3}
                  onChange={(e) => setFedDigName3(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="fedDigPost3"
                  value={fedDigPost3}
                  onChange={(e) => setFedDigPost3(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="fedDigName4"
                  value={fedDigName4}
                  onChange={(e) => setFedDigName4(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="fedDigPost4"
                  value={fedDigPost4}
                  onChange={(e) => setFedDigPost4(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="fedDigName5"
                  value={fedDigName5}
                  onChange={(e) => setFedDigName5(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="fedDigPost5"
                  value={fedDigPost5}
                  onChange={(e) => setFedDigPost5(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="fedDigName6"
                  value={fedDigName6}
                  onChange={(e) => setFedDigName6(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="fedDigPost6"
                  value={fedDigPost6}
                  onChange={(e) => setFedDigPost6(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="fedDigName7"
                  value={fedDigName7}
                  onChange={(e) => setFedDigName7(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="fedDigPost7"
                  value={fedDigPost7}
                  onChange={(e) => setFedDigPost7(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="fedDigName8"
                  value={fedDigName8}
                  onChange={(e) => setFedDigName8(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="fedDigPost8"
                  value={fedDigPost8}
                  onChange={(e) => setFedDigPost8(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="fedDigName9"
                  value={fedDigName9}
                  onChange={(e) => setFedDigName9(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="fedDigPost9"
                  value={fedDigPost9}
                  onChange={(e) => setFedDigPost9(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="fedDigName10"
                  value={fedDigName10}
                  onChange={(e) => setFedDigName10(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="fedDigPost10"
                  value={fedDigPost10}
                  onChange={(e) => setFedDigPost10(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Form.Label>JSGIF Region Dignitaries:</Form.Label>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="regDigName1"
                  value={regDigName1}
                  onChange={(e) => setRegDigName1(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="regDigPost1"
                  value={regDigPost1}
                  onChange={(e) => setRegDigPost1(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="regDigName2"
                  value={regDigName2}
                  onChange={(e) => setRegDigName2(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="regDigPost2"
                  value={regDigPost2}
                  onChange={(e) => setRegDigPost2(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="regDigName3"
                  value={regDigName3}
                  onChange={(e) => setRegDigName3(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="regDigPost3"
                  value={regDigPost3}
                  onChange={(e) => setRegDigPost3(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="regDigName4"
                  value={regDigName4}
                  onChange={(e) => setRegDigName4(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="regDigPost4"
                  value={regDigPost4}
                  onChange={(e) => setRegDigPost4(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="regDigName5"
                  value={regDigName5}
                  onChange={(e) => setRegDigName5(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="regDigPost5"
                  value={regDigPost5}
                  onChange={(e) => setRegDigPost5(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="regDigName6"
                  value={regDigName6}
                  onChange={(e) => setRegDigName6(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="regDigPost6"
                  value={regDigPost6}
                  onChange={(e) => setRegDigPost6(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="regDigName7"
                  value={regDigName7}
                  onChange={(e) => setRegDigName7(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="regDigPost7"
                  value={regDigPost7}
                  onChange={(e) => setRegDigPost7(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="regDigName8"
                  value={regDigName8}
                  onChange={(e) => setRegDigName8(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="regDigPost8"
                  value={regDigPost8}
                  onChange={(e) => setRegDigPost8(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="regDigName9"
                  value={regDigName9}
                  onChange={(e) => setRegDigName9(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="regDigPost9"
                  value={regDigPost9}
                  onChange={(e) => setRegDigPost9(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="regDigName10"
                  value={regDigName10}
                  onChange={(e) => setRegDigName10(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="regDigPost10"
                  value={regDigPost10}
                  onChange={(e) => setRegDigPost10(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Form.Label>Other VIP / Dignitaries:</Form.Label>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="vipDigName1"
                  value={vipDigName1}
                  onChange={(e) => setVipDigName1(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="vipDigPost1"
                  value={vipDigPost1}
                  onChange={(e) => setVipDigPost1(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="vipDigName2"
                  value={vipDigName2}
                  onChange={(e) => setVipDigName2(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="vipDigPost2"
                  value={vipDigPost2}
                  onChange={(e) => setVipDigPost2(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="vipDigName3"
                  value={vipDigName3}
                  onChange={(e) => setVipDigName3(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="vipDigPost3"
                  value={vipDigPost3}
                  onChange={(e) => setVipDigPost3(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="vipDigName4"
                  value={vipDigName4}
                  onChange={(e) => setVipDigName4(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="vipDigPost4"
                  value={vipDigPost4}
                  onChange={(e) => setVipDigPost4(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="vipDigName5"
                  value={vipDigName5}
                  onChange={(e) => setVipDigName5(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>Post</Form.Label>
                <Form.Control
                  placeholder="Post"
                  name="vipDigPost5"
                  value={vipDigPost5}
                  onChange={(e) => setVipDigPost5(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Form.Label>Event Details (max in 200 words):</Form.Label>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label></Form.Label>
                <Form.Control
                  placeholder="Event Short information to be given in max 200 words only."
                  name="eDetails"
                  value={eDetails}
                  onChange={(e) => setEDetails(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Form.Label>Event Photos (Max Five Photos)</Form.Label>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>EventName1.jpg</Form.Label>
                <Form.Control
                  placeholder="Photo1"
                  type="file"
                  accept="image/jpg, image/jpeg"
                  name="ePhoto1"
                  onChange={(e) => setEPhoto1(e.target.files[0])}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>EventName2.jpg</Form.Label>
                <Form.Control
                  placeholder="Photo2"
                  type="file"
                  accept="image/jpg, image/jpeg"
                  name="ePhoto2"
                  onChange={(e) => setEPhoto2(e.target.files[0])}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>EventName3.jpg</Form.Label>
                <Form.Control
                  placeholder="Photo3"
                  type="file"
                  accept="image/jpg, image/jpeg"
                  name="ePhoto3"
                  onChange={(e) => setEPhoto3(e.target.files[0])}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>EventName4.jpg</Form.Label>
                <Form.Control
                  placeholder="Photo4"
                  type="file"
                  accept="image/jpg, image/jpeg"
                  name="ePhoto4"
                  onChange={(e) => setEPhoto4(e.target.files[0])}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formGridZip">
                <Form.Label>EventName5.jpg</Form.Label>
                <Form.Control
                  placeholder="Photo5"
                  type="file"
                  accept="image/jpg, image/jpeg"
                  name="ePhoto5"
                  onChange={(e) => setEPhoto5(e.target.files[0])}
                />
              </Form.Group>
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>}
            >
              <Button className="btn--outline--dark" disabled>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button className="btn--outline--dark" onClick={(e) => FormSubmit(e)}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default RegionMangalyatraForm;
