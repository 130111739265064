import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const FedNomintaionIDForm = () => {
  const user = useSelector((state) => state.authentication.user);

  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [resAddress, setResAddress] = useState("");
  const [offAddress, setOffAddress] = useState("");
  const [stdCode, setStdCode] = useState("");
  const [resLandline, setResLandline] = useState("");
  const [offLandline, setOffLandline] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [regName, setRegName] = useState("");
  const [grpName, setGrpName] = useState("");
  const [grpAddress, setGrpAddress] = useState("");
  const [grpPosition, setGrpPosition] = useState("");
  const [grpPresidentYr, setGrpPresidentYr] = useState("");
  const [grpEstd, setGrpEstd] = useState("");
  const [grpCharted, setGrpCharted] = useState("");
  const [jsgOBPost1, setJsgOBPost1] = useState("");
  const [jsgOBYear1, setJsgOBYear1] = useState("");
  const [jsgOBPost2, setJsgOBPost2] = useState("");
  const [jsgOBYear2, setJsgOBYear2] = useState("");
  const [grpElectionDate, setGrpElectionDate] = useState("");
  const [obElectionDate, setObElectionDate] = useState("");
  const [grpPresident, setGrpPresident] = useState("");
  const [grpSecretary, setGrpSecretary] = useState("");
  const [emailList, setEmailList] = useState("");
  const [emailListFinal, setEmailListFinal] = useState("");

  const [emailInput, setEmailInput] = useState("");
  const handleInputChange = (e) => {
    setEmailInput(e.target.value);
    const newValue = e.target.value.replace(/\s/g, ""); // replace all spaces with empty string
    setEmailList(newValue);
    console.log(newValue);
  };
  const redirectToResponse = (id) => {
    navigate(`/fed-nomination-id-response/${id}`, { replace: true });
  };

  const navigate = useNavigate();

  async function FormSubmit(event) {
    event.preventDefault();
    setEmailListFinal(email + "" + emailList);
    let formField = new FormData();

    formField.append("user", user.user_id);
    formField.append("name", name);
    formField.append("date", date);
    formField.append("resAddress", resAddress);
    formField.append("offAddress", offAddress);
    formField.append("stdCode", stdCode);
    formField.append("resLandline", resLandline);
    formField.append("offLandline", offLandline);
    formField.append("mobile", mobile);
    formField.append("email", email);
    formField.append("regName", regName.toLowerCase());
    formField.append("grpName", grpName.toLowerCase());
    formField.append("grpAddress", grpAddress);
    formField.append("grpPosition", grpPosition);
    formField.append("grpPresidentYr", grpPresidentYr);
    formField.append("grpEstd", grpEstd);
    formField.append("grpCharted", grpCharted);
    formField.append("jsgOBPost1", jsgOBPost1);
    formField.append("jsgOBYear1", jsgOBYear1);
    formField.append("jsgOBPost2", jsgOBPost2);
    formField.append("jsgOBYear2", jsgOBYear2);
    formField.append("grpElectionDate", grpElectionDate);
    formField.append("obElectionDate", obElectionDate);
    formField.append("grpPresident", grpPresident);
    formField.append("grpSecretary", grpSecretary);
    formField.append("emailList", emailListFinal);

    await axios({
      method: "post",
      url: `/api/forms/federation/fed-nomination-id/`,
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        redirectToResponse(response.data.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get("/user/region");

    setRegions(response.data.sort((a, b) => a.region.localeCompare(b.region)));
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups`);

    setGroups(response.data);
  };

  useEffect(() => {
    fetchGroups();
  }, [regName]);

  const tutorials = () => {
    navigate("/tutorial/fed-id-nomination");
  };

  return (
    <div>
      <Container>
        <Form onSubmit={FormSubmit}>
          <Row>
            <Col md={8}>
              <h2>Federation ID Nomination Form</h2>
            </Col>
            <Col>
              <Button
                className="btn--primary"
                onClick={tutorials}
                style={{ marginRight: "10px" }}
              >
                Tutorial
              </Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  placeholder="Full Name of the Candidate"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Region</Form.Label>
                <Form.Select
                  type="text"
                  name="regName"
                  defaultValue={regName}
                  onChange={(e) => setRegName(e.target.value)}
                >
                  <option>Select your Region</option>
                  {regions.map((region) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() +
                        region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Residence Address</Form.Label>
                <Form.Control
                  placeholder="Residence Address"
                  name="resAddress"
                  value={resAddress}
                  onChange={(e) => setResAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Office Address</Form.Label>
                <Form.Control
                  placeholder="Office Address"
                  name="offAddress"
                  value={offAddress}
                  onChange={(e) => setOffAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>STD Code</Form.Label>
                <Form.Control
                  placeholder="STD Code"
                  name="stdCode"
                  value={stdCode}
                  onChange={(e) => setStdCode(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Residence Landline</Form.Label>
                <Form.Control
                  placeholder="Residence Landline"
                  name="resLandline"
                  value={resLandline}
                  onChange={(e) => setResLandline(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Office Landline</Form.Label>
                <Form.Control
                  placeholder="Office Landline"
                  name="offLandline"
                  value={offLandline}
                  onChange={(e) => setOffLandline(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  placeholder="Candidate Mobile Number"
                  name="mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  placeholder="Address"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Member of Group</Form.Label>
                <Form.Select
                  type="text"
                  name="grpName"
                  defaultValue={grpName}
                  onChange={(e) => setGrpName(e.target.value)}
                >
                  <option>Select your Group</option>
                  {groups.map((group) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      value={group.group.toLowerCase()}
                    >
                      {group.group.charAt(0).toUpperCase() +
                        group.group.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Position in the Group</Form.Label>
                <Form.Control
                  placeholder="Current position in Group"
                  name="grpPosition"
                  value={grpPosition}
                  onChange={(e) => setGrpPosition(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Group Address</Form.Label>
                <Form.Control
                  placeholder="Group Address"
                  name="grpAddress"
                  value={grpAddress}
                  onChange={(e) => setGrpAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Served as Group President during the Year
                </Form.Label>
                <Form.Control
                  placeholder="20XX"
                  name="grpPresidentYr"
                  value={grpPresidentYr}
                  onChange={(e) => setGrpPresidentYr(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  My Group was established during the Year
                </Form.Label>
                <Form.Control
                  placeholder="20XX"
                  name="grpEstd"
                  value={grpEstd}
                  onChange={(e) => setGrpEstd(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>My Group was chartered on</Form.Label>
                <Form.Control
                  type="date"
                  name="grpCharted"
                  value={grpCharted}
                  onChange={(e) => setGrpCharted(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Election of the Group was held on</Form.Label>
                <Form.Control
                  type="date"
                  name="grpElectionDate"
                  value={grpElectionDate}
                  onChange={(e) => setGrpElectionDate(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>JSGIF or Region OB Post 1</Form.Label>
                <Form.Control
                  placeholder="Name of the post 1"
                  name="jsgOBPost1"
                  value={jsgOBPost1}
                  onChange={(e) => setJsgOBPost1(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>JSGIF or Region OB Year 1</Form.Label>
                <Form.Control
                  placeholder="20XX"
                  name="jsgOBYear1"
                  value={jsgOBYear1}
                  onChange={(e) => setJsgOBYear1(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>JSGIF or Region OB Post 2</Form.Label>
                <Form.Control
                  placeholder="Name of the post 2"
                  name="jsgOBPost2"
                  value={jsgOBPost2}
                  onChange={(e) => setJsgOBPost2(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>JSGIF or Region OB Year 2</Form.Label>
                <Form.Control
                  placeholder="20XX"
                  name="jsgOBYear2"
                  value={jsgOBYear2}
                  onChange={(e) => setJsgOBYear2(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Election of the Office Bearers was held on
                </Form.Label>
                <Form.Control
                  type="date"
                  name="obElectionDate"
                  value={obElectionDate}
                  onChange={(e) => setObElectionDate(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Current President of the Group</Form.Label>
                <Form.Control
                  placeholder="Full name of the Group President"
                  name="grpPresident"
                  value={grpPresident}
                  onChange={(e) => setGrpPresident(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Current Secretary of the Group</Form.Label>
                <Form.Control
                  placeholder="Full name of the Group Secretary"
                  name="grpSecretary"
                  value={grpSecretary}
                  onChange={(e) => setGrpSecretary(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Form.Group>
              <Form.Label>Mail the Notice to</Form.Label>
              <Form.Control
                placeholder="Add each email address after a comma. Example = xyz@gmail.com, abc@gmail.com"
                name="emailInput"
                value={emailInput}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>
          </Row>

          <Button className="btn--outline--dark" type="submit">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default FedNomintaionIDForm;
