import React from "react";
import { Row } from "react-bootstrap";

function Ventures() {
  return (
    <div style={{ textAlign: "center" }}>
      <h2>Ventures</h2>
      <div style={{ textAlign: "left", color: "rgb(17,20,78)" }}>
        <h3 style={{}}>
          JSGIF is starting up with 6 ventures under its flag. All the details
          regarding these ventures will be published shortly. For now here are
          the names of all the ventures.
        </h3>
        <Row>
          <h4>{"> "}Shrot</h4>
          <h4>{"> "}Udaan</h4>
          <h4>{"> "}ASK JSG</h4>
          <h4>{"> "}Eminence</h4>
          <h4>{"> "}Runbhoomi</h4>
          <h4>{"> "}Beti Bachao Beti Padhao</h4>
          <h4>{"> "}Gurukul</h4>
        </Row>
      </div>
    </div>
  );
}

export default Ventures;
