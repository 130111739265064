import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../../SecurePageSection";

const UdaanForm = () => {
  const user = useSelector((state) => state.authentication.user);

  const [grpName, setgrpName] = useState("");
  const [grpPres, setgrpPres] = useState("");
  const [grpSec, setgrpSec] = useState("");
  const [regName, setregName] = useState("");
  const [regChair, setregChair] = useState("");
  const [regSec, setregSec] = useState("");
  const [regConvenor, setregConvenor] = useState("");
  const [grpLocation, setgrpLocation] = useState("");
  const [cName, setcName] = useState("");
  const [cEmail, setcEmail] = useState("");
  const [cDob, setcDob] = useState("");
  const [cWhatsapp, setcWhatsapp] = useState("");
  const [cAddress, setcAddress] = useState("");
  const [cSex, setcSex] = useState("");
  const [cMarital, setcMarital] = useState("");
  const [cSpouse, setcSpouse] = useState("");
  const [cProfession, setcProfession] = useState("");
  const [cField, setcField] = useState("");
  const [photos, setphotos] = useState(null);
  const [awardDate, setawardDate] = useState("");
  const [awardDescription, setawardDescription] = useState("");
  const [awardAuthority, setawardAuthority] = useState("");
  const [grpEmails, setGrpEmails] = useState("");
  const [regEmails, setRegEmails] = useState("");
  const [regConvenorEmail, setRegConvenorEmail] = useState("");

  const navigate = useNavigate();

  const fetchRegEmails = async () => {
    const chairmanResponse = await axios.get(`api/data/${regName}/region-ob/emails/`);
    console.log(chairmanResponse);
    const chairmanEmail = chairmanResponse.data
      .filter((item) => item.post === "chairman")
      .map((item) => item.email);
    const chairmanName = chairmanResponse.data
      .filter((item) => item.post === "chairman")
      .map((item) => item.name);
    const secEmail = chairmanResponse.data
      .filter((item) => item.post === "secretary")
      .map((item) => item.name);

    setregSec(secEmail);
    setRegEmails(chairmanEmail);
    setregChair(chairmanName);

    const convenorResponse = await axios.get(`api/data/udaan/region-convenor/emails/`);
    const convenorEmail = convenorResponse.data
      .filter(
        (item) =>
          item.post ===
            `${regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()} Region Convenor` ||
          item.post ===
            `${regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()} Region Coordinator`
      )
      .map((item) => item.email);

    const convenorName = convenorResponse.data
      .filter(
        (item) =>
          item.post ===
            `${regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()} Region Convenor` ||
          item.post ===
            `${regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()} Region Coordinator`
      )
      .map((item) => item.name);
    setregConvenor(convenorName);
    setRegConvenorEmail(convenorEmail);
  };

  const fetchGrpEmails = async () => {
    const grpResponse = await axios.get(`api/data/${grpName}/group/emails/`);
    console.log(grpResponse.data);

    const presName = grpResponse.data
      .filter((item) => item.grpName === grpName)
      .map((item) => item.presName);
    const secName = grpResponse.data
      .filter((item) => item.grpName === grpName)
      .map((item) => item.secName);

    const presEmail = grpResponse.data
      .filter((item) => item.grpName === grpName)
      .map((item) => item.presEmail);
    const secEmail = grpResponse.data
      .filter((item) => item.grpName === grpName)
      .map((item) => item.secEmail);

    setGrpEmails(presEmail, secEmail);
    setgrpPres(presName);
    setgrpSec(secName);
  };

  useEffect(() => {
    fetchRegEmails();
  }, [regName]);

  useEffect(() => {
    fetchGrpEmails();
  }, [grpName]);

  async function FormSubmit(event) {
    event.preventDefault();
    let formField = new FormData();

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,office@jsgif.co.in,dj22jsgif@gmail.com,preeti.k4040@gmail.com," +
      "manishdoshi9999@gmail.com,bakuleshvirani@gmail.com,adshah1952@gmail.com," +
      cEmail +
      "," +
      regEmails +
      "," +
      grpEmails +
      "," +
      regConvenorEmail;

    formField.append("user", user?.user_id);
    formField.append("regName", regName);
    formField.append("grpName", grpName);
    formField.append("grpName", grpName);
    formField.append("grpPres", grpPres);
    formField.append("grpSec", grpSec);
    formField.append("regName", regName);
    formField.append("regChair", regChair);
    formField.append("regSec", regSec);
    formField.append("regConvenor", regConvenor);
    formField.append("grpLocation", grpLocation);
    formField.append("cName", cName);
    formField.append("cDob", cDob);
    formField.append("cWhatsapp", cWhatsapp);
    formField.append("cAddress", cAddress);
    formField.append("cSex", cSex);
    formField.append("cMarital", cMarital);
    formField.append("cSpouse", cSpouse);
    formField.append("cProfession", cProfession);
    formField.append("cField", cField);
    formField.append("awardDate", awardDate);
    formField.append("awardDescription", awardDescription);
    formField.append("awardAuthority", awardAuthority);

    if (photos !== null) {
      for (let i = 0; i < photos.length; i++) {
        const fieldName = `photo${i}`;
        formField.append(fieldName, photos[i]);
      }
    }

    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/forms/ventures/udaan-group-applications/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        navigate(`/udaan-group-form/response/${response.data.id}`, { replace: true });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get(`/user/region/`);
    setRegions(response.data);
  };
  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups/`);
    setGroups(response.data);
  };
  useEffect(() => {
    fetchGroups();
  }, [regName]);

  return (
    <div>
      <SecurePageSection accessableUsers={"none"} />
      <Container>
        <Form>
          <Row>
            <Col md={8}>
              <h2>Udaan Application Form</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Name</Form.Label>
                <Form.Select
                  name="regName"
                  value={regName}
                  onChange={(e) => setregName(e.target.value)}
                >
                  <option>Select your Region</option>
                  {regions.map((region, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() + region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Chairman Name</Form.Label>
                <Form.Control
                  style={{ textTransform: "capitalize" }}
                  name="regChair"
                  value={regChair}
                  placeholder="Name of the Region Chairman"
                  onChange={(e) => setregChair(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Secretary Name</Form.Label>
                <Form.Control
                  style={{ textTransform: "capitalize" }}
                  name="regSec"
                  value={regSec}
                  placeholder="Name of the Region Secretary"
                  onChange={(e) => setregSec(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Convenor Name</Form.Label>
                <Form.Control
                  style={{ textTransform: "capitalize" }}
                  name="regConvenor"
                  value={regConvenor}
                  placeholder="Name of the Region's Udaan Convenor"
                  onChange={(e) => setregConvenor(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Group Name</Form.Label>
                <Form.Select
                  name="grpName"
                  value={grpName}
                  onChange={(e) => setgrpName(e.target.value)}
                >
                  <option>Select your Group</option>
                  {groups.map((group, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={group.group.toLowerCase()}
                    >
                      {group.group.charAt(0).toUpperCase() + group.group.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Group President Name</Form.Label>
                <Form.Control
                  style={{ textTransform: "capitalize" }}
                  name="grpPres"
                  value={grpPres}
                  placeholder="Name of the Group President"
                  onChange={(e) => setgrpPres(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Group Secretary Name</Form.Label>
                <Form.Control
                  style={{ textTransform: "capitalize" }}
                  name="grpSec"
                  value={grpSec}
                  placeholder="Name of the Group Secretary"
                  onChange={(e) => setgrpSec(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Group City</Form.Label>
                <Form.Control
                  name="grpLocation"
                  value={grpLocation}
                  placeholder="Location/City of the Group"
                  onChange={(e) => setgrpLocation(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Candidate Name</Form.Label>
                <Form.Control
                  name="cName"
                  value={cName}
                  placeholder="Name of the Candidate"
                  onChange={(e) => setcName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Candidate Email</Form.Label>
                <Form.Control
                  name="cEmail"
                  value={cEmail}
                  placeholder="Email of the Candidate"
                  onChange={(e) => setcEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="cDob"
                  value={cDob}
                  onChange={(e) => setcDob(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  name="cWhatsapp"
                  value={cWhatsapp}
                  placeholder="Candidate's Whatsapp Number"
                  onChange={(e) => setcWhatsapp(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="cAddress"
                  value={cAddress}
                  placeholder="Candidate's Full Address"
                  onChange={(e) => setcAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Gender</Form.Label>
                <Form.Select name="cSex" value={cSex} onChange={(e) => setcSex(e.target.value)}>
                  <option value="Male">Select your Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Marital Status</Form.Label>
                <Form.Select
                  name="cMarital"
                  value={cMarital}
                  onChange={(e) => setcMarital(e.target.value)}
                >
                  <option value="Male">Select your Marital Status</option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Divorcee">Divorcee</option>
                  <option value="Widow">Widow</option>
                </Form.Select>
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Name</Form.Label>
                <Form.Control
                  name="cSpouse"
                  value={cSpouse}
                  placeholder="Name of Candidate's Spouse"
                  onChange={(e) => setcSpouse(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Candidate's Profession</Form.Label>
                <Form.Control
                  name="cProfession"
                  value={cProfession}
                  placeholder="Candidate's Profession"
                  onChange={(e) => setcProfession(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Candidate's Field of work</Form.Label>
                <Form.Control
                  name="cField"
                  value={cField}
                  placeholder="Name of Candidate's Spouse"
                  onChange={(e) => setcField(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Group>
                  <Form.Label>
                    Photos
                    <span style={{ fontSize: "18px" }}>(Maximum - 5)</span>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="ePhotos"
                    multiple
                    accept="image/*"
                    onChange={(e) => {
                      const files = e.target.files;
                      if (files.length <= 5) {
                        setphotos(files);
                      } else {
                        window.alert("Maximum of 5 Images allowed.");
                      }
                    }}
                  />
                </Form.Group>
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Award Date</Form.Label>
                <Form.Control
                  name="awardDate"
                  value={awardDate}
                  type="date"
                  onChange={(e) => setawardDate(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Issuing Authority</Form.Label>
                <Form.Control
                  name="awardAuthority"
                  value={awardAuthority}
                  placeholder="Brief description of the Award"
                  onChange={(e) => setawardAuthority(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Award Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="awardDescription"
                  value={awardDescription}
                  placeholder="Brief description of the Award"
                  onChange={(e) => setawardDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button className="btn--outline--dark" onClick={(e) => FormSubmit(e)}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default UdaanForm;
