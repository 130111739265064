import React, { useState, useRef, useEffect } from "react";
import "./MannKiBaat.css";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Image, Accordion } from "react-bootstrap";

import Images from "../images";
import { LinkContainer } from "react-router-bootstrap";

function MannKiBaat() {
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <Row>
          <Col style={{ textAlign: "left" }}>
            <h1>Mann Ki Baat</h1>
          </Col>
        </Row>
        <div>
          <Row style={{ marginTop: "5px" }}>
            {/* <Col
              xs={12}
              md={3}
              lg={2}
              style={{ marginTop: "10px", textAlign: "center" }}
            >
              <LinkContainer to="/mann-ki-baat/6">
                <Button className="btn--secondary">
                  Mann Ki Baat - Editon 6
                </Button>
              </LinkContainer>
            </Col> */}
            <Col
              xs={12}
              md={3}
              lg={2}
              style={{ marginTop: "10px", textAlign: "center" }}
            >
              <LinkContainer to="/mann-ki-baat/5">
                <Button className="btn--secondary">
                  Mann Ki Baat - Editon 5
                </Button>
              </LinkContainer>
            </Col>
            <Col
              xs={12}
              md={3}
              lg={2}
              style={{ marginTop: "10px", textAlign: "center" }}
            >
              <LinkContainer to="/mann-ki-baat/4">
                <Button className="btn--secondary">
                  Mann Ki Baat - Editon 4
                </Button>
              </LinkContainer>
            </Col>
            <Col
              xs={12}
              md={3}
              lg={2}
              style={{ marginTop: "10px", textAlign: "center" }}
            >
              <LinkContainer to="/mann-ki-baat/3">
                <Button className="btn--secondary">
                  Mann Ki Baat - Editon 3
                </Button>
              </LinkContainer>
            </Col>
            <Col
              xs={12}
              md={3}
              lg={2}
              style={{ marginTop: "10px", textAlign: "center" }}
            >
              <LinkContainer to="/mann-ki-baat/2">
                <Button className="btn--secondary">
                  Mann Ki Baat - Editon 2
                </Button>
              </LinkContainer>
            </Col>
            <Col
              xs={12}
              md={3}
              lg={2}
              style={{ marginTop: "10px", textAlign: "center" }}
            >
              <LinkContainer to="/mann-ki-baat/1">
                <Button className="btn--secondary">
                  Mann Ki Baat - Editon 1
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default MannKiBaat;
