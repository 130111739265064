import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Images from "../../images/index";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

import "../Response.css";
import SecurePageSection from "../../SecurePageSection";

function PariResponse() {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponses] = useState([]);
  const responseId = useParams();
  const ref = React.createRef();

  const getResponse = async () => {
    const res = await axios.get(
      `/api/forms/ventures/pari-application/${responseId.id}`
    );
    setResponses(res.data);
  };

  useEffect(() => {
    getResponse();
  }, []);

  const navigate = useNavigate();

  const editForm = () => {
    navigate(`/form/grpagm/${response.id}/edit`);
  };

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("", "blank", "height=1123,width=800,scrollbars=yes,");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>Form B</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    html {
      overflow-y: auto;
    }

    .fResponseBody {
      padding: 20px 20px;
      width: 800px;
      min-height: 1123px;
      background: #fff;
    }
    
    .page{
      page-break-after: always;
    }
    
    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }
    
    
    .section2, .section3{
      margin-top: 30px;
    }
    
    .section4{
      margin-top: 20px;
    }
    
    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }
    
    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }
    
    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }
    
    
    .bordered-mid-row{
      padding: 2px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }
    
    .bordered-row-b{
      padding: 2px 0;
      border: 1px solid #000;
      height: 30px;
    }
    
    .bordered-col-b{
      padding: 2px 5px;
      border: 1px solid #000;
      height: 30px;
    }
    
    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }
    
    
    .bannerText {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    
    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }
    
    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }
    
    .toptext {
      margin: 20px 0;
    }
    
    .logoImage {
      height: 80px;
      margin: 10px;
    }
    
    .bannerYellow {
      background: #face1d;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }
    
    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }
    
    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }
    
    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    
    .H2double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 16px;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    
    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
    
    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
    
    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }
    
    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }
    
    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }
    
    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }
    
    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }`);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
    }, 1000);
  };

  async function handleStatus(event, status) {
    event.preventDefault();
    let formField = new FormData();

    formField.append("Status", status);

    await axios({
      method: "put",
      url: `/api/forms/ventures/pari-application/${responseId.id}/`,
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        window.location.reload();
      })
      .catch(function(error) {
        console.log(error);
      });

    console.log("status", status);
  }

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Pari Response</h2>
      <Row style={{ marginBottom: "1em", marginTop: "1em" }}>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h4>Current Status : {response.Status}</h4>
          <Button onClick={printDocument} style={{ margin: "0 5px" }}>
            Download Form
          </Button>
        </Col>
      </Row>
      {(() => {
        if (
          (typeof user !== "undefined" &&
            typeof user.username !== "undefined" &&
            user.username === "JSG0001") ||
          (typeof user !== "undefined" &&
            typeof user.username !== "undefined" &&
            user.username === "JSG0019") ||
          (typeof user !== "undefined" &&
            typeof user.username !== "undefined" &&
            user.username === "admin") ||
          (typeof user !== "undefined" &&
            typeof user.username !== "undefined" &&
            user.accessGroup === "pariChair")
        ) {
          return (
            <Row style={{ marginBottom: "1em", marginTop: "1em" }}>
              <Col className="d-flex justify-content-center">
                <Button
                  onClick={(e) => handleStatus(e, "Approved")}
                  className="btn-approve-hover"
                >
                  Approve
                </Button>
                <Button
                  onClick={(e) => handleStatus(e, "Rejected")}
                  className="btn-reject-hover"
                >
                  Reject
                </Button>
              </Col>
            </Row>
          );
        }
      })()}
      <div id="mainForm" className="centerFormat">
        <div className="fResponseBody">
          <div className="page">
            <div className="bannerYellow">
              <Row className="T-Banner">
                <Col xs={1}>
                  <img
                    src={Images.LogoB}
                    className="logoImage"
                    alt="jsgBanner"
                  ></img>
                </Col>
                <Col xs={11} className="bannerText text-center">
                  <h1
                    style={{
                      margin: "0",
                    }}
                  >
                    JAIN SOCIAL GROUP INT. FEDERATION
                  </h1>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    4-O/P, Vijay Chambers, Opp. Dreamland Cinema, Tribhuvan
                    Road, Mumbai - 400 004. (India)
                  </p>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Tel.: 022-23870724 / 23891884 Mobile : 8169274400
                  </p>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    E-mail : office@jsgif.co.in Website : www.jsgif.co.i
                  </p>
                </Col>
              </Row>
            </div>
            <Row>
              <h2 style={{ textAlign: "center" }}>Pari</h2>
            </Row>
            <Row>
              <h6 style={{ textTransform: "capitalize" }}>
                Region - {response.regName} | Group - {response.grpName}
              </h6>
            </Row>
            <div style={{ border: "1px solid #000", padding: "15px" }}>
              <Row
                style={{
                  padding: "1px 10px",
                  textAlign: "left",
                }}
              >
                <Col>
                  <Row>
                    <h3
                      className="H3single-enter"
                      style={{ textAlign: "center", fontSize: "18px" }}
                    >
                      Daughter's Details
                    </h3>
                    <Col xs={9}>
                      <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                        <Col
                          xs={3}
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          <p className="Tsingle-enter-label">Name</p>
                        </Col>
                        <Col className="bordered-col-b" xs={9}>
                          <p className="Tsingle-enter">{response.DName}</p>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                        <Col
                          xs={3}
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          <p className="Tsingle-enter-label">Date of Birth</p>
                        </Col>
                        <Col xs={3} className="bordered-col-b">
                          <p className="Tsingle-enter">{response.DDob}</p>
                        </Col>
                        <Col
                          xs={3}
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          <p className="Tsingle-enter-label">Time of Birth</p>
                        </Col>
                        <Col xs={3} className="bordered-col-b">
                          <p className="Tsingle-enter">{response.DTime}</p>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                        <Col
                          xs={3}
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          <p className="Tsingle-enter-label">Hospital Name</p>
                        </Col>
                        <Col className="bordered-col-b" xs={4}>
                          <p className="Tsingle-enter">
                            {response.DHospitalName}
                          </p>
                        </Col>
                        <Col
                          xs={2}
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          <p className="Tsingle-enter-label">Place of Birth</p>
                        </Col>
                        <Col className="bordered-col-b" xs={3}>
                          <p className="Tsingle-enter">{response.DPlace}</p>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={3}>
                      <Row
                        style={{
                          marginTop: "2px",
                          marginBottom: "2px",
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Col
                          style={{
                            marginLeft: "6px",
                            maxWidth: "120px",
                            height: "160px",
                          }}
                        >
                          <Image
                            style={{
                              borderColor: "#000",
                              borderStyle: "solid",
                              borderWidth: "1px 1px 1px 1px",
                              padding: "5px",
                            }}
                            src={response.DPhoto}
                            alt={response.DName}
                            fluid
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col style={{ padding: "0 10px", textAlign: "center" }}>
                  <h6 style={{ margin: "0" }}>Birth Certificate</h6>
                  <Image
                    src={response.DBirthCertificate}
                    alt={response.DName}
                    fluid
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div
            style={{
              border: "1px solid #000",
              padding: "15px",
              marginTop: "20px",
            }}
          >
            <Row
              style={{
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <h3
                    className="H3single-enter"
                    style={{ textAlign: "center", fontSize: "18px" }}
                  >
                    Father's Details
                  </h3>
                  <Col xs={9}>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col
                        xs={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">{response.FName}</p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        height: "60px",
                      }}
                    >
                      <Col
                        xs={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">{response.FAddress}</p>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col
                        xs={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.FMobile}</p>
                      </Col>
                      <Col
                        xs={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        <p className="Tsingle-enter-label">Whatsapp No.</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.FWhatsapp}</p>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col
                        xs={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">{response.FEmail}</p>
                      </Col>
                      <Col
                        xs={2}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        <p className="Tsingle-enter-label">Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">{response.FDob}</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col
                        xs={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        <p className="Tsingle-enter-label">
                          Occupation Details
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">{response.FProfession}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        style={{
                          marginLeft: "6px",
                          maxWidth: "120px",
                          height: "160px",
                        }}
                      >
                        <Image
                          style={{
                            borderColor: "#000",
                            borderStyle: "solid",
                            borderWidth: "1px 1px 1px 1px",
                            padding: "5px",
                          }}
                          src={response.FPhoto}
                          alt={response.FName}
                          fluid
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col style={{ padding: "0 10px", textAlign: "center" }}>
                <h6 style={{ margin: "0" }}>Father's Aadhar Front</h6>
                <Image src={response.FAadharFront} alt={response.FName} fluid />
              </Col>
              <Col style={{ padding: "0 10px", textAlign: "center" }}>
                <h6 style={{ margin: "0" }}>Father's Aadhar Back</h6>
                <Image src={response.FAadharBack} alt={response.FName} fluid />
              </Col>
              <Col style={{ padding: "0 10px", textAlign: "center" }}>
                <h6 style={{ margin: "0" }}>Father's PAN Front</h6>
                <Image src={response.FPanFront} alt={response.FName} fluid />
              </Col>
              <Col style={{ padding: "0 10px", textAlign: "center" }}>
                <h6 style={{ margin: "0" }}>Father's PAN Back</h6>
                <Image src={response.FPanBack} alt={response.FName} fluid />
              </Col>
            </Row>
          </div>
          <div
            style={{
              border: "1px solid #000",
              padding: "15px",
              marginTop: "20px",
            }}
          >
            <Row
              style={{
                padding: "1px 10px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row>
                  <h3
                    className="H3single-enter"
                    style={{ textAlign: "center", fontSize: "18px" }}
                  >
                    Mother's Details
                  </h3>
                  <Col xs={9}>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col xs={3}>
                        <p className="Tsingle-enter-label">Name</p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">{response.MName}</p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        height: "60px",
                      }}
                    >
                      <Col
                        xs={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        <p className="Tsingle-enter-label">
                          Correspondance Address
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">{response.MAddress}</p>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col
                        xs={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        <p className="Tsingle-enter-label">Mobile</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.MMobile}</p>
                      </Col>
                      <Col
                        xs={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        <p className="Tsingle-enter-label">Whatsapp No.</p>
                      </Col>
                      <Col xs={3} className="bordered-col-b">
                        <p className="Tsingle-enter">{response.MWhatsapp}</p>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col
                        xs={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        <p className="Tsingle-enter-label">Email Id</p>
                      </Col>
                      <Col className="bordered-col-b" xs={4}>
                        <p className="Tsingle-enter">{response.MEmail}</p>
                      </Col>
                      <Col
                        xs={2}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        <p className="Tsingle-enter-label">Birth Date</p>
                      </Col>
                      <Col className="bordered-col-b" xs={3}>
                        <p className="Tsingle-enter">{response.MDob}</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2px", marginBottom: "2px" }}>
                      <Col
                        xs={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        <p className="Tsingle-enter-label">
                          Occupation Details
                        </p>
                      </Col>
                      <Col className="bordered-col-b" xs={9}>
                        <p className="Tsingle-enter">{response.MProfession}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        style={{
                          marginLeft: "6px",
                          maxWidth: "120px",
                          height: "160px",
                        }}
                      >
                        <Image
                          style={{
                            borderColor: "#000",
                            borderStyle: "solid",
                            borderWidth: "1px 1px 1px 1px",
                            padding: "5px",
                          }}
                          src={response.MPhoto}
                          alt={response.MName}
                          fluid
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col style={{ padding: "0 10px", textAlign: "center" }}>
                <h6 style={{ margin: "0" }}>Mother's Aadhar Front</h6>
                <Image src={response.MAadharFront} alt={response.MName} fluid />
              </Col>
              <Col style={{ padding: "0 10px", textAlign: "center" }}>
                <h6 style={{ margin: "0" }}>Mother's Aadhar Back</h6>
                <Image src={response.MAadharBack} alt={response.MName} fluid />
              </Col>
              <Col style={{ padding: "0 10px", textAlign: "center" }}>
                <h6 style={{ margin: "0" }}>Mother's PAN Front</h6>
                <Image src={response.MPanFront} alt={response.MName} fluid />
              </Col>
              <Col style={{ padding: "0 10px", textAlign: "center" }}>
                <h6 style={{ margin: "0" }}>Mother's PAN Back</h6>
                <Image src={response.MPanBack} alt={response.MName} fluid />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PariResponse;
