import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Images from "../../images/index";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import "../Response.css";

function RegionNomintaionOBResponse() {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponses] = useState([]);
  const responseId = useParams();

  const getResponse = async () => {
    const res = await axios.get(`/api/forms/region/reg-nomination-ob/${responseId.id}`);
    setResponses(res.data);
  };

  useEffect(() => {
    getResponse();
  }, []);

  const navigate = useNavigate();

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("", "blank", "height=1123,width=800,scrollbars=yes,");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>Form B</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    html {
      overflow-y: auto;
    }

    fResponseBody {
      padding: 20px 20px;
      margin: 0px 20px;
      width: 800px;
      height: 1123px;
      background: #fff;
      overflow-y: auto;
    }

    .page{
      page-break-after: always;
    }

    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }

    .section2, .section3{
      margin-top: 30px;
    }

    .section4{
      margin-top: 20px;
    }

    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }

    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }

    .bordered-mid-row{
      padding: 3px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-col-b{
      padding: 3px 5px;
      border: 1px solid #000;
    }

    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }

    .bannerText {
      margin-top: 20px;
    }

    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }

    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }

    .toptext {
      margin: 20px 0;
    }

    .logoImage {
      height: 80px;
      margin: 10px;
    }

    .bannerYellow {
      background: #ffcc29;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }

    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }

    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }

    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }

    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }

    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }

    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }

    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }

    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }`);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
    }, 1000);
  };

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Region OB Nomination Form Response</h2>
      <Row style={{ marginBottom: "1em", marginTop: "1em", textAlign: "center" }}>
        <Col>
          <Button onClick={printDocument} variant="primary">
            Download Form
          </Button>
        </Col>
      </Row>
      <div id="mainForm" className="centerFormat">
        <div className="fResponseBody" style={{ fontWeight: "600" }}>
          <div className="bannerYellow">
            <Row className="T-Banner">
              <Col xs={1}>
                <img src={Images.LogoB} className="logoImage" alt="jsgBanner"></img>
              </Col>
              <Col xs={11} className="bannerText text-center">
                <h1 style={{ margin: "0" }}>JAIN SOCIAL GROUP INT. FEDERATION</h1>
                <p
                  className="Tsingle-enter"
                  style={{
                    padding: "0 20px",
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  4-O/P, Vijay Chambers, Opp. Dreamland Cinema, Tribhuvan Road, Mumbai - 400 004.
                  (India)
                </p>
                <p
                  className="Tsingle-enter"
                  style={{
                    padding: "0 20px",
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Tel.: 022-23870724 / 23891884 Mobile : 8169274400
                </p>
                <p
                  className="Tsingle-enter"
                  style={{
                    padding: "0 20px",
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  E-mail : office@jsgif.co.in Website : www.jsgif.co.i
                </p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col></Col>
            <Col xs={6}>
              <div
                style={{
                  textAlign: "center",
                  padding: "0 10px",
                  margin: "0",
                  border: "2px solid #000",
                  borderRadius: "15px",
                }}
              >
                <h5
                  style={{
                    fontSize: "18px",
                    fontWeight: "800",
                    padding: "2px 0",
                    margin: "0",
                  }}
                >
                  Nomination Form for Region
                </h5>
              </div>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col xs={10}>
              <p className="Tdouble-enter">To,</p>
              <p className="Tsingle-enter">The Election Commission - 2023-25,</p>
              <p className="Tsingle-enter">JSGIF</p>
              <p className="Tdouble-enter">
                Ref.: Nomination for Election of Office Bearers of Jain Social Groups International
                Federation,
                <span className="response">{response.regName}</span> Region Committee for the year
                2023-25
              </p>
            </Col>
            <Col
              xs={2}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <img
                style={{
                  border: "1px solid #000",
                  width: "90px",
                  height: "120px",
                }}
                src={response.image}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <p className="Tdouble-enter">1)</p>
            </Col>
            <Col xs={4} style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="Tdouble-enter">Full Name of the Candidate</p>
              </div>
              <div>
                <p className="Tdouble-enter">:</p>
              </div>
            </Col>
            <Col
              xs={7}
              style={{
                borderBottom: "1.5px solid #000",
                paddingRight: "0",
              }}
            >
              <p className="Tdouble-enter">{response.name}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <p className="Tdouble-enter">2)</p>
            </Col>
            <Col xs={2}>
              <p className="Tdouble-enter">Address</p>
            </Col>
            <Col xs={2} style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="Tdouble-enter">a) Residence</p>
              </div>
              <div>
                <p className="Tdouble-enter">:</p>
              </div>
            </Col>
            <Col
              xs={7}
              style={{
                borderBottom: "1.5px solid #000",
                paddingRight: "0",
              }}
            >
              <p className="Tdouble-enter">{response.resAddress}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <p className="Tdouble-enter"></p>
            </Col>
            <Col xs={2}>
              <p className="Tdouble-enter"></p>
            </Col>
            <Col xs={2} style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="Tdouble-enter">b) Office</p>
              </div>
              <div>
                <p className="Tdouble-enter">:</p>
              </div>
            </Col>
            <Col
              xs={7}
              style={{
                borderBottom: "1.5px solid #000",
                paddingRight: "0",
              }}
            >
              <p className="Tdouble-enter">{response.offAddress}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <p className="Tdouble-enter">3)</p>
            </Col>
            <Col xs={2}>
              <p className="Tdouble-enter">Phone No.</p>
            </Col>
            <Col xs={2} style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="Tdouble-enter">STD Code</p>
              </div>
              <div>
                <p className="Tdouble-enter">:</p>
              </div>
            </Col>
            <Col
              xs={1}
              style={{
                borderBottom: "1.5px solid #000",
                paddingRight: "0",
              }}
            >
              <p className="Tdouble-enter">{response.stdCode}</p>
            </Col>
            <Col xs={1}>
              <p className="Tdouble-enter">Resi:</p>
            </Col>
            <Col
              xs={2}
              style={{
                borderBottom: "1.5px solid #000",
                padding: "0",
              }}
            >
              <p className="Tdouble-enter">{response.resLandline}</p>
            </Col>
            <Col xs={1}>
              <p className="Tdouble-enter">Off:</p>
            </Col>
            <Col
              xs={2}
              style={{
                borderBottom: "1.5px solid #000",
                padding: "0",
              }}
            ></Col>
          </Row>
          <Row>
            <Col xs={1}>
              <p className="Tdouble-enter"></p>
            </Col>
            <Col xs={2}>
              <p className="Tdouble-enter"></p>
            </Col>
            <Col xs={2} style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="Tdouble-enter">Mobile</p>
              </div>
              <div>
                <p className="Tdouble-enter">:</p>
              </div>
            </Col>
            <Col
              xs={3}
              style={{
                borderBottom: "1.5px solid #000",
                paddingRight: "0",
              }}
            >
              <p className="Tdouble-enter">{response.mobile}</p>
            </Col>
            <Col xs={1}>
              <p className="Tdouble-enter">Email :</p>
            </Col>
            <Col
              xs={3}
              style={{
                borderBottom: "1.5px solid #000",
                padding: "0",
              }}
            >
              <p className="Tdouble-enter">{response.email}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <p className="Tdouble-enter">4)</p>
            </Col>
            <Col xs={4} style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="Tdouble-enter">Name of the Candidate's Group </p>
              </div>
              <div>
                <p className="Tdouble-enter">:</p>
              </div>
            </Col>
            <Col
              xs={7}
              style={{
                borderBottom: "1.5px solid #000",
                paddingRight: "0",
              }}
            >
              <p className="Tdouble-enter">
                JSG{" "}
                <span style={{ textTransform: "capitalize", fontWeight: "600" }}>
                  {response.grpName}
                </span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <p className="Tdouble-enter"></p>
            </Col>
            <Col xs={4} style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="Tdouble-enter">Address of the Candidate's Group</p>
              </div>
              <div>
                <p className="Tdouble-enter">:</p>
              </div>
            </Col>
            <Col
              xs={7}
              style={{
                borderBottom: "1.5px solid #000",
                paddingRight: "0",
              }}
            >
              <p className="Tdouble-enter">{response.grpAddress}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <p className="Tdouble-enter">5)</p>
            </Col>
            <Col xs={4} style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="Tdouble-enter">
                  Present Position in the Group (2023-25) Please attach xerox copy of Form 'B'
                </p>
              </div>
              <div>
                <p className="Tdouble-enter">:</p>
              </div>
            </Col>
            <Col
              xs={7}
              style={{
                borderBottom: "1.5px solid #000",
                paddingRight: "0",
              }}
            >
              <p className="Tdouble-enter">{response.grpPosition}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <p className="Tdouble-enter">6)</p>
            </Col>
            <Col xs={4} style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="Tdouble-enter">Candidate for the post of</p>
              </div>
              <div>
                <p className="Tdouble-enter">:</p>
              </div>
            </Col>
            <Col
              xs={7}
              style={{
                borderBottom: "1.5px solid #000",
                paddingRight: "0",
              }}
            >
              <p className="Tdouble-enter">{response.postOf}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <p className="Tdouble-enter">7)</p>
            </Col>
            <Col>
              <p className="Tdouble-enter">
                Election of the Group for the year 2023-25 was held on{" "}
                {moment(response.grpElectionDate).format("Do MMMM YYYY")} & Election of the Office
                Bearers was held on {moment(response.obElectionDate).format("Do MMMM YYYY")}
                and the same reported to JSGIF in Form 'B' on{" "}
                {moment(response.formbDate).format("Do MMMM YYYY")}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <p className="Tdouble-enter">8)</p>
            </Col>
            <Col>
              <p className="Tdouble-enter">
                The Minimum qualification to contest for Region Office Bearers post is Former
                President of Group, excluding the Founder President, who is General Council Member.
                For the post of Elect Chairman he should have served any office in Region for two
                terms.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="Tdouble-enter">
                I solemnly state that I have read all the rules, By-Laws and Norms regarding the
                Election of JSGIF and I agree to abide by the same without any dispute.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="Tdouble-enter">Yours Sincerely,</p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="Tsingle-enter">{response.name}</p>
                <p className="Tdouble-enter">I/We Confirm what is stated above is true & correct</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col>
              <p className="Tdouble-enter">Name & Signature of the Candidate</p>
              <p className="Tdouble-enter">
                Date -{" "}
                <span
                  style={{
                    fontWeight: "600",
                    fontFamily: "Arial",
                    borderBottom: "1.5px solid #000",
                  }}
                >
                  {moment(response.date).format("Do MMMM YYYY")}
                </span>
              </p>
            </Col>
            <Col style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  borderBottom: "1.5px solid #000",
                }}
              >
                <p className="Tdouble-enter">
                  {response.grpPresident} <br /> ( JSG{" "}
                  <span style={{ textTransform: "capitalize", fontWeight: "600" }}>
                    {response.grpName}{" "}
                  </span>
                  President)
                </p>
                <p className="Tdouble-enter">
                  {response.grpSecretary} <br /> ( JSG{" "}
                  <span style={{ textTransform: "capitalize", fontWeight: "600" }}>
                    {response.grpName}{" "}
                  </span>
                  Secretary)
                </p>
              </div>
              <p className="Tsingle-enter">
                Name & Signature of the Group President (2023-25) or both the Secretaries Jointly
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default RegionNomintaionOBResponse;
