import React, { useState, useRef, useEffect } from "react";
import { Card, Row, Col, Image, Button } from "react-bootstrap";
import logo from "../images/AskJSG.jpg";
import "./ventures.css";
import { useNavigate } from "react-router-dom";
import HTMLFlipBook from "react-pageflip";
import axios from "axios";
import { saveAs } from "file-saver";
import pdfFile from "../images/AskJSG.pdf";
import pdfFile2 from "../images/AskJSG Registration process.pdf";

function AskJsgRegistration() {
  const navigate = useNavigate();

  const zoomBgRedirect = () => {
    navigate(`/zoom-background/ask-jsg`);
  };

  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState("");

  const [bookWidth, setBookWidth] = useState("");
  const [bookHeight, setBookHeight] = useState("");

  const [isPageFlipReady, setIsPageFlipReady] = useState(false);

  const pageFlipRef = useRef();

  const handlePageChange = () => {
    let page = pageFlipRef.current.pageFlip().getCurrentPageIndex();
    setCurrentPage(page);
    console.log(page);
  };

  useEffect(() => {
    if (isPageFlipReady) {
      handlePageChange();
    }
  }, [isPageFlipReady]);

  const prevPage = () => {
    pageFlipRef.current.pageFlip().flipPrev();
  };

  const nextPage = () => {
    pageFlipRef.current.pageFlip().flipNext();
  };

  useEffect(() => {
    const totalPageCounter = () => {
      let counter = pageFlipRef.current.pageFlip().getPageCount();
      setTotalPages(counter);
    };

    if (isPageFlipReady) {
      totalPageCounter();
    }
  }, [isPageFlipReady]);

  const fetchPages = async () => {
    const pageResponse = await axios.get(`/api/book/16`);
    console.log(pageResponse.data);
    setPages(pageResponse.data);
  };

  useEffect(() => {
    fetchPages();
  }, []);

  const downloadPDF = () => {
    saveAs(pdfFile, "Ask JSG Leaflet.pdf");
  };

  const downloadPDF2 = () => {
    saveAs(pdfFile2, "Ask JSG Registration process.pdf");
  };

  const [turnEnable, setTurnEnable] = useState(true);

  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < 768; // or any other breakpoint you prefer
      setTurnEnable(!isMobile);
    }
    handleResize(); // Call handleResize initially to set the initial value of turnEnable
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const divRef = useRef(null);

  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreen = () => {
    const element = divRef.current;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  const handleExitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  useEffect(() => {
    function handleContainer() {
      if (window.innerWidth > 1400) {
        setBookWidth(isFullscreen ? "900px" : "900px");
        setBookHeight(isFullscreen ? "1860px" : "100%");
      } else {
        setBookHeight("1000px");
        setBookWidth("320px");
      }
    }
    handleContainer();
  }, [window.innerWidth, isFullscreen]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
      document.removeEventListener("mozfullscreenchange", handleFullscreenChange);
      document.removeEventListener("MSFullscreenChange", handleFullscreenChange);
    };
  }, []);

  const [committees, setCommittees] = useState([]);
  const [committeeChairman, setCommitteeChairman] = useState(null);

  const fetchCommittee = async () => {
    const response = await axios.get(`/api/data/venture-committee/ask-jsg`);

    const sortedData = response.data.sort((a, b) => a.order - b.order);
    const special = sortedData.find((committee) => committee.order === "1");

    setCommitteeChairman(special);
    setCommittees(sortedData.filter((committee) => committee.order !== "1"));
  };

  useEffect(() => {
    fetchCommittee();
  }, []);

  return (
    <div>
      <div className="ventureContainer">
        <Row>
          <Col md={3} xs={6}>
            <Image src={logo} fluid className="logoImg" />
          </Col>
          <Col
            md={9}
            xs={9}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1>Ask JSG Registration</h1>
          </Col>
        </Row>
      </div>

      <div className="ventureContainer" style={{ marginTop: "10px", paddingTop: "10px" }}>
        <Row>
          <Col md={4} xs={12} style={{ marginTop: "20px" }}>
            <Row
              style={{
                marginTop: "20px",
                textAlign: "center",
                padding: "0px 15px",
              }}
            >
              <Button onClick={zoomBgRedirect} className="btn--four">
                Get Zoom Background
              </Button>
            </Row>

            <Row
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                padding: "0px 15px",
              }}
            >
              <Button style={{ width: "100%" }} className="btn--secondary" onClick={() => downloadPDF2()}>
                Download Registration Process
              </Button>
            </Row>
          </Col>

          <Col md={8} xs={12} style={{ marginTop: "20px" }}>
            <Row
              ref={divRef}
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#face1d",
              }}
            >
              <div className="gurukul-book-container" style={{ width: bookWidth, height: bookHeight }}>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                  <Col
                    xs={12}
                    md={3}
                    style={{
                      marginTop: "5px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    {isFullscreen ? (
                      <Button
                        className="btn--secondary"
                        style={{
                          width: "160px",
                          margin: "0 20px",
                          padding: "8px",
                        }}
                        onClick={handleExitFullscreen}
                      >
                        Exit Fullscreen
                      </Button>
                    ) : (
                      <Button
                        className="btn--secondary"
                        style={{
                          width: "160px",
                          margin: "0 20px",
                          padding: "8px",
                        }}
                        onClick={handleFullscreen}
                      >
                        Fullscreen View
                      </Button>
                    )}
                  </Col>
                  <Col
                    xs={12}
                    md={3}
                    style={{
                      marginTop: "5px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Button style={{ width: "160px", margin: "0 20px" }} className="btn--primary" onClick={prevPage}>
                      Prev Page
                    </Button>
                  </Col>
                  <Col
                    xs={12}
                    md={3}
                    style={{
                      marginTop: "5px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ fontWeight: "600", fontSize: "20px" }}>
                      Page: {currentPage + 1}/ {totalPages}
                    </p>
                  </Col>
                  <Col
                    xs={12}
                    md={3}
                    style={{
                      marginTop: "5px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Button style={{ width: "160px", margin: "0 20px" }} className="btn--primary" onClick={nextPage}>
                      Next Page
                    </Button>
                  </Col>
                </Row>
                <div className="bookBook" style={{ marginTop: "20px" }}>
                  {pages.length !== [] ? (
                    <>
                      <HTMLFlipBook
                        style={{
                          margin: "0 auto",
                          touchAction: "manipulation",
                        }}
                        size="stretch"
                        width={390}
                        height={815}
                        ref={pageFlipRef}
                        showCover={true}
                        minWidth={250}
                        maxWidth={900}
                        minHeight={590}
                        maxHeight={1850}
                        mobileScrollSupport={false}
                        useMouseEvents={turnEnable}
                        onFlip={handlePageChange}
                        onInit={() => setIsPageFlipReady(true)}
                      >
                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.coverPage})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page1})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page2})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page3})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.lastPage})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>
                      </HTMLFlipBook>
                    </>
                  ) : (
                    <div>Loading...</div>
                  )}
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AskJsgRegistration;
