import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import { useLocation } from "react-router-dom";
import moment from "moment";
import EduPage1 from "./EduPage1";
import EduPage2 from "./EduPage2";
import EduPage3 from "./EduPage3";

function EduFormApp() {
  const user = useSelector((state) => state.authentication.user);
  const location = useLocation();

  //state for steps
  const [step, setstep] = useState(1);

  //state for form data
  const [formData, setFormData] = useState({
    date: "",
    place: "",
    photo: null,
    studyIn: "",
    persFName: "",
    persMName: "",
    persLName: "",
    persBDay: "",
    persMobile: "",
    persEmail: "",
    persAddress: "",
    parFName: "",
    parMName: "",
    parLName: "",
    parOccuation: "",
    parAnnualIncome: "",
    parPANno: "",
    parFamAssistance: "",
    parAddress: "",
    parPhonenoR: "",
    parPhonenoO: "",
    parPhonenoM: "",
    parEmail: "",
    parNativePlace: "",
    parState: "",
    parMemberOfJsg: "Yes",
    parGrpName: "",
    edu1Course: "",
    edu1PassMonth: "",
    edu1Marks: "",
    edu1TotalMarks: "",
    edu1Percentage: "",
    edu1Institite: "",
    edu2Course: "",
    edu2PassMonth: "",
    edu2Marks: "",
    edu2TotalMarks: "",
    edu2Percentage: "",
    edu2Institite: "",
    edu3Course: "",
    edu3PassMonth: "",
    edu3Marks: "",
    edu3TotalMarks: "",
    edu3Percentage: "",
    edu3Institite: "",
    edu4Course: "",
    edu4PassMonth: "",
    edu4Marks: "",
    edu4TotalMarks: "",
    edu4Percentage: "",
    edu4Institite: "",
    edu5Course: "",
    edu5PassMonth: "",
    edu5Marks: "",
    edu5TotalMarks: "",
    edu5Percentage: "",
    edu5Institite: "",
    edu6Course: "",
    edu6PassMonth: "",
    edu6Marks: "",
    edu6TotalMarks: "",
    edu6Percentage: "",
    edu6Institite: "",
    edu7Course: "",
    edu7PassMonth: "",
    edu7Marks: "",
    edu7TotalMarks: "",
    edu7Percentage: "",
    edu7Institite: "",
    HESCourse: "",
    HESDuration: "",
    HESStart: "",
    HESEnds: "",
    HESInstituteName: "",
    HESInstituteAddress: "",
    HESUniversityName: "",
    EEDEDDate1: "",
    EEDEDAgainst1: "",
    EEDAmount1: "",
    EEDRemarks1: "",
    EEDEDDate2: "",
    EEDEDAgainst2: "",
    EEDAmount2: "",
    EEDRemarks2: "",
    EEDEDDate3: "",
    EEDEDAgainst3: "",
    EEDAmount3: "",
    EEDRemarks3: "",
    EEDEDDate4: "",
    EEDEDAgainst4: "",
    EEDAmount4: "",
    EEDRemarks4: "",
    EEDEDDate5: "",
    EEDEDAgainst5: "",
    EEDAmount5: "",
    EEDRemarks5: "",
    EEDEDDate6: "",
    EEDEDAgainst6: "",
    EEDAmount6: "",
    EEDRemarks6: "",
    EEDAmountTotal: "",
    DAFOwnResources: "",
    DAFSelfApplied: "",
    DAFSelfSanctioned: "",
    DAFSelfDisbursed: "",
    DAFBankName: "",
    DAFBankApplied: "",
    DAFBankSanctioned: "",
    DAFBankDisbursed: "",
    DAFinstitute4OrganizationName: "",
    DAFinstitute4Applied: "",
    DAFinstitute4Sanctioned: "",
    DAFinstitute4Disbursed: "",
    DAFinstitute5OrganizationName: "",
    DAFinstitute5Applied: "",
    DAFinstitute5Sanctioned: "",
    DAFinstitute5Disbursed: "",
    DAFHead6: "",
    DAFOrganizationName6: "",
    DAFApplied6: "",
    DAFSanctioned6: "",
    DAFDisbursed6: "",
    DAFHead7: "",
    DAFOrganizationName7: "",
    DAFApplied7: "",
    DAFSanctioned7: "",
    DAFDisbursed7: "",
    DAFAppliedTotal: "",
    DAFSanctionedTotal: "",
    DAFDisbursedTotal: "",

    REFERANCE1Name: "",
    REFERANCE1Member: "Yes",
    REFERANCE1GRPName: "",
    REFERANCE1Address: "",
    REFERANCE1PhoneNoR: "",
    REFERANCE1PhoneNoO: "",
    REFERANCE1PhoneNoM: "",
    REFERANCE1Email: "",
    REFERANCE2Name: "",
    REFERANCE2Member: "Yes",
    REFERANCE2GRPName: "",
    REFERANCE2Address: "",
    REFERANCE2PhoneNoR: "",
    REFERANCE2PhoneNoO: "",
    REFERANCE2PhoneNoM: "",
    REFERANCE2Email: "",
    SADStudentRationCard: null,
    SADStudentPANCard: null,
    SADStudentLatestPaidFeeReciept: null,
    SADParentLatestRent: null,
    SADParentPANCard: null,
    SADParentTelephoneBill: null,
    SADRemarks: "",
    OBOFullName: "",
    OBODesignation: "",
    OBOGroupNameAndNo: "",
    OBORemarks: "",
  });

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
    window.scrollTo(0, 0);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
    window.scrollTo(0, 0);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    const { value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0]; // Get the first file from the FileList
      console.log("file", file);

      setFormData((prevState) => ({
        ...prevState,
        [input]: file, // Set the file object directly
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [input]: value.toLowerCase(),
      }));
    }
  };

  // javascript switch case to show different form in each step
  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      return (
        <div>
          <Container>
            <Row>
              <Col className="custom-margin">
                <EduPage1 nextStep={nextStep} handleFormData={handleInputData} values={formData} />
              </Col>
            </Row>
          </Container>
        </div>
      );
    // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 2:
      return (
        <div>
          <Container>
            <Row>
              <Col className="custom-margin">
                <EduPage2 nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
              </Col>
            </Row>
          </Container>
        </div>
      );

    case 3:
      return (
        <div>
          <Container>
            <Row>
              <Col className="custom-margin">
                <EduPage3 nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
              </Col>
            </Row>
          </Container>
        </div>
      );

    // default case to show nothing
    default:
      return <div></div>;
  }
}

export default EduFormApp;
