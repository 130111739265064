import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../SecurePageSection";

const RegEventEdit = () => {
  const user = useSelector((state) => state.authentication.user);
  const [submitting, setSubmitting] = useState(false);
  const [response, setResponse] = useState([]);

  const [eTitle, setETitle] = useState("");
  const [ytLink, setYtLink] = useState("");
  const [eDescription, setEDescription] = useState("");
  const [eStartDate, setEStartDate] = useState("");
  const [eStartTime, setEStartTime] = useState("");
  const [eEndDate, setEEndDate] = useState("");
  const [eEndTime, setEEndTime] = useState("");
  const [eCity, setECity] = useState("");
  const [ePhoto0, setEPhoto0] = useState("");
  const [ePhotos, setEPhotos] = useState(null);

  const EditForm = () => {
    axios.get(`/api/reg/event/${url.id}/`).then(function(res) {
      setResponse(res.data);
      console.log(res.data);
      setETitle(res.data.eTitle);
      setYtLink(res.data.ytLink);
      setEDescription(res.data.eDescription);
      setEStartDate(res.data.eStartDate);
      setEStartTime(res.data.eStartTime);
      setEEndDate(res.data.eEndDate);
      setEEndTime(res.data.eEndTime);
      setECity(res.data.eCity);
    });
  };

  useEffect(() => {
    EditForm();
  }, []);

  const url = useParams();
  const navigate = useNavigate();

  const redirectToResponse = (id) => {
    navigate(`/reg/event/${id}`);
  };

  async function FormSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    let formField = new FormData();

    formField.append("user", user.user_id);
    formField.append("eTitle", eTitle);
    formField.append("ytLink", ytLink);
    formField.append("eDescription", eDescription);
    formField.append("eStartDate", eStartDate);
    formField.append("eStartTime", eStartTime);
    formField.append("eEndDate", eEndDate);
    formField.append("eEndTime", eEndTime);
    formField.append("eCity", eCity);

    if (ePhoto0 !== null) {
      formField.append("ePhoto0", ePhoto0);
    }

    if (ePhotos !== null) {
      for (let i = 0; i < ePhotos.length; i++) {
        const fieldName = `ePhoto${i}`;
        formField.append(fieldName, ePhotos[i]);
      }
    }

    await axios({
      method: "put",
      url: `/api/reg/event/${response.id}/`,
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        redirectToResponse(response.data.id);
      })
      .catch(function(error) {
        console.log(error);
      });
    setSubmitting(false);
  }

  return (
    <div>
      <SecurePageSection accessableUsers={"admin,region"} />
      <Container>
        <Form>
          <Row>
            <Col md={8}>
              <h2>Region Event Edit</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Event Title</Form.Label>
                <Form.Control name="eTitle" value={eTitle} onChange={(e) => setETitle(e.target.value)} />
              </Form.Group>
            </Col>

            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Event City</Form.Label>
                <Form.Control name="eCity" value={eCity} onChange={(e) => setECity(e.target.value)} />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Event Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="eDescription"
                  value={eDescription}
                  onChange={(e) => setEDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Event Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="eStartDate"
                  value={eStartDate}
                  onChange={(e) => setEStartDate(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Event Start Time</Form.Label>
                <Form.Control
                  type="time"
                  name="eStartTime"
                  value={eStartTime}
                  onChange={(e) => setEStartTime(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Event End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="eEndDate"
                  value={eEndDate}
                  onChange={(e) => setEEndDate(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Event End Time</Form.Label>
                <Form.Control
                  type="time"
                  name="eEndTime"
                  value={eEndTime}
                  onChange={(e) => setEEndTime(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Youtube Link</Form.Label>
                <Form.Control name="ytLink" value={ytLink} onChange={(e) => setYtLink(e.target.value)} />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={6}>
              <p>
                Upload the thumbnail only if you wish to change it, if you do not wish to change the thumnail, do not
                upload any photo
              </p>
              <Form.Group>
                <Form.Label>
                  Thumbnail <span style={{ fontSize: "18px" }}>(Upload 1 Photo only)</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  name="ePhoto0"
                  accept="image/jpg, image/jpeg"
                  onChange={(e) => setEPhoto0(e.target.files[0])}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6}>
              <p>
                Upload All the images again even if you wish to change a single photo. If you do not wish to change any
                photos, do not upload any photos
              </p>
              <Form.Group>
                <Form.Label>
                  Event Photos <span style={{ fontSize: "18px" }}>(Upload Maximum 15 Photos only)</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  name="ePhotos"
                  multiple
                  accept="image/jpg, image/jpeg"
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files.length < 16) {
                      setEPhotos(files);
                    } else {
                      // Display an error message or handle the limit exceeded scenario
                      window.alert("Maximum of 15 Images allowed.");
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>}>
              <Button className="btn--outline--dark" disabled>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button className="btn--outline--dark" onClick={(e) => FormSubmit(e)}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default RegEventEdit;
