import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../components/Button.css";
import { Row, Col, Button, Container, Table } from "react-bootstrap";
import "../formsList.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import SecurePageSection from "../../SecurePageSection";
import { LinkContainer } from "react-router-bootstrap";

const FedAER = () => {
  const user = useSelector((state) => state.authentication.user);
  const location = useLocation();

  const [forms, setForms] = useState([]);

  const fetchForms = async () => {
    const response = await axios.get("/api/forms/federation/fed-aer/");

    const sortedData = response.data.sort(function(a, b) {
      const dateA = new Date(a.visitDate);
      const dateB = new Date(b.visitDate);
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });

    console.log(sortedData);
    setForms(sortedData);
  };

  const navigate = useNavigate();
  const newForm = () => {
    navigate("/form/fed-aer");
  };

  useEffect(() => {
    fetchForms();
  }, []);

  const tutorials = () => {
    navigate("/tutorial/eng/fed-aer");
  };

  return (
    <div>
      <SecurePageSection accessableUsers={"admin"} />
      <Container className="body">
        <Row style={{ marginBottom: "1em" }}>
          <Col xs={10}>
            <h2> Federation After Event Report </h2>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button className="btn--primary" onClick={tutorials} style={{ marginRight: "10px" }}>
              Tutorial
            </Button>

            <Button className="btn--primary" onClick={newForm}>
              New Form
            </Button>
          </Col>
        </Row>
        <Table striped bordered responsive="md">
          <thead className="tableHead">
            <tr>
              <th>S. No.</th>
              <th>Region</th>
              <th>Group</th>
              <th>Visited Date</th>
              <th>PRO Contacted?</th>
              <th>Wall Banner?</th>
              <th>Table Flag Displayed?</th>
              <th>Name Boards & Designation?</th>
              <th>Agenda Placed?</th>
              <th>Calling Protocol?</th>
              <th>Seating Protocol?</th>
              <th>Agenda Timings?</th>
              <th>Form</th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {forms.map((form, index) => (
              <tr key={index} style={{ textAlign: "center" }}>
                <td>{index + 1}</td>
                <td style={{ textTransform: "capitalize" }}>{form.regName}</td>
                <td style={{ textTransform: "capitalize" }}>{form.grpName}</td>
                <td>{moment(form.visitDate).format("Do MMMM YYYY")}</td>
                <td>{form.proContacted}</td>
                <td>{form.wallBanner}</td>
                <td>{form.tableFlag}</td>
                <td>{form.nameBoard}</td>
                <td>{form.tableAgenda}</td>
                <td>{form.callingFollowed}</td>
                <td>{form.seatinfFollwed}</td>
                <td>{form.agendaTime}</td>
                <td>
                  <Row>
                    {(() => {
                      if (form.user === user.user_id || user.accessGroup === "admin") {
                        return (
                          <Col>
                            <div style={{ textAlign: "center" }}>
                              <LinkContainer to={`/form/fed-aer/${form.id}/edit`}>
                                <Button variant="light" className="btn--four">
                                  Edit Form
                                </Button>
                              </LinkContainer>
                            </div>
                          </Col>
                        );
                      }
                    })()}
                  </Row>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default FedAER;
