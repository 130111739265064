import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import JsgNavbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./Layout.css";
import arrow from "./arrow.png";
import { useLocation, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Veer from "../components/ChatBot/Veer";
import Loader from "./Loader";

const Layout = () => {
  const location = useLocation();
  const user = useSelector((state) => state.authentication.user);

  const [isLoading, setIsLoading] = useState(true);
  const [loadTime, setLoadTime] = useState("1000");

  useEffect(() => {
    // Simulating an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const generateRandomNumber = () => {
      const min = 800;
      const max = 1700;
      setLoadTime(Math.floor(Math.random() * (max - min + 1)) + min);
    };

    generateRandomNumber();

    setTimeout(() => {
      setIsLoading(false);
    }, loadTime);
  }, [location.pathname]);

  useEffect(() => {
    if (user !== "undefined" || typeof user !== "undefined") {
      // Get IP
      async function getData() {
        await axios({
          method: "get",
          url: "https://geolocation-db.com/json/",
        })
          .then(function(ipResponse) {
            // Create the USER LOG after IP is recieved
            // Get Previous logs inorder get latest id of that user
            async function getPrevLogs() {
              await axios({
                method: "get",
                url: `/user/${user.user_id}/log/`,
              })
                .then(function(res) {
                  const latestId = res.data.reduce((maxId, item) => {
                    return item.id > maxId ? item.id : maxId;
                  }, 0);
                  timeLogger(latestId);
                  trackPageView();
                })
                .catch(function(err) {
                  console.log(err);
                });
            }
            getPrevLogs();

            // Update Time on page
            async function timeLogger(latestId) {
              const date = new Date();
              const endTime = moment(date).format("HH:mm:ss");

              async function getPrevLog() {
                await axios({
                  method: "get",
                  url: `/user/log/${latestId}`,
                })
                  .then(async function(res) {
                    const duration = moment(endTime, "hh:mm:ss A").diff(
                      moment(res.data.visitTime, "hh:mm:ss A")
                    );

                    const timeOnPage = moment.utc(duration).format("HH:mm:ss");

                    let formField = new FormData();
                    formField.append("timeOnPage", timeOnPage);

                    await axios({
                      method: "put",
                      url: `/user/log/${latestId}/`,
                      data: formField,
                    })
                      .then(async function(response) {})
                      .catch(function(error) {
                        console.log(error);
                      });
                  })
                  .catch(function(err) {
                    console.log(err);
                  });
              }
              getPrevLog();
            }

            // Create a new log
            async function trackPageView() {
              let formField = new FormData();

              formField.append("user", user.user_id);
              formField.append("pageVisited", location.pathname);
              formField.append(
                "visitdate",
                moment(new Date()).format("DD-MM-YYYY")
              );
              formField.append(
                "visitTime",
                moment(new Date()).format("HH:mm:ss")
              );
              formField.append("ipAddress", ipResponse.data.IPv4);

              await axios({
                method: "post",
                url: `/user/log/`,
                data: formField,
              })
                .then(async function(response) {})
                .catch(function(error) {
                  console.log(error);
                });
            }
          })
          .catch(function(err) {
            console.log(err);
            // Create the USER LOG if IP has issue
            // Get Previous logs inorder get latest id of that user
            async function getPrevLogs() {
              await axios({
                method: "get",
                url: `/user/${user.user_id}/log/`,
              })
                .then(function(res) {
                  const latestId = res.data.reduce((maxId, item) => {
                    return item.id > maxId ? item.id : maxId;
                  }, 0);
                  timeLogger(latestId);
                  trackPageView();
                })
                .catch(function(err) {
                  console.log(err);
                });
            }
            getPrevLogs();

            // Update Time on page
            async function timeLogger(latestId) {
              const date = new Date();
              const endTime = moment(date).format("HH:mm:ss");

              async function getPrevLog() {
                await axios({
                  method: "get",
                  url: `/user/log/${latestId}`,
                })
                  .then(async function(res) {
                    const duration = moment(endTime, "hh:mm:ss A").diff(
                      moment(res.data.visitTime, "hh:mm:ss A")
                    );

                    const timeOnPage = moment.utc(duration).format("HH:mm:ss");

                    let formField = new FormData();
                    formField.append("timeOnPage", timeOnPage);

                    await axios({
                      method: "put",
                      url: `/user/log/${latestId}/`,
                      data: formField,
                    })
                      .then(async function(response) {})
                      .catch(function(error) {
                        console.log(error);
                      });
                  })
                  .catch(function(err) {
                    console.log(err);
                  });
              }
              getPrevLog();
            }

            // Create a new log
            async function trackPageView() {
              let formField = new FormData();

              formField.append("user", user.user_id);
              formField.append("pageVisited", location.pathname);
              formField.append(
                "visitdate",
                moment(new Date()).format("DD-MM-YYYY")
              );
              formField.append(
                "visitTime",
                moment(new Date()).format("HH:mm:ss")
              );
              formField.append("ipAddress", "");

              await axios({
                method: "post",
                url: `/user/log/`,
                data: formField,
              })
                .then(async function(response) {})
                .catch(function(error) {
                  console.log(error);
                });
            }
          });
      }
      getData();
    }
  }, [location.pathname]);

  const [regions, setRegions] = useState([]);
  const [opened, setOpened] = useState(false);

  const toggleFloating = ({ opened }) => {
    setOpened(opened);
  };

  useEffect(() => {
    const fetchRegions = async () => {
      const response = await axios.get("/user/region");

      setRegions(response.data);
    };
    fetchRegions();
  }, []);

  const [showDiv, setShowDiv] = useState(true);

  useEffect(() => {
    setShowDiv(true);

    const timeout = setTimeout(() => {
      setShowDiv(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [location.pathname]);

  const [chatbotHeight, setChatbotHeight] = useState("530px");

  useEffect(() => {
    function handleResize() {
      const height = window.innerWidth <= 768 ? "85vh" : "530px";
      setChatbotHeight(height);
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleChatbotClose() {
    setOpened(false);
  }

  return (
    <div>
      <JsgNavbar />
      <div>
        <div className="layout">
          <Container
            style={{
              minHeight: "100vh",
              paddingBottom: "2em",
              paddingTop: "9em",
            }}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <Outlet />
                {showDiv && (
                  <div className="chatBotPointer arrow-fade-out">
                    <Row>
                      <Col
                        style={{
                          margin: "0",
                          padding: "0",
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "flex-end",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <p className="veer">VEER</p>
                      </Col>
                      <Col
                        style={{
                          margin: "0",
                          padding: "0 20px",
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "flex-start",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div className="arrow-container">
                          <div>
                            <img src={arrow} className="arrow" width="50px" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            )}

            <Veer />
          </Container>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
