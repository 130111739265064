import React, { useState } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// creating functional component ans getting props from app.js and destucturing them
const EduPage3 = ({ nextStep, handleFormData, prevStep, values }) => {
  //creating error state for validation
  const [error, setError] = useState(false);

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    nextStep();
  };

  const navigate = useNavigate();

  async function FormSubmit(event) {
    event.preventDefault();

    const emailList = "partsandspares@gmail.com,dj22jsgif@gmail.com," + values.persEmail + "," + values.parEmail;
    console.log(emailList);

    const formDataObj = new FormData();
    for (const key in values) {
      if (values[key] !== null) {
        formDataObj.append(key, values[key]);
      }
    }
    formDataObj.append("emailList", emailList);

    console.log(values);

    await axios({
      method: "post",
      url: "/api/forms/jsgff-education-loans/",
      data: formDataObj,
    })
      .then(function(response) {
        console.log(response);
        navigate(`/jsgff-education-loan/response/${response.data.id}`);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  return (
    <div>
      <Container>
        <Form>
          <Row>
            <Col xs={12}>
              <h2>JSGFF Education Loan Form Step 3</h2>
            </Col>
          </Row>
          <Row className="formSectionHeader">
            <h3>Two Reference</h3>
          </Row>

          <Row className="mb-3">
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>1st Reference Name</Form.Label>
                <Form.Control
                  name="REFERANCE1Name"
                  defaultValue={values.REFERANCE1Name}
                  placeholder="First name"
                  onChange={handleFormData("REFERANCE1Name")}
                />
              </Form.Group>
            </Col>

            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>1st Reference Member of JSG?</Form.Label>
                <Form.Select
                  name="REFERANCE1Member"
                  defaultValue={values.REFERANCE1Member}
                  placeholder="Middle name"
                  onChange={handleFormData("REFERANCE1Member")}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </Form.Group>
            </Col>

            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>1st Reference Group Name</Form.Label>
                <Form.Control
                  name="REFERANCE1GRPName"
                  defaultValue={values.REFERANCE1GRPName}
                  placeholder="Surname"
                  onChange={handleFormData("REFERANCE1GRPName")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>1st Reference Present Address</Form.Label>
                <Form.Control
                  name="REFERANCE1Address"
                  defaultValue={values.REFERANCE1Address}
                  placeholder="Address"
                  onChange={handleFormData("REFERANCE1Address")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>1st Reference Phone Number (R)</Form.Label>
                <Form.Control
                  name="REFERANCE1PhoneNoR"
                  defaultValue={values.REFERANCE1PhoneNoR}
                  placeholder="Phone Number (R)"
                  onChange={handleFormData("REFERANCE1PhoneNoR")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>1st Reference Phone Number (O)</Form.Label>
                <Form.Control
                  name="REFERANCE1PhoneNoO"
                  defaultValue={values.REFERANCE1PhoneNoO}
                  placeholder="Phone Number (O)"
                  onChange={handleFormData("REFERANCE1PhoneNoO")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>1st Reference Phone Number (M)</Form.Label>
                <Form.Control
                  name="REFERANCE1PhoneNoM"
                  defaultValue={values.REFERANCE1PhoneNoM}
                  placeholder="Phone Number (M)"
                  onChange={handleFormData("REFERANCE1PhoneNoM")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>1st Reference Email</Form.Label>
                <Form.Control
                  name="REFERANCE1Email"
                  defaultValue={values.REFERANCE1Email}
                  placeholder="Email"
                  onChange={handleFormData("REFERANCE1Email")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>2nd Reference Name</Form.Label>
                <Form.Control
                  name="REFERANCE2Name"
                  defaultValue={values.REFERANCE2Name}
                  placeholder="First name"
                  onChange={handleFormData("REFERANCE2Name")}
                />
              </Form.Group>
            </Col>

            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>2nd Reference Member of JSG?</Form.Label>
                <Form.Select
                  name="REFERANCE2Member"
                  defaultValue={values.REFERANCE2Member}
                  placeholder="Middle name"
                  onChange={handleFormData("REFERANCE2Member")}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </Form.Group>
            </Col>

            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>2nd Reference JSG Group Name</Form.Label>
                <Form.Control
                  name="REFERANCE2GRPName"
                  defaultValue={values.REFERANCE2GRPName}
                  placeholder="Surname"
                  onChange={handleFormData("REFERANCE2GRPName")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>2nd Reference Address</Form.Label>
                <Form.Control
                  name="REFERANCE2Address"
                  defaultValue={values.REFERANCE2Address}
                  placeholder="Address"
                  onChange={handleFormData("REFERANCE2Address")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>2nd Reference Phone Number (R)</Form.Label>
                <Form.Control
                  name="REFERANCE2PhoneNoR"
                  defaultValue={values.REFERANCE2PhoneNoR}
                  placeholder="Phone Number (R)"
                  onChange={handleFormData("REFERANCE2PhoneNoR")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>2nd Reference Phone Number (O)</Form.Label>
                <Form.Control
                  name="REFERANCE2PhoneNoO"
                  defaultValue={values.REFERANCE2PhoneNoO}
                  placeholder="Phone Number (O)"
                  onChange={handleFormData("REFERANCE2PhoneNoO")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>2nd Reference Phone Number (M)</Form.Label>
                <Form.Control
                  name="REFERANCE2PhoneNoM"
                  defaultValue={values.REFERANCE2PhoneNoM}
                  placeholder="Phone Number (M)"
                  onChange={handleFormData("REFERANCE2PhoneNoM")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>2nd Reference Email</Form.Label>
                <Form.Control
                  name="REFERANCE2Email"
                  defaultValue={values.REFERANCE2Email}
                  placeholder="Email"
                  onChange={handleFormData("REFERANCE2Email")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h4 style={{ textAlign: "center", margin: "0", marginTop: "20px" }}>Upload Images Only</h4>
          </Row>
          <Row>
            <h5 style={{ margin: "0", marginTop: "20px", textAlign: "center" }}>Student Uploads</h5>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Ration Card</Form.Label>
                <Form.Control
                  name="SADStudentRationCard"
                  type="file"
                  accept="image/*"
                  onChange={handleFormData("SADStudentRationCard")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Pan Card</Form.Label>
                <Form.Control
                  name="SADStudentPANCard"
                  type="file"
                  accept="image/*"
                  onChange={handleFormData("SADStudentPANCard")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Latest Paid Fee Reciept</Form.Label>
                <Form.Control
                  name="SADStudentLatestPaidFeeReciept"
                  type="file"
                  accept="image/*"
                  onChange={handleFormData("SADStudentLatestPaidFeeReciept")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h5 style={{ margin: "0", marginTop: "20px", textAlign: "center" }}>Parent Uploads</h5>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Latest Rent</Form.Label>
                <Form.Control
                  name="SADParentLatestRent"
                  type="file"
                  accept="image/*"
                  onChange={handleFormData("SADParentLatestRent")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Pan Card</Form.Label>
                <Form.Control
                  name="SADParentPANCard"
                  type="file"
                  accept="image/*"
                  onChange={handleFormData("SADParentPANCard")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group>
                <Form.Label>Parent Telephone Bill</Form.Label>
                <Form.Control
                  name="SADParentTelephoneBill"
                  type="file"
                  accept="image/*"
                  onChange={handleFormData("SADParentTelephoneBill")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  name="SADRemarks"
                  defaultValue={values.SADRemarks}
                  placeholder="Remarks"
                  onChange={handleFormData("SADRemarks")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="formSectionHeader">
            <h3>Two Reference</h3>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>OB Full Name</Form.Label>
                <Form.Control
                  name="OBOFullName"
                  defaultValue={values.OBOFullName}
                  placeholder="Phone Number (M)"
                  onChange={handleFormData("OBOFullName")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>OB Designation</Form.Label>
                <Form.Control
                  name="OBODesignation"
                  defaultValue={values.OBODesignation}
                  placeholder="Email"
                  onChange={handleFormData("OBODesignation")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>OB Group Name and Number</Form.Label>
                <Form.Control
                  name="OBOGroupNameAndNo"
                  defaultValue={values.OBOGroupNameAndNo}
                  placeholder="Phone Number (M)"
                  onChange={handleFormData("OBOGroupNameAndNo")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>OB Remarks</Form.Label>
                <Form.Control
                  name="OBORemarks"
                  defaultValue={values.OBORemarks}
                  placeholder="Email"
                  onChange={handleFormData("OBORemarks")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button className="btn--outline" onClick={prevStep}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Previous
              </Button>
            </Col>
            <Col>
              <Button className="btn--outline--dark" onClick={FormSubmit}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default EduPage3;
