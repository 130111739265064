import React, { useEffect } from "react";
import HeroSection from "../components/HeroSection";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

function HomeScreen() {
  const user = useSelector((state) => state.authentication.user);

  return (
    <div>
      <HeroSection />
    </div>
  );
}

export default HomeScreen;
