import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, ListGroup, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";

function VideoAnmol() {
  const [tutorial, setTutorial] = useState("");

  const url = useParams();

  const fetchTutorial = async () => {
    // const response = await axios.get(`/api/data/tutorial/eng/${url.id}`);
    const response = await axios.get(
      `/api/data/lan/tutorial/?lan=eng&f_id=${url.id}`
    );
    const data = response.data[0];

    console.log(data);
    setTutorial(data);
  };

  useEffect(() => {
    fetchTutorial();
  }, [url.id]);

  return (
    <div>
      <Row>
        <Col>
          <h2>Anmol Book Video</h2>
        </Col>
      </Row>
      <Row>
        <Col style={{ display: "flex", justifyContent: "center" }}>
          <iframe
            width="840"
            height="420"
            src="https://www.youtube.com/embed/sYhAwHoP2cg?si=3MnceazR0ScXHhHl"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Col>
      </Row>
    </div>
  );
}

export default VideoAnmol;
