import React, { useEffect, useState } from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import axios from "axios";

import "./Testimonials.css";

function Testimonials() {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchIDs = async () => {
      const response = await axios.get(`/api/data/testimonials/`);
      const sortedArray = response.data.sort((a, b) => b.id - a.id);

      console.log(sortedArray);
      setTestimonials(sortedArray);
    };
    fetchIDs();
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <h2>Testimonials</h2>
      <Row>
        {testimonials.map((testimonial) => (
          <Col style={{ margin: "1em 0" }} lg={6} md={12} xs={12} key={testimonial.id}>
            <Card className="testimonialCard">
              <Card.Body>
                <Row>
                  <div className="tesimonialCol3">
                    <div className="testimonialImageContainer">
                      <Image src={testimonial.image} alt={testimonial.name} className="testimonialImage" fluid />
                    </div>
                  </div>
                  <div className="tesimonialCol7">
                    <Row
                      style={{
                        borderBottom: "2px solid #fff",
                        marginBottom: "8px",
                      }}
                    >
                      <Col
                        xs={7}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-end",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Card.Title className="tetsimonialHead">{testimonial.name}</Card.Title>
                      </Col>
                      <Col
                        xs={5}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Card.Subtitle className="tetsimonialSubHead">{testimonial.post}</Card.Subtitle>
                      </Col>
                    </Row>
                    <Card.Text className="tetsimonialText">{testimonial.text}</Card.Text>
                  </div>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Testimonials;
