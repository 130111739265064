import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../../../SecurePageSection";

const RegQ1Form = () => {
  const user = useSelector((state) => state.authentication.user);
  const [submitting, setSubmitting] = useState(false);

  const [regName, setRegName] = useState("");
  const [regChairName, setregChairName] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [numGrps, setnumGrps] = useState("");
  const [numFormA, setnumFormA] = useState("");
  const [numFormB, setnumFormB] = useState("");
  const [numGrpDues, setnumGrpDues] = useState("");
  const [instaFollowers, setinstaFollowers] = useState("");
  const [fbFollowers, setfbFollowers] = useState("");
  const [ytFollowers, setytFollowers] = useState("");
  const [regGrpEvents1, setregGrpEvents1] = useState("");
  const [regGrpEvents2, setregGrpEvents2] = useState("");
  const [regGrpEvents3, setregGrpEvents3] = useState("");
  const [regGrpEvents4, setregGrpEvents4] = useState("");
  const [regGrpEvents5, setregGrpEvents5] = useState("");
  const [regfedEvents1, setregfedEvents1] = useState("");
  const [regfedEvents2, setregfedEvents2] = useState("");
  const [regfedEvents3, setregfedEvents3] = useState("");
  const [regfedEvents4, setregfedEvents4] = useState("");
  const [regfedEvents5, setregfedEvents5] = useState("");
  const [picEvent1, setpicEvent1] = useState(null);
  const [picEvent2, setpicEvent2] = useState(null);
  const [picEvent3, setpicEvent3] = useState(null);
  const [picEvent4, setpicEvent4] = useState(null);
  const [picEvent5, setpicEvent5] = useState(null);
  const [picEvent6, setpicEvent6] = useState(null);
  const [picEvent7, setpicEvent7] = useState(null);
  const [picEvent8, setpicEvent8] = useState(null);
  const [picEvent9, setpicEvent9] = useState(null);
  const [picEvent10, setpicEvent10] = useState(null);
  const [numMembers, setnumMembers] = useState("");
  const [emailList, setemailList] = useState("");
  const [regEmails, setRegEmails] = useState("");
  const [regConvenor, setRegConvenor] = useState("");

  const navigate = useNavigate();

  const fetchRegEmails = async () => {
    const chairmanResponse = await axios.get(`api/data/${regName}/region-ob/emails/`);
    const chairmanEmail = chairmanResponse.data.filter((item) => item.post === "chairman").map((item) => item.email);
    setRegEmails(chairmanEmail);

    const convenorResponse = await axios.get(`api/data/eminence/region-convenor/emails/`);
    const convenorEmail = convenorResponse.data
      .filter(
        (item) =>
          item.post === `${regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()} Region Convenor` ||
          item.post === `${regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()} Region Coordinator`
      )
      .map((item) => item.email);

    const chairmanName = chairmanResponse.data.filter((item) => item.post === "chairman").map((item) => item.name);
    setRegConvenor(convenorEmail);
    // setregChairName(chairmanName);
  };

  useEffect(() => {
    fetchRegEmails();
  }, [regName]);

  async function FormSubmit(event) {
    event.preventDefault();
    let formField = new FormData();

    if (!regName || !name || !email) {
      alert("Please Submit your Region Name, Respondant's Name and Respondant's Event");
      setSubmitting(false);
      return;
    }

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,office@jsgif.co.in,dj22jsgif@gmail.com,dsmehta8842@gmail.com," +
      email +
      "," +
      regEmails;
    // "," +
    // regConvenor;

    formField.append("user", user?.user_id);
    formField.append("regName", regName);
    formField.append("regChairName", regChairName);
    formField.append("name", name);
    formField.append("email", email);
    formField.append("numFormA", numFormA);
    formField.append("numFormB", numFormB);
    formField.append("numGrps", numGrps);
    formField.append("numGrpDues", numGrpDues);
    formField.append("instaFollowers", instaFollowers);
    formField.append("fbFollowers", fbFollowers);
    formField.append("ytFollowers", ytFollowers);
    formField.append("regGrpEvents1", regGrpEvents1);
    formField.append("regGrpEvents2", regGrpEvents2);
    formField.append("regGrpEvents3", regGrpEvents3);
    formField.append("regGrpEvents4", regGrpEvents4);
    formField.append("regGrpEvents5", regGrpEvents5);
    formField.append("regfedEvents1", regfedEvents1);
    formField.append("regfedEvents2", regfedEvents2);
    formField.append("regfedEvents3", regfedEvents3);
    formField.append("regfedEvents4", regfedEvents4);
    formField.append("regfedEvents5", regfedEvents5);

    if (picEvent1 !== null) {
      formField.append("picEvent1", picEvent1);
    }
    if (picEvent2 !== null) {
      formField.append("picEvent2", picEvent2);
    }
    if (picEvent3 !== null) {
      formField.append("picEvent3", picEvent3);
    }
    if (picEvent4 !== null) {
      formField.append("picEvent4", picEvent4);
    }
    if (picEvent5 !== null) {
      formField.append("picEvent5", picEvent5);
    }
    if (picEvent6 !== null) {
      formField.append("picEvent6", picEvent6);
    }
    if (picEvent7 !== null) {
      formField.append("picEvent7", picEvent7);
    }
    if (picEvent8 !== null) {
      formField.append("picEvent8", picEvent8);
    }
    if (picEvent9 !== null) {
      formField.append("picEvent9", picEvent9);
    }
    if (picEvent10 !== null) {
      formField.append("picEvent10", picEvent10);
    }

    formField.append("numMembers", numMembers);
    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/forms/ventures/eminence-q1-regions/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        navigate(`/eminence/q1/region/response/${response.data.id}`, { replace: true });
      })
      .catch(function(error) {
        console.log(error);
        alert("We encountered an error processing your request. Please try again later");
      });
    setSubmitting(false);
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get(`/user/region/`);
    setRegions(response.data);
  };
  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups/`);
    setGroups(response.data);
  };
  useEffect(() => {
    fetchGroups();
  }, [regName]);

  return (
    <div>
      {/* <SecurePageSection accessableUsers={"admin,region,former,intDirector,offBearer,eminence,eminenceChair"} /> */}
      <SecurePageSection accessableUsers={"admin,eminenceChair"} />
      <Container>
        <Form>
          <Row>
            <Col lg={8}>
              <h2>Eminence 1st Quarter Application (Regions)</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  name="name"
                  value={name}
                  placeholder="Respondant's Full name"
                  onChange={(e) => setname(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Email ID</Form.Label>
                <Form.Control
                  name="email"
                  value={email}
                  placeholder="Respondant's Email Address"
                  onChange={(e) => setemail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Name</Form.Label>
                <Form.Select name="regName" value={regName} onChange={(e) => setRegName(e.target.value)}>
                  <option>Select your Region</option>
                  {regions.map((region, index) => (
                    <option key={index} style={{ textTransform: "capitalize" }} value={region.region.toLowerCase()}>
                      {region.region.charAt(0).toUpperCase() + region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Chairman Name</Form.Label>
                <Form.Control
                  name="regChairName"
                  value={regChairName}
                  placeholder="Region Chairman's Full name"
                  onChange={(e) => setregChairName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Number of groups</Form.Label>
                <Form.Control
                  name="numGrps"
                  value={numGrps}
                  placeholder="Number of groups in your region"
                  onChange={(e) => setnumGrps(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Number of groups dues pending</Form.Label>
                <Form.Control
                  name="numGrpDues"
                  value={numGrpDues}
                  placeholder="Number of groups in your Region have pending dues "
                  onChange={(e) => setnumGrpDues(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Number of groups submitted Form A</Form.Label>
                <Form.Control
                  name="numFormA"
                  value={numFormA}
                  placeholder="Number of groups in your Region that have submitted FORM A"
                  onChange={(e) => setnumFormA(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Number of groups submitted Form B</Form.Label>
                <Form.Control
                  name="numFormB"
                  value={numFormB}
                  placeholder="Number of groups in your Region that have submitted FORM B"
                  onChange={(e) => setnumFormB(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <h5 style={{ textAlign: "center", margin: "0", marginTop: "15px" }}>
              (Upload the names of the Regional Office Bearers that follow JSGIF's Social Media Handles. Seperate each
              name with a Comma)
            </h5>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Instagram Followers</Form.Label>
                <Form.Control
                  as="textarea"
                  name="instaFollowers"
                  value={instaFollowers}
                  rows="3"
                  placeholder="Example - Number of followers from our group - 3
                  Names - XYZ, ABC, PQR"
                  onChange={(e) => setinstaFollowers(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Facebook Followers</Form.Label>
                <Form.Control
                  as="textarea"
                  name="fbFollowers"
                  value={fbFollowers}
                  rows="3"
                  placeholder="Example - Number of followers from our group - 5
                  Names - XYZ, ABC, PQR, KLM, LMN"
                  onChange={(e) => setfbFollowers(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Youtube Subscribers</Form.Label>
                <Form.Control
                  as="textarea"
                  name="ytFollowers"
                  value={ytFollowers}
                  rows="3"
                  placeholder="Example - Number of subscribers from our group - 1
                  Names -  ABC"
                  onChange={(e) => setytFollowers(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Average Members in each meeting</Form.Label>
                <Form.Control
                  name="numMembers"
                  value={numMembers}
                  rows="3"
                  placeholder="(Average = Total members present in all meetings / Number of events held)"
                  onChange={(e) => setnumMembers(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xl={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Events conducted by the region 1</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Grp Event Subject 1</Form.Label>
                    <Form.Control
                      name="regGrpEvents1"
                      value={regGrpEvents1}
                      placeholder="Subject of Event 1"
                      onChange={(e) => setregGrpEvents1(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Grp Event Photo 1</Form.Label>
                    <Form.Control
                      name="picEvent1"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent1(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col xl={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Events conducted by the region 2</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Grp Event Subject 2</Form.Label>
                    <Form.Control
                      name="regGrpEvents2"
                      value={regGrpEvents2}
                      placeholder="Subject of Event 2"
                      onChange={(e) => setregGrpEvents2(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Grp Event Photo 2</Form.Label>
                    <Form.Control
                      name="picEvent2"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent2(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Events conducted by the region 3</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Grp Event Subject 3</Form.Label>
                    <Form.Control
                      name="regGrpEvents3"
                      value={regGrpEvents3}
                      placeholder="Subject of Event 3"
                      onChange={(e) => setregGrpEvents3(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Grp Event Photo 3</Form.Label>
                    <Form.Control
                      name="picEvent3"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent3(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col xl={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Events conducted by the region 4</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Grp Event Subject 4</Form.Label>
                    <Form.Control
                      name="regGrpEvents4"
                      value={regGrpEvents4}
                      placeholder="Subject of Event 4"
                      onChange={(e) => setregGrpEvents4(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Grp Event Photo 4</Form.Label>
                    <Form.Control
                      name="picEvent4"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent4(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl={12} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Events conducted by the region 5</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Grp Event Subject 5</Form.Label>
                    <Form.Control
                      name="regGrpEvents5"
                      value={regGrpEvents5}
                      placeholder="Subject of Event 5"
                      onChange={(e) => setregGrpEvents5(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Grp Event Photo 5</Form.Label>
                    <Form.Control
                      name="picEvent5"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent5(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Federation events hosted by the region 1</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Fed Event Subject 1</Form.Label>
                    <Form.Control
                      name="regfedEvents1"
                      value={regfedEvents1}
                      placeholder="Subject of Fed Event 1"
                      onChange={(e) => setregfedEvents1(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Fed Event Photo 1</Form.Label>
                    <Form.Control
                      name="picEvent6"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent6(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col xl={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Federation events hosted by the region 2</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Fed Event Subject 2</Form.Label>
                    <Form.Control
                      name="regfedEvents2"
                      value={regfedEvents2}
                      placeholder="Subject of Fed Event 2"
                      onChange={(e) => setregfedEvents2(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Fed Event Photo 2</Form.Label>
                    <Form.Control
                      name="picEvent7"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent7(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Federation events hosted by the region 3</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Fed Event Subject 3</Form.Label>
                    <Form.Control
                      name="regfedEvents3"
                      value={regfedEvents3}
                      placeholder="Subject of Fed Event 3"
                      onChange={(e) => setregfedEvents3(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Fed Event Photo 3</Form.Label>
                    <Form.Control
                      name="picEvent8"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent8(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col xl={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Federation events hosted by the region 4</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Fed Event Subject 4</Form.Label>
                    <Form.Control
                      name="regfedEvents4"
                      value={regfedEvents4}
                      placeholder="Subject of Fed Event 4"
                      onChange={(e) => setregfedEvents4(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Fed Event Photo 4</Form.Label>
                    <Form.Control
                      name="picEvent9"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent9(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl={12} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Federation events hosted by the region 5</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Fed Event Subject 5</Form.Label>
                    <Form.Control
                      name="regfedEvents5"
                      value={regfedEvents5}
                      placeholder="Subject of Fed Event 5"
                      onChange={(e) => setregfedEvents5(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Fed Event Photo 5</Form.Label>
                    <Form.Control
                      name="picEvent10"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent10(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>}>
              <Button className="btn--outline--dark" disabled>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button className="btn--outline--dark" onClick={(e) => FormSubmit(e)}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default RegQ1Form;
