import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../components/Button.css";
import { Row, Col, Form, Button, Container, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../formsList.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import SecurePageSection from "../../SecurePageSection";
import { LinkContainer } from "react-router-bootstrap";

import pdfFile from "../../images/Form B.pdf";
import { saveAs } from "file-saver";

const GroupFormB = () => {
  const user = useSelector((state) => state.authentication.user);

  const [forms, setForms] = useState([]);
  const [regName, setRegName] = useState("");
  const [groupForms, setGroupForms] = useState([]);

  let filterUrl = "";

  if (regName === "") {
    filterUrl = `/api/forms/group/grp-form-b/`;
  }
  if (regName !== "") {
    filterUrl = `/api/forms/group/filter/grp-form-b/?regName=${regName}`;
  }

  const fetchForms = async () => {
    const response = await axios.get(filterUrl);

    console.log(response.data);
    setForms(response.data);
  };

  const fetchGroupForms = async () => {
    const response = await axios.get(`/api/forms/${user.jsgGroupName}/grp-form-b/`);

    console.log(response.data);
    setGroupForms(response.data);
    // setGName(response.data[0].gName);
  };

  const navigate = useNavigate();
  const newForm = () => {
    navigate("/form/grp-form-b");
  };

  useEffect(() => {
    fetchGroupForms();
    fetchForms();
  }, [regName]);

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get("/user/region");

    setRegions(response.data.sort((a, b) => a.region.localeCompare(b.region)));
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const tutorials = () => {
    navigate("/tutorials/grp/form-b");
  };

  const downloadPDF = () => {
    saveAs(pdfFile, "Form A.pdf");
  };

  return (
    <div>
      <SecurePageSection accessableUsers={"admin,group,former,intDirector,offBearer"} />
      <Container className="body">
        <Row style={{ marginBottom: "1em" }}>
          <Col md={3}>
            <h2>Group Form B</h2>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              style={{ marginRight: "10px" }}
              className="btn--secondary"
              onClick={() => downloadPDF()}
            >
              Download Empty Form
            </Button>
            {/* <Button className="btn--primary" onClick={tutorials} style={{ marginRight: "10px" }}>
              Tutorial
            </Button> */}

            <Button className="btn--primary" onClick={newForm}>
              New Form
            </Button>
          </Col>
        </Row>
        {(() => {
          if (
            (typeof user !== "undefined" &&
              typeof user.username !== "undefined" &&
              user.accessGroup === "admin") ||
            (typeof user !== "undefined" &&
              typeof user.username !== "undefined" &&
              user.accessGroup === "intDirector") ||
            (typeof user !== "undefined" &&
              typeof user.username !== "undefined" &&
              user.accessGroup === "offBearer")
          ) {
            return (
              <div>
                <Row>
                  <Col>
                    <Form.Label>Region</Form.Label>
                    <Form.Select
                      defaultValue={regName}
                      onChange={(e) => setRegName(e.target.value)}
                    >
                      <option value="">All</option>
                      {regions.map((region) => (
                        <option
                          style={{ textTransform: "capitalize" }}
                          value={region.region.toLowerCase()}
                        >
                          {region.region.charAt(0).toUpperCase() +
                            region.region.slice(1).toLowerCase()}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Table striped bordered responsive="md">
                    <thead className="tableHead">
                      <tr>
                        <th>S. No</th>
                        <th>ID</th>
                        <th>Region </th>
                        <th>Group Name</th>
                        <th>Group Number</th>
                        <th>Form</th>
                      </tr>
                    </thead>
                    <tbody className="tableBody">
                      {forms
                        .slice(0)
                        .reverse()
                        .map((form, index) => (
                          <tr
                            key={index}
                            style={{
                              textAlign: "center",
                              textTransform: "capitalize",
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>{form.id}</td>
                            <td style={{ textTransform: "capitalize" }}>{form.regName}</td>
                            <td style={{ textTransform: "capitalize" }}>{form.grpName}</td>
                            <td>{form.grpNumber}</td>
                            <td style={{ width: "20rem" }}>
                              <Row>
                                <Col>
                                  <div style={{ textAlign: "center" }}>
                                    <LinkContainer to={`/grp-form-b-response/${form.id}`}>
                                      <Button variant="light" className="btn--four">
                                        View Form
                                      </Button>
                                    </LinkContainer>
                                  </div>
                                </Col>
                                {(() => {
                                  if (
                                    form.grpName === user.jsgGroupName ||
                                    user.accessGroup === "admin"
                                  ) {
                                    return (
                                      <Col>
                                        <div style={{ textAlign: "center" }}>
                                          <LinkContainer to={`/form/grp-form-b/${form.id}/edit`}>
                                            <Button variant="light" className="btn--four">
                                              Edit Form
                                            </Button>
                                          </LinkContainer>
                                        </div>
                                      </Col>
                                    );
                                  }
                                })()}
                              </Row>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Row>
              </div>
            );
          }
        })()}
        {(() => {
          if (
            typeof user !== "undefined" &&
            typeof user.username !== "undefined" &&
            user.accessGroup === "group"
          ) {
            return (
              <div>
                <Row style={{ marginTop: "20px" }}>
                  <Table striped bordered responsive="md">
                    <thead className="tableHead">
                      <tr>
                        <th>S. No</th>
                        <th>ID</th>
                        <th>Region </th>
                        <th>Group Name</th>
                        <th>Group Number</th>
                        <th>Form</th>
                      </tr>
                    </thead>
                    <tbody className="tableBody">
                      {groupForms
                        .slice(0)
                        .reverse()
                        .map((form, index) => (
                          <tr
                            key={index}
                            style={{
                              textAlign: "center",
                              textTransform: "capitalize",
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>{form.id}</td>
                            <td style={{ textTransform: "capitalize" }}>{form.regName}</td>
                            <td style={{ textTransform: "capitalize" }}>{form.grpName}</td>
                            <td>{form.grpNumber}</td>
                            <td style={{ width: "20rem" }}>
                              <Row>
                                <Col>
                                  <div style={{ textAlign: "center" }}>
                                    <LinkContainer to={`/grp-form-b-response/${form.id}`}>
                                      <Button variant="light" className="btn--four">
                                        View Form
                                      </Button>
                                    </LinkContainer>
                                  </div>
                                </Col>
                                {(() => {
                                  if (
                                    form.grpName === user.jsgGroupName ||
                                    user.accessGroup === "admin"
                                  ) {
                                    return (
                                      <Col>
                                        <div style={{ textAlign: "center" }}>
                                          <LinkContainer to={`/form/grp-form-b/${form.id}/edit`}>
                                            <Button variant="light" className="btn--four">
                                              Edit Form
                                            </Button>
                                          </LinkContainer>
                                        </div>
                                      </Col>
                                    );
                                  }
                                })()}
                              </Row>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Row>
              </div>
            );
          }
        })()}
      </Container>
    </div>
  );
};

export default GroupFormB;
