import React, { useState, useRef, useEffect } from "react";
import HTMLFlipBook from "react-pageflip";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Button, Row, Col, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import mannKiBBaat1 from "../images/mannKiBaat.jpg";

function Mkb6() {
  const user = useSelector((state) => state.authentication.user);
  const location = useLocation();

  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState("");
  const [isPageFlipReady, setIsPageFlipReady] = useState(false);
  const pageFlipRef = useRef();

  const handlePageChange = () => {
    let page = pageFlipRef.current.pageFlip().getCurrentPageIndex();
    setCurrentPage(page);
    console.log(page);
  };

  useEffect(() => {
    if (isPageFlipReady) {
      handlePageChange();
    }
  }, [isPageFlipReady]);

  const prevPage = () => {
    pageFlipRef.current.pageFlip().flipPrev();
  };

  const nextPage = () => {
    pageFlipRef.current.pageFlip().flipNext();
  };

  useEffect(() => {
    const totalPageCounter = () => {
      let counter = pageFlipRef.current.pageFlip().getPageCount();
      setTotalPages(counter);
    };

    if (isPageFlipReady) {
      totalPageCounter();
    }
  }, [isPageFlipReady]);

  const fetchPages = async () => {
    const pageResponse = await axios.get(`/api/book/17`);
    console.log(pageResponse.data);
    setPages(pageResponse.data);
  };

  useEffect(() => {
    fetchPages();
  }, []);

  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < 768; // or any other breakpoint you prefer
      setTurnEnable(!isMobile);
    }
    handleResize(); // Call handleResize initially to set the initial value of turnEnable
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const divRef = useRef(null);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [bookWidth, setBookWidth] = useState("");
  const [bookHeight, setBookHeight] = useState("");

  const handleFullscreen = () => {
    const element = divRef.current;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  const handleExitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  useEffect(() => {
    function handleContainer() {
      if (window.innerWidth > 1400) {
        setBookWidth(isFullscreen ? "1400px" : "1400px");
        setBookHeight(isFullscreen ? "1060px" : "100%");
      } else {
        setBookWidth("100%");
        setBookHeight("100%");
      }
    }
    handleContainer();
  }, [window.innerWidth, isFullscreen]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  const [turnEnable, setTurnEnable] = useState(true);
  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < 768; // or any other breakpoint you prefer
      setTurnEnable(!isMobile);
    }
    handleResize(); // Call handleResize initially to set the initial value of turnEnable
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  return (
    <div className="book-container">
      <div className="bookControls">
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col
            xs={12}
            md={6}
            style={{
              marginTop: "5px",
              textAlign: "center",
            }}
          >
            <h2>Mann Ki Baat - Edition 5</h2>
          </Col>
        </Row>
      </div>
      <div
        ref={divRef}
        style={{
          backgroundColor: "#face1d",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className="gurukul-book-container"
          style={{ width: bookWidth, height: bookHeight }}
        >
          <div className="bookControls">
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col
                xs={12}
                md={3}
                style={{
                  marginTop: "5px",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {isFullscreen ? (
                  <Button
                    className="btn--secondary"
                    style={{
                      width: "160px",
                      margin: "0 20px",
                      padding: "8px",
                    }}
                    onClick={handleExitFullscreen}
                  >
                    Exit Fullscreen
                  </Button>
                ) : (
                  <Button
                    className="btn--secondary"
                    style={{
                      width: "160px",
                      margin: "0 20px",
                      padding: "8px",
                    }}
                    onClick={handleFullscreen}
                  >
                    Fullscreen View
                  </Button>
                )}
              </Col>
              <Col
                xs={12}
                md={3}
                style={{
                  marginTop: "5px",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Button
                  style={{ width: "160px", margin: "0 20px" }}
                  className="btn--primary"
                  onClick={prevPage}
                >
                  Prev Page
                </Button>
              </Col>
              <Col
                xs={12}
                md={3}
                style={{
                  marginTop: "5px",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <p style={{ fontWeight: "600", fontSize: "20px" }}>
                  Page: {currentPage + 1}/ {totalPages}
                </p>
              </Col>
              <Col
                xs={12}
                md={3}
                style={{
                  marginTop: "5px",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Button
                  style={{ width: "160px", margin: "0 20px" }}
                  className="btn--primary"
                  onClick={nextPage}
                >
                  Next Page
                </Button>
              </Col>
            </Row>
          </div>
          <div className="bookBook" style={{ marginTop: "20px" }}>
            {pages.length !== [] ? (
              <>
                <HTMLFlipBook
                  style={{
                    margin: "0 auto",
                    touchAction: "manipulation",
                  }}
                  size="stretch"
                  width={508}
                  height={720}
                  ref={pageFlipRef}
                  showCover={false}
                  minWidth={315}
                  maxWidth={800}
                  minHeight={400}
                  maxHeight={1210}
                  useMouseEvents={turnEnable}
                  mobileScrollSupport={false}
                  onFlip={handlePageChange}
                  onInit={() => setIsPageFlipReady(true)}
                >
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page4})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${pages.page5})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                </HTMLFlipBook>
              </>
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ maxWidth: "850px" }}>
          <h2 style={{ textAlign: "center" }}>Mann Ki Baat</h2>
          * <Image src={mannKiBBaat1} fluid />
        </div>
      </div> */}
    </div>
  );
}

export default Mkb6;
