import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import Images from "../../images/index";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import "../Response.css";

function MbsNominationResponse() {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponses] = useState([]);
  const responseId = useParams();

  const getResponse = async () => {
    const res = await axios.get(`/api/forms/mbs-nomination/${responseId.id}`);
    setResponses(res.data);
  };

  useEffect(() => {
    getResponse();
  });

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("", "blank", "height=1123,width=800,scrollbars=yes,");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>Form B</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    html {
      overflow-y: auto;
    }

    fResponseBody {
      padding: 20px 20px;
      margin: 0px 20px;
      width: 800px;
      height: 1123px;
      background: #fff;
      overflow-y: auto;
    }

    .page{
      page-break-after: always;
    }

    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }

    .section2, .section3{
      margin-top: 30px;
    }

    .section4{
      margin-top: 20px;
    }

    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }

    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }

    .bordered-mid-row{
      padding: 3px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-col-b{
      padding: 3px 5px;
      border: 1px solid #000;
    }

    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }

    .bannerText {
      margin-top: 20px;
    }

    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }

    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }

    .toptext {
      margin: 20px 0;
    }

    .logoImage {
      height: 80px;
      margin: 10px;
    }

    .bannerYellow {
      background: #ffcc29;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }

    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }

    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }

    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }

    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }

    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }

    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }

    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }

    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }`);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
    }, 1000);
  };

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>MBS Nomiantion Form Response</h2>
      <Button onClick={printDocument} variant="primary">
        Download Form
      </Button>
      <div id="mainForm" className="centerFormat">
        <div className="fResponseBody">
          <div className="bannerYellow">
            <Row className="T-Banner">
              <Col xs={1}>
                <img src={Images.LogoB} className="logoImage" alt="jsgBanner"></img>
              </Col>
              <Col xs={11} className="bannerText text-center">
                <h1>JAIN SOCIAL GROUP INT. FEDERATION</h1>
                <h2>{response.memGroup}</h2>
              </Col>
            </Row>
          </div>
          <Row>
            <p className="Tsingle-enter">From,</p>
            <p className="Tsingle-enter">
              Name : <span className="response">{response.fromName}</span>
            </p>
            <p className="Tsingle-enter">
              Address : <span className="response">{response.fromAddress}</span>
            </p>
            <p className="Tsingle-enter">
              Contact No. : <span className="response">{response.fromContact}</span>{" "}
            </p>
            <p className="Tsingle-enter">
              Mobile No. : <span className="response">{response.fromMobile}</span>{" "}
            </p>
            <p className="Tsingle-enter">
              Email ID : <span className="response">{response.fromEmail}</span>{" "}
            </p>
            <p className="Tsingle-enter">
              Date : <span className="response">{moment(response.fromDate).format("Do MMMM YYYY")}</span>
            </p>
          </Row>

          <Row>
            <p className="Tdouble-enter">To,</p>
            <p className="Tsingle-enter">The Chairman / Secretary,</p>
            <p className="Tsingle-enter">JSG Welfare Society & the Mutual Benefit Scheme of JSGIF</p>
            <p className="Tsingle-enter">4-p, Vijay Chambers, 4th Floor,</p>
            <p className="Tsingle-enter">Tribhuvan Road,</p>
            <p className="Tsingle-enter">Opp. Dreamland Cinema,</p>
            <p className="Tsingle-enter">Mumbai - 400 004.</p>

            <p className="Tdouble-enter">Respected Sir,</p>
            <p className="Tdouble-enter">
              Ref : JSG Welfare Society Membership No. <span className="response">{response.welfareMembershipNum}</span>{" "}
              & the MBS Scheme Membership No. <span className="response">{response.mbsMembershipNum}</span>
            </p>
            <p className="Tdouble-enter">Sub: Change in Nomination / New Nomination</p>
            <p className="Tdouble-enter">
              I, <span className="response">{response.memName}</span>, am a member of your MBS scheme. I am a Member of
              JSG <span className="response">{response.memGroup}</span>. With immediate effect, I am changing my earlier
              Nomination and filing my new nomination. I am nominating the following person(s) to whom in the event of
              my death the amount of scheme is to be paid. This letter supersedes and cancels all my previous
              nominations made by me time to time.
            </p>
          </Row>
          <p className="Tdouble-enter">
            New 1st Nominee Name : <span className="response">{response.nomineeName1}</span>
          </p>
          <p className="Tsingle-enter">
            Nominee Address: <span className="response">{response.nomineeAddress1}</span>
          </p>
          <p className="Tsingle-enter">
            Relationship with member, if any : <span className="response">{response.nomineeRelation1}</span>
          </p>
          <p className="Tdouble-enter">
            New 2nd Nominee Name : <span className="response">{response.nomineeName2}</span>
          </p>
          <p className="Tsingle-enter">
            Nominee Address: <span className="response">{response.nomineeAddress2}</span>
          </p>
          <p className="Tsingle-enter">
            Relationship with member, if any : <span className="response">{response.nomineeRelation2}</span>
          </p>
          <p className="Tdouble-enter">
            100% Death compensation is to be paid to 1st Nominee only. If at all 1st Nominee is passed away, than only
            2nd Nominee in entitled to receive 100% payment. 2nd Nominee must present death certificate of 1st Nominee.
          </p>
          <p className="Tdouble-enter">
            This change in nomination is made on{" "}
            <span className="response">{moment(response.changeMadeOn).format("Do MMMM YYYY")}</span> at{" "}
            <span className="response">{response.changeMadeAt}</span> in presence off
            <span className="response">{response.changePresenceOf}</span>
          </p>
          <Row>
            <Col>
              <p className="Tsign-enter">Witness</p>
            </Col>
            <Col>
              <p className="Tsign-enter">Signature of Member</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="Tsign-enter">
                JSG <span className="response">{response.memGroup}</span> Group
              </p>
              <p className="Tsingle-enter">Name & Designation &</p>
              <p className="Tsingle-enter">Group Rubber Stamp</p>
            </Col>
            <Col>
              <p className="Tsign-enter">
                <br />
              </p>
              <p className="Tsingle-enter">
                <br />
              </p>
              <p className="Tsingle-enter">To be certified by Bank</p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default MbsNominationResponse;
