import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import axios from "axios";
import "./FedInfo.css";

function FormerPresident() {
  const [presidents, setPresidents] = useState([]);

  useEffect(() => {
    const fetchpresidents = async () => {
      const response = await axios.get(`/api/data/former-presidents/`);

      const sortedData = response.data.sort(function(a, b) {
        var termA = a.term.toUpperCase();
        var termB = b.term.toUpperCase();
        if (termA < termB) {
          return -1;
        }
        if (termA > termB) {
          return 1;
        }
        return 0;
      });

      console.log(response.data);
      setPresidents(sortedData);
    };
    fetchpresidents();
  }, []);

  return (
    <div>
      <Row>
        {presidents.map((user) => (
          <Col style={{ margin: "1em 0.5em" }} key={user.id}>
            <div class="cardContainer">
              <Card className="formerPresCard">
                <Card.Img
                  variant="top"
                  className="formerPresImage"
                  src={user.photo}
                  alt=""
                />
                <Card.Body>
                  <Card.Title style={{ fontSize: "16px", color: "#face1d" }}>
                    JSGian {user.name}
                  </Card.Title>
                  <Card.Subtitle style={{ fontSize: "14px", color: "#ffffff" }}>
                    {user.term}
                  </Card.Subtitle>

                  {/* <Row>
                    <p className="formerPresText1">
                      <i className="fa-solid fa-mobile-button"></i> :
                      {user.mobile}
                    </p>
                    <p className="formerPresText1">{user.email}</p>
                  </Row> */}
                </Card.Body>
              </Card>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default FormerPresident;
