import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SgnBpage3 = ({ handleFormData, prevStep, values }) => {
  const [error, setError] = useState(false);

  const [emailInput, setEmailInput] = useState("");
  const [regEmails, setRegEmails] = useState("");
  const [defaultEmails, setDefaultEmails] = useState(
    "office@jsgif.co.in,president@jsgif.co.in,secretarygeneral@jsgif.co.in",
    "dj22jsgif@gmail.com",
    "manish.shah95@gmail.com"
  );
  const [emailList, setEmailList] = useState([]);

  const handleInputChange = (e) => {
    setEmailInput(e.target.value);
    const newValue = e.target.value.replace(/\s/g, ""); // replace all spaces with empty string
    setEmailList(newValue);
  };

  // const fetchRegEmails = async () => {
  //   const response = await axios.get(`api/data/${values.regName}/region-ob/emails/`);

  //   const filteredEmails = response.data
  //     .filter((item) => item.post === "chairman" || item.post === "secretary")
  //     .map((item) => item.email);

  //   console.log("email", filteredEmails);
  //   setRegEmails(filteredEmails);
  // };

  // useEffect(() => {
  //   fetchRegEmails();
  // }, [values.regName]);

  const navigate = useNavigate();

  async function FormSubmit(event) {
    event.preventDefault();

    const finalEmail =
      defaultEmails +
      "," +
      regEmails +
      "," +
      values.presEmail +
      "," +
      values.secEmail +
      "," +
      emailList;

    const formDataObj = new FormData();
    for (const key in values) {
      if (values[key] !== null) {
        formDataObj.append(key, values[key]);
      }
    }
    formDataObj.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/forms/sangini/sgn-form-b/",
      data: formDataObj,
    })
      .then(function(response) {
        console.log(response);
        navigate(`/sgn-form-b-response/${response.data.id}`);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  return (
    <div>
      <Container>
        <Form>
          <Row>
            <Col xs={12}>
              <h2>Sangini Form B Form</h2>
            </Col>
          </Row>
          <Row className="formSectionHeader">
            <h3>Vice President Details</h3>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Vice President Name</Form.Label>
                <Form.Control
                  name="vPresName"
                  defaultValue={values.vPresName}
                  placeholder="Vice President Name"
                  onChange={handleFormData("vPresName")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="vPresPhoto"
                  defaultValue={values.vPresPhoto}
                  type="file"
                  placeholder="Vice President Photo"
                  onChange={handleFormData("vPresPhoto")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="vPresaddress"
                  defaultValue={values.vPresaddress}
                  placeholder="Vice President Address"
                  onChange={handleFormData("vPresaddress")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  name="vPresPinCode"
                  defaultValue={values.vPresPinCode}
                  placeholder="Vice President Address Pincode"
                  onChange={handleFormData("vPresPinCode")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Office Landline</Form.Label>
                <Form.Control
                  name="vPresOffLandLine"
                  defaultValue={values.vPresOffLandLine}
                  placeholder="Vice President Office Landline"
                  onChange={handleFormData("vPresOffLandLine")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Residence Landline</Form.Label>
                <Form.Control
                  name="vPresResLandLine"
                  defaultValue={values.vPresResLandLine}
                  placeholder="Vice President Residence Landline"
                  onChange={handleFormData("vPresResLandLine")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name="vPresMobileNumber"
                  defaultValue={values.vPresMobileNumber}
                  placeholder="Vice President Mobile Number"
                  onChange={handleFormData("vPresMobileNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  name="vPresWhatsappNumber"
                  defaultValue={values.vPresWhatsappNumber}
                  placeholder="Vice President Whatsapp Number"
                  onChange={handleFormData("vPresWhatsappNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  name="vPresEmail"
                  defaultValue={values.vPresEmail}
                  placeholder="Vice President Email Address"
                  onChange={handleFormData("vPresEmail")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Occupation Details</Form.Label>
                <Form.Control
                  name="vPresOccupation"
                  defaultValue={values.vPresOccupation}
                  placeholder="Vice President Occupation"
                  onChange={handleFormData("vPresOccupation")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  name="vPresBirthDay"
                  defaultValue={values.vPresBirthDay}
                  type="date"
                  placeholder="Vice President Date of Birth"
                  onChange={handleFormData("vPresBirthDay")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Name</Form.Label>
                <Form.Control
                  name="vPresSpouseName"
                  defaultValue={values.vPresSpouseName}
                  placeholder="Vice President Spouse Name"
                  onChange={handleFormData("vPresSpouseName")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Date of Birth</Form.Label>
                <Form.Control
                  name="vPresSpouseBirthDay"
                  defaultValue={values.vPresSpouseBirthDay}
                  type="date"
                  placeholder="Vice President's Spouse Date of Birth"
                  onChange={handleFormData("vPresSpouseBirthDay")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Marraige Anniversary</Form.Label>
                <Form.Control
                  name="vPresMarraigeDate"
                  defaultValue={values.vPresMarraigeDate}
                  type="date"
                  placeholder="Vice President Marraige Anniversary"
                  onChange={handleFormData("vPresMarraigeDate")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="formSectionHeader">
            <h3>Secretary Details</h3>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Secretary Name</Form.Label>
                <Form.Control
                  name="secName"
                  defaultValue={values.secName}
                  placeholder="Secretary Name"
                  onChange={handleFormData("secName")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="secPhoto"
                  defaultValue={values.secPhoto}
                  type="file"
                  placeholder="Secretary Photo"
                  onChange={handleFormData("secPhoto")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="secaddress"
                  defaultValue={values.secaddress}
                  placeholder="Secretary Address"
                  onChange={handleFormData("secaddress")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  name="secPinCode"
                  defaultValue={values.secPinCode}
                  placeholder="Secretary Address Pincode"
                  onChange={handleFormData("secPinCode")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Office Landline</Form.Label>
                <Form.Control
                  name="secOffLandLine"
                  defaultValue={values.secOffLandLine}
                  placeholder="Secretary Office Landline"
                  onChange={handleFormData("secOffLandLine")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Residence Landline</Form.Label>
                <Form.Control
                  name="secResLandLine"
                  defaultValue={values.secResLandLine}
                  placeholder="Secretary Residence Landline"
                  onChange={handleFormData("secResLandLine")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name="secMobileNumber"
                  defaultValue={values.secMobileNumber}
                  placeholder="Secretary Mobile Number"
                  onChange={handleFormData("secMobileNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  name="secWhatsappNumber"
                  defaultValue={values.secWhatsappNumber}
                  placeholder="Secretary Whatsapp Number"
                  onChange={handleFormData("secWhatsappNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  name="secEmail"
                  defaultValue={values.secEmail}
                  placeholder="Secretary Email Address"
                  onChange={handleFormData("secEmail")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Occupation Details</Form.Label>
                <Form.Control
                  name="secOccupation"
                  defaultValue={values.secOccupation}
                  placeholder="Secretary Occupation"
                  onChange={handleFormData("secOccupation")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  name="secBirthDay"
                  defaultValue={values.secBirthDay}
                  type="date"
                  placeholder="Secretary Date of Birth"
                  onChange={handleFormData("secBirthDay")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Name</Form.Label>
                <Form.Control
                  name="secSpouseName"
                  defaultValue={values.secSpouseName}
                  placeholder="Secretary Spouse Name"
                  onChange={handleFormData("secSpouseName")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Date of Birth</Form.Label>
                <Form.Control
                  name="secSpouseBirthDay"
                  defaultValue={values.secSpouseBirthDay}
                  type="date"
                  placeholder="Nominated Former President's Spouse Date of Birth"
                  onChange={handleFormData("secSpouseBirthDay")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Marraige Anniversary</Form.Label>
                <Form.Control
                  name="secMarraigeDate"
                  defaultValue={values.secMarraigeDate}
                  type="date"
                  placeholder="Secretary Marraige Anniversary"
                  onChange={handleFormData("secMarraigeDate")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="formSectionHeader">
            <h3>Joint Secretary Details</h3>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Joint Secretary Name</Form.Label>
                <Form.Control
                  name="jtSecName"
                  defaultValue={values.jtSecName}
                  placeholder="Joint Secretary Name"
                  onChange={handleFormData("jtSecName")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="jtSecPhoto"
                  defaultValue={values.jtSecPhoto}
                  type="file"
                  placeholder="Joint Secretary Photo"
                  onChange={handleFormData("jtSecPhoto")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="jtSecaddress"
                  defaultValue={values.jtSecaddress}
                  placeholder="Joint Secretary Address"
                  onChange={handleFormData("jtSecaddress")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  name="jtSecPinCode"
                  defaultValue={values.jtSecPinCode}
                  placeholder="Joint Secretary Address Pincode"
                  onChange={handleFormData("jtSecPinCode")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Office Landline</Form.Label>
                <Form.Control
                  name="jtSecOffLandLine"
                  defaultValue={values.jtSecOffLandLine}
                  placeholder="Joint Secretary Office Landline"
                  onChange={handleFormData("jtSecOffLandLine")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Residence Landline</Form.Label>
                <Form.Control
                  name="jtSecResLandLine"
                  defaultValue={values.jtSecResLandLine}
                  placeholder="Joint Secretary Residence Landline"
                  onChange={handleFormData("jtSecResLandLine")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name="jtSecMobileNumber"
                  defaultValue={values.jtSecMobileNumber}
                  placeholder="Joint Secretary Mobile Number"
                  onChange={handleFormData("jtSecMobileNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  name="jtSecWhatsappNumber"
                  defaultValue={values.jtSecWhatsappNumber}
                  placeholder="Joint Secretary Whatsapp Number"
                  onChange={handleFormData("jtSecWhatsappNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  name="jtSecEmail"
                  defaultValue={values.jtSecEmail}
                  placeholder="Joint Secretary Email Address"
                  onChange={handleFormData("jtSecEmail")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Occupation Details</Form.Label>
                <Form.Control
                  name="jtSecOccupation"
                  defaultValue={values.jtSecOccupation}
                  placeholder="Joint Secretary Occupation"
                  onChange={handleFormData("jtSecOccupation")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  name="jtSecBirthDay"
                  defaultValue={values.jtSecBirthDay}
                  type="date"
                  placeholder="Joint Secretary Date of Birth"
                  onChange={handleFormData("jtSecBirthDay")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Name</Form.Label>
                <Form.Control
                  name="jtSecSpouseName"
                  defaultValue={values.jtSecSpouseName}
                  placeholder="Joint Secretary Spouse Name"
                  onChange={handleFormData("jtSecSpouseName")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Date of Birth</Form.Label>
                <Form.Control
                  name="jtSecSpouseBirthDay"
                  defaultValue={values.jtSecSpouseBirthDay}
                  type="date"
                  placeholder="Joint Secretary's Spouse Date of Birth"
                  onChange={handleFormData("jtSecSpouseBirthDay")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Marraige Anniversary</Form.Label>
                <Form.Control
                  name="jtSecMarraigeDate"
                  defaultValue={values.jtSecMarraigeDate}
                  type="date"
                  placeholder="Joint Secretary Marraige Anniversary"
                  onChange={handleFormData("jtSecMarraigeDate")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="formSectionHeader">
            <h3>Treasurer Details</h3>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Treasurer Name</Form.Label>
                <Form.Control
                  name="treasurerName"
                  defaultValue={values.treasurerName}
                  placeholder="Treasurer Name"
                  onChange={handleFormData("treasurerName")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  name="treasurerPhoto"
                  defaultValue={values.treasurerPhoto}
                  type="file"
                  placeholder="Treasurer Photo"
                  onChange={handleFormData("treasurerPhoto")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="treasureraddress"
                  defaultValue={values.treasureraddress}
                  placeholder="Treasurer Address"
                  onChange={handleFormData("treasureraddress")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  name="treasurerPinCode"
                  defaultValue={values.treasurerPinCode}
                  placeholder="Treasurer Address Pincode"
                  onChange={handleFormData("treasurerPinCode")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Office Landline</Form.Label>
                <Form.Control
                  name="treasurerOffLandLine"
                  defaultValue={values.treasurerOffLandLine}
                  placeholder="Treasurer Office Landline"
                  onChange={handleFormData("treasurerOffLandLine")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Residence Landline</Form.Label>
                <Form.Control
                  name="treasurerResLandLine"
                  defaultValue={values.treasurerResLandLine}
                  placeholder="Treasurer Residence Landline"
                  onChange={handleFormData("treasurerResLandLine")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name="treasurerMobileNumber"
                  defaultValue={values.treasurerMobileNumber}
                  placeholder="Treasurer Mobile Number"
                  onChange={handleFormData("treasurerMobileNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  name="treasurerWhatsappNumber"
                  defaultValue={values.treasurerWhatsappNumber}
                  placeholder="Treasurer Whatsapp Number"
                  onChange={handleFormData("treasurerWhatsappNumber")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  name="treasurerEmail"
                  defaultValue={values.treasurerEmail}
                  placeholder="Treasurer Email Address"
                  onChange={handleFormData("treasurerEmail")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Occupation Details</Form.Label>
                <Form.Control
                  name="treasurerOccupation"
                  defaultValue={values.treasurerOccupation}
                  placeholder="Treasurer Occupation"
                  onChange={handleFormData("treasurerOccupation")}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  name="treasurerBirthDay"
                  defaultValue={values.treasurerBirthDay}
                  type="date"
                  placeholder="Treasurer Date of Birth"
                  onChange={handleFormData("treasurerBirthDay")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Name</Form.Label>
                <Form.Control
                  name="treasurerSpouseName"
                  defaultValue={values.treasurerSpouseName}
                  placeholder="Treasurer Spouse Name"
                  onChange={handleFormData("treasurerSpouseName")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse Date of Birth</Form.Label>
                <Form.Control
                  name="treasurerSpouseBirthDay"
                  defaultValue={values.treasurerSpouseBirthDay}
                  type="date"
                  placeholder="Nominated Former President's Spouse Date of Birth"
                  onChange={handleFormData("treasurerSpouseBirthDay")}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Marraige Anniversary</Form.Label>
                <Form.Control
                  name="treasurerMarraigeDate"
                  defaultValue={values.treasurerMarraigeDate}
                  type="date"
                  placeholder="Treasurer Marraige Anniversary"
                  onChange={handleFormData("treasurerMarraigeDate")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Mail the Form to</Form.Label>
              <Form.Control
                placeholder="Add each email address after a comma. Example = xyz@gmail.com, abc@gmail.com"
                name="emailInput"
                value={emailInput}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Col>
              <Button className="btn--outline" onClick={(e) => prevStep(e)}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Previous
              </Button>
            </Col>
            <Col>
              <Button className="btn--outline--dark" onClick={(e) => FormSubmit(e)}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default SgnBpage3;
