import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import axios from "axios";
import "./FedInfo.css";
import Images from "../images";

function Founders() {
  const [presidents, setPresidents] = useState([]);

  useEffect(() => {
    const fetchpresidents = async () => {
      const response = await axios.get(`/api/data/former-presidents/`);

      const sortedData = response.data.sort(function(a, b) {
        var termA = a.term.toUpperCase();
        var termB = b.term.toUpperCase();
        if (termA < termB) {
          return -1;
        }
        if (termA > termB) {
          return 1;
        }
        return 0;
      });

      console.log(response.data);
      setPresidents(sortedData);
    };
    fetchpresidents();
  }, []);

  return (
    <div>
      <Row>
        <Col md={6} xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "30px",
              backgroundColor: "rgba(245, 236, 206, 0.5)",
              padding: "20px 20px",
              height: "350px",
              textAlign: "justify",
            }}
          >
            <img src={Images.Founder1} height="140px" alt="jsgif-founders" />
            <h4>JSGian Kantilal L. Vora</h4>
            <p style={{ textAlign: "justify" }}>
              One of the four founders pillars of the Jain Social Group
              movement, Shri Kantilalbhai Vora, associated with Mumbai's
              Sthanakvasi Jain Yuvak Madal from 1954 to 1964, became the founder
              president of the first ever Jain Social Group - The Jain Social
              Group-Mumbai Main. Fired up with a great ambition and his tireless
              efforts, he was the President for 10 years and saw the Jain Social
              Group movement bloom into a fruitful tree.
            </p>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "30px",
              backgroundColor: "rgba(245, 236, 206, 0.5)",
              padding: "20px 20px",
              height: "350px",
            }}
          >
            <img src={Images.Founder2} height="140px" alt="jsgif-founders" />
            <h4>JSGian Chimanlal J. Shah</h4>
            <p style={{ textAlign: "justify" }}>
              Respected Shri C.J. Shah was the pioneer of JSG movement and was
              the former President of Jain Social Group Mumbai Main. 1985 was
              the era of clubs, boys and girls of the society had a lot of
              difficulty in getting married in the society. Keeping in mind that
              it was necessary to combine the four sects JSG was established.
              Federation of JSG was established in 1980 by collecting 16 groups
              with the inspiration and blessings of Mr. Chimanbhai. We salute
              our co-founder.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "30px",
              backgroundColor: "rgba(245, 236, 206, 0.5)",
              padding: "20px 20px",
              height: "350px",
              textAlign: "justify",
              marginTop: "20px",
            }}
          >
            <img src={Images.Founder3} height="140px" alt="jsgif-founders" />
            <h4>JSGian Lalubhai G. Mehta</h4>
            <p style={{ textAlign: "justify" }}>
              A mention about Jain Social Group foundation is incomplete without
              the mention of this dynamic personality Shri Labhubhai Mehta who
              with his friendly and simplistic approach as the treasurer of the
              Federation then, took the movement to newer zeniths. He was a rare
              personality who always thought about the upliftment of the society
              in general and today it is thanks to him that we are enjoying the
              fruits of the tree.
            </p>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "30px",
              backgroundColor: "rgba(245, 236, 206, 0.5)",
              padding: "20px 20px",
              height: "350px",
              textAlign: "justify",
              marginTop: "20px",
            }}
          >
            <img src={Images.Founder4} height="140px" alt="jsgif-founders" />
            <h4>JSGian Sukhlal Bakhai</h4>
            <p style={{ textAlign: "justify" }}>
              Shri Sukhlalbhai Bakhai was also a pioneer of Jain Social Group.
              He made a lot of efforts for the all-round development of JSG Int
              Federation. He was also the former President of Mumbai Main. He
              had a very important contribution in protecting Jain culture and
              Jain unity. We salute our co-founder.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Founders;
