import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../../../SecurePageSection";

const GrpQ1Form = () => {
  const user = useSelector((state) => state.authentication.user);
  const [submitting, setSubmitting] = useState(false);

  const [regName, setRegName] = useState("");
  const [grpName, setGrpName] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [grpPresName, setgrpPresName] = useState(null);
  const [regChairName, setregChairName] = useState("");
  const [formA, setformA] = useState("");
  const [formB, setformB] = useState("");
  const [duePending, setduePending] = useState("");
  const [instaFollowers, setinstaFollowers] = useState(null);
  const [fbFollowers, setfbFollowers] = useState("");
  const [ytFollowers, setytFollowers] = useState("");
  const [subEvent1, setsubEvent1] = useState("");
  const [subEvent2, setsubEvent2] = useState("");
  const [subEvent3, setsubEvent3] = useState("");
  const [subEvent4, setsubEvent4] = useState("");
  const [subEvent5, setsubEvent5] = useState("");
  const [subEvent6, setsubEvent6] = useState("");
  const [subEvent7, setsubEvent7] = useState("");
  const [subEvent8, setsubEvent8] = useState("");
  const [subEvent9, setsubEvent9] = useState("");
  const [subEvent10, setsubEvent10] = useState("");
  const [picEvent1, setpicEvent1] = useState(null);
  const [picEvent2, setpicEvent2] = useState(null);
  const [picEvent3, setpicEvent3] = useState(null);
  const [picEvent4, setpicEvent4] = useState(null);
  const [picEvent5, setpicEvent5] = useState(null);
  const [picEvent6, setpicEvent6] = useState(null);
  const [picEvent7, setpicEvent7] = useState(null);
  const [picEvent8, setpicEvent8] = useState(null);
  const [picEvent9, setpicEvent9] = useState(null);
  const [picEvent10, setpicEvent10] = useState(null);
  const [numMembers, setnumMembers] = useState(null);
  const [regEmails, setRegEmails] = useState("");
  const [grpEmails, setGrpEmails] = useState("");
  // const [regConvenor, setRegConvenor] = useState("");

  const navigate = useNavigate();

  const fetchRegEmails = async () => {
    const chairmanResponse = await axios.get(`api/data/${regName}/region-ob/emails/`);
    const chairmanEmail = chairmanResponse.data
      .filter((item) => item.post === "chairman")
      .map((item) => item.email);
    setRegEmails(chairmanEmail);

    const convenorResponse = await axios.get(`api/data/eminence/region-convenor/emails/`);
    const convenorEmail = convenorResponse.data
      .filter(
        (item) =>
          item.post ===
            `${regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()} Region Convenor` ||
          item.post ===
            `${regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()} Region Coordinator`
      )
      .map((item) => item.email);
    const chairmanName = chairmanResponse.data
      .filter((item) => item.post === "chairman")
      .map((item) => item.name);

    // setRegConvenor(convenorEmail);
    setregChairName(chairmanName);
  };

  useEffect(() => {
    fetchRegEmails();
  }, [regName]);

  const fetchGrpEmails = async () => {
    const grpResponse = await axios.get(`api/data/${grpName}/group/emails/`);
    const res = grpResponse?.data[0];
    setGrpEmails(res.presEmail + "," + res.secEmail);
    setgrpPresName(res.presName);
  };

  useEffect(() => {
    fetchGrpEmails();
  }, [grpName]);

  async function FormSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    let formField = new FormData();

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,office@jsgif.co.in,dj22jsgif@gmail.com,dsmehta8842@gmail.com," +
      email +
      "," +
      grpEmails +
      "," +
      regEmails;
    // "," +
    // regConvenor;

    console.log(finalEmail);

    if ((!regName || !grpName || !formA || !formB || !duePending || !email, !subEvent1)) {
      alert(
        "Please Submit your Region Name, Group Name, FormA, FormB, No Due Certificate and atleast 1 Event"
      );
      setSubmitting(false);
      return;
    }

    formField.append("user", user?.user_id);
    formField.append("regName", regName);
    formField.append("email", email);
    formField.append("grpName", grpName);
    formField.append("grpPresName", grpPresName);
    formField.append("regChairName", regChairName);
    formField.append("name", name);
    formField.append("formA", formA);
    formField.append("formB", formB);
    formField.append("duePending", duePending);
    formField.append("instaFollowers", instaFollowers);
    formField.append("fbFollowers", fbFollowers);
    formField.append("ytFollowers", ytFollowers);
    formField.append("subEvent1", subEvent1);
    formField.append("subEvent2", subEvent2);
    formField.append("subEvent3", subEvent3);
    formField.append("subEvent4", subEvent4);
    formField.append("subEvent5", subEvent5);
    formField.append("subEvent6", subEvent6);
    formField.append("subEvent7", subEvent7);
    formField.append("subEvent8", subEvent8);
    formField.append("subEvent9", subEvent9);
    formField.append("subEvent10", subEvent10);

    if (picEvent1 !== null) {
      formField.append("picEvent1", picEvent1);
    }
    if (picEvent2 !== null) {
      formField.append("picEvent2", picEvent2);
    }
    if (picEvent3 !== null) {
      formField.append("picEvent3", picEvent3);
    }
    if (picEvent4 !== null) {
      formField.append("picEvent4", picEvent4);
    }
    if (picEvent5 !== null) {
      formField.append("picEvent5", picEvent5);
    }
    if (picEvent6 !== null) {
      formField.append("picEvent6", picEvent6);
    }
    if (picEvent7 !== null) {
      formField.append("picEvent7", picEvent7);
    }
    if (picEvent8 !== null) {
      formField.append("picEvent8", picEvent8);
    }
    if (picEvent9 !== null) {
      formField.append("picEvent9", picEvent9);
    }
    if (picEvent10 !== null) {
      formField.append("picEvent10", picEvent10);
    }
    formField.append("numMembers", numMembers);
    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/forms/ventures/eminence-q1-groups/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        navigate(`/eminence/q1/group/response/${response.data.id}`, { replace: true });
      })
      .catch(function(error) {
        console.log(error);
        alert("We encountered an error processing your request. Please try again later");
      });

    setSubmitting(false);
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get(`/user/region/`);
    setRegions(response.data);
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups/`);
    setGroups(response.data);
  };
  useEffect(() => {
    fetchGroups();
  }, [regName]);

  const [sgnGroups, setSgnGroups] = useState([]);
  const fetchSgnGroups = async () => {
    const response = await axios.get(`/user/${regName}/sangini/`);
    setSgnGroups(response.data);
  };
  useEffect(() => {
    fetchSgnGroups();
  }, [regName]);

  return (
    <div>
      {/* <SecurePageSection
        accessableUsers={"admin,region,group,former,intDirector,offBearer,eminence,eminenceChair,sangini,sanginiGroup"}
      /> */}
      <SecurePageSection accessableUsers={"admin,eminenceChair"} />
      <Container>
        <Form>
          <Row>
            <Col lg={8}>
              <h2>Eminence 1st Quarter Application (Groups and Sangini)</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  name="name"
                  value={name}
                  placeholder="Respondant's Full name"
                  onChange={(e) => setname(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Email</Form.Label>
                <Form.Control
                  name="email"
                  value={email}
                  placeholder="Respondant's Email ID"
                  onChange={(e) => setemail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Name</Form.Label>
                <Form.Select
                  name="regName"
                  value={regName}
                  onChange={(e) => setRegName(e.target.value)}
                >
                  <option>Select your Region</option>
                  {regions.map((region, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() + region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            {(() => {
              if (
                user.accessGroup === "admin" ||
                user.accessGroup === "group" ||
                user.accessGroup === "intDirector" ||
                user.accessGroup === "offBearer" ||
                user.accessGroup === "former" ||
                user.accessGroup === "region"
              ) {
                return (
                  <Col lg={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Group Name</Form.Label>
                      <Form.Select
                        name="grpName"
                        value={grpName}
                        onChange={(e) => setGrpName(e.target.value)}
                      >
                        <option>Select your Group</option>
                        {groups.map((group, index) => (
                          <option
                            key={index}
                            style={{ textTransform: "capitalize" }}
                            value={group.group.toLowerCase()}
                          >
                            {group.group.charAt(0).toUpperCase() +
                              group.group.slice(1).toLowerCase()}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                );
              } else if (
                user.accessGroup === "admin" ||
                user.accessGroup === "sangini" ||
                user.accessGroup === "sanginiGroup"
              ) {
                return (
                  <Col lg={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Sangini Group Name</Form.Label>
                      <Form.Select
                        name="grpName"
                        value={grpName}
                        onChange={(e) => setGrpName(e.target.value)}
                      >
                        <option>Select your Group</option>
                        {sgnGroups.map((group, index) => (
                          <option
                            key={index}
                            style={{ textTransform: "capitalize" }}
                            value={group.group.toLowerCase()}
                          >
                            {group.group.charAt(0).toUpperCase() +
                              group.group.slice(1).toLowerCase()}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                );
              }
            })()}
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Chairman Name</Form.Label>
                <Form.Control
                  style={{ textTransform: "capitalize" }}
                  name="regChairName"
                  value={regChairName}
                  placeholder="Region Chairman's Full name"
                  onChange={(e) => setregChairName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Group President Name</Form.Label>
                <Form.Control
                  style={{ textTransform: "capitalize" }}
                  name="grpPresName"
                  value={grpPresName}
                  placeholder="Group President's Full name"
                  onChange={(e) => setgrpPresName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {/*   <Row>
            <h5 style={{ textAlign: "center", margin: "0", marginTop: "15px" }}>
              (Collect your Group's No Due Confirmation from the Region Chairman)
            </h5>
          </Row>
           <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Copy of Form A</Form.Label>
                <Form.Control
                  name="formA"
                  type="file"
                  placeholder="Respondant's Full name"
                  onChange={(e) => setformA(e.target.files[0])}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Copy of Form B</Form.Label>
                <Form.Control name="formB" type="file" onChange={(e) => setformB(e.target.files[0])} />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Dues Pending Confirmation</Form.Label>
                <Form.Control type="file" name="duePending" onChange={(e) => setduePending(e.target.files[0])} />
              </Form.Group>
            </Col>
          </Row> */}

          <Row>
            <h5 style={{ textAlign: "center", margin: "0", marginTop: "15px" }}>
              (Upload the names of the JSGians from your group that follow JSGIF's Social Media
              Handles. Seperate each name with a Comma)
            </h5>
          </Row>

          <Row className="mb-3">
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Instagram Followers</Form.Label>
                <Form.Control
                  as="textarea"
                  name="instaFollowers"
                  value={instaFollowers}
                  rows="3"
                  placeholder="Example - Number of followers from our group - 3
                  Names - XYZ, ABC, PQR"
                  onChange={(e) => setinstaFollowers(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Facebook Followers</Form.Label>
                <Form.Control
                  as="textarea"
                  name="fbFollowers"
                  value={fbFollowers}
                  rows="3"
                  placeholder="Example - Number of followers from our group - 5
                  Names - XYZ, ABC, PQR, KLM, LMN"
                  onChange={(e) => setfbFollowers(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label>Youtube Subscribers</Form.Label>
                <Form.Control
                  as="textarea"
                  name="ytFollowers"
                  value={ytFollowers}
                  rows="3"
                  placeholder="Example - Number of subscribers from our group - 1
                  Names -  ABC"
                  onChange={(e) => setytFollowers(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Average Members in each meeting</Form.Label>
                <Form.Control
                  name="numMembers"
                  value={numMembers}
                  rows="3"
                  placeholder="(Average = Total members present in all meetings / Number of events held)"
                  onChange={(e) => setnumMembers(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Event 1</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Subject 1</Form.Label>
                    <Form.Control
                      name="subEvent1"
                      value={subEvent1}
                      placeholder="Subject of Event 1"
                      onChange={(e) => setsubEvent1(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Photo 1</Form.Label>
                    <Form.Control
                      name="picEvent1"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent1(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col md={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Event 2</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Subject 2</Form.Label>
                    <Form.Control
                      name="subEvent2"
                      value={subEvent2}
                      placeholder="Subject of Event 2"
                      onChange={(e) => setsubEvent2(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Photo 2</Form.Label>
                    <Form.Control
                      name="picEvent2"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent2(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Event 3</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Subject 3</Form.Label>
                    <Form.Control
                      name="subEvent3"
                      value={subEvent3}
                      placeholder="Subject of Event 3"
                      onChange={(e) => setsubEvent3(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Photo 3</Form.Label>
                    <Form.Control
                      name="picEvent3"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent3(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col md={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Event 4</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Subject 4</Form.Label>
                    <Form.Control
                      name="subEvent4"
                      value={subEvent4}
                      placeholder="Subject of Event 4"
                      onChange={(e) => setsubEvent4(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Photo 4</Form.Label>
                    <Form.Control
                      name="picEvent4"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent4(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Event 5</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Subject 5</Form.Label>
                    <Form.Control
                      name="subEvent5"
                      value={subEvent5}
                      placeholder="Subject of Event 5"
                      onChange={(e) => setsubEvent5(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Photo 5</Form.Label>
                    <Form.Control
                      name="picEvent5"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent5(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col md={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Event 6</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Subject 6</Form.Label>
                    <Form.Control
                      name="subEvent6"
                      value={subEvent6}
                      placeholder="Subject of Event 6"
                      onChange={(e) => setsubEvent6(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Photo 6</Form.Label>
                    <Form.Control
                      name="picEvent6"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent6(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Event 7</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Subject 7</Form.Label>
                    <Form.Control
                      name="subEvent7"
                      value={subEvent7}
                      placeholder="Subject of Event 7"
                      onChange={(e) => setsubEvent7(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Photo 7</Form.Label>
                    <Form.Control
                      name="picEvent7"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent7(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col md={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Event 8</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Subject 8</Form.Label>
                    <Form.Control
                      name="subEvent8"
                      value={subEvent8}
                      placeholder="Subject of Event 8"
                      onChange={(e) => setsubEvent8(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Photo 8</Form.Label>
                    <Form.Control
                      name="picEvent8"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent8(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Event 9</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Subject 9</Form.Label>
                    <Form.Control
                      name="subEvent9"
                      value={subEvent9}
                      placeholder="Subject of Event 9"
                      onChange={(e) => setsubEvent9(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Photo 9</Form.Label>
                    <Form.Control
                      name="picEvent9"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent9(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col md={6} xs={12}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="formSectionHeader" style={{ marginTop: "30px", width: "97%" }}>
                    <h4 style={{ textAlign: "center" }}>Event 10</h4>
                  </div>
                </div>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Subject 10</Form.Label>
                    <Form.Control
                      name="subEvent10"
                      value={subEvent10}
                      placeholder="Subject of Event 10"
                      onChange={(e) => setsubEvent10(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Event Photo 10</Form.Label>
                    <Form.Control
                      name="picEvent10"
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => setpicEvent10(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>}
            >
              <Button className="btn--outline--dark" disabled>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button className="btn--outline--dark" onClick={(e) => FormSubmit(e)}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default GrpQ1Form;
