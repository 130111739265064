import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav, NavDropdown, Row, Col } from "react-bootstrap";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../_actions";
import Images from "../images/index";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";

function JsgNavbar() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get("/user/region");

    setRegions(response.data.sort((a, b) => a.region.localeCompare(b.region)));
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get("/user/group");

    setGroups(response.data);
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const signoutSession = () => {
    sessionStorage.removeItem("user");
    localStorage.removeItem("user");
    dispatch(userActions.login({}));
  };

  const navigate = useNavigate();

  return (
    <div>
      <Navbar
        fixed="top"
        collapseOnSelect
        className="navbarBg"
        expand="lg"
        bg="dark"
        variant="dark"
      >
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                alt=""
                src={Images.Logo}
                width="75"
                height="75"
                className="d-inline-block align-top brandImage"
              />{" "}
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav navbarScroll className="ms-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/mann-ki-baat">
                <Nav.Link>Mann Ki Baat</Nav.Link>
              </LinkContainer>
              <NavDropdown title="About Us" id="collasible-nav-dropdown">
                <LinkContainer to="/aboutus">
                  <NavDropdown.Item>About JSGIF</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/testimonials">
                  <NavDropdown.Item>Testimonials</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/media-coverages">
                  <NavDropdown.Item>Media Coverages</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <NavDropdown title="Federation" id="collasible-nav-dropdown">
                <NavDropdown.Header>JSGIF</NavDropdown.Header>

                <LinkContainer to="/founders">
                  <NavDropdown.Item>JSGIF Founders</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/former-presidents">
                  <NavDropdown.Item>JSGIF Former Presidents</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/fed/ob/2023-25">
                  <NavDropdown.Item>JSGIF Office Bearers</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/fed/id/2023-25">
                  <NavDropdown.Item>
                    JSGIF International Directors
                  </NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/reg/chairmen/2023-25">
                  <NavDropdown.Item>JSGIF Region Chairmen</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/fed/committee/2023-25">
                  <NavDropdown.Item>JSGIF Committee Chairmen</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/constitution-index">
                  <NavDropdown.Item>JSGIF Constitution</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/oath-of-office">
                  <NavDropdown.Item>Oath of Office</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/fed/ob-projects">
                  <NavDropdown.Item>
                    JSGIF Office Bearers Projects
                  </NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/fed/events">
                  <NavDropdown.Item>Events</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/mangalyatra">
                  <NavDropdown.Item>Mangalyatra</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/mangalyatra/mahiladivas-video">
                  <NavDropdown.Item>
                    Video Mahiladivas Mangalyatra
                  </NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/kartavya">
                  <NavDropdown.Item>Kartavya</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/samvid">
                  <NavDropdown.Item>Samvid</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/anmol">
                  <NavDropdown.Item>Anmol</NavDropdown.Item>
                </LinkContainer>

                <NavDropdown.Header>Downloads</NavDropdown.Header>

                <LinkContainer to="/logo-downloads">
                  <NavDropdown.Item>Download Logos</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/theme-song">
                  <NavDropdown.Item>JSGIF Theme Song</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/navkar-mahamantra">
                  <NavDropdown.Item>Navakar Mahamantra</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/fed/zoom-background">
                  <NavDropdown.Item>Zoom Background</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/banner-download/group">
                  <NavDropdown.Item>Group Banner</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/banner-download/region">
                  <NavDropdown.Item>Region Banner</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title="Region" id="collasible-nav-dropdown">
                <NavDropdown.Header>Regions</NavDropdown.Header>
                {regions.map((region) => (
                  <LinkContainer
                    to={`/region/${region.region.toLowerCase()}`}
                    key={region.id}
                  >
                    <NavDropdown.Item key={region.id}>
                      <span style={{ textTransform: "Capitalize" }}>
                        {region.region}
                      </span>
                    </NavDropdown.Item>
                  </LinkContainer>
                ))}
              </NavDropdown>

              <NavDropdown
                title="Forms"
                align={{ lg: "end" }}
                id="collasible-nav-dropdown"
              >
                <Row
                  className="customDropdown1"
                  style={{ borderLeft: "#face1d 1px solid" }}
                >
                  {(() => {
                    if (
                      typeof user !== "undefined" &&
                      typeof user.username !== "undefined"
                    ) {
                      return (
                        <>
                          {(() => {
                            if (
                              user.accessGroup === "admin" ||
                              user.accessGroup === "offBearer" ||
                              user.accessGroup === "intDirector" ||
                              user.accessGroup === "former"
                            ) {
                              return (
                                <Col
                                  xs={12}
                                  md={4}
                                  className="customDropdownItem1"
                                >
                                  <div>
                                    <NavDropdown.Header>
                                      Federation
                                    </NavDropdown.Header>
                                    <LinkContainer to="/fed-agm">
                                      <NavDropdown.Item>AGM</NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/fed-ccm">
                                      <NavDropdown.Item>CCM</NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/fed-idmeet">
                                      <NavDropdown.Item>
                                        ID Meet
                                      </NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/fed-obmeet">
                                      <NavDropdown.Item>
                                        OB Meet
                                      </NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/fed-aer">
                                      <NavDropdown.Item>
                                        After Event Report
                                      </NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/form/fed-nomination-id">
                                      <NavDropdown.Item>
                                        ID Nomination Form
                                      </NavDropdown.Item>
                                    </LinkContainer>
                                  </div>
                                </Col>
                              );
                            }
                          })()}

                          {(() => {
                            if (
                              user.accessGroup === "admin" ||
                              user.accessGroup === "region"
                            ) {
                              return (
                                <Col
                                  xs={12}
                                  md={4}
                                  className="customDropdownItem1"
                                >
                                  <div>
                                    <NavDropdown.Header>
                                      Region
                                    </NavDropdown.Header>
                                    <LinkContainer to="/reg-agm">
                                      <NavDropdown.Item>AGM</NavDropdown.Item>
                                    </LinkContainer>

                                    <LinkContainer to="/reg-obmeet">
                                      <NavDropdown.Item>
                                        OB Meet
                                      </NavDropdown.Item>
                                    </LinkContainer>

                                    <LinkContainer to="/reg-committee-meet">
                                      <NavDropdown.Item>
                                        Committee Meet
                                      </NavDropdown.Item>
                                    </LinkContainer>

                                    <LinkContainer to="/form/reg-nomination-ob">
                                      <NavDropdown.Item>
                                        OB Nomination Form
                                      </NavDropdown.Item>
                                    </LinkContainer>
                                    {(() => {
                                      if (
                                        user.username === "JSG0018" ||
                                        user.username === "JSG0017" ||
                                        user.username === "JSG0016" ||
                                        user.accessGroup === "admin"
                                      ) {
                                        return (
                                          <LinkContainer to="/reg-mangalyatra">
                                            <NavDropdown.Item>
                                              Mangalyatra Reports
                                            </NavDropdown.Item>
                                          </LinkContainer>
                                        );
                                      }
                                    })()}

                                    {(() => {
                                      if (
                                        user.accessGroup === "admin" ||
                                        user.accessGroup === "region"
                                      ) {
                                        return (
                                          <LinkContainer to="/form/reg-mangalyatra">
                                            <NavDropdown.Item>
                                              Mangalyatra Reporting
                                            </NavDropdown.Item>
                                          </LinkContainer>
                                        );
                                      }
                                    })()}
                                  </div>
                                </Col>
                              );
                            }
                          })()}
                        </>
                      );
                    }
                  })()}

                  <Col xs={12} md={4} className="customDropdownItem1">
                    <div>
                      <NavDropdown.Header>Sangini</NavDropdown.Header>
                      {(() => {
                        if (
                          user?.accessGroup === "admin" ||
                          user?.accessGroup === "sangini" ||
                          user?.accessGroup === "sanginiGroup"
                        ) {
                          return (
                            <LinkContainer to="/sgn-committee-meet">
                              <NavDropdown.Item>
                                Committee Meet
                              </NavDropdown.Item>
                            </LinkContainer>
                          );
                        }
                      })()}
                      {(() => {
                        if (
                          user?.accessGroup === "admin" ||
                          user?.accessGroup === "sangini" ||
                          user?.accessGroup === "sanginiGroup"
                        ) {
                          return (
                            <LinkContainer to="/sgn-forum-registraion">
                              <NavDropdown.Item>
                                Forum Registraion
                              </NavDropdown.Item>
                            </LinkContainer>
                          );
                        }
                      })()}
                      <LinkContainer to="/sgn-membership-form">
                        <NavDropdown.Item>Membership Form</NavDropdown.Item>
                      </LinkContainer>

                      {(() => {
                        if (
                          user?.username === "JSG0018" ||
                          user?.username === "JSG0017" ||
                          user?.username === "JSG0016" ||
                          user?.accessGroup === "admin"
                        ) {
                          return (
                            <LinkContainer to="/sgn-mangalyatra">
                              <NavDropdown.Item>
                                Mangalyatra Reports
                              </NavDropdown.Item>
                            </LinkContainer>
                          );
                        }
                      })()}

                      {(() => {
                        if (
                          user?.accessGroup === "admin" ||
                          user?.accessGroup === "sangini" ||
                          user?.accessGroup === "sanginiGroup"
                        ) {
                          return (
                            <LinkContainer to="/form/sgn-mangalyatra">
                              <NavDropdown.Item>
                                Mangalyatra Reporting
                              </NavDropdown.Item>
                            </LinkContainer>
                          );
                        }
                      })()}

                      {(() => {
                        if (
                          user?.accessGroup === "admin" ||
                          user?.accessGroup === "sangini" ||
                          user?.accessGroup === "sanginiGroup"
                        ) {
                          return (
                            <LinkContainer to="/sgn-form-a">
                              <NavDropdown.Item>
                                Sangini Form A
                              </NavDropdown.Item>
                            </LinkContainer>
                          );
                        }
                      })()}
                      {(() => {
                        if (
                          user?.accessGroup === "admin" ||
                          user?.accessGroup === "sangini" ||
                          user?.accessGroup === "sanginiGroup"
                        ) {
                          return (
                            <LinkContainer to="/sgn-form-b">
                              <NavDropdown.Item>
                                Sangini Form B
                              </NavDropdown.Item>
                            </LinkContainer>
                          );
                        }
                      })()}
                    </div>
                  </Col>

                  <Col xs={12} md={4} className="customDropdownItem1">
                    <div>
                      <NavDropdown.Header>Group</NavDropdown.Header>
                      {(() => {
                        if (
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "admin") ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "group")
                        ) {
                          return (
                            <LinkContainer to="/grpagm">
                              <NavDropdown.Item>AGM</NavDropdown.Item>
                            </LinkContainer>
                          );
                        }
                      })()}
                      {(() => {
                        if (
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "admin") ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "group")
                        ) {
                          return (
                            <LinkContainer to="/grpegm">
                              <NavDropdown.Item>EGM</NavDropdown.Item>
                            </LinkContainer>
                          );
                        }
                      })()}
                      {(() => {
                        if (
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "admin") ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "group")
                        ) {
                          return (
                            <LinkContainer to="/grpcommitteemeet">
                              <NavDropdown.Item>
                                Committee Meet
                              </NavDropdown.Item>
                            </LinkContainer>
                          );
                        }
                      })()}
                      {(() => {
                        if (
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "admin") ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "group")
                        ) {
                          return (
                            <LinkContainer to="/grp-forum-registraion">
                              <NavDropdown.Item>
                                Group Registration
                              </NavDropdown.Item>
                            </LinkContainer>
                          );
                        }
                      })()}

                      <LinkContainer to="/grp-membership-form">
                        <NavDropdown.Item>Membership Form</NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to="/grp-form-a">
                        <NavDropdown.Item>Form A</NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to="/grp-form-b">
                        <NavDropdown.Item>Form B</NavDropdown.Item>
                      </LinkContainer>
                      {(() => {
                        if (
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "admin") ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.username === "JSG0018") ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.username === "JSG0017") ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.username === "JSG0016")
                        ) {
                          return (
                            <LinkContainer to="/grp-mangalyatra">
                              <NavDropdown.Item>
                                Mangalyatra Reports
                              </NavDropdown.Item>
                            </LinkContainer>
                          );
                        }
                      })()}
                      {(() => {
                        if (
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "admin") ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "group") ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "intDirector") ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "offBearer") ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "former") ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.accessGroup === "region") ||
                          (typeof user !== "undefined" &&
                            typeof user.username !== "undefined" &&
                            user.username === "JSG0143")
                        ) {
                          return (
                            <LinkContainer to="/form/grp-mangalyatra">
                              <NavDropdown.Item>
                                Mangalyatra Reporting
                              </NavDropdown.Item>
                            </LinkContainer>
                          );
                        }
                      })()}
                    </div>
                  </Col>

                  <Col xs={12} md={4} className="customDropdownItem1">
                    {/* <div>
                      <NavDropdown.Header>JSGFF</NavDropdown.Header>
                      <LinkContainer to="/form/jsgff-education-loan">
                        <NavDropdown.Item>Education Loan Form</NavDropdown.Item>
                      </LinkContainer>
                    </div> */}
                  </Col>

                  <Col xs={12} md={4} className="customDropdownItem1">
                    {/* <div>
                      <NavDropdown.Header>MBS</NavDropdown.Header>
                      <LinkContainer to="/form/jsgff-education-loan">
                        <NavDropdown.Item>Education Loan Form</NavDropdown.Item>
                      </LinkContainer>
                    </div> */}
                  </Col>
                </Row>

                <Row>
                  <Col
                    xs={6}
                    style={{
                      textAlign: "center",
                      borderRight: "1px solid #face1d",
                    }}
                  >
                    <LinkContainer to="/fed/bank-details">
                      <NavDropdown.Item>JSGIF Bank Details</NavDropdown.Item>
                    </LinkContainer>
                  </Col>
                  <Col xs={6} style={{ textAlign: "center" }}>
                    <LinkContainer to="/tutorials">
                      <NavDropdown.Item>Tutorial Videos</NavDropdown.Item>
                    </LinkContainer>
                  </Col>
                </Row>
              </NavDropdown>

              <NavDropdown title="Sangini" id="collasible-nav-dropdown">
                <LinkContainer to="/sgn/committee/2023-25">
                  <NavDropdown.Item href="">
                    Sangini Committee Members
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/sgn/events">
                  <NavDropdown.Item>Sangini Events</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/zoom-background/sangini">
                  <NavDropdown.Item>Sangini Zoom Background</NavDropdown.Item>
                </LinkContainer>

                <NavDropdown.Header>Sangini Regions</NavDropdown.Header>

                <LinkContainer to="/sangini/region/bombay">
                  <NavDropdown.Item>Bombay</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/sangini/region/gujarat">
                  <NavDropdown.Item>Gujarat</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/sangini/region/indore">
                  <NavDropdown.Item>Indore</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/sangini/region/m.p">
                  <NavDropdown.Item>M.P</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/sangini/region/maharashtra">
                  <NavDropdown.Item>Maharashtra</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/sangini/region/mewar">
                  <NavDropdown.Item>Mewar</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/sangini/region/northern">
                  <NavDropdown.Item>Northern</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/sangini/region/saurashtra">
                  <NavDropdown.Item>Saurashtra</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/sangini/region/south">
                  <NavDropdown.Item>South</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title="Ventures" id="collasible-nav-dropdown">
                <LinkContainer to="/ventures/aashray">
                  <NavDropdown.Item>JSGIF Aashray</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/ventures/ask-jsg">
                  <NavDropdown.Item>JSGIF Ask JSG</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/ventures/constitution-&-bylaws">
                  <NavDropdown.Item>
                    JSGIF Constitution & Bylaws
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/ventures/eminence">
                  <NavDropdown.Item>JSGIF Eminence</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/ventures/gurukul">
                  <NavDropdown.Item>JSGIF Gurukul</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/ventures/pari">
                  <NavDropdown.Item>JSGIF Pari</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/ventures/runbhoomi">
                  <NavDropdown.Item>JSGIF Runbhoomi</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/ventures/strot">
                  <NavDropdown.Item>JSGIF Strot</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/ventures/udaan">
                  <NavDropdown.Item>JSGIF Udaan</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              {(() => {
                if (
                  typeof user !== "undefined" &&
                  typeof user.username !== "undefined"
                ) {
                  return (
                    <LinkContainer to="/">
                      <Nav.Link onClick={signoutSession}>Sign Out</Nav.Link>
                    </LinkContainer>
                  );
                } else
                  return (
                    <LinkContainer to="/login">
                      <Nav.Link>Sign In</Nav.Link>
                    </LinkContainer>
                  );
              })()}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default JsgNavbar;
